import React from 'react';
import { Card, Typography } from '@material-ui/core';

type Props = { title: string };

const Section: React.FC<Props> = ({ title, children }) => {
    return (
        <Card style={{ padding: '.5rem .5rem 1rem .5rem', width: '100%', marginTop: '1rem' }}>
            <Typography style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>{title}</Typography>
            {children}
        </Card>
    );
};

export default Section;
