import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router';

export type Hospital = {
    id: number;
    name: string;
    slug: string;
    domains: Domain[];
};

export type Domain = {
    name: string;
    types: Type[];
};

export type Type = {
    name: string;
    code: string;
};

export type Role = {
    name: string;
    isHospitalDependent: boolean;
    isAdmin: boolean;
    isReadonly: boolean;
    hospital?: Hospital;
};

type User = {
    id: number;
    name: string;
    email: string;
    roles: Role[];
};

type AuthContextValue = {
    isAuthenticated: boolean;
    user: User | null;
    onSignedIn: (user: User, redirectUri?: string) => void;
    onSignedOut: () => void;
};

const AuthContext = React.createContext<AuthContextValue>({} as AuthContextValue);

export const AuthContextProvider: React.FC = ({ children }) => {
    const history = useHistory();
    const [user, setUser] = useState<User | null>(null);

    const value = useMemo(
        () => ({
            user,
            isAuthenticated: !!user,
            onSignedIn: (user: User, redirectUrl?: string) => {
                setUser(user);
                redirectUrl && setTimeout(() => history.push(redirectUrl));
            },
            onSignedOut: () => {
                setUser(null);
                history.push('/');
            },
        }),
        [user, history]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
