import React from 'react';
import LinkCallout from './LinkCallout';

type Props = {
    email: string;
    link: string;
};

const ForgotLinkCallout: React.FC<Props> = ({ email, link }) => (
    <LinkCallout link={link}>
        To reset his/her password, <strong>{email}</strong> must use the <strong>following link below.</strong>
        <br />
        This link is <strong>valid for 1 day</strong>, after which you will have to generate a new link.
    </LinkCallout>
);

export default ForgotLinkCallout;
