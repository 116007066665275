import React from 'react';
import { ManualValues, Country, Fund } from '../EditPatient.config';
import { Grid, Paper, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Section from '../../../../../styles/components/forms/Section';
import SaveIcon from '@material-ui/icons/Save';
import Identification from '../../form/sections/manual/Identification';
import Address from '../../form/sections/manual/Address';
import PersonalInformation from '../../form/sections/manual/PersonalInformation';
import Insurance from '../../form/sections/manual/Insurance';
import { validateForm } from '../../form/PatientForm.config';
import styles from '../import/EditPatientFormImport.module.scss';
import OtherMedicalInfo from '../../form/sections/manual/OtherMedicalInfo';
import PatientDetailBackButton from '../../detail/backbutton/PatientDetailBackButton';
type EditPatientFormManualProps = {
    initialValues: ManualValues;
    countries: Country[] | null;
    funds: Fund[] | null;
    onSubmit: (values: ManualValues) => void;
    onClose: () => void;
    hospitalName: string;
};
export default function EditPatientFormManual(props: EditPatientFormManualProps) {
    return (
        <Formik
            initialValues={props.initialValues}
            initialTouched={{
                ssn: true,
                uzaNr: true,
                firstName: true,
                surname: true,
                address: true,
                postalCode: true,
                city: true,
                healthInsuranceFundNr: true,
                healthInsuranceFundId: true,
                countryId: true,
                gender: true,
                dateOfBirth: true,
                dateOfDeath: true,
                localPatientNr: true,
                telNr: true,
                mail: true,
                homeDoctorName: true,
                homeDoctorContactInfo: true,
                attendingDoctorName: true,
                attendingDoctorContactInfo: true,
            }}
            enableReinitialize
            onSubmit={props.onSubmit}
            validationSchema={validateForm(props.funds, null)}
            validateOnMount
        >
            {({ isSubmitting, isValid }) => (
                <Form>
                    <Paper>
                        <Section name="Identification">
                            <Grid container spacing={3}>
                                <Identification isNewPatient={false} patientNumbers={null} />
                            </Grid>
                        </Section>
                        <Section name="Personal Information">
                            <Grid container spacing={3}>
                                <PersonalInformation />
                            </Grid>
                        </Section>
                        <Section name="Address">
                            <Grid container spacing={3}>
                                <Address countries={props.countries} />
                            </Grid>
                        </Section>
                        <Section name="Insurance">
                            <Grid container spacing={3}>
                                <Insurance funds={props.funds} />
                            </Grid>
                        </Section>
                        <Section name="Other Medical Info">
                            <Grid container spacing={3}>
                                <OtherMedicalInfo />
                            </Grid>
                        </Section>
                    </Paper>
                    <div style={{ display: 'flex' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={styles.submitButton}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting || !isValid}
                        >
                            {'Update & close'}
                        </Button>
                        <div className={styles.submitButton}>
                            <PatientDetailBackButton isContained={false} onGoToPatientDetail={props.onClose} />
                        </div>
                        {isSubmitting && (
                            <CircularProgress size="1.4rem" style={{ marginLeft: '1rem', marginTop: '1rem' }} />
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
}
