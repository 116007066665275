import React from 'react';
import { Grid } from '@material-ui/core';
import MocSectionContainer from '../MocSectionContainer';
import {
    CheckBoxes,
    FastDateField,
    FastSelectField,
    CheckboxField,
    FormikErrorMessage,
    FastTextField,
} from '../../components';
import { Mocs } from '../../../../../../api/types';
import {
    AdjuvantRadiotherapyTreatment,
    AdjuvantChemotherapyTreatment,
    AdjuvantImmunotherapyTreatment,
} from './AdjuvantTherapy.config';
import styles from './AdjuvantTherapy.module.scss';
import { Values } from '../../MocForm.config';
import { useFormikContext } from 'formik';
import { useConstant, useEmptyArray, useResetOnValue } from '../useResetEffect';

type RadiotherapyType = Mocs.GetReferenceData.Moc_Ref_RadiotherapyType;
type ChemotherapyType = Mocs.GetReferenceData.Moc_Ref_ChemotherapyType;

type Props = {
    isValid: boolean | null;
};

const AdjuvantTherapy: React.FC<Props> = React.memo(({ isValid }) => {
    const { values } = useFormikContext<Values>();
    const { adjuvantTherapy } = values;

    const isAdjuvantRadiotherapySelected = adjuvantTherapy.adjuvantRadiotherapy;
    const isAdjuvantChemotherapySelected = adjuvantTherapy.adjuvantChemotherapy;
    const isAdjuvantImmunotherapySelected = adjuvantTherapy.adjuvantImmunotherapy;
    const isAdjuvantOtherSelected = adjuvantTherapy.adjuvantOther;

    useResetOnValue(
        'adjuvantTherapy.adjuvantRadiotherapy',
        false,
        useConstant('adjuvantTherapy.adjuvantRadiotherapyType', ''),
        useEmptyArray('adjuvantTherapy.adjuvantRadiotherapyTreatments')
    );

    useResetOnValue(
        'adjuvantTherapy.adjuvantChemotherapy',
        false,
        useConstant('adjuvantTherapy.adjuvantChemotherapyType', ''),
        useConstant('adjuvantTherapy.adjuvantChemotherapyTypeOther', ''),
        useEmptyArray('adjuvantTherapy.adjuvantChemotherapyTreatments')
    );

    useResetOnValue(
        'adjuvantTherapy.adjuvantImmunotherapy',
        false,
        useConstant('adjuvantTherapy.adjuvantImmunotherapyType', ''),
        useConstant('adjuvantTherapy.adjuvantImmunotherapyTypeOther', ''),
        useEmptyArray('adjuvantTherapy.adjuvantImmunotherapyTreatments')
    );

    useResetOnValue('adjuvantTherapy.adjuvantOther', false, useConstant('adjuvantTherapy.adjuvantOtherValue', ''));

    return (
        <MocSectionContainer code="adjuvant-therapy" isValid={isValid}>
            <Grid container>
                <Grid item xs={12}>
                    <CheckboxField name="adjuvantTherapy.adjuvantRadiotherapy" label="Radiotherapie" />
                    {isAdjuvantRadiotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FastSelectField<RadiotherapyType>
                                        name="adjuvantTherapy.adjuvantRadiotherapyType"
                                        placeholder="Radiotherapie Type*"
                                        prompt="Selecteer radiotherapie type"
                                        referenceDataPath="radiotherapyTypes"
                                    />
                                    <FormikErrorMessage name="adjuvantTherapy.adjuvantRadiotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckBoxes<RadiotherapyType, AdjuvantRadiotherapyTreatment>
                                        referenceDataPath="radiotherapyTreatmentTypes"
                                        name="adjuvantTherapy.adjuvantRadiotherapyTreatments"
                                        create={(reference) => ({
                                            code: reference.code,
                                            gray: '',
                                            startDate: null,
                                            endDate: null,
                                        })}
                                        detail={(_, _value, treatmentValuePath) => {
                                            return (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <FastTextField
                                                            name={`${treatmentValuePath}.gray`}
                                                            label="Gray*"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.startDate`}
                                                            label="Start datum*"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.endDate`}
                                                            label="Eind datum"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="adjuvantTherapy.adjuvantChemotherapy" label="Chemotherapie" />
                    {isAdjuvantChemotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FastSelectField<ChemotherapyType>
                                        name="adjuvantTherapy.adjuvantChemotherapyType"
                                        placeholder="Chemotherapie Type*"
                                        referenceDataFilter={(refValue) => refValue.isOther}
                                        prompt="Selecteer chemotherapie type"
                                        detail={({ isOther }) =>
                                            isOther && (
                                                <FastTextField
                                                    name="adjuvantTherapy.adjuvantChemotherapyTypeOther"
                                                    label="Ander type*"
                                                />
                                            )
                                        }
                                        referenceDataPath="chemotherapyTypes"
                                    />
                                    <FormikErrorMessage name="adjuvantTherapy.adjuvantChemotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckBoxes<ChemotherapyType, AdjuvantChemotherapyTreatment>
                                        referenceDataPath="radiotherapyTreatmentTypes"
                                        name="adjuvantTherapy.adjuvantChemotherapyTreatments"
                                        create={(reference) => ({
                                            code: reference.code,
                                            startDate: null,
                                            endDate: null,
                                        })}
                                        detail={(_, _value, treatmentValuePath) => {
                                            return (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.startDate`}
                                                            label="Start datum*"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.endDate`}
                                                            label="Eind datum"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="adjuvantTherapy.adjuvantImmunotherapy" label="Immuuntherapie" />
                    {isAdjuvantImmunotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FastSelectField<ChemotherapyType>
                                        name="adjuvantTherapy.adjuvantImmunotherapyType"
                                        placeholder="Immuuntherapie Type*"
                                        referenceDataFilter={(refValue) => refValue.isOther}
                                        prompt="Selecteer immuuntherapie type"
                                        detail={({ isOther }) =>
                                            isOther && (
                                                <FastTextField
                                                    name="adjuvantTherapy.adjuvantImmunotherapyTypeOther"
                                                    label="Ander type*"
                                                />
                                            )
                                        }
                                        referenceDataPath="chemotherapyTypes"
                                    />
                                    <FormikErrorMessage name="adjuvantTherapy.adjuvantImmunotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <CheckBoxes<ChemotherapyType, AdjuvantImmunotherapyTreatment>
                                        referenceDataPath="radiotherapyTreatmentTypes"
                                        name="adjuvantTherapy.adjuvantImmunotherapyTreatments"
                                        create={(reference) => ({
                                            code: reference.code,
                                            startDate: null,
                                            endDate: null,
                                        })}
                                        detail={(_, _value, treatmentValuePath) => {
                                            return (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.startDate`}
                                                            label="Start datum*"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FastDateField
                                                            name={`${treatmentValuePath}.endDate`}
                                                            label="Eind datum"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="adjuvantTherapy.adjuvantOther" label="Andere" />
                    {isAdjuvantOtherSelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container>
                                <Grid item xs={12}>
                                    <FastTextField name="adjuvantTherapy.adjuvantOtherValue" label="Andere" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default AdjuvantTherapy;
