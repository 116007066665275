import { Mocs } from '../../../../../../api/types';
import { toValuesString } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.GetMoc.NetMocData & Mocs.GetMoc.LocalMocData,
    | 'localTreatmentPlan'
    | 'netMocTreatmentPlan'
    | 'eligibleForAStudy'
    | 'concerningStudy'
    | 'treatmentPlans'
    | 'netMocTreatmentPlans'
    | 'tumorEvolution'
    | 'treatmentStrategy'
>;
export type SaveMocValues = Pick<
    Mocs.SaveMoc.NetMocFields & Mocs.SaveMoc.LocalMocFields,
    | 'localTreatmentPlan'
    | 'netMocTreatmentPlan'
    | 'eligibleForAStudy'
    | 'concerningStudy'
    | 'treatmentPlans'
    | 'netMocTreatmentPlans'
    | 'tumorEvolution'
    | 'treatmentStrategy'
>;
export type TreatmentPlan = {
    id: number;
    code: string;
    name: string;
    group: string;
    color: string;
};
export type FormValues = {
    localTreatmentPlan: string;
    netMocTreatmentPlan: string;
    eligibleForAStudy: boolean;
    concerningStudy: string;
    tumorEvolution: string;
    treatmentStrategy: string;
    treatmentPlans: TreatmentPlan[];
    netMocTreatmentPlans: TreatmentPlan[];
};
export const getNewMocValues = (): GetMocValues => ({
    localTreatmentPlan: '',
    netMocTreatmentPlan: '',
    eligibleForAStudy: false,
    concerningStudy: '',
    tumorEvolution: '',
    treatmentStrategy: '',
    treatmentPlans: [],
    netMocTreatmentPlans: [],
});
export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    localTreatmentPlan: form.localTreatmentPlan,
    netMocTreatmentPlan: form.netMocTreatmentPlan,
    eligibleForAStudy: form.eligibleForAStudy,
    concerningStudy: form.concerningStudy,
    tumorEvolution: form.tumorEvolution,
    treatmentStrategy: form.treatmentStrategy,
    treatmentPlans: mapTreatmentPlans(form.treatmentPlans),
    netMocTreatmentPlans: mapTreatmentPlans(form.netMocTreatmentPlans),
});

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    localTreatmentPlan: toValuesString(moc.localTreatmentPlan),
    netMocTreatmentPlan: toValuesString(moc.netMocTreatmentPlan),
    eligibleForAStudy: moc.eligibleForAStudy,
    concerningStudy: toValuesString(moc.concerningStudy),
    tumorEvolution: toValuesString(moc.tumorEvolution),
    treatmentStrategy: toValuesString(moc.treatmentStrategy),
    treatmentPlans: moc.treatmentPlans
        .sort((x, y) => x.sequence - y.sequence)
        .map((t, index) => ({
            id: index,
            code: t.treatmentPlanCode,
            name: '',
            group: '',
            color: '',
        })),
    netMocTreatmentPlans: moc.netMocTreatmentPlans
        .sort((x, y) => x.sequence - y.sequence)
        .map((t, index) => ({
            id: index,
            code: t.treatmentPlanCode,
            name: '',
            group: '',
            color: '',
        })),
});
export const initialValues: FormValues = mapInitialValues(getNewMocValues());

function mapTreatmentPlans(treatmentPlans: TreatmentPlan[]) {
    return treatmentPlans.map((t, key) => ({ treatmentPlanCode: t.code, sequence: key }));
}
