import React, { Fragment } from 'react';
import * as clinicalBiology from '../../../../forms/sections/clinicalBiology/ClinicalBiology.config';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { ReferenceDataMapper } from '../..';
import { TypeAndDateAndValues, QuestionMocAnswer } from '../Questions';
import List, { MainSubItem } from '../List';

type ClinicalBiologyProps = {
    values: clinicalBiology.FormValues;
    mapReferenceData: ReferenceDataMapper;
};
export default function ClinicalBiology(props: ClinicalBiologyProps) {
    const { ...valuesToCheck } = props.values;
    const isEmpty = JSON.stringify(valuesToCheck) === JSON.stringify(clinicalBiology.initialValues);

    return isEmpty ? (
        <QuestionMocAnswer question="Labo-onderzoeken (klinische biologie)" answer="/" />
    ) : (
        <View>
            <Text style={styles.mocSubHeader}> Labo-onderzoeken (klinische biologie)</Text>
            <List>
                {props.values.clinicalBiologies.map((item, key) => (
                    <Fragment key={key}>
                        <MainSubItem>
                            <TypeAndDateAndValues
                                type={
                                    item.typeOther
                                        ? `${props.mapReferenceData('enetsTypes', item.typeCode)} - ${item.typeOther}`
                                        : props.mapReferenceData('enetsTypes', item.typeCode)
                                }
                                date={item.date}
                                value={item.value}
                                unit={props.mapReferenceData('enetsUnits', item.unitCode)}
                                unitOther={item.unitOther}
                                comments={item.additionalInfo}
                            />
                        </MainSubItem>
                    </Fragment>
                ))}
            </List>
        </View>
    );
}
