import MocTypeCode, { CKZPancreas, CKZOesophagus, NETMOC, LocalMOC, CKZSarcomen } from '../../MocTypeCode';

export const GeneralInformation = 'general-information';
export const Annex55 = 'annex55';
export const PatientCharacteristics = 'patient-characteristics';
export const Surgery = 'surgery';
export const PostSurgery = 'post-surgery';
export const GeneralRemarks = 'general-remarks';
export const Report = 'report';
export const Imaging = 'imaging';
export const ClinicalBiology = 'clinical-biology';
export const Pathology = 'pathology';
export const TreatmentPlan = 'treatment-plan';
export const Information = 'information';
export const Chirurgie = 'chirurgie';
export const NeoAdjuvantTreatment = 'neo-adjuvant-treatment';
export const ImagingDimensions = 'imaging-dimensions';
export const PathologyFnclcc = 'pathology-fnclcc';
export const CkzSarcomenTreatmentPlan = 'ckzsarcomen-treatment-plan';
export const CkzSarcomenAnnex55 = 'ckzsarcomen-annex55';
export const AdjuvantTherapy = 'adjuvant-therapy';

type MocSectionCode =
    | typeof GeneralInformation
    | typeof Annex55
    | typeof PatientCharacteristics
    | typeof Surgery
    | typeof PostSurgery
    | typeof GeneralRemarks
    | typeof Report
    | typeof Imaging
    | typeof ClinicalBiology
    | typeof Pathology
    | typeof TreatmentPlan
    | typeof Information
    | typeof Chirurgie
    | typeof NeoAdjuvantTreatment
    | typeof ImagingDimensions
    | typeof PathologyFnclcc
    | typeof CkzSarcomenTreatmentPlan
    | typeof CkzSarcomenAnnex55
    | typeof AdjuvantTherapy;

type MocTypeCodeToMocSectionCodes = { [K in MocTypeCode]: MocSectionCode[] };
type MocSectionCodeToName = { [K in MocSectionCode]: string };
type MocSectionCodeToIsValidatable = { [K in MocSectionCode]: boolean };

const MocTypeSectionCodes: MocTypeCodeToMocSectionCodes = {
    [CKZPancreas]: [GeneralInformation, Annex55, PatientCharacteristics, Surgery, PostSurgery, GeneralRemarks],
    [CKZOesophagus]: [GeneralInformation, Annex55, PatientCharacteristics, Surgery, PostSurgery, GeneralRemarks],
    [NETMOC]: [Report, Imaging, Pathology, ClinicalBiology, TreatmentPlan, GeneralRemarks],
    [LocalMOC]: [Report, Imaging, Pathology, ClinicalBiology, TreatmentPlan, GeneralRemarks],
    [CKZSarcomen]: [
        Information,
        NeoAdjuvantTreatment,
        Chirurgie,
        ImagingDimensions,
        PathologyFnclcc,
        AdjuvantTherapy,
        CkzSarcomenTreatmentPlan,
        CkzSarcomenAnnex55,
        GeneralRemarks,
    ],
};

const MocSectionNames: MocSectionCodeToName = {
    [GeneralInformation]: 'General Information',
    [Annex55]: 'Annex 55',
    [PatientCharacteristics]: 'Patient Characteristics',
    [Surgery]: 'Surgery',
    [PostSurgery]: 'Post Surgery',
    [GeneralRemarks]: 'General Remarks',
    [Report]: 'Verslag',
    [Imaging]: 'Beeldvorming',
    [ClinicalBiology]: 'Klinische Biologie',
    [Pathology]: 'Pathologie',
    [TreatmentPlan]: 'Behandelplan',
    [Information]: 'Algemeen',
    [Chirurgie]: 'Chirurgie',
    [NeoAdjuvantTreatment]: '(Neo-)adjuvante behandeling',
    [ImagingDimensions]: 'Beeldvorming',
    [PathologyFnclcc]: 'Pathologie',
    [CkzSarcomenTreatmentPlan]: 'Behandelplan',
    [CkzSarcomenAnnex55]: 'Annex 55',
    [AdjuvantTherapy]: 'Adjuvante behandeling',
};

const MocSectionIsValidatable: MocSectionCodeToIsValidatable = {
    [GeneralInformation]: true,
    [Annex55]: true,
    [PatientCharacteristics]: true,
    [Surgery]: true,
    [PostSurgery]: true,
    [GeneralRemarks]: false,
    [Report]: true,
    [Imaging]: true,
    [ClinicalBiology]: true,
    [Pathology]: true,
    [TreatmentPlan]: false,
    [Information]: true,
    [Chirurgie]: true,
    [NeoAdjuvantTreatment]: true,
    [AdjuvantTherapy]: true,
    [ImagingDimensions]: true,
    [PathologyFnclcc]: true,
    [CkzSarcomenTreatmentPlan]: true,
    [CkzSarcomenAnnex55]: false,
};

type MocSectionValues = {
    generalInformation: {
        hadSurgery: boolean | '';
        lesionToTreatCode: string;
    };
};

export function getSectionCodes(mocTypeCode: MocTypeCode, mocValues: MocSectionValues): MocSectionCode[] {
    const without = (excluded: MocSectionCode[]) => (value: MocSectionCode) => !excluded.includes(value);

    switch (mocTypeCode) {
        case 'CKZPancreas':
        case 'CKZOesophagus': {
            const all = mocSectionCodesFor(mocTypeCode);
            const filteredSections = GetExcludedSections();
            return all.filter(without(filteredSections));
        }
        case 'NETMOC':
        case 'LocalMOC': {
            return mocSectionCodesFor(mocTypeCode);
        }
        case 'CKZSarcomen': {
            return mocSectionCodesFor(mocTypeCode);
        }
        default:
            return [];
    }

    function GetExcludedSections() {
        const hadSurgery = mocValues.generalInformation.hadSurgery === true;
        const hasValidLesionToTreat = mocValues.generalInformation.lesionToTreatCode !== '';

        const hadSurgeryFilter: MocSectionCode[] = hadSurgery ? [] : [PatientCharacteristics, Surgery, PostSurgery];
        const hasValidLesionToTreatFilter: MocSectionCode[] = hasValidLesionToTreat ? [] : [Annex55];

        return [...hadSurgeryFilter, ...hasValidLesionToTreatFilter];
    }
}

export const mocSectionCodesFor = (type: MocTypeCode) => MocTypeSectionCodes[type];
export const mocSectionNameFor = (section: MocSectionCode) => MocSectionNames[section];
export const elementAnchorIdFor = (section: MocSectionCode) => `mocsection-${section}-anchor`;
export const mocSectionIsValidatableFor = (section: MocSectionCode) => MocSectionIsValidatable[section];

export default MocSectionCode;
