import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import { FastSelectField, FastTextField } from '../../components';
import { Mocs } from '../../../../../../api/types';
import BiopsyDateList from '../../enets/BiopsyDateList';
import CytologyDateList from '../../enets/CytologyDateList';
import ResectionDateList from '../../enets/ResectionDateList';

type WhoGrade = Mocs.GetReferenceData.Moc_Ref_WhoGrade;

type Props = {
    isValid: boolean | null;
};

export default function Pathology(props: Props) {
    return (
        <MocSectionContainer code="pathology" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FastSelectField<WhoGrade>
                        name="pathology.whoGradeCode"
                        placeholder="WHO gradering"
                        prompt="Selecteer WHO gradering"
                        referenceDataPath="whoGrades"
                        detail={({ isOther }) =>
                            isOther && <FastTextField name="pathology.whoGradeOther" label="Type" />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.ptCode"
                        placeholder="pT"
                        prompt="Selecteer pT waarde"
                        referenceDataPath="ptTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.pnCode"
                        placeholder="pN"
                        prompt="Selecteer pN waarde"
                        referenceDataPath="pnTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.pmCode"
                        placeholder="pM"
                        prompt="Selecteer pM waarde"
                        referenceDataPath="pmTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.yptCode"
                        placeholder="ypT"
                        prompt="Selecteer ypT waarde"
                        referenceDataPath="yptTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.ypnCode"
                        placeholder="ypN"
                        prompt="Selecteer ypN waarde"
                        referenceDataPath="ypnTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathology.ypmCode"
                        placeholder="ypM"
                        prompt="Selecteer ypM waarde"
                        referenceDataPath="ypmTypes"
                    />
                </Grid>
                <Grid item xs={12}>
                    <BiopsyDateList
                        name="pathology"
                        referenceTypeDataFilter={(refVal) => refVal.isEnets}
                        referenceValueDataFilter={(refVal) => refVal.isEnets}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CytologyDateList />
                </Grid>
                <Grid item xs={12}>
                    <ResectionDateList
                        name="pathology"
                        referenceTypeDataFilter={(refVal) => refVal.isEnets}
                        referenceValueDataFilter={(refValue) => refValue.isEnets}
                    />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
