import React from 'react';
import { useLocation } from 'react-router';
import { Typography, Avatar, Grid, Paper } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { parse } from 'qs';
import useAuth from '../../auth/useAuth';
import SignInForm, { User } from './signin/SignInForm';
import styles from './HomePage.module.scss';
import WelcomeContent from './WelcomeContent';

function parseSearch(search: string) {
    return parse(search, { ignoreQueryPrefix: true });
}

const HomePage: React.FC = () => {
    const { onSignedIn } = useAuth();
    const { search } = useLocation();

    const handleSignIn = (user: User) => {
        const { redirectUrl } = parseSearch(search);
        onSignedIn(user, typeof redirectUrl === 'string' ? redirectUrl : undefined);
    };

    return (
        <Grid container component="main" className={styles.main}>
            <Grid item xs={false} sm={6} md={7} lg={8} xl={9}>
                <WelcomeContent />
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3} className={styles.signin_griditem}>
                <Paper elevation={4} square className={styles.signin_paper}>
                    <div className={styles.signin_wrapper}>
                        <Avatar className={styles.avatar} color="primary">
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in to OncoBase
                        </Typography>
                        <SignInForm onSignedIn={handleSignIn} />
                        {/* <Link to="/passwordreset">
                        <Typography variant="body2">Forgot your password?</Typography>
                    </Link> */}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default HomePage;
