import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Select, MenuItem, Hidden } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import styles from './HospitalPicker.module.scss';

export type Hospital = {
    id: number;
    name: string;
    slug: string;
    domains: Domain[];
};

export type Domain = {
    name: string;
    types: Type[];
};

export type Type = {
    name: string;
    code: string;
};

type Props = {
    hospital: Hospital;
    hospitals: Hospital[];
    onChange: (hospital: Hospital) => void;
};

function getValue(hospital: Hospital) {
    return hospital.slug;
}

function getHospital(value: unknown, hospitals: Hospital[]) {
    return hospitals.filter((h) => h.slug === value)[0];
}

const HospitalName: React.FC<Hospital> = ({ name }) => (
    <Typography variant="h5" className={styles.name}>
        <Hidden smDown>{name}</Hidden>
        <Hidden mdUp>
            <LocalHospitalIcon />
        </Hidden>
    </Typography>
);

const HospitalPicker: React.FC<Props> = ({ hospital, hospitals, onChange }) => {
    if (hospitals.length === 1) {
        return <HospitalName {...hospital} />;
    }

    const value = getValue(hospital);
    const handleChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
        onChange(getHospital(e.target.value, hospitals));

    return (
        <Select
            value={value}
            onChange={handleChange}
            disableUnderline
            renderValue={(v) => <HospitalName {...getHospital(v, hospitals)} />}
            inputProps={{ classes: { icon: styles.icon } }}
            MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
            }}
        >
            {hospitals.map((h, idx) => (
                <MenuItem key={idx} value={h.slug}>
                    {h.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default HospitalPicker;
