import React, { useState } from 'react';
import { Snackbar, IconButton, makeStyles, SnackbarContent } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { green } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
    message: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    close: {
        padding: theme.spacing(0.5)
    },
    success: {
        backgroundColor: green[300]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    }
}));

type SnackVariant = 'success' | 'error';
export type ShowSnack = (message: string, variant?: SnackVariant) => void;

const icons: { [K in SnackVariant]: React.ComponentType<SvgIconProps> } = {
    success: SuccessIcon,
    error: ErrorIcon
};

type Props = {
    open: boolean;
    onClose: () => void;
    message: string;
    variant?: SnackVariant;
};

const OncoBaseSnackbar: React.FC<Props> = ({ open, onClose, message, variant = 'success' }) => {
    const classes = useStyles();
    const Icon = icons[variant];

    const handleClose = (_: React.SyntheticEvent<any, Event>, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
        >
            <SnackbarContent
                className={classes[variant]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={classes.icon} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                ]}
            />
        </Snackbar>
    );
};

export function useSnackBar(): [ShowSnack, Props] {
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState<SnackVariant>();
    const show: ShowSnack = (message, variant) => {
        setMessage(message);
        setVariant(variant);
    };
    const handleClose = () => setMessage('');
    const props: Props = {
        open: !!message,
        onClose: handleClose,
        message,
        variant
    };
    return [show, props];
}

export default OncoBaseSnackbar;
