import React from 'react';
import cx from 'classnames';
import { FormControlLabel, Radio, Typography } from '@material-ui/core';
import { FormikBooleanRadioGroup } from '../../../../../styles/components/forms/FormikBooleanRadioGroup';
import useFieldValue from '../sections/useFieldValue';
import Question from './Question';
import { Language, getTranslator } from './language';
import styles from './YesNoQuestion.module.scss';
import { Field, useFormikContext } from 'formik';

type Variant = 'yes' | 'no' | 'both';

type Props = {
    name: string;
    question: string;
    lang?: Language;
    inline?: boolean;
    reverse?: boolean;
    renderYes?: () => React.ReactNode;
    renderNo?: () => React.ReactNode;
    variant?: Variant;
};

// "Active" means the configured variant is selected

const YesNoQuestion: React.FC<Props> = ({
    name,
    question,
    lang = 'en',
    inline = false,
    reverse = false,
    variant = 'yes',
    renderYes,
    renderNo,
    children,
}) => {
    const translate = getTranslator(lang);
    const value = useFieldValue<boolean | ''>(name);
    const isYesActive = value === true && (variant === 'yes' || variant === 'both');
    const isNoActive = value === false && (variant === 'no' || variant === 'both');
    const isActive = isYesActive || isNoActive;
    const { isSubmitting } = useFormikContext();

    const noActiveContent = isNoActive && ((renderNo && renderNo()) || children);
    const yesActiveContent = isYesActive && ((renderYes && renderYes()) || children);

    if (inline && (renderYes || renderNo)) {
        console.error("Cannot combine 'inline' with 'renderYes' or 'renderNo' props");
    }

    return (
        <Question question={question} children-inline={inline}>
            <div
                className={cx(styles.content, {
                    [styles.active]: isActive,
                    [styles.has_children]: !!children,
                })}
            >
                {!inline && <div className={styles.line}></div>}
                <Field
                    name={name}
                    component={FormikBooleanRadioGroup}
                    className={cx(styles.radio_group, { [styles.inline]: inline, [styles.reverse]: reverse })}
                >
                    <div className={cx(styles.radio_option, { [styles.active]: isNoActive })}>
                        <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            className={styles.radio_option_label}
                            disabled={isSubmitting}
                            label={
                                <Typography variant="body1" className={styles.radio_option_label_text}>
                                    {translate('no')}
                                </Typography>
                            }
                        />
                        {noActiveContent}
                    </div>
                    <div className={cx(styles.radio_option, { [styles.active]: isYesActive && !isSubmitting })}>
                        <FormControlLabel
                            value={true}
                            control={<Radio color="primary" />}
                            className={styles.radio_option_label}
                            disabled={isSubmitting}
                            label={
                                <Typography variant="body1" className={styles.radio_option_label_text}>
                                    {translate('yes')}
                                </Typography>
                            }
                        />
                        {yesActiveContent}
                    </div>
                </Field>
            </div>
        </Question>
    );
};

export default YesNoQuestion;
