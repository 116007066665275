import { Mocs } from '../../../../../../api/types';
import { toMocNumber, toValuesNumber, toValuesString } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.GetMoc.LocalMocData & Mocs.GetMoc.NetMocData,
    | 'whoGradeCode'
    | 'whoGradeOther'
    | 'ptCode'
    | 'pmCode'
    | 'pnCode'
    | 'yptCode'
    | 'ypmCode'
    | 'ypnCode'
    | 'biopsyDates'
    | 'cytologyDates'
    | 'resectionDates'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.LocalMocFields & Mocs.SaveMoc.NetMocFields,
    | 'whoGradeCode'
    | 'whoGradeOther'
    | 'ptCode'
    | 'pmCode'
    | 'pnCode'
    | 'yptCode'
    | 'ypmCode'
    | 'ypnCode'
    | 'biopsyDates'
    | 'cytologyDates'
    | 'resectionDates'
>;

export type FormValues = {
    whoGradeCode: string;
    whoGradeOther: string;
    ptCode: string;
    pnCode: string;
    pmCode: string;
    yptCode: string;
    ypnCode: string;
    ypmCode: string;
    biopsyDates: BiopsyDate[];
    cytologyDates: CytologyDate[];
    resectionDates: ResectionDate[];
};

export type BiopsyDate = {
    date: string | null;
    locationCode: string;
    comment: string;
    biopsyTypes: BiopsyType[];
    fileId: number | null;
};

export type BiopsyType = {
    valueCode: string;
    valueOther: number | '';
    typeCode: string;
    typeOther: string;
    unit: string;
    info: string;
};

export type CytologyDate = {
    date: string | null;
    locationCode: string;
    comment: string;
    cytologyTypes: CytologyType[];
    fileId: number | null;
};

export type CytologyType = {
    valueCode: string;
    valueOther: number | '';
    typeCode: string;
    typeOther: string;
    unit: string;
    info: string;
};

export type ResectionDate = {
    date: string | null;
    locationCode: string;
    kindCode: string;
    comment: string;
    resectionTypes: ResectionType[];
    fileId: number | null;
};

export type ResectionType = {
    typeCode: string;
    typeOther: string;
    valueCode: string;
    valueOther: number | '';
    unit: string;
    info: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    whoGradeCode: toValuesString(moc.whoGradeCode),
    whoGradeOther: toValuesString(moc.whoGradeOther),
    ptCode: toValuesString(moc.ptCode),
    pmCode: toValuesString(moc.pmCode),
    pnCode: toValuesString(moc.pnCode),
    yptCode: toValuesString(moc.yptCode),
    ypmCode: toValuesString(moc.ypmCode),
    ypnCode: toValuesString(moc.ypnCode),
    biopsyDates: moc.biopsyDates.map(
        biopsyDate => ({...biopsyDate, biopsyTypes: biopsyDate.biopsyTypes.map(
            biopsyType => ({...biopsyType, valueOther: toValuesNumber(biopsyType.valueOther)}))})),
    resectionDates: moc.resectionDates.map(
        resectionDate => ({...resectionDate, resectionTypes: resectionDate.resectionTypes.map(
            resectionType => ({...resectionType, valueOther: toValuesNumber(resectionType.valueOther)}))})),
    cytologyDates: moc.cytologyDates.map(
        cytologyDate => ({...cytologyDate, cytologyTypes: cytologyDate.cytologyTypes.map(
            cytologyType => ({...cytologyType, valueOther: toValuesNumber(cytologyType.valueOther)}))})),
});

export const getNewMocValues = (): GetMocValues => ({
    whoGradeCode: '',
    whoGradeOther: '',
    ptCode: '',
    pnCode: '',
    pmCode: '',
    yptCode: '',
    ypnCode: '',
    ypmCode: '',
    biopsyDates: [],
    resectionDates: [],
    cytologyDates: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    whoGradeCode: form.whoGradeCode,
    whoGradeOther: form.whoGradeOther,
    ptCode: form.ptCode,
    pnCode: form.pnCode,
    pmCode: form.pmCode,
    yptCode: form.yptCode,
    ypnCode: form.ypnCode,
    ypmCode: form.ypmCode,
    biopsyDates: form.biopsyDates.map(
        biopsyDate => ({...biopsyDate, 
            biopsyTypes: biopsyDate.biopsyTypes.map(biopsyType => ({...biopsyType, valueOther: toMocNumber(biopsyType.valueOther)}))})),
    resectionDates: form.resectionDates.map(
        resectionDate => ({...resectionDate, 
            resectionTypes: resectionDate.resectionTypes.map(resectionType => ({...resectionType, valueOther: toMocNumber(resectionType.valueOther)}))})),
    cytologyDates: form.cytologyDates.map(
        cytologyDate => ({...cytologyDate, 
            cytologyTypes: cytologyDate.cytologyTypes.map(cytologyType => ({...cytologyType, valueOther: toMocNumber(cytologyType.valueOther)}))})),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
