import React, { useState } from 'react';
import MaterialTable from 'material-table';
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    IconButton,
    AccordionDetails,
    Button,
    Backdrop,
    CircularProgress,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './FileOverview.module.scss';
import { format, parseJSON } from 'date-fns';
import { Patients } from '../../../api/types';

type File = Patients.GetPatient.File;

type Props = {
    files: File[];
    loading: boolean;
    onNewFile: () => void;
    onDownloadFile: (fileId: number) => void;
    onRemoveFile: (fileId: number) => void;
};

const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd MMM yyyy') : '');

const FileOverview: React.FC<Props> = ({ files, onNewFile, onDownloadFile, loading, onRemoveFile }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandedChange = (e: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded);
    };

    return (
        <Accordion
            square
            expanded={expanded}
            onChange={handleExpandedChange}
            classes={{ root: styles.expansion_panel, expanded: styles.expansion_panel_expanded }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="patient-details-content"
                id="patient-details-header"
                classes={{ root: styles.summary, content: styles.summary_content, expanded: styles.summary_expanded }}
            >
                <div className={styles.flex_container}>
                    <Typography variant="overline" className={styles.label}>
                        FILES
                    </Typography>
                    {!expanded && (
                        <Typography variant="body1" className={styles.label_filecount}>
                            {files.length > 0
                                ? '(' + files.length + (files.length === 1 ? ' file)' : ' files)')
                                : 'No files yet'}
                        </Typography>
                    )}
                </div>
                <IconButton
                    color="primary"
                    onClick={(event) => {
                        onNewFile();
                        event.stopPropagation();
                    }}
                    classes={{ root: styles.add_icon_button }}
                >
                    <AddIcon />
                </IconButton>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container>
                    <Grid item xs={12} style={{ position: 'relative' }}>
                        {files.length === 0 && <div style={{ fontStyle: 'italic' }}>No files yet</div>}
                        {files.length > 0 && (
                            <MaterialTable<File>
                                style={{ maxHeight: '330px', overflowY: 'auto' }}
                                columns={[
                                    { title: 'Id', field: 'id', hidden: true },
                                    {
                                        title: 'Filename',
                                        field: 'name',
                                        render: (rowData) => (
                                            <span>
                                                <Button color="primary" onClick={() => onDownloadFile(rowData.id)}>
                                                    {rowData.name}
                                                </Button>
                                            </span>
                                        ),
                                    },
                                    {
                                        title: 'Document type',
                                        field: 'patientFileTypeName',
                                    },
                                    {
                                        title: 'Upload date',
                                        field: 'uploadDate',
                                        render: (data) => showDate(data.uploadDate),
                                    },
                                    {
                                        title: 'Uploaded by',
                                        field: 'uploadUserName',
                                    },
                                    {
                                        title: 'MOC type',
                                        field: 'mocTypeName',
                                    },
                                    {
                                        title: 'MC date',
                                        field: 'mocDate',
                                        render: (data) => showDate(data.mocDate),
                                    },
                                    {
                                        title: '',
                                        field: 'delete',
                                        cellStyle: {
                                            padding: '6px',
                                        },
                                        render: (data) => (
                                            <IconButton
                                                color="primary"
                                                onClick={() => onRemoveFile(data!.id)}
                                                className={styles.edit_icon_button}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        ),
                                    },
                                ]}
                                data={files}
                                options={{
                                    sorting: true,
                                    search: false,
                                    pageSize: 5,
                                    toolbar: false,
                                    paging: false,
                                    thirdSortClick: false,
                                    headerStyle: { zIndex: 0 },
                                }}
                                localization={{ body: { emptyDataSourceMessage: 'No files found' } }}
                            />
                        )}
                        <Backdrop open={loading} className={styles.backdrop}>
                            <div className={styles.backdrop_content}>
                                <CircularProgress color="inherit" />
                                <Typography className={styles.backdrop_text}>Loading patient files...</Typography>
                            </div>
                        </Backdrop>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

export default FileOverview;
