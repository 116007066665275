import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router';
import HomePage from './home/HomePage';
import AccountConfirmationPage from './home/account/confirmation/AccountConfirmationPage';
import ForgotPasswordPage from './home/account/forgot/ForgotPasswordPage';

const UnauthenticatedApp: React.FC = () => {
    const location = useLocation();
    return (
        <Switch>
            <Route path="/" exact component={HomePage} />
            <Route path="/account/confirm" component={AccountConfirmationPage} />
            <Route path="/account/forgot" component={ForgotPasswordPage} />
            <Redirect to={encodeURI(`/?redirectUrl=${location.pathname}`)} />
        </Switch>
    );
};

export default UnauthenticatedApp;
