import React, { useState, useEffect } from 'react';
import styles from './ReportPage.module.css';
import { Paper, Typography, Container, Select, MenuItem, Grid, FormControlLabel, Radio } from '@material-ui/core';
import useApi from '../../api/useApi';
import qs from 'qs';
import OncoBaseSnackbar, { useSnackBar } from '../../styles/components/OncoBaseSnackbar';
import { format, addDays, startOfDay } from 'date-fns';
import { Mocs } from '../../api/types';
import ExportTable from '../export/ExportTable';
import { KeyboardDatePicker, MaterialUiPickersDate } from '@material-ui/pickers';

type Response = Mocs.Export.Response;
type MocDetail = Mocs.Export.MocDetail;

const mocTypes = ['CKZPancreas', 'CKZOesophagus'];

function ReportPage() {
    const api = useApi();
    const [showSnack, snackbarProps] = useSnackBar();
    const [isExporting, setIsExporting] = useState(false);

    const [mocDetails, setMocDetails] = useState<MocDetail[]>();

    const [mocType, setMocType] = useState<string>('empty');
    const [hadSurgery, setHadSurgery] = useState<'yes' | 'no' | 'nvt'>('nvt');
    const [startDateSurgery, setStartDateSurgery] = useState<MaterialUiPickersDate>(null);
    const [endDateSurgery, setEndDateSurgery] = useState<MaterialUiPickersDate>(null);
    const [startDateMoc, setStartDateMoc] = useState<MaterialUiPickersDate>(null);
    const [endDateMoc, setEndDateMoc] = useState<MaterialUiPickersDate>(null);

    const isValidRequest = mocType !== null && mocType !== 'empty';
    const isValidDownloadRequest =
        isValidRequest && mocDetails !== undefined && mocDetails!.findIndex((m) => m.export) !== -1;

    useEffect(() => {
        if (mocType !== 'empty') {
            getMocDetails();
        }
        // eslint-disable-next-line
    }, [mocType, hadSurgery, startDateSurgery, endDateSurgery, startDateMoc, endDateMoc]);

    function handleMocTypeChanged(event: React.ChangeEvent<{ value: unknown }>) {
        if (event.target.value !== undefined) {
            setMocType(event.target.value as string);
        } else {
            setMocType('empty');
        }
    }

    function handleHadSurgeryChanged(newvalue: 'yes' | 'no' | 'nvt') {
        setHadSurgery(newvalue);
    }

    async function handleDownloadFile() {
        setIsExporting(true);

        try {
            const mocIdsToExport = mocDetails!.filter((m) => m.export).map((m) => m.id);
            const downloadKey = await createFileToDownload(mocIdsToExport);
            await getFileToDownload(downloadKey);
            await getMocDetails();
        } catch {
            showSnack("Couldn't download export file", 'error');
        }

        setIsExporting(false);
    }

    async function createFileToDownload(mocIdsToExport: number[]) {
        const { data, status } = await api.post<Mocs.CreateExportReport.Response>(`mocs/report/download`, {
            mocTypeCode: mocType,
            mocIdsToExport: mocIdsToExport,
            excelSheetName: `ComplexSurgery_${mocType.replace('CKZ', '')}`,
        } as Mocs.CreateExportReport.Request);

        if (status >= 400) {
            throw new Error();
        }

        return data.downloadKey;
    }

    async function getFileToDownload(downloadKey: string) {
        const urlGet = `mocs/export/download?${qs.stringify({
            excelFileName: `ComplexSurgery_${mocType.replace('CKZ', '')}_${downloadKey}.xlsx`,
            KeyFileToDownload: `${downloadKey}`,
        })}`;

        var response = await api.get(urlGet, { responseType: 'blob' });
        if (response.status >= 400) {
            throw new Error();
        }

        downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
    }

    function downLoadFile(data: any, type: string, filename: string) {
        const blob = new Blob([data], { type });

        //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
        }
    }

    async function getMocDetails() {
        const url = `mocs/report?${qs.stringify({
            mocTypeCode: mocType,
            hadSurgery: hadSurgery !== 'nvt' ? hadSurgery === 'yes' : null,
            surgeryEndDate: !endDateSurgery ? null : format(startOfDay(addDays(endDateSurgery, 1)), 'yyyy/MM/dd'),
            surgeryStartDate: !startDateSurgery ? null : format(startOfDay(startDateSurgery), 'yyyy/MM/dd'),
            mocEndDate: !endDateMoc ? null : format(startOfDay(addDays(endDateMoc, 1)), 'yyyy/MM/dd'),
            mocStartDate: !startDateMoc ? null : format(startOfDay(startDateMoc), 'yyyy/MM/dd'),
        })}`;
        const { data } = await api.get<Response>(url);
        setMocDetails(data.details);
    }

    function handleCheckExport(id: number, checked: boolean) {
        const updatedMocDetails = [...mocDetails!];
        const checkedMocIndex = mocDetails!.findIndex((m) => m.id === id);
        updatedMocDetails![checkedMocIndex].export = checked;
        setMocDetails(updatedMocDetails);
    }

    return (
        <Container component="main">
            <Paper className={styles.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Simulate export CKZ Data</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={5}>
                            <Select
                                value={mocType}
                                fullWidth={true}
                                variant="outlined"
                                onChange={handleMocTypeChanged}
                                disabled={isExporting}
                            >
                                <MenuItem key="empty" value="empty" disabled>
                                    <i>Please select a CKZ type</i>
                                </MenuItem>
                                {mocTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2}>
                                <Typography>Had surgery</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    value="nvt"
                                    checked={hadSurgery === 'nvt'}
                                    control={<Radio />}
                                    label="Nvt"
                                    onChange={() => handleHadSurgeryChanged('nvt')}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    value="yes"
                                    checked={hadSurgery === 'yes'}
                                    control={<Radio />}
                                    label="Yes"
                                    onChange={() => handleHadSurgeryChanged('yes')}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <FormControlLabel
                                    value="no"
                                    checked={hadSurgery === 'no'}
                                    control={<Radio />}
                                    label="No"
                                    onChange={() => handleHadSurgeryChanged('no')}
                                />
                            </Grid>
                            <Grid item xs={7}></Grid>

                            <Grid item xs={2}>
                                <Typography>Start period surgery</Typography>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    value={startDateSurgery}
                                    onChange={(date) => setStartDateSurgery(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>End period surgery</Typography>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    value={endDateSurgery}
                                    onChange={(date) => setEndDateSurgery(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>

                            <Grid item xs={2}>
                                <Typography>Start period moc</Typography>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    value={startDateMoc}
                                    onChange={(date) => setStartDateMoc(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography>End period moc</Typography>
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    value={endDateMoc}
                                    onChange={(date) => setEndDateMoc(date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {isValidRequest && mocDetails && (
                    <ExportTable
                        mocDetails={mocDetails}
                        isValidDownloadRequest={isValidDownloadRequest}
                        onCheckExportMoc={handleCheckExport}
                        onDownloadExportFile={handleDownloadFile}
                        isExporting={isExporting}
                        enableCheckboxForInvalidMocs={true}
                    />
                )}
                <OncoBaseSnackbar {...snackbarProps} />
            </Paper>
        </Container>
    );
}

export default ReportPage;
