import React from 'react';
import { Checkbox, Button, CircularProgress } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import MaterialTable from 'material-table';
import { format, parseJSON, compareAsc } from 'date-fns';

import styles from './ExportTable.module.css';
import { Mocs } from '../../api/types';

type MocDetail = Mocs.Export.MocDetail;

type Props = {
    mocDetails: MocDetail[];
    isValidDownloadRequest: boolean;
    onCheckExportMoc: (mocId: number, checked: boolean) => void;
    onDownloadExportFile: () => void;
    isExporting: boolean;
    enableCheckboxForInvalidMocs?: boolean;
};

const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd/MM/yyyy') : '');
const showYesNoBlank = (answer: boolean | null) => {
    return answer === null ? '' : answer ? 'Yes' : 'No';
};

function compareDate(date1: string | null, date2: string | null) {
    if (date1 === null) return -1;
    if (date2 === null) return 1;

    return compareAsc(parseJSON(date1), parseJSON(date2));
}

function compareString(string1: string | null, string2: string | null) {
    if (string1 === null) return -1;
    if (string2 === null) return 1;

    return string1.localeCompare(string2);
}

function compareBoolean(bool1: boolean | null, bool2: boolean | null) {
    if (bool1 === null) return -1;
    if (bool2 === null) return 1;

    if (bool1 === bool2) return 0;

    if (bool1) return 1;

    return -1;
}

function ExportTable({
    mocDetails,
    isValidDownloadRequest,
    onCheckExportMoc,
    onDownloadExportFile,
    isExporting,
    enableCheckboxForInvalidMocs,
}: Props) {
    return (
        <>
            <MaterialTable<MocDetail>
                key={'ExportTable'}
                style={{ margin: '1rem 0' }}
                title="Mocs to Export"
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    {
                        title: 'Patient',
                        defaultSort: 'desc',
                        render: (data) => `${data.surname} ${data.firstname}`,
                        customSort: (data1, data2) =>
                            compareString(`${data1.surname} ${data1.firstname}`, `${data2.surname} ${data2.firstname}`),
                    },
                    {
                        title: 'UZA Patientnr',
                        field: 'uzaNr',
                        customSort: (data1, data2) => compareString(data1.uzaNr, data2.uzaNr),
                    },
                    {
                        title: 'Referring Hospital',
                        field: 'referringHospital',
                        customSort: (data1, data2) => compareString(data1.referringHospital, data2.referringHospital),
                    },
                    {
                        title: 'Had surgery?',
                        field: 'hadSurgery',
                        render: (data) => showYesNoBlank(data.hadSurgery),
                        customSort: (data1, data2) => compareBoolean(data1.hadSurgery, data2.hadSurgery),
                    },
                    {
                        title: 'Surgery date',
                        field: 'surgeryDate',
                        render: (data) => showDate(data.surgeryDate),
                        customSort: (data1, data2) => compareDate(data1.surgeryDate, data2.surgeryDate),
                    },
                    {
                        title: 'MC date',
                        field: 'mcdate',
                        render: (data) => showDate(data.mcDate),
                        customSort: (data1, data2) => compareDate(data1.mcDate, data2.mcDate),
                    },
                    {
                        title: 'Export',
                        field: 'export',
                        sorting: false,
                        render: (data) => (
                            <div className={styles.export__column_content}>
                                <Checkbox
                                    checked={data.export}
                                    onClick={() => onCheckExportMoc(data.id, !data.export)}
                                    disabled={!enableCheckboxForInvalidMocs && !data.isValid}
                                />
                                {!data.isValid && (
                                    <WarningIcon className={styles.warning_icon} titleAccess="Moc is incomplete" />
                                )}
                            </div>
                        ),
                    },
                ]}
                data={mocDetails}
                options={{
                    sorting: true,
                    search: false,
                    pageSize: 10,
                    thirdSortClick: false,
                    headerStyle: { zIndex: 0 },
                }}
                actions={[
                    {
                        icon: '',
                        isFreeAction: true,
                        onClick: onDownloadExportFile,
                    },
                ]}
                components={{
                    Action: (props) => (
                        <div style={{ display: 'flex' }}>
                            {isExporting && (
                                <CircularProgress color="inherit" style={{ padding: '0.25rem', margin: '0 0.75rem' }} />
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={(event) => props.action.onClick(event, props.data)}
                                disabled={!isValidDownloadRequest || isExporting}
                            >
                                Export to excel
                            </Button>
                        </div>
                    ),
                }}
                localization={{ body: { emptyDataSourceMessage: 'No Mocs to export found' } }}
            />
        </>
    );
}

export default ExportTable;
