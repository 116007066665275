import React from 'react';
import { RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { RadioGroupProps, fieldToRadioGroup } from 'formik-material-ui';

export const FormikBooleanRadioGroup = ({ form, field, children, ...props }: RadioGroupProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue(field.name, 'true' === e.target.value);
    };
    return (
        <MuiRadioGroup {...fieldToRadioGroup({ ...props, field, form })} onChange={handleChange}>
            {children}
        </MuiRadioGroup>
    );
};
