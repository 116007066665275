import { Mocs } from '../../../../../../api/types';
import { toMocBoolean, toValuesBoolean, toValuesString } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    | 'adjuvantRadiotherapy'
    | 'adjuvantRadiotherapyType'
    | 'adjuvantRadiotherapyTreatments'
    | 'adjuvantChemotherapy'
    | 'adjuvantChemotherapyType'
    | 'adjuvantChemotherapyTypeOther'
    | 'adjuvantChemotherapyTreatments'
    | 'adjuvantImmunotherapy'
    | 'adjuvantImmunotherapyType'
    | 'adjuvantImmunotherapyTypeOther'
    | 'adjuvantImmunotherapyTreatments'
    | 'adjuvantOther'
    | 'adjuvantOtherValue'
>;

export type SaveMocValues = Pick<
    Mocs.CkzSarcomenData,
    | 'adjuvantRadiotherapy'
    | 'adjuvantRadiotherapyType'
    | 'adjuvantRadiotherapyTreatments'
    | 'adjuvantChemotherapy'
    | 'adjuvantChemotherapyType'
    | 'adjuvantChemotherapyTypeOther'
    | 'adjuvantChemotherapyTreatments'
    | 'adjuvantImmunotherapy'
    | 'adjuvantImmunotherapyType'
    | 'adjuvantImmunotherapyTypeOther'
    | 'adjuvantImmunotherapyTreatments'
    | 'adjuvantOther'
    | 'adjuvantOtherValue'
>;

export type FormValues = {
    adjuvantRadiotherapy: boolean | '';
    adjuvantRadiotherapyType: string;
    adjuvantRadiotherapyTreatments: AdjuvantRadiotherapyTreatment[];
    adjuvantChemotherapy: boolean | '';
    adjuvantChemotherapyType: string;
    adjuvantChemotherapyTypeOther: string;
    adjuvantChemotherapyTreatments: AdjuvantChemotherapyTreatment[];
    adjuvantImmunotherapy: boolean | '';
    adjuvantImmunotherapyType: string;
    adjuvantImmunotherapyTypeOther: string;
    adjuvantImmunotherapyTreatments: AdjuvantImmunotherapyTreatment[];
    adjuvantOther: boolean | '';
    adjuvantOtherValue: string;
};

export type AdjuvantRadiotherapyTreatment = {
    code: string;
    gray: string;
    startDate: string | null;
    endDate: string | null;
};

export type AdjuvantChemotherapyTreatment = {
    code: string;
    startDate: string | null;
    endDate: string | null;
};

export type AdjuvantImmunotherapyTreatment = {
    code: string;
    startDate: string | null;
    endDate: string | null;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    adjuvantRadiotherapy: toValuesBoolean(moc.adjuvantRadiotherapy),
    adjuvantRadiotherapyType: toValuesString(moc.adjuvantRadiotherapyType),
    adjuvantRadiotherapyTreatments: moc.adjuvantRadiotherapyTreatments.map((r) => ({
        code: r.code,
        gray: r.gray,
        startDate: r.startDate,
        endDate: r.endDate,
    })),
    adjuvantChemotherapy: toValuesBoolean(moc.adjuvantChemotherapy),
    adjuvantChemotherapyType: toValuesString(moc.adjuvantChemotherapyType),
    adjuvantChemotherapyTypeOther: toValuesString(moc.adjuvantChemotherapyTypeOther),
    adjuvantChemotherapyTreatments: moc.adjuvantChemotherapyTreatments.map((r) => ({
        code: r.code,
        startDate: r.startDate,
        endDate: r.endDate,
    })),
    adjuvantImmunotherapy: toValuesBoolean(moc.adjuvantImmunotherapy),
    adjuvantImmunotherapyType: toValuesString(moc.adjuvantImmunotherapyType),
    adjuvantImmunotherapyTypeOther: toValuesString(moc.adjuvantImmunotherapyTypeOther),
    adjuvantImmunotherapyTreatments: moc.adjuvantImmunotherapyTreatments.map((r) => ({
        code: r.code,
        startDate: r.startDate,
        endDate: r.endDate,
    })),
    adjuvantOther: toValuesBoolean(moc.adjuvantOther),
    adjuvantOtherValue: toValuesString(moc.adjuvantOtherValue),
});

export const getNewMocValues = (): GetMocValues => ({
    adjuvantRadiotherapy: false,
    adjuvantRadiotherapyType: '',
    adjuvantRadiotherapyTreatments: [],
    adjuvantChemotherapy: false,
    adjuvantChemotherapyType: '',
    adjuvantChemotherapyTypeOther: '',
    adjuvantChemotherapyTreatments: [],
    adjuvantImmunotherapy: false,
    adjuvantImmunotherapyType: '',
    adjuvantImmunotherapyTypeOther: '',
    adjuvantImmunotherapyTreatments: [],
    adjuvantOther: false,
    adjuvantOtherValue: '',
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    adjuvantRadiotherapy: toMocBoolean(form.adjuvantRadiotherapy),
    adjuvantRadiotherapyType: form.adjuvantRadiotherapyType,
    adjuvantRadiotherapyTreatments: form.adjuvantRadiotherapyTreatments,
    adjuvantChemotherapy: toMocBoolean(form.adjuvantChemotherapy),
    adjuvantChemotherapyType: form.adjuvantChemotherapyType,
    adjuvantChemotherapyTypeOther: form.adjuvantChemotherapyTypeOther,
    adjuvantChemotherapyTreatments: form.adjuvantChemotherapyTreatments,
    adjuvantImmunotherapy: toMocBoolean(form.adjuvantImmunotherapy),
    adjuvantImmunotherapyType: form.adjuvantImmunotherapyType,
    adjuvantImmunotherapyTypeOther: form.adjuvantImmunotherapyTypeOther,
    adjuvantImmunotherapyTreatments: form.adjuvantImmunotherapyTreatments,
    adjuvantOther: toMocBoolean(form.adjuvantOther),
    adjuvantOtherValue: form.adjuvantOtherValue,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
