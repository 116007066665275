export enum EditFormLabels {
    Address = 'Address',
    Zip = 'Zip',
    City = 'City',
    Country = 'Country',
    HealthInsuranceFund = 'Health insurance fund',
    HealthInsuranceFundNr = 'Health insurance fund number',
    FirstName = 'Firstname',
    SurName = 'Surname',
    DateOfBirth = 'Date of birth',
    DateOfDeath = 'Date of death',
    Gender = 'Gender',
    SSN = 'SSN',
    UzaNr = 'UzaNr',
    Tel = 'Phone',
    Mail = 'Email',
    NameHomeDoctor = 'Name general practitioner',
    ContactHomeDoctor = 'Contact general practitioner',
    NameAttendingDoctor = 'Name treating physicianr',
    ContactAttendingDoctor = 'Contact treating physician',
}
