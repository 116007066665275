import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import useData, { ResponseDataToData } from '../../../../api/useData';
import { Patients } from '../../../../api/types';
import usePermissions from '../../../../auth/usePermissions';
import PatientInfo from './PatientInfo';
import PatientMocs from './PatientMocs';
import PatientFiles from './PatientFiles';
import useHospital from '../../../../hospital/useHospital';
import styles from './PatientDetailPage.module.scss';
import OncoBaseSnackbar, { useSnackBar } from '../../../../styles/components/OncoBaseSnackbar';
import { Backdrop, CircularProgress, Typography } from '@material-ui/core';
import ErrorSummary from '../../../../styles/components/ErrorSummary';

type Patient = Patients.GetPatient.PatientDetail;
type Moc = Patients.GetPatient.Moc;
type File = Patients.GetPatient.File;

type RouteInfo = {
    id: string;
    mocid: string;
};

type Props = RouteComponentProps<RouteInfo>;

const responseMapper: ResponseDataToData<Patients.GetPatient.PatientDetail> = (d) => d.patient;

const PatientDetailPage: React.FC<Props> = ({ match }) => {
    const history = useHistory();
    const { hospital } = useHospital();
    const permissions = usePermissions();
    const { canEditPatient } = permissions;
    const [patient, setPatient] = useState<Patient | null>(null);
    const [mocs, setMocs] = useState<Moc[] | null>(null);
    const [files, setFiles] = useState<File[] | null>(null);
    const patientId = match.params.id;
    const patientsUrl = `/${hospital!.slug}/patients`;
    const patientBaseUrl = `${patientsUrl}/${patientId}`;
    const [getPatient, patientResponse] = useData('patients/' + patientId, responseMapper);
    const [showSnack, snackBarProps] = useSnackBar();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    useEffect(() => {
        const { hasLoaded, data, isError } = patientResponse;
        if (isError) {
            setError('Could not connect to server');
            return;
        }
        if (hasLoaded) {
            if (!data) {
                history.push(patientsUrl);
            } else {
                setPatient(data);
                setMocs(data.mocs);
                setFiles(data.files);
            }
        }
    }, [patientResponse, history, mocs, patientsUrl]);

    const handlePatientEdit = useCallback(() => {
        history.push(`${patientBaseUrl}/edit`);
    }, [patientBaseUrl, history]);

    const handlePatientFilesChanged = (newPatientFiles: File[]) => {
        setFiles(newPatientFiles);
    };

    const handleMocFilesChanged = (mocFiles: File[]) => {
        const nonMocFiles = files!.filter((f) => !f.mocId);
        const newPatientFiles = [...nonMocFiles, ...mocFiles];
        setFiles(newPatientFiles);
    };

    if (error !== null) {
        return (
            <div>
                <ErrorSummary errors={[error]} />
            </div>
        );
    }

    if (!patient) {
        return (
            <Backdrop open={true} className={styles.backdrop}>
                <div className={styles.backdrop_content}>
                    <CircularProgress color="inherit" />
                    <Typography className={styles.backdrop_text}>Loading patient details...</Typography>
                </div>
            </Backdrop>
        );
    }

    return (
        <div className={styles.sticky_container}>
            <div className={styles.sticky_patientinfo}>
                <PatientInfo patient={patient} onEdit={handlePatientEdit} isReadOnly={!canEditPatient} />
            </div>
            {files && (
                <PatientFiles
                    patientId={patient.id}
                    patientFiles={files}
                    onPatientFilesChanged={handlePatientFilesChanged}
                    showSnack={showSnack}
                />
            )}
            {mocs && files && (
                <PatientMocs
                    patient={patient}
                    patientBaseUrl={patientBaseUrl}
                    patientMocs={mocs}
                    permissions={permissions}
                    showSnack={showSnack}
                    mocFiles={files.filter((f) => f.patientFileTypeIsMocDependent)}
                    onMocFilesChanged={handleMocFilesChanged}
                />
            )}
            <OncoBaseSnackbar {...snackBarProps} />
        </div>
    );
};

export default PatientDetailPage;
