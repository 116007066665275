import { Mocs } from '../../../../../api/types';
import * as GeneralInformation from '../sections/generalInformation/GeneralInformation.config';
import * as PatientCharacteristics from '../sections/patientCharacteristics/PatientCharacteristics.config';
import * as Surgery from '../sections/surgery/ckzOesophagus/Surgery.config';
import * as PostSurgery from '../sections/postSurgery/ckzOesophagus/PostSurgery.config';
import * as GeneralRemarks from '../sections/generalRemarks/GeneralRemarks.config';
import * as Annex55 from '../sections/annex_55/Annex55.config';
import MakeOptional from '../MakeOptional';
import MocTypePropNames from '../MocTypePropNames';

type GetMocValues = MakeOptional<Mocs.GetMoc.MocData, MocTypePropNames>;
type NewMocValues = Pick<GetMocValues, 'ckzOesophagus'>;
type SaveMocValues = Pick<Mocs.SaveMoc.Request, 'ckzOesophagus'>;

export type FormValues = {
    generalInformation: GeneralInformation.FormValues;
    annex55: Annex55.FormValues;
    patientCharacteristics: PatientCharacteristics.FormValues;
    oesophagusSurgery: Surgery.FormValues;
    oesophagusPostSurgery: PostSurgery.FormValues;
    generalRemarks: GeneralRemarks.FormValues;
};

export const mapInitialSectionValues = (moc: GetMocValues): FormValues => ({
    generalInformation: GeneralInformation.mapInitialValues(moc.ckzOesophagus!),
    annex55: Annex55.mapInitialValues(moc.ckzOesophagus!),
    patientCharacteristics: PatientCharacteristics.mapInitialValues(moc.ckzOesophagus!),
    oesophagusSurgery: Surgery.mapInitialValues(moc.ckzOesophagus!),
    oesophagusPostSurgery: PostSurgery.mapInitialValues(moc.ckzOesophagus!),
    generalRemarks: GeneralRemarks.mapInitialValues(moc.ckzOesophagus!),
});

export const getNewMocValues: () => NewMocValues = () => ({
    ckzOesophagus: {
        id: 0,
        ...GeneralInformation.getNewMocValues(),
        ...Annex55.getNewMocValues(),
        ...PatientCharacteristics.getNewMocValues(),
        ...Surgery.getNewMocValues(),
        ...PostSurgery.getNewMocValues(),
        ...GeneralRemarks.getNewMocValues(),
    },
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    ckzOesophagus: {
        ...GeneralInformation.mapSubmitValues(form.generalInformation),
        ...Annex55.mapSubmitValues(form.annex55),
        ...PatientCharacteristics.mapSubmitValues(form.patientCharacteristics),
        ...Surgery.mapSubmitValues(form.oesophagusSurgery),
        ...PostSurgery.mapSubmitValues(form.oesophagusPostSurgery),
        ...GeneralRemarks.mapSubmitValues(form.generalRemarks),
    },
});
