import React from 'react';
import { getIn, useFormikContext } from 'formik';
import { Values as MocValues, MocFileType } from '../MocForm.config';
import FileFieldBase from './FileFieldBase';

type Props = {
    type: MocFileType;
    name: string;
};

const FileNameField: React.FC<Props> = ({ type, name }) => {
    const { values, setFieldValue } = useFormikContext<MocValues>();

    const handleSubmitFile = (fileId: number) => {
        setFieldValue(name, fileId);
    };

    const handleDeleteFile = () => {
        setFieldValue(name, null);
    };

    const fileId = getIn(values, name) as number | null;
    const hasFile = fileId !== null;
    const file = hasFile ? values.mocFiles.filter((f) => f.id === fileId)[0] : null;

    return <FileFieldBase type={type} file={file} onAfterSubmit={handleSubmitFile} onAfterDelete={handleDeleteFile} />;
};

export default FileNameField;
