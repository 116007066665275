import { createMuiTheme } from '@material-ui/core';
import { primary, secondary } from './colors';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Roboto, Arial',
        button: {
            fontSize: '.8rem'
        }
    },
    palette: {
        primary: {
            main: primary
        },
        secondary: {
            main: secondary
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '.8rem'
            }
        }
    }
});

export default theme;
