import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import * as generalInformation from '../../../../forms/sections/generalInformation/GeneralInformation.config';
import { styles } from '../../styles';
import {
    QuestionAnswerAndOther,
    QuestionAnswer,
    QuestionYesNoAnswer,
    QuestionAnswerAndDate,
    QuestionYesNoAnswerAndDate,
    QuestionYesNoAnswerProps,
    QuestionDateAnswer,
    formatDateWithPrefix,
} from '../Questions';
import { ReferenceDataMapper } from '../..';
import * as generalInformationConfig from '../../../../forms/sections/generalInformation/GeneralInformation.config';
type GeneralInformationProps = {
    values: generalInformation.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function GeneralInformation({ values, mapReferenceData }: GeneralInformationProps) {
    const { ...valuesToCheck } = values;
    valuesToCheck.mccmReport = generalInformationConfig.initialValues.mccmReport;
    valuesToCheck.surgeryReport = generalInformationConfig.initialValues.surgeryReport;
    valuesToCheck.pathologyReport = generalInformationConfig.initialValues.pathologyReport;
    const isEmpty = JSON.stringify(valuesToCheck) === JSON.stringify(generalInformationConfig.initialValues);

    return isEmpty ? null : (
        <View>
            <Text style={styles.header}>General Information</Text>
            <QuestionYesNoAnswer question="Did the patient undergo surgery" answer={values.hadSurgery} />
            <QuestionAnswerAndOther
                question="Indication"
                answer={mapReferenceData('indications', values.indicationCode)}
                other={values.indicationOtherValue}
            />

            <QuestionDateAnswer question="First diagnosis" answer={values.dateOfDiagnosis} />
            <QuestionAnswerAndDate
                question="First diagnosis"
                answer={mapReferenceData('diagnosticTypes', values.firstDiagnosticTypeCode)}
                date={values.firstDiagnosticDate}
            />
            <QuestionAnswerAndDate
                question="First tissue sample"
                answer={mapReferenceData('tissueSampleMethods', values.firstTissueSampleMethodCode)}
                date={values.firstTissueSampleMethodDate}
            />
            <QuestionYesNoAnswer question="Was the patient referred" answer={values.referred} />
            <QuestionAnswer
                question="Name Referring hospital"
                answer={mapReferenceData('hospitals', values.referringHospitalCode)}
            />
            <QuestionYesNoAnswerAndDate
                question="Was there a MOC at the referring hospital"
                answer={values.mccmAtReferringHospital}
                date={values.mccmReferringHospitalDate}
            />
            <QuestionPatientHospitalized
                question="Was the patient hospitalized at the referring hospital"
                answer={values.hospitalizedAtReferringHospital}
                yesDate={values.dateOfDischargeAtReferringHospital}
                noDate={values.dateOfLastConsultationAtReferringHospital}
            />
        </View>
    );
}

type QuestionPatientHospitalizedProps = QuestionYesNoAnswerProps & {
    yesDate: string | null;
    noDate: string | null;
};

function QuestionPatientHospitalized(values: QuestionPatientHospitalizedProps) {
    const answer = values.answer
        ? `Yes${formatDateWithPrefix(', he was discharged on', values.yesDate)}`
        : `No${formatDateWithPrefix(', the last consultation was on', values.noDate)}`;
    return typeof values.answer === 'boolean' ? <QuestionAnswer question={values.question} answer={answer} /> : null;
}
