import React from 'react';
import { ManualValues, Country, Fund } from '../EditPatient.config';
import { Grid, Paper, TextField } from '@material-ui/core';
import Section from '../../../../../styles/components/forms/Section';
import { formatDate } from '../../form/ImportPatientDetail.config';
import PatientPersonalInformationSection from '../../detail/patientInfoSections/PatientPersonalInformationSection';
import PatientAddressSection from '../../detail/patientInfoSections/PatientAddressSection';
import PatientInsuranceSection from '../../detail/patientInfoSections/PatientInsuranceSection';
import PatientOtherMedicalInfoSection from '../../detail/patientInfoSections/PatientOtherMedicalInfoSection';

type ReadOnlyPatientData = {
    readonlyValues: ManualValues;
    countries: Country[] | null;
    funds: Fund[] | null;
};
export default function ReadOnlyPatientData(props: ReadOnlyPatientData) {
    return (
        <Paper>
            <Section name="Identification">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            label="UzaNr"
                            defaultValue={props.readonlyValues.uzaNr}
                            variant="outlined"
                            disabled
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="SSN"
                            defaultValue={props.readonlyValues.ssn}
                            variant="outlined"
                            disabled
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </Section>

            <PatientPersonalInformationSection
                firstName={props.readonlyValues.firstName}
                surname={props.readonlyValues.surname}
                dateOfBirth={formatDate(props.readonlyValues.dateOfBirth)!}
                dateOfDeath={formatDate(props.readonlyValues.dateOfDeath)}
                gender={props.readonlyValues.gender}
            />
            <PatientAddressSection
                address={props.readonlyValues.address}
                postalCode={props.readonlyValues.postalCode}
                city={props.readonlyValues.city}
                countryName={
                    props.countries
                        ? props.countries.find((c) => c.id === props.readonlyValues.countryId)!.name
                        : 'Could not find country'
                }
                mail={props.readonlyValues.mail ?? ''}
                phone={props.readonlyValues.telNr ?? ''}
            />
            <PatientInsuranceSection
                fundName={
                    props.funds
                        ? props.funds.find((f) => f.id === props.readonlyValues.healthInsuranceFundId)?.name!
                        : 'Could not find fund'
                }
                fundNr={props.readonlyValues.healthInsuranceFundNr}
            />
            <PatientOtherMedicalInfoSection
                homeDoctorName={props.readonlyValues.homeDoctorName ?? ''}
                homeDoctorContactInfo={props.readonlyValues.homeDoctorContactInfo ?? ''}
                attendingDoctorName={props.readonlyValues.attendingDoctorName ?? ''}
                attendingDoctorContactInfo={props.readonlyValues.attendingDoctorContactInfo ?? ''}
            />
        </Paper>
    );
}
