export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}
export enum DateTimeKind {
    Unspecified = 0,
    Utc = 1,
    Local = 2
}
export enum Gender {
    Male = 0,
    Female = 1,
    Unknown = 2
}
