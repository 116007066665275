import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Identity as Model } from '../types';

export function getCurrentUser(api: AxiosInstance): Promise<AxiosResponse<Model.GetCurrentUser.Response>> {
    // We dont use the given API because we want to bypass the default interceptors (redirect to home when not authenticated)
    return axios.get('users/current', {
        baseURL: api.defaults.baseURL,
        headers: { 
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache', 
        },
        withCredentials: true,
        validateStatus: () => true
    });
}
