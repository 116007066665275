import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Formik, Form } from 'formik';
import useData, { ResponseDataToData } from '../../../../api/useData';
import { Patients } from '../../../../api/types';
import Section from '../../../../styles/components/forms/Section';
import ErrorSummary from '../../../../styles/components/ErrorSummary';
import { Values, validateForm } from './PatientForm.config';
import Identification from './sections/manual/Identification';
import PersonalInformation from './sections/manual/PersonalInformation';
import Address from './sections/manual/Address';
import Insurance from './sections/manual/Insurance';
import OtherMedicalInfo from './sections/manual/OtherMedicalInfo';
type Country = Patients.Country;
type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;

type Props = {
    initialValues: Values;
    submitText: string;
    hospitalName: string;
    onSubmit: (values: Values) => void;
};

const fundsResponseMapper: ResponseDataToData<Fund[]> = (d) => d.healthInsuranceFunds;
const countriesResponseMapper: ResponseDataToData<Country[]> = (d) => d.countries;
const patientNumbersResponseMapper: ResponseDataToData<string[]> = (d) => d.patientNumbers;

const PatientForm: React.FC<Props> = ({ initialValues, submitText, onSubmit, hospitalName }) => {
    const [funds, setFunds] = useState<Fund[] | null>(null);
    const [getFunds, fundsResponse] = useData('healthinsurancefunds', fundsResponseMapper);

    const [countries, setCountries] = useState<Country[] | null>(null);
    const [getCountries, countriesResponse] = useData('countries', countriesResponseMapper);

    const [otherPatientNumbers, setOtherPatientNumbers] = useState<string[] | null>(null);
    const [getPatientNumbers, patientNumbersResponse] = useData('patients/numbers', patientNumbersResponseMapper);

    const isNewPatient = !initialValues.uzaNr;

    useEffect(() => {
        getFunds();
    }, [getFunds]);

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    useEffect(() => {
        getPatientNumbers();
    }, [getPatientNumbers]);

    useEffect(() => {
        fundsResponse.hasLoaded && !fundsResponse.isError && setFunds(fundsResponse.data);
    }, [fundsResponse]);

    useEffect(() => {
        countriesResponse.hasLoaded && !countriesResponse.isError && setCountries(countriesResponse.data);
    }, [countriesResponse]);

    useEffect(() => {
        const { hasLoaded, isError, data } = patientNumbersResponse;
        if (!hasLoaded || isError) {
            return;
        }

        const patientNumbers = data;
        const otherPatientNumbers = isNewPatient
            ? patientNumbers
            : patientNumbers.filter((nr) => nr !== initialValues.uzaNr);

        setOtherPatientNumbers(otherPatientNumbers);
    }, [patientNumbersResponse, isNewPatient, initialValues.uzaNr]);

    const errors: string[] = [];
    if (fundsResponse.isError && countriesResponse.isError && patientNumbersResponse.isError) {
        errors.push('Could not connect to server');
    } else {
        fundsResponse.isError && errors.push('Could not load health insurance funds');
        countriesResponse.isError && errors.push('Could not load countries');
        patientNumbersResponse.isError && errors.push('Could not load existing patient numbers');
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validateForm(funds, otherPatientNumbers)}
        >
            {({ isSubmitting }) => {
                const disableSubmit = isSubmitting || !funds || !countries;
                return (
                    <div style={{ flexGrow: 1 }}>
                        <Form>
                            <Paper>
                                <ErrorSummary errors={errors} />
                                <Section name="Identification">
                                    <Identification isNewPatient={isNewPatient} patientNumbers={otherPatientNumbers} />
                                </Section>
                                <Section name="Personal information">
                                    <PersonalInformation />
                                </Section>
                                <Section name="Address">
                                    <Address countries={countries} />
                                </Section>
                                <Section name="Insurance">
                                    <Insurance funds={funds} />
                                </Section>
                                <Section name="Other Medical Info">
                                    <OtherMedicalInfo />
                                </Section>
                            </Paper>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{ margin: '1.5rem 0' }}
                                startIcon={<SaveIcon />}
                                disabled={disableSubmit}
                            >
                                {submitText}
                            </Button>
                            {isSubmitting && <CircularProgress />}
                        </Form>
                    </div>
                );
            }}
        </Formik>
    );
};

export default PatientForm;
