import React from 'react';
import { Grid } from '@material-ui/core';
import { Mocs } from '../../../../../../api/types';
import { YesNoQuestion, RadioButtons, FastSelectField, FastTextField, Question } from '../../components';

type AdjuvenantTherapyType = Mocs.GetReferenceData.Moc_Ref_AdjuvenantTherapyType;
type TriStateSelection = Mocs.GetReferenceData.Moc_Ref_TriStateSelection;

type PostSurgerySection = 'pancreasPostSurgery' | 'oesophagusPostSurgery';

type Props = {
    section: PostSurgerySection;
    indicationCode: string | undefined;
};

const AdjuvantTherapy: React.FC<Props> = ({ section, indicationCode }) => {
    const fieldPath = (relative: string) => `${section}.${relative}`;
    const isIndicationMalignantTumour = indicationCode && indicationCode === 'Malignant';

    return (
        <Grid container spacing={3}>
            {isIndicationMalignantTumour && (
                <Grid item xs={12}>
                    <YesNoQuestion
                        name={fieldPath('wasThereAdjuvenantTherapyAfterSurgery')}
                        question="Was there adjuvant therapy after surgery?"
                    >
                        <FastSelectField<AdjuvenantTherapyType>
                            name={fieldPath('adjuvenantTherapyTypeCode')}
                            placeholder="Adjuvenant therapy type"
                            prompt="Select a therapy type"
                            referenceDataPath="adjuvenantTherapyTypes"
                            detail={({ isOther }) =>
                                isOther && (
                                    <FastTextField
                                        name={fieldPath('adjuvenantTherapyTypeOtherValue')}
                                        label="Specify"
                                    />
                                )
                            }
                        />
                    </YesNoQuestion>
                </Grid>
            )}
            <Grid item xs={12}>
                <Question question="Was the patient included in a clinical trial for (neo)adjuvant therapy or surgery?">
                    <RadioButtons<TriStateSelection>
                        name={fieldPath('clinicalTrialWasIncludedCode')}
                        referenceDataPath="triStateSelections"
                        activeLine
                        activeCode={/yes/i}
                        detail={({ code }) =>
                            code === 'Yes' && (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FastTextField
                                            name={fieldPath('clinicalTrialEudraCTNumber')}
                                            label="EudraCT number"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FastTextField name={fieldPath('clinicalTrialNctNumber')} label="NCT number" />
                                    </Grid>
                                </Grid>
                            )
                        }
                    />
                </Question>
            </Grid>
        </Grid>
    );
};

export default AdjuvantTherapy;
