import { Mocs } from '../../../../../../../api/types';
import { toMocBoolean, toValuesBoolean, toValuesString, toValuesDate } from '../../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzOesophagusData,
    | 'hadOtherMajorPostopComplications'
    | 'generalClavienDindoGradeCode'
    | 'hadRedoSurgery'
    | 'redoSurgeryTypeCode'
    | 'redoSurgeryTypeOtherValue'
    | 'redoSurgeryMccmReport'
    | 'redoSurgeryPathologyReport'
    | 'redoSurgerySurgeryReport'
    | 'postOpDischarged'
    | 'postOpDischargedDate'
    | 'postOpDischargedDestinationCode'
    | 'postOpDischargedTransferHospitalName'
    | 'postOpDischargedTransferBecauseComplications'
    | 'postOpDischargedReadmissionTypeCode'
    | 'postOpDischargedReadmissionReason'
    | 'postOpDied'
    | 'postOpDiedInHospital'
    | 'postOpDiedDate'
    | 'postOpDiedCause'
    | 'wasThereAdjuvenantTherapyAfterSurgery'
    | 'adjuvenantTherapyTypeCode'
    | 'adjuvenantTherapyTypeOtherValue'
    | 'clinicalTrialWasIncludedCode'
    | 'clinicalTrialEudraCTNumber'
    | 'clinicalTrialNctNumber'
    | 'postoperativeComplications'
    | 'majorPostoperativeComplications'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzOesophagusFields,
    | 'hadOtherMajorPostopComplications'
    | 'generalClavienDindoGradeCode'
    | 'hadRedoSurgery'
    | 'redoSurgeryTypeCode'
    | 'redoSurgeryTypeOtherValue'
    | 'redoSurgeryMccmReport'
    | 'redoSurgeryPathologyReport'
    | 'redoSurgerySurgeryReport'
    | 'postOpDischarged'
    | 'postOpDischargedDate'
    | 'postOpDischargedDestinationCode'
    | 'postOpDischargedTransferHospitalName'
    | 'postOpDischargedTransferBecauseComplications'
    | 'postOpDischargedReadmissionTypeCode'
    | 'postOpDischargedReadmissionReason'
    | 'postOpDied'
    | 'postOpDiedInHospital'
    | 'postOpDiedDate'
    | 'postOpDiedCause'
    | 'wasThereAdjuvenantTherapyAfterSurgery'
    | 'adjuvenantTherapyTypeCode'
    | 'adjuvenantTherapyTypeOtherValue'
    | 'clinicalTrialWasIncludedCode'
    | 'clinicalTrialEudraCTNumber'
    | 'clinicalTrialNctNumber'
    | 'postoperativeComplications'
    | 'majorPostoperativeComplications'
>;

export type FormValues = {
    hadOtherMajorPostopComplications: boolean | '';
    generalClavienDindoGradeCode: string;
    hadRedoSurgery: boolean | '';
    redoSurgeryTypeCode: string;
    redoSurgeryTypeOtherValue: string;
    redoSurgeryMccmReport: string;
    redoSurgeryPathologyReport: string;
    redoSurgerySurgeryReport: string;
    postOpDischarged: boolean | '';
    postOpDischargedDate: string | null;
    postOpDischargedDestinationCode: string;
    postOpDischargedTransferHospitalName: string;
    postOpDischargedTransferBecauseComplications: boolean | '';
    postOpDischargedReadmissionTypeCode: string;
    postOpDischargedReadmissionReason: string;
    postOpDied: boolean | '';
    postOpDiedInHospital: boolean | '';
    postOpDiedDate: string | null;
    postOpDiedCause: string;
    wasThereAdjuvenantTherapyAfterSurgery: boolean | '';
    adjuvenantTherapyTypeCode: string;
    adjuvenantTherapyTypeOtherValue: string;
    clinicalTrialWasIncludedCode: string;
    clinicalTrialEudraCTNumber: string;
    clinicalTrialNctNumber: string;
    postoperativeComplications: PostoperativeComplication[];
    majorPostoperativeComplications: MajorPostoperativeComplication[];
};

export type PostoperativeComplication = {
    code: string;
    clavienDindoGradeCode: string;
};

export type MajorPostoperativeComplication = {
    code: string;
    postoperativeComplicationSubTypes: PostoperativeComplicationSubType[];
};

export type PostoperativeComplicationSubType = {
    code: string;
    otherValue: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    hadOtherMajorPostopComplications: toValuesBoolean(moc.hadOtherMajorPostopComplications),
    generalClavienDindoGradeCode: moc.generalClavienDindoGradeCode,
    hadRedoSurgery: toValuesBoolean(moc.hadRedoSurgery),
    redoSurgeryTypeCode: moc.redoSurgeryTypeCode,
    redoSurgeryTypeOtherValue: toValuesString(moc.redoSurgeryTypeOtherValue),
    redoSurgeryMccmReport: toValuesString(moc.redoSurgeryMccmReport),
    redoSurgeryPathologyReport: toValuesString(moc.redoSurgeryPathologyReport),
    redoSurgerySurgeryReport: toValuesString(moc.redoSurgerySurgeryReport),
    postOpDischarged: toValuesBoolean(moc.postOpDischarged),
    postOpDischargedDate: toValuesDate(moc.postOpDischargedDate),
    postOpDischargedDestinationCode: moc.postOpDischargedDestinationCode,
    postOpDischargedTransferHospitalName: toValuesString(moc.postOpDischargedTransferHospitalName),
    postOpDischargedTransferBecauseComplications: toValuesBoolean(moc.postOpDischargedTransferBecauseComplications),
    postOpDischargedReadmissionTypeCode: moc.postOpDischargedReadmissionTypeCode,
    postOpDischargedReadmissionReason: toValuesString(moc.postOpDischargedReadmissionReason),
    postOpDied: toValuesBoolean(moc.postOpDied),
    postOpDiedInHospital: toValuesBoolean(moc.postOpDiedInHospital),
    postOpDiedDate: toValuesDate(moc.postOpDiedDate),
    postOpDiedCause: toValuesString(moc.postOpDiedCause),
    wasThereAdjuvenantTherapyAfterSurgery: toValuesBoolean(moc.wasThereAdjuvenantTherapyAfterSurgery),
    adjuvenantTherapyTypeCode: moc.adjuvenantTherapyTypeCode,
    adjuvenantTherapyTypeOtherValue: toValuesString(moc.adjuvenantTherapyTypeOtherValue),
    clinicalTrialWasIncludedCode: moc.clinicalTrialWasIncludedCode,
    clinicalTrialEudraCTNumber: toValuesString(moc.clinicalTrialEudraCTNumber),
    clinicalTrialNctNumber: toValuesString(moc.clinicalTrialNctNumber),
    postoperativeComplications: moc.postoperativeComplications.map((p) => ({
        code: p.postoperativeComplicationCode,
        clavienDindoGradeCode: p.clavienDindoGradeCode,
    })),
    majorPostoperativeComplications: moc.majorPostoperativeComplications.map((p) => ({
        code: p.postoperativeComplicationCode,
        postoperativeComplicationSubTypes: p.postoperativeComplicationSubTypes.map((s) => ({
            code: s.postoperativeComplicationSubTypeCode,
            otherValue: s.otherValue,
        })),
    })),
});

export const getNewMocValues = (): GetMocValues => ({
    hadOtherMajorPostopComplications: null,
    generalClavienDindoGradeCode: '',
    hadRedoSurgery: null,
    redoSurgeryTypeCode: '',
    redoSurgeryTypeOtherValue: '',
    redoSurgeryMccmReport: '',
    redoSurgeryPathologyReport: '',
    redoSurgerySurgeryReport: '',
    postOpDischarged: null,
    postOpDischargedDate: null,
    postOpDischargedDestinationCode: '',
    postOpDischargedTransferHospitalName: '',
    postOpDischargedTransferBecauseComplications: null,
    postOpDischargedReadmissionTypeCode: '',
    postOpDischargedReadmissionReason: '',
    postOpDied: null,
    postOpDiedInHospital: null,
    postOpDiedDate: null,
    postOpDiedCause: '',
    wasThereAdjuvenantTherapyAfterSurgery: null,
    adjuvenantTherapyTypeCode: '',
    adjuvenantTherapyTypeOtherValue: '',
    clinicalTrialWasIncludedCode: '',
    clinicalTrialEudraCTNumber: '',
    clinicalTrialNctNumber: '',
    postoperativeComplications: [],
    majorPostoperativeComplications: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    hadOtherMajorPostopComplications: toMocBoolean(form.hadOtherMajorPostopComplications),
    generalClavienDindoGradeCode: form.generalClavienDindoGradeCode,
    hadRedoSurgery: toMocBoolean(form.hadRedoSurgery),
    redoSurgeryTypeCode: form.redoSurgeryTypeCode,
    redoSurgeryTypeOtherValue: form.redoSurgeryTypeOtherValue,
    redoSurgeryMccmReport: form.redoSurgeryMccmReport,
    redoSurgeryPathologyReport: form.redoSurgeryPathologyReport,
    redoSurgerySurgeryReport: form.redoSurgerySurgeryReport,
    postOpDischarged: toMocBoolean(form.postOpDischarged),
    postOpDischargedDate: form.postOpDischargedDate,
    postOpDischargedDestinationCode: form.postOpDischargedDestinationCode,
    postOpDischargedTransferHospitalName: form.postOpDischargedTransferHospitalName,
    postOpDischargedTransferBecauseComplications: toMocBoolean(form.postOpDischargedTransferBecauseComplications),
    postOpDischargedReadmissionTypeCode: form.postOpDischargedReadmissionTypeCode,
    postOpDischargedReadmissionReason: form.postOpDischargedReadmissionReason,
    postOpDied: toMocBoolean(form.postOpDied),
    postOpDiedInHospital: toMocBoolean(form.postOpDiedInHospital),
    postOpDiedDate: form.postOpDiedDate,
    postOpDiedCause: form.postOpDiedCause,
    wasThereAdjuvenantTherapyAfterSurgery: toMocBoolean(form.wasThereAdjuvenantTherapyAfterSurgery),
    adjuvenantTherapyTypeCode: form.adjuvenantTherapyTypeCode,
    adjuvenantTherapyTypeOtherValue: form.adjuvenantTherapyTypeOtherValue,
    clinicalTrialWasIncludedCode: form.clinicalTrialWasIncludedCode,
    clinicalTrialEudraCTNumber: form.clinicalTrialEudraCTNumber,
    clinicalTrialNctNumber: form.clinicalTrialNctNumber,
    postoperativeComplications: form.postoperativeComplications,
    majorPostoperativeComplications: form.majorPostoperativeComplications,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
