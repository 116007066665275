import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import * as postSurgery from '../../../../../forms/sections/postSurgery/ckzPancreas/PostSurgery.config';
import { styles } from '../../../styles';
import {
    QuestionDateAnswer,
    QuestionAnswer,
    QuestionYesNoAnswer,
    QuestionAnswerAndOther,
    QuestionProps,
} from '../../Questions';
import { PostoperativeComplication } from '../../../../../forms/sections/postSurgery/ckzPancreas/PostSurgery.config';
import List, { Item, SubItem } from '../../List';
import { ReferenceDataMapper } from '../../..';

type PostSurgeryProps = {
    values: postSurgery.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function PostSurgery({ values, mapReferenceData }: PostSurgeryProps) {
    return (
        <View>
            <Text style={styles.header}>Post-Surgery</Text>
            <QuestionYesNoAnswer
                question="Postoperative complications 90 days post-op"
                answer={values.hadPostoperativeComplications}
            />
            <QuestionListPostoperativeComplicationsAnswer
                question={'Type of postoperative complications'}
                answer={values.postoperativeComplications}
                mapper={mapReferenceData}
            />
            <QuestionAnswer
                question="General Clavien-Dindo classification"
                answer={mapReferenceData('clavienDindoClassifications', values.generalClavienDindoClassificationCode)}
            />
            <QuestionYesNoAnswer question="Re-operation necessary" answer={values.isReoperationNecessary} />
            <QuestionAnswer
                question="Type of surgery"
                answer={mapReferenceData('redoSurgeryTypes', values.reoperationSurgeryType)}
            />
            <QuestionYesNoAnswer
                question="Was the patient discharged after surgery during 90-day post-op"
                answer={values.postOpDischarged}
            />
            <QuestionDateAnswer question="Discharge date after surgery" answer={values.postOpDischargedDate} />
            <QuestionAnswer
                question="Destination"
                answer={mapReferenceData('destinations', values.postOpDischargedDestinationCode)}
            />
            <QuestionAnswer
                question="Name of other hospital"
                answer={mapReferenceData('hospitals', values.postOpDischargedTransferHospitalName)}
            />
            <QuestionYesNoAnswer
                question="Because of complications"
                answer={values.postOpDischargedTransferBecauseComplications}
            />
            <QuestionAnswer
                question="Re-admission within 30 days after discharge"
                answer={mapReferenceData('readmissionTypes', values.postOpDischargedReadmissionTypeCode)}
            />
            <QuestionAnswer question="Reason for re-admission" answer={values.postOpDischargedReadmissionReason} />

            <QuestionYesNoAnswer
                question="Did the patient die during the 90-day post-op period"
                answer={values.postOpDied}
            />
            <QuestionYesNoAnswer question="In-hospital" answer={values.postOpDiedInHospital} />
            <QuestionDateAnswer question="Date of death" answer={values.postOpDiedDate} />
            <QuestionAnswer question="Cause of death" answer={values.postOpDiedCause} />
            <QuestionYesNoAnswer
                question="Was there adjuvant therapy after surgery"
                answer={values.wasThereAdjuvenantTherapyAfterSurgery}
            />
            <QuestionAnswerAndOther
                question="Type of adjuvant therapy after surgery"
                answer={mapReferenceData('adjuvenantTherapyTypes', values.adjuvenantTherapyTypeCode)}
                other={values.adjuvenantTherapyTypeOtherValue}
            />
            <QuestionAnswer
                question="Was the patient included in a clinical trial"
                answer={values.clinicalTrialWasIncludedCode}
            />
            <QuestionAnswer question="EudraCT number" answer={values.clinicalTrialEudraCTNumber} />
            <QuestionAnswer question="NCT number" answer={values.clinicalTrialNctNumber} />
        </View>
    );
}

type QuestionListPostoperativeComplicationsAnswerProps = QuestionProps & {
    answer: PostoperativeComplication[];
    mapper: ReferenceDataMapper;
};

function QuestionListPostoperativeComplicationsAnswer(props: QuestionListPostoperativeComplicationsAnswerProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}</Text>
            <List>
                {props.answer.map((complication, i) => (
                    <Fragment key={i}>
                        <Item>
                            {complication.otherValue
                                ? `${props.mapper('postoperativeComplications', complication.code)} - ${
                                      complication.otherValue
                                  }`
                                : props.mapper('postoperativeComplications', complication.code)}
                        </Item>
                        {complication.isGlsGradeCode ? (
                            <SubItem key={`${i}-glsgrade`}>{`GLS Grade: ${props.mapper(
                                'isglsGrades',
                                complication.isGlsGradeCode
                            )}`}</SubItem>
                        ) : null}
                        {complication.isGpsGradeCode ? (
                            <SubItem key={`${i}-gpsgrade`}>{`GPS Grade: ${props.mapper(
                                'isgpsGrades',
                                complication.isGpsGradeCode
                            )}`}</SubItem>
                        ) : null}
                    </Fragment>
                ))}
            </List>
        </View>
    ) : null;
}
