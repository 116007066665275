import React from 'react';
import { Button } from '@material-ui/core';

type PatientDetailBackButtonProps = {
    isContained: boolean;
    onGoToPatientDetail: () => void;
};
export default function PatientDetailBackButton(props: PatientDetailBackButtonProps) {
    return (
        <Button
            variant={props.isContained ? 'contained' : 'text'}
            color="primary"
            type="button"
            onClick={props.onGoToPatientDetail}
        >
            Close
        </Button>
    );
}
