import { toISODateFormat } from '../form/ImportPatientDetail.config';
import { Patients } from '../../../../api/types';
import { defaultGender } from '../form/GenderDefaultValue.config';

export type Selectable = {
    id: number;
    display: string;
};
export type ManualValues = {
    id: Number;
    ssn: string;
    uzaNr: string;
    firstName: string;
    surname: string;
    address: string;
    postalCode: string;
    city: string;
    healthInsuranceFundNr: string;
    healthInsuranceFundId: number | null;
    countryId: number | null;
    gender: number;
    dateOfBirth: string;
    dateOfDeath: string | null;
    localPatientNr: string | null;
    telNr: string | null;
    mail: string | null;
    homeDoctorName: string | null;
    homeDoctorContactInfo: string | null;
    attendingDoctorName: string | null;
    attendingDoctorContactInfo: string | null;
};
export type ImportValues = {
    id: Number;
    importId: number;
    ssn: string;
    firstName: string;
    surname: string;
    address: string;
    postalCode: string;
    city: string;
    countryId: number;
    countryAttemptedValue: string;
    gender: number;
    genderAttemptedValue: string;
    dateOfBirth: string;
    dateOfDeath: string;
    healthInsuranceFundId: number;
    healthInsuranceFundAttemptedValue: string;
    healthInsuranceFundNr: string;
    localPatientNr: string | null;
    tel: string;
    mail: string;
    homeDoctorName: string | null;
    homeDoctorContactInfo: string | null;
    attendingDoctorName: string | null;
    attendingDoctorContactInfo: string | null;
};
export type Country = Patients.Country;
export type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;

export function importValuesToPostRequest(values: ImportValues) {
    return {
        zisImportId: values.importId,
        Id: values.id,
        ClientProvidedCountryId: values.countryId,
        ClientProvidedGender: values.gender,
        ClientProvidedHealthInsuranceFundId: values.healthInsuranceFundId,
        ClientProvidedHealthInsuranceFundNr: values.healthInsuranceFundNr,
        HomeDoctorName: values.homeDoctorName,
        HomeDoctorContactInfo: values.homeDoctorContactInfo,
        AttendingDoctorName: values.attendingDoctorName,
        AttendingDoctorContactInfo: values.attendingDoctorContactInfo,
    };
}

export function manualValuesToPostRequest(values: ManualValues) {
    return {
        ...values,
        zisImportId: null,
        dateOfBirth: toISODateFormat(values?.dateOfBirth!),
        dateOfDeath: values?.dateOfDeath ? toISODateFormat(values?.dateOfDeath) : null,
    };
}

export function isValid(input: string | number | null) {
    const defaultValueOfInputType = typeof input === 'string' ? '' : 0;
    return !!input && input !== defaultValueOfInputType;
}
export function mapToImportValues(
    data: Patients.ImportPatientInfo.Response,
    manualValues: ManualValues,
    id: number
): ImportValues {
    return {
        id: id,
        importId: data.importId,
        ssn: isValid(data.ssn) ? data.ssn : manualValues.ssn,
        firstName: isValid(data.firstName) ? data.firstName : manualValues.firstName,
        surname: isValid(data.surname) ? data.surname : manualValues.surname,
        address: isValid(data.address) ? data.address : manualValues.address,
        postalCode: isValid(data.postalCode) ? data.postalCode : manualValues.postalCode,
        city: isValid(data.city) ? data.city : manualValues.city,
        countryId:
            isValid(data.countryId) || isValid(data.countryAttemptedValue)
                ? data.countryId || 0
                : manualValues.countryId!,
        countryAttemptedValue: data.countryAttemptedValue || '',
        gender: data.gender === null ? defaultGender : data.gender,
        genderAttemptedValue: data.genderAttemptedValue || '',
        dateOfBirth: isValid(data.dateOfBirth) ? data.dateOfBirth! : manualValues.dateOfBirth,
        dateOfDeath: isValid(data.dateOfDeath) ? data.dateOfDeath! : manualValues.dateOfDeath || '',
        healthInsuranceFundId:
            isValid(data.healthInsuranceFundId) || isValid(data.healthInsuranceFundAttemptedValue)
                ? data.healthInsuranceFundId || 0
                : manualValues.healthInsuranceFundId!,
        healthInsuranceFundAttemptedValue: data.healthInsuranceFundAttemptedValue || '',
        healthInsuranceFundNr: isValid(data.healthInsuranceFundNr)
            ? data.healthInsuranceFundNr
            : manualValues.healthInsuranceFundNr,
        localPatientNr: manualValues?.localPatientNr || '',
        mail: isValid(data.mail) ? data.mail : manualValues.mail || '',
        tel: isValid(data.tel) ? data.tel : manualValues.telNr || '',
        homeDoctorName: manualValues?.homeDoctorName || '',
        homeDoctorContactInfo: manualValues?.homeDoctorContactInfo || '',
        attendingDoctorName: manualValues?.attendingDoctorName || '',
        attendingDoctorContactInfo: manualValues?.attendingDoctorContactInfo || '',
    };
}
