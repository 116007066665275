import React from 'react';
import styles from './Logo.module.scss';

type Props = {
    logoFileName: string;
};

const Logo: React.FC<Props> = ({ logoFileName }) => {
    const logoFilePath = `${process.env.PUBLIC_URL}/logos/${logoFileName}`;
    return <div className={styles.logo} style={{ backgroundImage: `url(${logoFilePath})` }} />;
};

export default Logo;
