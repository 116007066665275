import MocTypeCode from '../MocTypeCode';
import { Mocs, Patients } from '../../../../api/types';
import { Values } from '../forms/MocForm.config';
import { canHandle as canHandlePdf, getPdfElementFactory } from '../pdf/handling';
import jsxPdfToHtml from '../html/jsxPdfToHtml';

type Patient = Patients.GetPatient.PatientDetail;
type ReferenceData = Mocs.GetReferenceData.Response;

export function canHandle(mocId: number | string, mocType: MocTypeCode) {
    return canHandlePdf(mocId, mocType);
}

export function handle(patient: Patient, values: Values, referenceData: ReferenceData) {
    const pdfElementFactory = getPdfElementFactory(values.mocTypeCode);
    const pdfElement = pdfElementFactory(patient, values, referenceData);
    return jsxPdfToHtml(pdfElement);
}
