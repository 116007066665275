import React from 'react';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import { FormHelperText, TextField } from '@material-ui/core';
import { FastField } from '../Fast';
import useDebouncedOnChange from './useDebouncedOnChange';
import { Values } from '../MocForm.config';
import { useFormikContext, getIn } from 'formik';

type Props = {
    name: string;
    label?: string;
    disabled?: boolean;
    maxLength?: number;
};

const FastTextArea: React.FC<Props> = ({ name, label, disabled = false, maxLength }: Props) => {
    const { values } = useFormikContext<Values>();
    const valueToCheck: string = getIn(values, name);

    return (
        <>
            <FastField
                name={name}
                type="text"
                label={label}
                disabled={disabled}
                variant="outlined"
                fullWidth
                multiline
                rowsMax={10}
                component={FastTextAreaInner}
            />
            {lengthConstraintsAreReached(valueToCheck, maxLength) && (
                <FormHelperText>No more than {maxLength} characters</FormHelperText>
            )}
        </>
    );
};

const FastTextAreaInner: React.ComponentType<TextFieldProps> = (props) => {
    const muiProps = fieldToTextField(props);
    const debouncedMuiProps = useDebouncedOnChange(muiProps);
    return <TextField {...debouncedMuiProps} inputRef={props.inputRef} />;
};

function lengthConstraintsAreReached(valueToCheck: string, maxLength: number | undefined) {
    return valueToCheck && maxLength && valueToCheck.length >= maxLength;
}

export default FastTextArea;
