import React, { PropsWithChildren } from 'react';
import MocSectionContainer from '../../MocSectionContainer';
import { Grid, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { FastTextArea } from '../../../components';
import { TreatmentPlan } from './TreatmentPlan.config';
import styles from './TreatmentPlan.module.css';
import DragAndDropZone from '../../../components/DragAndDropZone';
import { Field } from 'formik';
import FormikTextField from '../../../../../../../styles/components/forms/FormikTextField';
import FormikCheckbox from '../../../../../../../styles/components/forms/FormikCheckbox';

type TreatmentplanProps = PropsWithChildren<{
    isValid: boolean | null;
}>;

export default function Treatmentplan(props: TreatmentplanProps) {
    function renderItem(
        item: TreatmentPlan,
        isSelected: boolean,
        refItem: TreatmentPlan | undefined,
        onClick?: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined
    ) {
        return (
            <MenuItem
                onClick={onClick}
                selected={isSelected}
                style={{
                    backgroundColor: isSelected ? 'rgb(152,152,152)' : '',
                }}
            >
                {refItem !== undefined ? refItem.name : item.code}
            </MenuItem>
        );
    }

    return (
        <MocSectionContainer code="ckzsarcomen-treatment-plan" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FastTextArea
                        name="ckzSarcomenTreatmentPlan.treatmentPlan"
                        label="Behandelplan weke delen en bottumoren"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="ckzSarcomenTreatmentPlan.eligibleForAStudy"
                        type="checkbox"
                        component={FormikCheckbox}
                        label="Komt in aanmerking voor een studie"
                    />
                    <span>Komt in aanmerking voor een studie</span>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        name="ckzSarcomenTreatmentPlan.concerningStudy"
                        type="Text"
                        component={FormikTextField}
                        label="In verband met studie"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '50px' }}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={styles.border}>
                        <InputLabel shrink className={styles.inputLabel}>
                            Weke delen en bottumoren - Gecodeerd behandelplan
                        </InputLabel>
                        <div style={{ padding: '20px 0 20px 0', display: 'flex' }}>
                            <DragAndDropZone<TreatmentPlan>
                                formikName="ckzSarcomenTreatmentPlan.treatmentPlans"
                                refDataName="ckzSarcomenTreatmentPlans"
                                sourceTitle="Kies uit de volgende stappen"
                                destinationTitle="Stel het behandelplan op"
                                renderItem={renderItem}
                            />
                        </div>
                    </FormControl>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
