import { Mocs } from '../../../../../../api/types';
import { toMocBoolean, toValuesBoolean, toValuesString, toValuesDate } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    | 'mocTypeCode'
    | 'requesterMoc'
    | 'netMocCoordinator'
    | 'incidentDate'
    | 'medicalHistory'
    | 'oncologyHistory'
    | 'currentMedicalDiagnose'
    | 'geneticalSyndromeCode'
    | 'recidive'
    | 'recidiveDate'
    | 'metastase'
    | 'metastaseDate'
    | 'notifyingDoctor'
    | 'primaryTumors'
    | 'executedTreatments'
    | 'netMocParticipants'
    | 'icneTworkSigned'
    | 'icdnetSigned'
    | 'secondOpinion'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzSarcomenFields,
    | 'mocTypeCode'
    | 'requesterMoc'
    | 'netMocCoordinator'
    | 'incidentDate'
    | 'medicalHistory'
    | 'oncologyHistory'
    | 'currentMedicalDiagnose'
    | 'geneticalSyndromeCode'
    | 'recidive'
    | 'recidiveDate'
    | 'metastase'
    | 'metastaseDate'
    | 'notifyingDoctor'
    | 'primaryTumors'
    | 'executedTreatments'
    | 'netMocParticipants'
    | 'icneTworkSigned'
    | 'icdnetSigned'
    | 'secondOpinion'
>;

export type FormValues = {
    mocTypeCode: string;
    requesterMoc: string;
    netMocCoordinator: string;
    incidentDate: string | null;
    medicalHistory: string;
    oncologyHistory: string;
    currentMedicalDiagnose: string;
    geneticalSyndromeCode: string;
    recidive: boolean | '';
    recidiveDate: string | null;
    metastase: boolean | '';
    metastaseDate: string | null;
    notifyingDoctor: string;
    primaryTumors: PrimaryTumor[];
    executedTreatments: ExecutedTreatment[];
    netMocParticipants: string;
    icneTworkSigned: boolean | '';
    icdnetSigned: boolean | '';
    secondOpinion: boolean | '';
};

export type PrimaryTumor = {
    primaryTumorCode: string;
    primaryTumorHistologyCode: string;
};

export type ExecutedTreatment = {
    treatmentCode: string;
    treatmentOther: string;
    currentTherapy: boolean;
    startDate: string | null;
    endDate: string | null;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    mocTypeCode: toValuesString(moc.mocTypeCode),
    requesterMoc: toValuesString(moc.requesterMoc),
    netMocCoordinator: toValuesString(moc.netMocCoordinator),
    incidentDate: toValuesDate(moc.incidentDate),
    medicalHistory: toValuesString(moc.medicalHistory),
    oncologyHistory: toValuesString(moc.oncologyHistory),
    currentMedicalDiagnose: toValuesString(moc.currentMedicalDiagnose),
    geneticalSyndromeCode: toValuesString(moc.geneticalSyndromeCode),
    recidive: toValuesBoolean(moc.recidive),
    recidiveDate: toValuesDate(moc.recidiveDate),
    metastase: toValuesBoolean(moc.metastase),
    metastaseDate: toValuesDate(moc.metastaseDate),
    notifyingDoctor: toValuesString(moc.notifyingDoctor),
    primaryTumors: mapListWithMinimum(
        moc.primaryTumors,
        (p) => ({
            primaryTumorCode: p?.primaryTumorCode || '',
            primaryTumorHistologyCode: p?.primaryTumorHistologyCode || '',
        }),
        1 // minItems
    ),
    executedTreatments: moc.executedTreatments,
    netMocParticipants: toValuesString(moc.netMocParticipants),
    icneTworkSigned: toValuesBoolean(moc.icneTworkSigned),
    icdnetSigned: toValuesBoolean(moc.icdnetSigned),
    secondOpinion: toValuesBoolean(moc.secondOpinion),
});

function mapListWithMinimum<TMocItem, TValuesItem>(
    list: TMocItem[],
    mapOrCreate: (item?: TMocItem) => TValuesItem,
    minItems: number = 0
): TValuesItem[] {
    const nrOfEmptyItems = minItems > list.length ? minItems - list.length : 0;
    return [...list.map(mapOrCreate), ...new Array(nrOfEmptyItems).fill(mapOrCreate())];
}

export const getNewMocValues = (): GetMocValues => ({
    mocTypeCode: '',
    requesterMoc: '',
    netMocCoordinator: '',
    incidentDate: null,
    medicalHistory: '',
    oncologyHistory: '',
    currentMedicalDiagnose: '',
    geneticalSyndromeCode: '',
    recidive: null,
    recidiveDate: null,
    metastase: null,
    metastaseDate: null,
    notifyingDoctor: '',
    primaryTumors: [],
    executedTreatments: [],
    netMocParticipants: '',
    icneTworkSigned: null,
    icdnetSigned: null,
    secondOpinion: null,
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    mocTypeCode: form.mocTypeCode,
    requesterMoc: form.requesterMoc,
    netMocCoordinator: form.netMocCoordinator,
    incidentDate: form.incidentDate,
    medicalHistory: form.medicalHistory,
    oncologyHistory: form.oncologyHistory,
    currentMedicalDiagnose: form.currentMedicalDiagnose,
    geneticalSyndromeCode: form.geneticalSyndromeCode,
    recidive: toMocBoolean(form.recidive),
    recidiveDate: form.recidiveDate,
    metastase: toMocBoolean(form.metastase),
    metastaseDate: form.metastaseDate,
    notifyingDoctor: form.notifyingDoctor,
    primaryTumors: form.primaryTumors,
    executedTreatments: form.executedTreatments,
    netMocParticipants: form.netMocParticipants,
    icneTworkSigned: toMocBoolean(form.icneTworkSigned),
    icdnetSigned: toMocBoolean(form.icdnetSigned),
    secondOpinion: toMocBoolean(form.secondOpinion),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
