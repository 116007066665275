import { Mocs } from '../../../../../../api/types';
import { toMocNumber, toValuesNumber } from '../../mappers';
import { ClinicalBiology } from '../MocSectionCode';

export type GetMocValues = Pick<Mocs.GetMoc.LocalMocData & Mocs.GetMoc.NetMocData, 'clinicalBiologies'>;

export type SaveMocValues = Pick<Mocs.SaveMoc.LocalMocFields & Mocs.SaveMoc.NetMocFields, 'clinicalBiologies'>;

export type FormValues = {
    clinicalBiologies: ClinicalBiology[];
};

export type ClinicalBiology = {
    date: string | null;
    typeCode: string;
    typeOther: string;
    value: number | '';
    unitCode: string;
    unitOther: string;
    additionalInfo: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    clinicalBiologies: moc.clinicalBiologies.map(cb => ({...cb, value: toValuesNumber(cb.value)})),
});

export const getNewMocValues = (): GetMocValues => ({
    clinicalBiologies: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    clinicalBiologies: form.clinicalBiologies.map(cb => ({...cb, value: toMocNumber(cb.value)})),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
