import React from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import MocSectionContainer from '../../../sections/MocSectionContainer';
import { Values } from '../../../MocForm.config';
import { PostoperativeComplication } from './PostSurgery.config';
import { useResetOnValue, useResetOnChange, useEmptyArray, useConstant } from '../../useResetEffect';
import { Mocs } from '../../../../../../../api/types';
import { FastSelectField, FastTextField, FastTextArea, YesNoQuestion, CheckBoxes } from '../../../components';
import PatientDischarged from '../PatientDischarged';
import PatientDied from '../PatientDied';
import AdjuvantTherapy from '../AdjuvantTherapy';
import useReferenceData from '../../../../referenceData/useReferenceData';
import usePermissions from '../../../../../../../auth/usePermissions';

type Props = {
    isValid: boolean | null;
};

type IsgpsGrade = Mocs.GetReferenceData.Moc_Ref_IsgpsGrade;
type IsglsGrade = Mocs.GetReferenceData.Moc_Ref_IsglsGrade;
type ClavienDindoClassification = Mocs.GetReferenceData.Moc_Ref_ClavienDindoClassification;
type RefPostoperativeComplication = Mocs.GetReferenceData.Moc_Ref_PostoperativeComplication;

const PostSurgery: React.FC<Props> = React.memo(({ isValid }) => {
    const { indications } = useReferenceData();
    const { values, initialValues } = useFormikContext<Values>();
    const hadSurgery = values.generalInformation.hadSurgery;
    const indication = indications.find(indi => indi.code === values.generalInformation.indicationCode);

    const { pancreasPostSurgery } = initialValues;
    const permissions = usePermissions();
    const { canEditLockedMocReports } = permissions;

    const mccmReport = pancreasPostSurgery.reoperationMccmReport;
    const pathologyReport = pancreasPostSurgery.reoperationPathologyReport;
    const surgeryReport = pancreasPostSurgery.reoperationSurgeryReport;

    useResetOnValue(
        'pancreasPostSurgery.hadPostoperativeComplications',
        false,
        useEmptyArray('pancreasPostSurgery.postoperativeComplications')
    );

    useResetOnValue(
        'pancreasPostSurgery.isReoperationNecessary',
        false,
        useConstant('pancreasPostSurgery.reoperationSurgeryType', ''),
        useConstant('pancreasPostSurgery.reoperationMccmReport', ''),
        useConstant('pancreasPostSurgery.reoperationPathologyReport', ''),
        useConstant('pancreasPostSurgery.reoperationSurgeryReport', '')
    );

    useResetOnValue(
        'pancreasPostSurgery.postOpDischarged',
        false,
        useConstant('pancreasPostSurgery.postOpDischargedDate', null),
        useConstant('pancreasPostSurgery.postOpDischargedDestinationCode', ''),
        useConstant('pancreasPostSurgery.postOpDischargedTransferHospitalName', ''),
        useConstant('pancreasPostSurgery.postOpDischargedTransferBecauseComplications', ''),
        useConstant('pancreasPostSurgery.postOpDischargedReadmissionTypeCode', ''),
        useConstant('pancreasPostSurgery.postOpDischargedReadmissionReason', '')
    );

    useResetOnChange(
        'pancreasPostSurgery.postOpDischargedDestinationCode',
        useConstant('pancreasPostSurgery.postOpDischargedTransferHospitalName', ''),
        useConstant('pancreasPostSurgery.postOpDischargedTransferBecauseComplications', '')
    );

    useResetOnChange(
        'pancreasPostSurgery.postOpDischargedReadmissionTypeCode',
        useConstant('pancreasPostSurgery.postOpDischargedReadmissionReason', '')
    );

    useResetOnValue(
        'pancreasPostSurgery.postOpDied',
        false,
        useConstant('pancreasPostSurgery.postOpDiedInHospital', ''),
        useConstant('pancreasPostSurgery.postOpDiedDate', null),
        useConstant('pancreasPostSurgery.postOpDiedCause', '')
    );

    useResetOnValue(
        'pancreasPostSurgery.wasThereAdjuvenantTherapyAfterSurgery',
        false,
        useConstant('pancreasPostSurgery.adjuvenantTherapyTypeCode', '')
    );

    useResetOnChange(
        'pancreasPostSurgery.clinicalTrialWasIncludedCode',
        useConstant('pancreasPostSurgery.clinicalTrialEudraCTNumber', ''),
        useConstant('pancreasPostSurgery.clinicalTrialNctNumber', '')
    );

    if (!hadSurgery) {
        return null;
    }

    return (
        <MocSectionContainer code="post-surgery" isValid={isValid}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="pancreasPostSurgery.hadPostoperativeComplications"
                        question="Postoperative complications (90 days post-op, in-hospital complications)"
                    >
                        <CheckBoxes<RefPostoperativeComplication, PostoperativeComplication>
                            name="pancreasPostSurgery.postoperativeComplications"
                            referenceDataPath="postoperativeComplications"
                            referenceDataFilter={reference => reference.isPancreas}
                            create={({ code }) => ({
                                code,
                                isGlsGradeCode: '',
                                isGpsGradeCode: '',
                                otherValue: ''
                            })}
                            detail={({ code }, _, valuePath) => {
                                switch (code) {
                                    case 'Fistula':
                                    case 'Haemorrhage':
                                    case 'DGE': {
                                        return (
                                            <FastSelectField<IsgpsGrade>
                                                name={`${valuePath}.isGpsGradeCode`}
                                                placeholder="ISGPS Grade"
                                                prompt="Select an ISGPS grade"
                                                referenceDataPath="isgpsGrades"
                                                referenceDataFilter={grade => {
                                                    if (code === 'Fistula') {
                                                        return grade.code !== 'GradeA';
                                                    }
                                                    return true;
                                                }}
                                            />
                                        );
                                    }
                                    case 'Bile_leakage': {
                                        return (
                                            <FastSelectField<IsglsGrade>
                                                name={`${valuePath}.isGlsGradeCode`}
                                                placeholder="ISGLS Grade"
                                                prompt="Select an ISGLS grade"
                                                referenceDataPath="isglsGrades"
                                            />
                                        );
                                    }
                                    case 'Other': {
                                        return (
                                            <FastTextField
                                                name={`${valuePath}.otherValue`}
                                                label="Other postoperative complication"
                                            />
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </YesNoQuestion>
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<ClavienDindoClassification>
                        name="pancreasPostSurgery.generalClavienDindoClassificationCode"
                        placeholder="General Clavien-Dindo classification (90 days post-op, in-hospital complications)"
                        prompt="Select a Clavien-Dindo classification"
                        referenceDataPath="clavienDindoClassifications"
                    />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion name="pancreasPostSurgery.isReoperationNecessary" question="Re-operation necessary?">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FastTextField
                                    name="pancreasPostSurgery.reoperationSurgeryType"
                                    label="Type of surgery"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    Please upload the following report(s) without patient identification variables
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="pancreasPostSurgery.reoperationMccmReport"
                                    label="MC/CM report"
                                    disabled={mccmReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="pancreasPostSurgery.reoperationPathologyReport"
                                    label="Pathology report"
                                    disabled={pathologyReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="pancreasPostSurgery.reoperationSurgeryReport"
                                    label="Surgery report"
                                    disabled={surgeryReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                        </Grid>
                    </YesNoQuestion>
                </Grid>
                <Grid item xs={12}>
                    <PatientDischarged section="pancreasPostSurgery" />
                </Grid>
                <Grid item xs={12}>
                    <PatientDied section="pancreasPostSurgery" />
                </Grid>
                <Grid item xs={12}>
                    <AdjuvantTherapy section="pancreasPostSurgery" indicationCode={indication && indication.code} />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default PostSurgery;
