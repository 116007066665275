import React from 'react';
import cs from 'classnames';
import { Typography } from '@material-ui/core';
import styles from './PasswordRequirementsList.module.scss';

type Props = {
    value?: string;
};

function validationClassName(regExp: RegExp, value?: string) {
    let isValid = undefined;
    if (value && value.length >= 6) {
        isValid = !!value.match(regExp);
    }
    return cs(styles.rule, { [styles.valid]: isValid === true, [styles.invalid]: isValid === false });
}

const PasswordRequirementsList: React.FC<Props> = ({ value }) => (
    <ul>
        <li className={validationClassName(/^.{6,}$/, value)}>
            <Typography variant="subtitle2">Must be at least 6 characters long.</Typography>
        </li>
        <li className={validationClassName(/(?=.*\d)/, value)}>
            <Typography variant="subtitle2">Requires at least one digit.</Typography>
        </li>
        <li className={validationClassName(/(?=.*[a-z])/, value)}>
            <Typography variant="subtitle2">Requires at least one lowercase character.</Typography>
        </li>
        <li className={validationClassName(/(?=.*[A-Z])/, value)}>
            <Typography variant="subtitle2">Requires at least one uppercase character.</Typography>
        </li>
        <li className={validationClassName(/(?=.*\W)/, value)}>
            <Typography variant="subtitle2">Requires at least one special character.</Typography>
        </li>
    </ul>
);

export default PasswordRequirementsList;
