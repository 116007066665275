import * as Yup from 'yup';

export type Values = {
    currentPassword: string;
    password: string;
    confirmPassword: string;
};

export const initialValues: Values = {
    currentPassword: '',
    password: '',
    confirmPassword: ''
};

/* 
Below regex:
- At least one digit
- At least one lowercase character
- At least one uppercase character
- At least one special character
*/
export const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    password: Yup.string()
        .required('Password is required')
        .min(6, 'Not a valid password')
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/, 'Not a valid password'),
    confirmPassword: Yup.string()
        .required('Confirmed password is required')
        .test('equal_to_password', 'Passwords do not match', function(value) {
            const { password } = this.parent as Values;
            return password === value;
        })
});
