import { FormValues } from './Annex55.config';

export const getIsCmOrPmGreaterOrEqualTo1 = (annex55: FormValues) => {
    const valuesPmCodes = ['3', '4', '5', '6', '9', '10'];
    const valuesCmCodes = ['3', '4', '5', '6', '11', '12'];
    return !!valuesPmCodes.find((x) => x === annex55.pmCode) || !!valuesCmCodes.find((x) => x === annex55.cmCode);
};

export const getFields = (lesionToTreatCode: string, annex55: FormValues) => {
    const isPrimaryTumour = lesionToTreatCode === 'Primary';
    const isRelapse = lesionToTreatCode === 'Relapse';
    const isMetastasis = lesionToTreatCode === 'Meta';

    const isCmOrPmGreaterOrEqualTo1 = getIsCmOrPmGreaterOrEqualTo1(annex55);

    return {
        isEnabled: {
            incidenceDate: true,
            incidenceDateRelapse: isRelapse,
            basisOfDiagnosis: isPrimaryTumour,
            whoScoreAtDiagnosis: isPrimaryTumour,
            laterality: isPrimaryTumour,
            differentiation: isPrimaryTumour,
            hasPeriodWithoutSymptoms: isRelapse || isMetastasis,
            location: isRelapse || isMetastasis,
            c_P_Yp: isPrimaryTumour || isMetastasis,
            otherClassification: isPrimaryTumour,
            stadium: isPrimaryTumour,
            executedTreatments: isPrimaryTumour,
            dateMoc: true,
            sourceHospital: true,
            reasonMoc: true,
            treatmentPlans: true,
            primaryTumourLocation: true,
            primaryTumourHistology: true,
            metastasisLocations: isPrimaryTumour && isCmOrPmGreaterOrEqualTo1,
        },
        label: {
            treatments: isPrimaryTumour
                ? 'Verder behandelingsplan'
                : 'Behandelingsplan in het kader van het huidig probleem (intentie tot)',
        },
        isPrimaryTumour,
        isRelapse,
        isMetastasis,
    };
};
