import { Grid } from '@material-ui/core';
import React from 'react';
import ItemList from '../components/ItemList';
import { CytologyDate } from '../sections/pathology/Pathology.config';
import CytologyTypeList from './CytologyTypeList';
import { FastDateField, FastSelectField, FastTextArea, FileNameQuestion } from '../components';

function detail(name: string) {
    return (
        <>
            <Grid item xs={12} md={4}>
                <FastDateField name={`${name}.date`} label="Datum"></FastDateField>
            </Grid>
            <Grid item xs={12} md={8}>
                <FastSelectField
                    name={`${name}.locationCode`}
                    prompt="Selecteer locatie cytologie"
                    placeholder="Locatie cytologie"
                    referenceDataPath="primaryTumors"
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <FastTextArea name={`${name}.comment`} label="Commentaar"></FastTextArea>
            </Grid>
            <Grid item xs={12}>
                <FileNameQuestion type="Cytology" question="Bestand opladen" name={`${name}.fileId`} />
            </Grid>
            <Grid item xs={12}>
                <CytologyTypeList name={`${name}.cytologyTypes`}></CytologyTypeList>
            </Grid>
        </>
    );
}

function create(): CytologyDate {
    return {
        date: null,
        comment: '',
        locationCode: '',
        cytologyTypes: [],
        fileId: null,
    };
}

export default function CytologyDateList() {
    return (
        <ItemList
            name="pathology.cytologyDates"
            minItems={0}
            addText="Extra cytologie toevoegen"
            title="Cytologie"
            detail={detail}
            create={create}
        />
    );
}
