import { Mocs } from '../../../../../../../api/types';
import { toValuesString } from '../../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    'treatmentPlan' | 'eligibleForAStudy' | 'concerningStudy' | 'treatmentPlans'
>;
export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzSarcomenFields,
    'treatmentPlan' | 'eligibleForAStudy' | 'concerningStudy' | 'treatmentPlans'
>;
export type TreatmentPlan = {
    id: number;
    code: string;
    name: string;
};
export type FormValues = {
    treatmentPlan: string;
    eligibleForAStudy: boolean;
    concerningStudy: string;
    treatmentPlans: TreatmentPlan[];
};
export const getNewMocValues = (): GetMocValues => ({
    treatmentPlan: '',
    eligibleForAStudy: false,
    concerningStudy: '',
    treatmentPlans: [],
});
export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    treatmentPlan: form.treatmentPlan,
    eligibleForAStudy: form.eligibleForAStudy,
    concerningStudy: form.concerningStudy,
    treatmentPlans: mapTreatmentPlans(form.treatmentPlans),
});

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    treatmentPlan: toValuesString(moc.treatmentPlan),
    eligibleForAStudy: moc.eligibleForAStudy,
    concerningStudy: toValuesString(moc.concerningStudy),
    treatmentPlans: moc.treatmentPlans
        .sort((x, y) => x.sequence - y.sequence)
        .map((t, index) => ({
            id: index,
            code: t.treatmentPlanCode,
            name: '',
        })),
});
export const initialValues: FormValues = mapInitialValues(getNewMocValues());

function mapTreatmentPlans(treatmentPlans: TreatmentPlan[]) {
    return treatmentPlans.map((t, key) => ({ treatmentPlanCode: t.code, sequence: key }));
}
