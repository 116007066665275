import * as Yup from 'yup';

export type Values = {
    email: string;
    password: string;
};

export const initialValues: Values = {
    email: '',
    password: ''
};

export const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required')
});
