import MocSectionCode, { getSectionCodes, mocSectionIsValidatableFor } from './MocSectionCode';
import MocTypeCode from '../../MocTypeCode';

type ValidationError = {
    sectionCode: string;
    fieldCode: string;
};

type MocValues = {
    mocTypeCode: MocTypeCode;
} & MocSectionValues;

type MocSectionValues = {
    generalInformation: {
        hadSurgery: boolean | '';
        lesionToTreatCode: string;
    };
};

export type ValidatedSection = {
    code: MocSectionCode;
    isValid: boolean;
    invalidFields: string[];
};

export type Section = {
    code: MocSectionCode;
    isValid: boolean;
    isInvalid: boolean;
    invalidFields: string[];
};

export function mapValidationToSections(
    errors: ValidationError[],
    mocTypeCode: MocTypeCode,
    mocSectionValues: MocSectionValues
): ValidatedSection[] {
    const sectionCodes = getSectionCodes(mocTypeCode, mocSectionValues);
    const sections = sectionCodes
        .filter((code) => mocSectionIsValidatableFor(code))
        .map((code) => {
            const sectionErrors = errors.filter((e) => e.sectionCode === code);
            const sectionErrorFields = sectionErrors.map((e) => e.fieldCode);
            return {
                code,
                isValid: !sectionErrors.length,
                invalidFields: sectionErrorFields,
            };
        });
    return sections;
}

export function combineValidationToSections(validatedSections: ValidatedSection[], mocValues: MocValues): Section[] {
    const sectionCodes = getSectionCodes(mocValues.mocTypeCode, mocValues);
    const sections = sectionCodes.map((code) => {
        const validatedSection = validatedSections.find((vs) => vs.code === code);
        return {
            code,
            isValid: !!validatedSection && validatedSection.isValid,
            isInvalid: !!validatedSection && !validatedSection.isValid,
            invalidFields: validatedSection ? validatedSection.invalidFields : [],
        };
    });
    return sections;
}
