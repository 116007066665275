import { useReducer } from 'react';

export type DataState<T> = {
    hasLoaded: boolean;
    isLoading: boolean;
    isError: boolean;
    data: T;
};

export const loading = () => ({ type: 'LOADING' });
export const error = () => ({ type: 'ERROR' });
export function loaded<T>(data: T) {
    return { type: 'LOADED', data };
}

export default function useDataReducer<T>() {
    return useReducer(
        (s: DataState<T>, a): DataState<T> => {
            switch (a.type) {
                case 'LOADING':
                    return { ...s, isLoading: true, isError: false };
                case 'ERROR':
                    return { ...s, isLoading: false, isError: true };
                case 'LOADED':
                    return { hasLoaded: true, isLoading: false, isError: false, data: a.data };
                default:
                    return s;
            }
        },
        { hasLoaded: false, isLoading: false, isError: false, data: {} as T }
    );
}
