import React from 'react';
import { useFormikContext } from 'formik';
import { Values as MocValues, MocFileType } from '../MocForm.config';
import FileFieldBase from './FileFieldBase';

type Props = {
    type: MocFileType;
};

const FileField: React.FC<Props> = ({ type }) => {
    const { values } = useFormikContext<MocValues>();

    const hasFile = !!values && values.mocFiles.findIndex((f) => f.type === type) !== -1;
    const file = hasFile ? values.mocFiles.filter((f) => f.type === type)[0] : null;

    return <FileFieldBase type={type} file={file} />;
};

export default FileField;
