import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import { Mocs } from '../../../../../../../api/types';
import MocSectionContainer from '../../MocSectionContainer';
import { TreatmentDetails, EndoscopicTreatmentDetails } from './Surgery.config';
import { CodeAndOtherValue } from '../../../MocForm.config';
import { Values } from '../../../MocForm.config';
import useReferenceData from '../../../../referenceData/useReferenceData';
import { useResetOnValue, useConstant, useResetOnChange, useEmptyArray } from '../../useResetEffect';
import {
    YesNoQuestion,
    CheckBoxes,
    DateQuestion,
    Question,
    RadioButtons,
    FastTextField,
    FastSelectField,
    FastNumberField,
    FastDateField,
} from '../../../components';

type TreatmentType = Mocs.GetReferenceData.Moc_Ref_TreatmentType;
type EndoscopicTreatmentType = Mocs.GetReferenceData.Moc_Ref_EndoscopicTreatmentType;
type SurgeryType = Mocs.GetReferenceData.Moc_Ref_SurgeryType;
type NomenclatureCode = Mocs.GetReferenceData.Moc_Ref_NomenclatureCode;
type OpenSurgeryType = Mocs.GetReferenceData.Moc_Ref_OpenSurgeryType;
type MinimallyInvasiveSurgeryType = Mocs.GetReferenceData.Moc_Ref_MinimallyInvasiveSurgeryType;
type SurgeryMode = Mocs.GetReferenceData.Moc_Ref_SurgeryMode;
type Oesophagectomy = Mocs.GetReferenceData.Moc_Ref_Oesophagectomy;
type TumourLocation = Mocs.GetReferenceData.Moc_Ref_TumourLocation;
type SurgeryIntention = Mocs.GetReferenceData.Moc_Ref_SurgeryIntention;
type MandardGrade = Mocs.GetReferenceData.Moc_Ref_MandardGrade;
type Gastrectomy = Mocs.GetReferenceData.Moc_Ref_Gastrectomy;
type Field = Mocs.GetReferenceData.Moc_Ref_Field;
type LymphadenectomyRegion = Mocs.GetReferenceData.Moc_Ref_LymphadenectomyRegion;
type OesophagealConduit = Mocs.GetReferenceData.Moc_Ref_OesophagealConduit;
type Anastomosis = Mocs.GetReferenceData.Moc_Ref_Anastomosis;
type ResectionType = Mocs.GetReferenceData.Moc_Ref_ResectionType;

type Props = {
    isValid: boolean | null;
};

const Surgery: React.FC<Props> = React.memo(({ isValid }) => {
    const referenceData = useReferenceData();
    const { values } = useFormikContext<Values>();
    const { generalInformation, oesophagusSurgery } = values;

    const hadSurgery = generalInformation.hadSurgery;

    const indicationCode = generalInformation.indicationCode;
    const indication = referenceData.indications.find((ind) => ind.code === indicationCode);
    const isIndicationMalignantTumour = !!indication && indication.code === 'Malignant';
    const isIndicationBenignTumour = !!indication && indication.code === 'Benign';
    const isIndicationOtherBenignTumour = !!indication && indication.code === 'Other_benign';

    const surgeryIntentionCode = oesophagusSurgery.surgeryIntentionCode;
    const surgeryIntention = referenceData.surgeryIntentions.find((ind) => ind.code === surgeryIntentionCode);
    const isSurgeryIntentionPostInduction = !!surgeryIntention && surgeryIntention.code === 'Post_induction';
    const hadOtherTreatement = oesophagusSurgery.hadOtherTreatmentBeforeThisSurgery;
    const otherTreatmentWasChemoRadioOrTargeted = oesophagusSurgery.treatmentTypes.some(
        (treatmentType) =>
            treatmentType.code === 'Chemo' || treatmentType.code === 'Radio' || treatmentType.code === 'Targeted'
    );

    useResetOnValue(
        'oesophagusSurgery.hadOtherTreatmentBeforeThisSurgery',
        false,
        useEmptyArray('oesophagusSurgery.treatmentTypes')
    );

    useResetOnChange('oesophagusSurgery.surgeryIntentionCode', useConstant('oesophagusSurgery.mandardGradeCode', ''));

    useResetOnChange(
        'oesophagusSurgery.surgeryTypeCode',
        useConstant('oesophagusSurgery.minimallyInvasiveSurgeryTypeCode', ''),
        useEmptyArray('oesophagusSurgery.openSurgeryTypes'),
        useConstant('oesophagusSurgery.reasonForConversion', '')
    );

    useResetOnValue(
        'oesophagusSurgery.wasResectionPerformedPathological',
        true,
        useConstant('oesophagusSurgery.wasProximalMarginInvolved', '')
    );

    useResetOnValue(
        'oesophagusSurgery.hadLymphadenectomy',
        false,
        useConstant('oesophagusSurgery.lymphadenectomyFieldCode', ''),
        useEmptyArray('oesophagusSurgery.lymphadenectomyRegions'),
        useConstant('oesophagusSurgery.numberOfLocoRegionalLymphNodesRetrieved', 0),
        useConstant('oesophagusSurgery.numberOfMetastaticLocoRegionalLymphNodes', 0)
    );

    useResetOnValue('oesophagusSurgery.hadOtherResections', false, useEmptyArray('oesophagusSurgery.resectionTypes'));

    if (!hadSurgery) {
        return null;
    }

    return (
        <MocSectionContainer code="surgery" isValid={isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="oesophagusSurgery.isPETPerformedPriorToSurgery"
                        question="PET/CT performed prior to surgery?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="oesophagusSurgery.hadOtherTreatmentBeforeThisSurgery"
                        question="Did the patient receive any other treatment modality before this surgical procedure?"
                    >
                        <CheckBoxes<TreatmentType, TreatmentDetails>
                            referenceDataPath="treatmentTypes"
                            referenceDataFilter={(reference) => reference.isOesophagus}
                            name="oesophagusSurgery.treatmentTypes"
                            create={(reference) => ({
                                code: reference.code,
                                typeOfSurgery: '',
                                startDate: null,
                                latestTreatmentDate: null,
                                latestSurgeryDate: null,
                                otherValue: '',
                                otherDate: null,
                                endoscopicTreatmentTypes: [],
                            })}
                            detail={({ code: treatmentTypeCode }, _, treatmentTypeValuePath) => {
                                switch (treatmentTypeCode) {
                                    case 'Chemo':
                                    case 'Radio':
                                    case 'Targeted': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.startDate`}
                                                        label="Start date"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.latestTreatmentDate`}
                                                        label="Date latest treatment"
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    case 'Surgery': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastTextField
                                                        name={`${treatmentTypeValuePath}.typeOfSurgery`}
                                                        label="Type of surgery"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.latestSurgeryDate`}
                                                        label="Date latest surgery"
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    case 'Endoscopic': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <CheckBoxes<EndoscopicTreatmentType, EndoscopicTreatmentDetails>
                                                        referenceDataPath="endoscopicTreatmentTypes"
                                                        name={`${treatmentTypeValuePath}.endoscopicTreatmentTypes`}
                                                        create={(reference) => ({
                                                            code: reference.code,
                                                            latestTreatmentDate: null,
                                                            otherValue: '',
                                                        })}
                                                        detail={(
                                                            { code: endoTreatmentTypeCode },
                                                            __,
                                                            endoTreatmentTypeValuePath
                                                        ) => (
                                                            <Grid container spacing={3}>
                                                                {endoTreatmentTypeCode === 'Ablation' && (
                                                                    <Grid item xs={12}>
                                                                        <FastTextField
                                                                            name={`${endoTreatmentTypeValuePath}.otherValue`}
                                                                            label="Other"
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                <Grid item xs={12}>
                                                                    <FastDateField
                                                                        name={`${endoTreatmentTypeValuePath}.latestTreatmentDate`}
                                                                        label="Date latest treatment"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    case 'Other': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastTextField
                                                        name={`${treatmentTypeValuePath}.otherValue`}
                                                        label="Other"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.otherDate`}
                                                        label="Date other treatment modality"
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </YesNoQuestion>
                </Grid>

                <Grid item xs={12}>
                    <DateQuestion name="oesophagusSurgery.dateOfSurgery" question="Date of surgery" />
                </Grid>

                {(isIndicationMalignantTumour || isIndicationOtherBenignTumour || isIndicationBenignTumour) && (
                    <>
                        <Grid item xs={12}>
                            <FastSelectField<TumourLocation>
                                name="oesophagusSurgery.tumourLocationCode"
                                prompt="Select a location"
                                placeholder="Tumour location"
                                referenceDataPath="tumourLocations"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FastSelectField<SurgeryIntention>
                                name="oesophagusSurgery.surgeryIntentionCode"
                                prompt="Select a surgery intention"
                                placeholder="Surgery intention"
                                referenceDataPath="surgeryIntentions"
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <FastSelectField<SurgeryMode>
                        name="oesophagusSurgery.surgeryModeCode"
                        prompt="Select a surgery mode"
                        placeholder="Surgery mode"
                        referenceDataPath="surgeryModes"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Question question="Type of surgery">
                        <RadioButtons<SurgeryType>
                            name="oesophagusSurgery.surgeryTypeCode"
                            referenceDataPath="surgeryTypes"
                            detail={(reference) => {
                                switch (reference.code) {
                                    case 'MIS': {
                                        return (
                                            <FastSelectField<MinimallyInvasiveSurgeryType>
                                                name="oesophagusSurgery.minimallyInvasiveSurgeryTypeCode"
                                                prompt="Select a type"
                                                referenceDataPath="minimallyInvasiveSurgeryTypes"
                                                referenceDataFilter={(reference) => reference.isOesophagus}
                                            />
                                        );
                                    }
                                    case 'Open': {
                                        return (
                                            <CheckBoxes<OpenSurgeryType, { code: string }>
                                                name="oesophagusSurgery.openSurgeryTypes"
                                                referenceDataPath="openSurgeryTypes"
                                                create={(reference) => ({ code: reference.code })}
                                            />
                                        );
                                    }
                                    case 'Conversion': {
                                        return (
                                            <FastTextField
                                                name="oesophagusSurgery.reasonForConversion"
                                                label="Reason for conversion"
                                            />
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </Question>
                </Grid>

                <Grid item xs={12}>
                    <Question question="Nomenclature code">
                        <RadioButtons<NomenclatureCode>
                            name="oesophagusSurgery.nomenclatureCode"
                            referenceDataPath="nomenclatureCodes"
                            referenceDataFilter={(x) => x.isOesophagus}
                        />
                    </Question>
                </Grid>

                <Grid item xs={12}>
                    <FastSelectField<Oesophagectomy>
                        name="oesophagusSurgery.oesophagectomyCode"
                        prompt="Select a Oesophagectomy"
                        placeholder="Oesophagectomy"
                        referenceDataPath="oesophagectomies"
                    />
                </Grid>

                {isIndicationMalignantTumour && (
                    <>
                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="oesophagusSurgery.wasResectionPerformedSurgical"
                                question="Was a macroscopic R0-resection performed (surgical)?"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="oesophagusSurgery.wasResectionPerformedPathological"
                                question="Was a microscopic R0-resection performed (pathological)?"
                                variant="no"
                            >
                                <YesNoQuestion
                                    name="oesophagusSurgery.wasProximalMarginInvolved"
                                    question="Was the proximal margin involved?"
                                />
                            </YesNoQuestion>
                        </Grid>

                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="oesophagusSurgery.wasLymphovascularInvasion"
                                question="Was there lymphovascular invasion?"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="oesophagusSurgery.wasPerineuralInvasion"
                                question="Was there perineural invasion?"
                            />
                        </Grid>
                    </>
                )}

                {(isSurgeryIntentionPostInduction || (hadOtherTreatement && otherTreatmentWasChemoRadioOrTargeted)) && (
                    <Grid item xs={12}>
                        <FastSelectField<MandardGrade>
                            name="oesophagusSurgery.mandardGradeCode"
                            placeholder="Mandard grade"
                            prompt="Select a grade"
                            referenceDataPath="mandardGrades"
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FastSelectField<Gastrectomy>
                        name="oesophagusSurgery.gastrectomyCode"
                        placeholder="Gastrectomy"
                        prompt="Select a gastrectomy"
                        referenceDataPath="gastrectomies"
                    />
                </Grid>

                <Grid item xs={12}>
                    <YesNoQuestion name="oesophagusSurgery.hadLymphadenectomy" question="Lymphadenectomy">
                        <Grid container>
                            <Grid item xs={12}>
                                <FastSelectField<Field>
                                    name="oesophagusSurgery.lymphadenectomyFieldCode"
                                    placeholder="Field"
                                    prompt="Select a field"
                                    referenceDataPath="fields"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckBoxes<LymphadenectomyRegion, { code: string }>
                                    name="oesophagusSurgery.lymphadenectomyRegions"
                                    referenceDataPath="lymphadenectomyRegions"
                                    referenceDataFilter={(reference) => reference.isOesophagus}
                                    create={({ code }) => ({ code: code })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <FastNumberField
                                            name="oesophagusSurgery.numberOfLocoRegionalLymphNodesRetrieved"
                                            label="Number of loco-regional lymph nodes retrieved"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FastNumberField
                                            name="oesophagusSurgery.numberOfMetastaticLocoRegionalLymphNodes"
                                            label="Number of metastatic loco-regional lymph nodes"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </YesNoQuestion>
                </Grid>

                <Grid item xs={12}>
                    <YesNoQuestion name="oesophagusSurgery.hadOtherResections" question="Other resections">
                        <CheckBoxes<ResectionType, CodeAndOtherValue>
                            name="oesophagusSurgery.resectionTypes"
                            referenceDataPath="resectionTypes"
                            create={({ code }) => ({ code, otherValue: '' })}
                            detail={({ isOther }, _, valuePath) =>
                                isOther && (
                                    <FastTextField name={`${valuePath}.otherValue`} label="Specify other resection" />
                                )
                            }
                        />
                    </YesNoQuestion>
                </Grid>

                <Grid item xs={12}>
                    <FastSelectField<OesophagealConduit>
                        name="oesophagusSurgery.oesophagealConduitCode"
                        placeholder="Oesophageal conduit"
                        prompt="Select a oesophageal conduit"
                        referenceDataPath="oesophagealConduits"
                    />
                </Grid>

                <Grid item xs={12}>
                    <FastSelectField<Anastomosis>
                        name="oesophagusSurgery.anastomosisCode"
                        placeholder="Anastomosis"
                        prompt="Select an anastomosis"
                        referenceDataPath="anastomoses"
                        detail={(reference) =>
                            reference.isOther && (
                                <FastTextField name="oesophagusSurgery.anastomosisOtherValue" label="Other" />
                            )
                        }
                    />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default Surgery;
