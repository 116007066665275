import React from 'react';
import { Formik, FormikHelpers, Form, Field } from 'formik';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { Identity } from '../../../api/types';
import FormikTextField from '../../../styles/components/forms/FormikTextField';
import { signin } from '../../../api/account';
import { getCurrentUser } from '../../../api/identity';
import useApi from '../../../api/useApi';
import styles from './SignInForm.module.scss';
import { initialValues, Values, validationSchema } from './SignInForm.config';

export type User = Identity.GetCurrentUser.Response;

type Props = {
    onSignedIn: (user: User) => void;
};

const SignInForm: React.FC<Props> = ({ onSignedIn }) => {
    const api = useApi();
    const handleSubmit = async (values: Values, helpers: FormikHelpers<Values>) => {
        helpers.setSubmitting(true);
        const signInResponse = await signin(api, { ...values, rememberMe: false });
        const isSignedIn = signInResponse.status >= 200 && signInResponse.status < 400;
        if (isSignedIn) {
            const userResponse = await getCurrentUser(api);
            const user = userResponse.data;
            onSignedIn(user);
        } else {
            helpers.setStatus({ error: true });
        }
    };
    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ values, status, isSubmitting }) => {
                const canEdit = !isSubmitting;
                const canSubmit = !isSubmitting && !!values.email && !!values.password;
                return (
                    <Form>
                        <div className={styles.row}>
                            <Field
                                name="email"
                                label="Email address"
                                autoComplete="email"
                                autoFocus
                                fullWidth
                                disabled={!canEdit}
                                component={FormikTextField}
                            />
                        </div>
                        <div className={styles.row}>
                            <Field
                                name="password"
                                type="password"
                                label="Password"
                                autoComplete="current-password"
                                fullWidth
                                disabled={!canEdit}
                                component={FormikTextField}
                            />
                        </div>
                        {status && status.error && (
                            <div className={styles.error_row}>
                                <Typography variant="body1">
                                    <strong>!</strong> Invalid sign in credentials
                                </Typography>
                            </div>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!canSubmit}
                            className={styles.submit_btn}
                        >
                            Sign in
                        </Button>
                        {isSubmitting && (
                            <div className={styles.loading_row}>
                                <CircularProgress />
                            </div>
                        )}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SignInForm;
