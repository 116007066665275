import { Mocs } from '../../../../../../api/types';
import { toMocNumber, toValuesNumber, toValuesString } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    'fnclccGrade' | 'ptCode' | 'pmCode' | 'pnCode' | 'biopsyDates' | 'resectionDates'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzSarcomenFields,
    'fnclccGrade' | 'ptCode' | 'pmCode' | 'pnCode' | 'biopsyDates' | 'resectionDates'
>;

export type FormValues = {
    fnclccGrade: string;
    ptCode: string;
    pnCode: string;
    pmCode: string;
    biopsyDates: BiopsyDate[];
    resectionDates: ResectionDate[];
};

export type BiopsyDate = {
    date: string | null;
    locationCode: string;
    comment: string;
    biopsyTypes: BiopsyType[];
    fileId: number | null;
};

export type BiopsyType = {
    valueCode: string;
    valueOther: number | '';
    typeCode: string;
    typeOther: string;
    unit: string;
    info: string;
};

export type ResectionDate = {
    date: string | null;
    locationCode: string;
    kindCode: string;
    comment: string;
    resectionTypes: ResectionType[];
    fileId: number | null;
};

export type ResectionType = {
    typeCode: string;
    typeOther: string;
    valueCode: string;
    valueOther: number | '';
    unit: string;
    info: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    fnclccGrade: toValuesString(moc.fnclccGrade),
    ptCode: toValuesString(moc.ptCode),
    pmCode: toValuesString(moc.pmCode),
    pnCode: toValuesString(moc.pnCode),
    biopsyDates: moc.biopsyDates.map((biopsyDate) => ({
        ...biopsyDate,
        biopsyTypes: biopsyDate.biopsyTypes.map((biopsyType) => ({
            ...biopsyType,
            valueOther: toValuesNumber(biopsyType.valueOther),
        })),
    })),
    resectionDates: moc.resectionDates.map((resectionDate) => ({
        ...resectionDate,
        resectionTypes: resectionDate.resectionTypes.map((resectionType) => ({
            ...resectionType,
            valueOther: toValuesNumber(resectionType.valueOther),
        })),
    })),
});

export const getNewMocValues = (): GetMocValues => ({
    fnclccGrade: '',
    ptCode: '',
    pnCode: '',
    pmCode: '',
    biopsyDates: [],
    resectionDates: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    fnclccGrade: form.fnclccGrade,
    ptCode: form.ptCode,
    pnCode: form.pnCode,
    pmCode: form.pmCode,
    biopsyDates: form.biopsyDates.map((biopsyDate) => ({
        ...biopsyDate,
        biopsyTypes: biopsyDate.biopsyTypes.map((biopsyType) => ({
            ...biopsyType,
            valueOther: toMocNumber(biopsyType.valueOther),
        })),
    })),
    resectionDates: form.resectionDates.map((resectionDate) => ({
        ...resectionDate,
        resectionTypes: resectionDate.resectionTypes.map((resectionType) => ({
            ...resectionType,
            valueOther: toMocNumber(resectionType.valueOther),
        })),
    })),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
