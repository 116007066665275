import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatDate } from '../sections/Questions';
import MocTypeCode, { mocTypeNameFor } from '../../../MocTypeCode';
import { styles as globalStyles } from '../styles';
import { Patients } from '../../../../../../api/types';

type Patient = Patients.GetPatient.PatientDetail;

type Props = {
    patient: Patient;
    mocTypeCode: MocTypeCode;
    mocDate: string | null;
};

export default function Header(props: Props) {
    return (
        <View style={styles.container}>
            <View style={styles.leftColumn}>
                <Text style={styles.mocType}>{mocTypeNameFor(props.mocTypeCode)}</Text>
                <Text style={globalStyles.text}>{formatDate(props.mocDate)}</Text>
            </View>
            <View style={styles.rightColumnPatient}>
                <Text style={globalStyles.text}>
                    {props.patient.surname} {props.patient.firstName}
                </Text>
                <Text style={globalStyles.text}>{formatDate(props.patient.dateOfBirth)}</Text>
                <Text style={globalStyles.text}>{props.patient.uzaNr}</Text>
                <Text style={globalStyles.text}>{props.patient.referringHospitalName}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    leftColumn: {
        flexDirection: 'column',
        width: '50%',
        paddingTop: 5,
        paddingRight: 8,
    },
    rightColumnPatient: {
        width: '50%',
        paddingTop: 5,
        paddingLeft: 8,
        alignItems: 'flex-end',
    },
    mocType: {
        fontSize: 18,
        margin: '2px 0px',
    },
});
