import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';
import { formatDateTime } from './Questions';

export default function Footer() {
    const now = new Date();
    return (
        <>
            <Text style={styles.generatedDate} fixed>
                {formatDateTime(now)}
            </Text>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />
        </>
    );
}

const styles = StyleSheet.create({
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        paddingRight: 35,
        textAlign: 'right',
        color: 'grey',
    },
    generatedDate: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
});
