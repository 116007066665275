import { Grid } from '@material-ui/core';
import React from 'react';
import { FileNameQuestion } from '../../../components';
import MocSectionContainer from '../../MocSectionContainer';

type annex55Props = {
    isValid: boolean | null;
};

export default function Annex55(props: annex55Props) {
    return (
        <MocSectionContainer code="ckzsarcomen-annex55" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FileNameQuestion
                        type="Annex55"
                        question="Bestand opladen"
                        name="ckzSarcomenAnnex55.annex55FileId"
                    ></FileNameQuestion>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
