import React from 'react';
import FormikDatePickerField from '../../../../../styles/components/forms/FormikDatePickerField';
import { FastField } from '../Fast';

type Props = {
    name: string;
    label?: string;
    shrinkLabel?: boolean;
    disableFuture?: boolean;
};

const FastDateField: React.FC<Props> = ({ name, label, disableFuture = true }: Props) => (
    <FastField name={name} component={FormikDatePickerField} label={label} disableFuture={disableFuture} />
);

export default FastDateField;
