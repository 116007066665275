import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useApi from '../../../../api/useApi';
import { Patients } from '../../../../api/types';
import ImportPatientForm from '../form/ImportPatientForm';
import { PatientInfo, valuesToPostRequest, getMappedPatientInfo } from '../form/ImportPatientDetail.config';
import ImportDetailPatientForm from '../form/ImportDetailPatientForm';
import useHospital from '../../../../hospital/useHospital';
import { SearchValues, initialValues } from '../form/ImportPatient.config';
import { Button } from '@material-ui/core';

type ImportPatientPageProps = RouteComponentProps;

export default function ImportPatientPage({ history }: ImportPatientPageProps) {
    const api = useApi();
    const [patientInfo, setPatientInfo] = useState<PatientInfo>();
    const [uzaNr, setUzaNr] = useState('');
    const { hospital } = useHospital();
    const [importError, setImportError] = useState('');

    const handleImport = async (values: SearchValues) => {
        const { status, data } = await api.post<Patients.ImportPatientInfo.Response>('patients/import', {
            ...values,
        });
        setUzaNr(values.uzaNr);

        if (status < 400) {
            setPatientInfo(getMappedPatientInfo(data));
        } else if (status === 404) {
            setImportError("The patient was not found. Please register this patient's information with UZA (ZIS).");
        } else if (status > 400) {
            setImportError(
                'Something went wrong while trying to import patient from ZIS. Please contact your UZA contact person.'
            );
        }
    };

    const handleSubmit = async (values: PatientInfo) => {
        const { status, data } = await api.post<Patients.AddImportedZISPatient.Response>(
            'patients/zisimported',
            valuesToPostRequest(values)
        );
        if (status < 400) {
            history.push(`/${hospital!.slug}/patients/${data.id}`);
        }
    };

    const handleReset = () => {
        setPatientInfo(undefined);
    };
    const handleManualInput = () => {
        history.push(`/${hospital!.slug}/patients/new/manualinput?uzaNrHint=${uzaNr}`);
    };
    return (
        <>
            <h1>Import a patient</h1>
            {!patientInfo && (
                <>
                    <ImportPatientForm
                        initialValues={initialValues}
                        onSubmit={handleImport}
                        importErrors={importError}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleManualInput}
                        type="submit"
                        style={{ margin: '1.5rem 0' }}
                    >
                        Add manually
                    </Button>
                </>
            )}

            {patientInfo && (
                <ImportDetailPatientForm
                    initialValues={patientInfo}
                    onSubmit={handleSubmit}
                    uzaNr={uzaNr}
                    onReset={handleReset}
                />
            )}
        </>
    );
}
