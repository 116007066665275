import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import { FastSelectField } from '../../components';
import { Mocs } from '../../../../../../api/types';
import BiopsyDateList from '../../enets/BiopsyDateList';
import ResectionDateList from '../../enets/ResectionDateList';

type FNCLCCGrade = Mocs.GetReferenceData.Moc_Ref_FNCLCCGrade;

type Props = {
    isValid: boolean | null;
};

export default function PathologyFnclcc(props: Props) {
    return (
        <MocSectionContainer code="pathology-fnclcc" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FastSelectField<FNCLCCGrade>
                        name="pathologyFnclcc.fnclccGrade"
                        placeholder="FNCLCC-gradering"
                        prompt="Selecteer FNCLCC-gradering"
                        referenceDataPath="fnclccGrades"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathologyFnclcc.ptCode"
                        placeholder="pT"
                        prompt="Selecteer pT waarde"
                        referenceDataPath="ptTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathologyFnclcc.pnCode"
                        placeholder="pN"
                        prompt="Selecteer pN waarde"
                        referenceDataPath="pnTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField
                        name="pathologyFnclcc.pmCode"
                        placeholder="pM"
                        prompt="Selecteer pM waarde"
                        referenceDataPath="pmTypes"
                    />
                </Grid>
                <Grid item xs={12}>
                    <BiopsyDateList
                        name="pathologyFnclcc"
                        referenceTypeDataFilter={(refVal) => refVal.isSarcomen}
                        referenceValueDataFilter={(refVal) => refVal.isSarcomen}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ResectionDateList
                        name="pathologyFnclcc"
                        referenceTypeDataFilter={(refVal) => refVal.isSarcomen}
                        referenceValueDataFilter={(refVal) => refVal.isSarcomen}
                    />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
