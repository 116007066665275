import React, { Fragment } from 'react';
import { FieldArray, getIn, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Mocs } from '../../../../../api/types';
import useReferenceData from '../../referenceData/useReferenceData';
import styles from './CheckBoxes.module.scss';

type ReferenceData = Mocs.GetReferenceData.Response;
type ValueObject = string | { code: string };
type ReferenceObject = { id: number; code: string; name: string };

const alwaysTrue = () => true;

function isValueObject(o: ValueObject): o is { code: string } {
    return typeof o !== 'string';
}

type InnerProps<TReferenceItem extends ReferenceObject, TValueItem extends ValueObject> = {
    arrayHelpers: FieldArrayRenderProps;
    referenceDataItems: TReferenceItem[];
    selectedItems: TValueItem[];
    create: (reference: TReferenceItem) => TValueItem;
    detail?: (reference: TReferenceItem, value: TValueItem, valuePath: string) => React.ReactNode;
    isSubmitting: boolean;
    paddingBottom?: number;
};

const Inner = React.memo(
    function <TReferenceItem extends ReferenceObject = ReferenceObject, TValueItem extends ValueObject = ValueObject>({
        arrayHelpers,
        referenceDataItems,
        selectedItems,
        create,
        detail,
        isSubmitting,
        paddingBottom,
    }: InnerProps<TReferenceItem, TValueItem>) {
        return (
            <div className={styles.container}>
                {referenceDataItems.map((reference) => {
                    const thisValueIndex = selectedItems
                        .map((value) => (isValueObject(value) ? value.code : (value as string)))
                        .indexOf(reference.code);
                    const thisValue = selectedItems[thisValueIndex];
                    const thisValueIsSelected = thisValueIndex > -1;
                    const thisValuePath = `${arrayHelpers.name}[${thisValueIndex}]`;
                    const check = () => arrayHelpers.push(create(reference));
                    const unCheck = () => arrayHelpers.remove(thisValueIndex);
                    const handleChange = (_: any, checked: any) => (checked ? check() : unCheck());

                    const valueDetail = detail && thisValueIsSelected && detail(reference, thisValue, thisValuePath);
                    return (
                        <Fragment key={reference.code + reference.name}>
                            <Grid item xs={12} style={{ paddingBottom: paddingBottom }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color="primary"
                                            name={thisValuePath}
                                            checked={thisValueIsSelected}
                                            onChange={handleChange}
                                            disabled={isSubmitting}
                                        />
                                    }
                                    label={reference.name}
                                    labelPlacement="end"
                                />
                            </Grid>
                            {valueDetail && (
                                <Grid item xs={12} className={styles.detail_container}>
                                    <div className={styles.detail_line}></div>
                                    {valueDetail}
                                </Grid>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        );
    },
    (prev, next) => prev.selectedItems === next.selectedItems && prev.isSubmitting === next.isSubmitting
) as any;

type CheckBoxesProps<TReferenceItem extends ReferenceObject, TValueItem extends ValueObject> = {
    referenceDataPath: keyof ReferenceData;
    referenceDataFilter?: (reference: TReferenceItem) => boolean;
    name: string;
    create: (reference: TReferenceItem) => TValueItem;
    detail?: (reference: TReferenceItem, value: TValueItem, valuePath: string) => React.ReactNode;
    paddingBottom?: number;
};

function CheckBoxes<TReferenceItem extends ReferenceObject, TValueItem extends ValueObject>({
    referenceDataPath,
    referenceDataFilter = alwaysTrue,
    name,
    create,
    detail,
    paddingBottom,
}: CheckBoxesProps<TReferenceItem, TValueItem>) {
    const { isSubmitting } = useFormikContext();
    const referenceData = useReferenceData();
    const referenceDataItems = (getIn(referenceData, referenceDataPath) as TReferenceItem[]).filter(
        referenceDataFilter
    );
    return (
        <Grid container>
            <FieldArray
                name={name}
                render={(arrayHelpers) => {
                    const selectedItems = getIn(arrayHelpers.form.values, name) as TValueItem[];
                    return (
                        <Inner<TReferenceItem, TValueItem>
                            arrayHelpers={arrayHelpers}
                            referenceDataItems={referenceDataItems}
                            selectedItems={selectedItems}
                            create={create}
                            detail={detail}
                            isSubmitting={isSubmitting}
                            paddingBottom={paddingBottom}
                        />
                    );
                }}
            />
        </Grid>
    );
}

export default CheckBoxes;
