import React from 'react';
import cx from 'classnames';
import { Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/WarningOutlined';
import styles from './ErrorSummary.module.scss';

type Props = {
    className?: string;
    errors: string[] | { [key: string]: string | undefined };
};

const ErrorSummary: React.FC<Props> = (props) => {
    const errors = Array.isArray(props.errors)
        ? props.errors
        : Object.values(props.errors).filter((value: string | undefined): value is string => !!value);

    if (!errors || !errors.length) {
        return null;
    }

    return (
        <ul className={cx(props.className, styles.summary)}>
            {errors.map((error, key) => (
                <li key={key} className={styles.error}>
                    <div className={styles.icon_wrapper}>
                        <ErrorIcon />
                    </div>
                    <div className={styles.text}>
                        <Typography variant="body1">{error}</Typography>
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default ErrorSummary;
