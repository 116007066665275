import { Button, Card, Grid, IconButton, List, ListItem, Typography } from '@material-ui/core';
import { FieldArray, getIn, setIn, useFormikContext } from 'formik';
import React from 'react';
import { Values as MocValues } from '../../MocForm.config';
import { CodeAndOtherValue } from '../../MocForm.config';
import { FastSelectField, FastTextField } from '../../components';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import styles from './TreatmentList.module.scss';
import { Mocs } from '../../../../../../api/types';

type Type = Mocs.GetReferenceData.Moc_Ref_ENETS_Type;

type TreatmentListProps = {
    maxItems: number;
    name: string;
    title: string;
};

export function TreatmentList(props: TreatmentListProps) {
    const { values, setValues } = useFormikContext<MocValues>();
    const treatmentPlans = getIn(values, props.name) as CodeAndOtherValue[];

    function setTreatments(treatments: CodeAndOtherValue[]) {
        setValues(setIn(values, props.name, treatments));
    }

    function create() {
        return {
            code: '',
            otherValue: '',
        } as CodeAndOtherValue;
    }

    function itemUp(index: number) {
        const firstPartOfArray = treatmentPlans.slice(0, index - 1);
        const originalItem = treatmentPlans[index];
        const swappedItem = treatmentPlans[index - 1];
        const lastPartOfArray = treatmentPlans.slice(index + 1);

        const updatedTreatmentPlans = [...firstPartOfArray, originalItem, swappedItem, ...lastPartOfArray];

        setTreatments(updatedTreatmentPlans);
    }

    function itemDown(index: number) {
        const firstPartOfArray = treatmentPlans.slice(0, index);
        const originalItem = treatmentPlans[index];
        const swappedItem = treatmentPlans[index + 1];
        const lastPartOfArray = treatmentPlans.slice(index + 2);

        const updatedTreatmentPlans = [...firstPartOfArray, swappedItem, originalItem, ...lastPartOfArray];

        setTreatments(updatedTreatmentPlans);
    }

    function isAddItemEnabled() {
        return treatmentPlans.length < 6;
    }

    function renderListItem(index: number, remove: (index: number) => void) {
        return (
            <ListItem key={`${props.name}_${index}`}>
                <Grid container>
                    <Grid item xs={9}>
                        <FastSelectField<Type>
                            name={`${props.name}[${index}].code`}
                            prompt="Selecteer een behandeling"
                            placeholder="behandeling"
                            referenceDataPath="ckzTreatments"
                            detail={({ isOther }) =>
                                isOther && (
                                    <FastTextField name={`${props.name}[${index}].otherValue`} label="Specifieer" />
                                )
                            }
                            hideSideline={true}
                        />
                    </Grid>
                    <Grid item xs={3} className={styles.buttons}>
                        <IconButton disabled={index === 0} onClick={() => itemUp(index)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                        <IconButton disabled={index === treatmentPlans.length - 1} onClick={() => itemDown(index)}>
                            <ArrowDownwardIcon />
                        </IconButton>
                        <IconButton className={styles.deletebutton} color="primary" onClick={() => remove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </ListItem>
        );
    }

    return (
        <Card style={{ padding: '.5rem .5rem 1rem .5rem' }}>
            {props.title && (
                <Typography style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>{props.title}</Typography>
            )}
            <FieldArray name={props.name}>
                {({ remove, push }) => (
                    <>
                        <List>
                            {treatmentPlans?.length > 0 &&
                                treatmentPlans.map((_, index) => renderListItem(index, remove))}
                        </List>
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={!isAddItemEnabled()}
                            onClick={() => push(create())}
                        >
                            Behandeling toevoegen
                        </Button>
                    </>
                )}
            </FieldArray>
        </Card>
    );
}
