import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import FormikDatePickerField from '../../../../../../styles/components/forms/FormikDatePickerField';
import MocSectionContainer from '../../sections/MocSectionContainer';
import useReplaceFormValues from '../../useReplaceFormValues';
import { FastField } from '../../Fast';
import { Values } from '../../MocForm.config';
import {
    DateQuestion,
    YesNoQuestion,
    FastSelectField,
    FastTextField,
    Question,
    FastDateField,
    RadioButtons,
} from '../../components';
import * as Annex55 from '../annex_55/Annex55.config';
import * as PatientCharacteristics from '../patientCharacteristics/PatientCharacteristics.config';
import * as CkzPancreasSurgery from '../surgery/ckzPancreas/Surgery.config';
import * as CkzOesophagusSurgery from '../surgery/ckzOesophagus/Surgery.config';
import * as CkzPancreasPostSurgery from '../postSurgery/ckzPancreas/PostSurgery.config';
import * as CkzOesophagusPostSurgery from '../postSurgery/ckzOesophagus/PostSurgery.config';
import Referred from './Referred';
import Reports from './Reports';
import useReferenceData from '../../../referenceData/useReferenceData';
import { useResetOnValue, useConstant, useResetOnChange, useResetOnChangeUnless } from '../useResetEffect';
import { Mocs } from '../../../../../../api/types';
import MocTypeCode from '../../../MocTypeCode';

type Props = {
    isValid: boolean | null;
};

type Indication = Mocs.GetReferenceData.Moc_Ref_Indication;
type DiagnosticType = Mocs.GetReferenceData.Moc_Ref_DiagnosticType;
type TissueSampleMethod = Mocs.GetReferenceData.Moc_Ref_TissueSampleMethod;
type LesionToTreatType = Mocs.GetReferenceData.Moc_Ref_LesionToTreat;

function createIndicationsFilter(mocTypeCode: MocTypeCode) {
    switch (mocTypeCode) {
        case 'CKZPancreas': {
            return (indication: Indication) => indication.isPancreas;
        }
        case 'CKZOesophagus': {
            return (indication: Indication) => indication.isOesophagus;
        }
        default: {
            return () => true;
        }
    }
}

const MocGeneralInformation: React.FC<Props> = React.memo(({ isValid }) => {
    const referenceData = useReferenceData();
    const replaceFormValues = useReplaceFormValues('generalInformation');
    const { values } = useFormikContext<Values>();
    const { generalInformation, mocTypeCode } = values;
    const indicationsFilter = createIndicationsFilter(mocTypeCode);

    const hadSurgery = generalInformation.hadSurgery;
    const indication = referenceData.indications.find((ind) => ind.code === generalInformation.indicationCode);
    const isMalignantTumour = indication?.code === 'Malignant';

    useResetOnValue(
        'generalInformation.hadSurgery',
        false,
        useConstant('generalInformation.dateOfDiagnosis', null),
        useConstant('generalInformation.firstDiagnosticTypeCode', ''),
        useConstant('generalInformation.firstDiagnosticDate', null),
        useConstant('generalInformation.firstTissueSampleMethodCode', ''),
        useConstant('generalInformation.firstTissueSampleMethodDate', null),
        useConstant('generalInformation.pathologyReport', ''),
        useConstant('generalInformation.surgeryReport', ''),
        useConstant('patientCharacteristics', PatientCharacteristics.initialValues),
        useConstant('pancreasSurgery', CkzPancreasSurgery.initialValues),
        useConstant('oesophagusSurgery', CkzOesophagusSurgery.initialValues),
        useConstant('pancreasPostSurgery', CkzPancreasPostSurgery.initialValues),
        useConstant('oesophagusPostSurgery', CkzOesophagusPostSurgery.initialValues)
    );

    useResetOnChange(
        'generalInformation.indicationCode',
        useConstant('generalInformation.indicationOtherValue', ''),
        useConstant('generalInformation.hasAnnex55', false),
        useConstant('generalInformation.dateOfDiagnosis', null),
        useConstant('pancreasSurgery.preNeoadjuvantTreatmentPancreaticResectabilityStatusCode', ''),
        useConstant('pancreasSurgery.preSurgeryPancreaticResectabilityStatusCode', ''),
        useConstant('oesophagusSurgery.tumourLocationCode', ''),
        useConstant('oesophagusSurgery.surgeryIntentionCode', ''),
        useConstant('oesophagusSurgery.wasResectionPerformedSurgical', ''),
        useConstant('oesophagusSurgery.wasResectionPerformedPathological', ''),
        useConstant('oesophagusSurgery.wasLymphovascularInvasion', ''),
        useConstant('oesophagusSurgery.wasPerineuralInvasion', ''),
        useConstant('postSurgery.wasThereAdjuvenantTherapyAfterSurgery', '')
    );

    useResetOnValue(
        'generalInformation.hasAnnex55',
        false,
        useConstant('annex55', Annex55.initialValues),
        useConstant('generalInformation.lesionToTreatCode', '')
    );

    useResetOnChangeUnless(
        'generalInformation.lesionToTreatCode',
        'mocId',
        useConstant('annex55', Annex55.initialValues)
    );

    return (
        <MocSectionContainer code="general-information" isValid={isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DateQuestion name="mocDate" question="MC date" label="The MC date" disableFuture={false} />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion name="generalInformation.hadSurgery" question="Did the patient undergo surgery?" />
                </Grid>
                <Grid item xs={12}>
                    <Question question="Indication">
                        <FastSelectField<Indication>
                            name="generalInformation.indicationCode"
                            prompt="Select an indication"
                            referenceDataPath="indications"
                            referenceDataFilter={indicationsFilter}
                            detail={({ disclaimerText, isOther }) =>
                                (disclaimerText || isOther) && (
                                    <Grid container spacing={3}>
                                        {isOther && (
                                            <Grid item xs={12}>
                                                <FastTextField
                                                    name="generalInformation.indicationOtherValue"
                                                    label="Specify other indication"
                                                />
                                            </Grid>
                                        )}
                                        {disclaimerText && (
                                            <Grid item xs={12}>
                                                <strong>{replaceFormValues(disclaimerText)}</strong>
                                            </Grid>
                                        )}
                                    </Grid>
                                )
                            }
                        />
                    </Question>
                </Grid>
                {isMalignantTumour && (
                    <Grid item xs={12}>
                        <YesNoQuestion
                            name="generalInformation.hasAnnex55"
                            question="Annex 55?"
                            renderYes={() => {
                                return (
                                    <Grid item xs={12}>
                                        <Question question="Lesion to treat">
                                            <RadioButtons<LesionToTreatType>
                                                name="generalInformation.lesionToTreatCode"
                                                referenceDataPath="lesionToTreatTypes"
                                                referenceDataFilter={(refVal) => {
                                                    switch (mocTypeCode) {
                                                        case 'CKZPancreas':
                                                            return refVal.isPancreas;
                                                        case 'CKZOesophagus':
                                                            return refVal.isOesophagus;
                                                        default:
                                                            return true;
                                                    }
                                                }}
                                            />
                                        </Question>
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                )}

                {hadSurgery && (
                    <>
                        {mocTypeCode === 'CKZOesophagus' && indication && indication.code === 'Benign' && (
                            <Grid item xs={12}>
                                <FastField
                                    name="generalInformation.dateOfDiagnosis"
                                    label="Date of diagnosis"
                                    component={FormikDatePickerField}
                                    disableFuture
                                />
                            </Grid>
                        )}
                        {mocTypeCode === 'CKZPancreas' && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FastSelectField<DiagnosticType>
                                        name="generalInformation.firstDiagnosticTypeCode"
                                        placeholder="Type of FIRST diagnostic method"
                                        prompt="Select a diagnostic method"
                                        referenceDataPath="diagnosticTypes"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FastDateField
                                        name="generalInformation.firstDiagnosticDate"
                                        label="Date of first diagnosis"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FastSelectField<TissueSampleMethod>
                                        name="generalInformation.firstTissueSampleMethodCode"
                                        placeholder="Method to obtain first tissue sample"
                                        prompt="Select a sample method"
                                        referenceDataPath="tissueSampleMethods"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FastDateField
                                        name="generalInformation.firstTissueSampleMethodDate"
                                        label="Date of first tissue sample"
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}
                <Referred />
                <Reports hadSurgery={hadSurgery} />
            </Grid>
        </MocSectionContainer>
    );
});

export default MocGeneralInformation;
