import React, { useRef, useEffect } from 'react';
import { Field } from 'formik';
import { Grid } from '@material-ui/core';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';

type Props = {
    isNewPatient: boolean;
    patientNumbers: string[] | null;
};

const Identification: React.FC<Props> = ({ isNewPatient, patientNumbers }) => {
    const disableUzaNr = !patientNumbers || !isNewPatient;
    const uzaNrFieldRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!disableUzaNr && uzaNrFieldRef.current) {
            uzaNrFieldRef.current.focus();
        }
    }, [disableUzaNr]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={5}>
                <Field
                    inputRef={uzaNrFieldRef}
                    name="uzaNr"
                    type="text"
                    component={FormikTextField}
                    label="UZA patient number"
                    inputProps={{ maxLength: 9 }}
                />
            </Grid>
            <Grid item xs={6} md={5}>
                <Field name="ssn" type="text" component={FormikTextField} label="SSN" />
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    inputRef={uzaNrFieldRef}
                    name="localPatientNr"
                    type="text"
                    component={FormikTextField}
                    label="Local patient number"
                />
            </Grid>
        </Grid>
    );
};

export default Identification;
