import React from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './Brand.module.scss';
import { useHistory } from 'react-router';

const Brand: React.FC = () => {
    const history = useHistory();
    const handleClick = () => history.push('/');
    return (
        <div className={styles.wrapper} onClick={handleClick}>
            <Typography variant="h5" className={styles.text}>
                OncoBase
            </Typography>
        </div>
    );
};

export default Brand;
