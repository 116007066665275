import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import {
    FastSelectField,
    FastTextField,
    Question,
    RadioButtons,
    FormikErrorMessage,
    FastDateField,
    YesNoQuestion,
    FileQuestion,
    FastTextArea,
    CheckboxField,
} from '../../components';
import { Mocs } from '../../../../../../api/types';
import { useFormikContext } from 'formik';
import PrimaryTumorList from '../../enets/PrimaryTumorList';
import ExecutedTreatmentList from '../../enets/ExecutedTreatmentList';
import { Values } from '../../MocForm.config';
import MocTypeCode from '../../../MocTypeCode';

type MocType = Mocs.GetReferenceData.Moc_Ref_MocType;
type GeneticalSyndrome = Mocs.GetReferenceData.Moc_Ref_GeneticalSyndrome;
type EcogScore = Mocs.GetReferenceData.Moc_Ref_EcogScore;
type FunctionalSyndrome = Mocs.GetReferenceData.Moc_Ref_FunctionalSyndrome;
type ImplementationNetMocTreatmentType = Mocs.GetReferenceData.Moc_Ref_ImplementationNetMocTreatmentType;

type Props = {
    isValid: boolean | null;
};

function createMocTypeFilter(mocTypeCode: MocTypeCode) {
    switch (mocTypeCode) {
        case 'LocalMOC': {
            return (mocType: MocType) => mocType.isLocalMoc;
        }
        case 'NETMOC': {
            return (mocType: MocType) => mocType.isNETMoc;
        }
        default: {
            return () => true;
        }
    }
}

export default function Report(props: Props) {
    const { values } = useFormikContext<Values>();
    const { mocTypeCode } = values;

    const mocTypesFilter = createMocTypeFilter(mocTypeCode);
    const geneticalSyndromeFilter = (syndrome: GeneticalSyndrome) => syndrome.isEnets;

    return (
        <MocSectionContainer code="report" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FastDateField
                        name="mocDate"
                        label={mocTypeCode === 'NETMOC' ? 'Datum Super MOC' : 'Datum lokale MOC'}
                        disableFuture={false}
                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <FastSelectField<MocType>
                        name="report.mocTypeCode"
                        placeholder="MOC Type*"
                        prompt="Selecteer MOC type"
                        referenceDataPath="mocTypes"
                        referenceDataFilter={mocTypesFilter}
                    />
                    <FormikErrorMessage name="report.mocTypeCode" />
                </Grid>
                {mocTypeCode === 'NETMOC' && (
                    <Grid item xs={12}>
                        <CheckboxField name="report.secondOpinion" label="2de opinie" />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FastTextField name="report.requesterLocalMoc" label="Aanvrager lokale MOC" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextField name="report.requesterNetMoc" label="Aanvrager NET MOC" />
                </Grid>
                {mocTypeCode === 'NETMOC' && (
                    <Grid item xs={12}>
                        <FastTextField name="report.netMocCoordinator" label="NET MOC Coördinator" />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <FastDateField name="report.incidentDate" label="Incidentiedatum" />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <FastTextArea name="report.medicalHistory" label="Medische voorgeschiedenis" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea name="report.oncologyHistory" label="Oncologische voorgeschiedenis" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea name="report.currentMedicalDiagnose" label="Huidige probleemstelling" />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<GeneticalSyndrome>
                        name="report.geneticalSyndromeCode"
                        placeholder="Genetisch syndroom*"
                        prompt="Selecteer genetisch syndroom"
                        referenceDataPath="geneticalSyndromes"
                        referenceDataFilter={geneticalSyndromeFilter}
                    />
                    <FormikErrorMessage name="report.geneticalSyndromeCode" />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<EcogScore>
                        name="report.ecogScoreCode"
                        placeholder="ECOG Performance status*"
                        prompt="Selecteer ECOG status"
                        referenceDataPath="ecogScores"
                    />
                    <FormikErrorMessage name="report.ecogScoreCode" />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<FunctionalSyndrome>
                        name="report.functionalSyndromeCode"
                        placeholder="Functioneel syndroom*"
                        prompt="Selecteer functioneel syndroom"
                        referenceDataPath="functionalSyndromes"
                    />
                    <FormikErrorMessage name="report.functionalSyndromeCode" />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="report.recidive"
                        question="Recidief ziekte ?"
                        lang="nl"
                        renderYes={() => <FastDateField name="report.recidiveDate" label="Datum recidief ziekte" />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="report.metastase"
                        question="Metastatische ziekte ?"
                        lang="nl"
                        renderYes={() => (
                            <FastDateField name="report.metastaseDate" label="Datum metastatische ziekte" />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryTumorList name={'report'} />
                </Grid>
                <Grid item xs={12}>
                    <ExecutedTreatmentList name={'report'} />
                </Grid>
                {mocTypeCode === 'LocalMOC' && (
                    <Grid item xs={12}>
                        <Question question="Implementatie van NET-MOC behandelplan">
                            <RadioButtons<ImplementationNetMocTreatmentType>
                                name="report.implementationNetMocTreatmentTypeCode"
                                referenceDataPath="implementationNetMocTreatmentTypes"
                            />
                        </Question>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FastTextArea name="report.notifyingDoctor" label="Arts(en) ter verwittiging" maxLength={255} />
                </Grid>
                {mocTypeCode === 'NETMOC' && (
                    <Grid item xs={12}>
                        <FastTextArea name="report.netMocParticipants" label="Deelnemers NET MOC" maxLength={255} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <CheckboxField name="report.icneTworkSigned" label="IC NETwerk Signed" />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="report.icdnetSigned" label="IC DNET Signed" />
                </Grid>
                <Grid item xs={12}>
                    <FileQuestion type="MOC" question="Bestand opladen"></FileQuestion>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
