import React from 'react';
import { PatientInfo, formatDate } from '../../ImportPatientDetail.config';
import { Grid, FormHelperText, MenuItem, TextField } from '@material-ui/core';
import { Field } from 'formik';
import { Gender } from '../../../../../../api/enums';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import { defaultGender } from '../../GenderDefaultValue.config';
import styles from './ImportSections.module.css';

type Props = {
    patientInfo: PatientInfo;
};
export const PersonalInformation: React.FC<Props> = ({ patientInfo }) => (
    <Grid container spacing={3}>
        <Grid item xs={6} md={5}>
            <TextField
                label="Firstname"
                defaultValue={patientInfo.firstName}
                variant="outlined"
                fullWidth
                multiline
                disabled
            />
        </Grid>
        <Grid item xs={6} md={5}>
            <TextField
                label="Surname"
                defaultValue={patientInfo.surname}
                variant="outlined"
                disabled
                fullWidth
                multiline
            />
        </Grid>
        <Grid item xs={6} md={5}>
            <TextField
                label="Date of birth"
                defaultValue={formatDate(patientInfo.dateOfBirth)}
                variant="outlined"
                disabled
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={6} md={5}>
            <TextField
                label="Date of death"
                defaultValue={formatDate(patientInfo.dateOfDeath)}
                variant="outlined"
                disabled
                multiline
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
        <Grid item xs={6} md={5}>
            {(patientInfo.gender !== defaultGender || patientInfo.genderAttemptedValue !== null) && (
                <>
                    <Field
                        as="select"
                        name="gender"
                        component={FormikSelectField}
                        placeholder="Gender"
                        disabled={patientInfo.gender !== defaultGender}
                    >
                        <MenuItem key="empty-indication" value={defaultGender}>
                            <i>Select a gender</i>
                        </MenuItem>
                        {Object.keys(Gender)
                            .filter((key) => !isNaN(Number(key)))
                            .map((gender) => (
                                <MenuItem key={Number(gender)} value={Number(gender)}>
                                    {Gender[Number(gender)]}
                                </MenuItem>
                            ))}
                    </Field>
                    {patientInfo.gender === defaultGender &&
                        patientInfo.genderAttemptedValue !== null &&
                        patientInfo.genderAttemptedValue.length > 0 && (
                            <FormHelperText className={styles.formHelperText}>
                                Please select the gender corresponding to {patientInfo.genderAttemptedValue}
                            </FormHelperText>
                        )}
                </>
            )}
            {patientInfo.gender === defaultGender && patientInfo.genderAttemptedValue === '' && (
                <TextField
                    label="Gender"
                    defaultValue={patientInfo.genderAttemptedValue}
                    variant="outlined"
                    disabled
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        </Grid>
    </Grid>
);
