import React, { useEffect, useState } from 'react';
import { Grid, MenuItem, FormHelperText } from '@material-ui/core';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import { Patients } from '../../../../../../api/types';
import { Values } from '../../PatientForm.config';
type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;

type Props = {
    funds: Fund[] | null;
    validation?: boolean;
};

const Insurance: React.FC<Props> = ({ funds: allFunds }) => {
    const [countryFunds, setCountryFunds] = useState<Fund[] | null>(null);
    const { values, setFieldValue } = useFormikContext<Values>();
    const { countryId, healthInsuranceFundId } = values;

    useEffect(() => {
        if (allFunds != null) {
            const newCountryFunds = allFunds.filter((f) => (f.country && f.country.id === countryId) || !f.country);
            setCountryFunds(newCountryFunds);
            if (!newCountryFunds.find((f) => f.id === healthInsuranceFundId)) {
                const newHealthInsuranceId = newCountryFunds.length === 1 ? newCountryFunds[0].id : 0;
                setFieldValue('healthInsuranceFundId', newHealthInsuranceId);
            }
        }
    }, [allFunds, countryId, healthInsuranceFundId, setFieldValue]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={5}>
                <Field
                    as="select"
                    name="healthInsuranceFundId"
                    component={FormikSelectField}
                    placeholder="Health insurance fund"
                    disabled={!countryFunds}
                >
                    <MenuItem key="empty-healthinsurancefund" value={0} disabled>
                        <i>{allFunds ? 'Select a health insurance fund' : 'Loading ...'}</i>
                    </MenuItem>
                    {countryFunds &&
                        countryFunds.map((fund) => (
                            <MenuItem key={fund.id} value={fund.id}>
                                {fund.name}
                            </MenuItem>
                        ))}
                </Field>
                <ErrorMessage name="healthInsuranceFundId">
                    {(msg) => (
                        <FormHelperText className="error error-message" error={true}>
                            {msg}
                        </FormHelperText>
                    )}
                </ErrorMessage>
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    name="healthInsuranceFundNr"
                    type="text"
                    component={FormikTextField}
                    label="Health insurance fund number"
                />
            </Grid>
        </Grid>
    );
};

export default Insurance;
