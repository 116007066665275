import React, { useState, useEffect } from 'react';
import HospitalContext, { Hospital } from './HospitalContext';
import { useRouteMatch, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

type Props = {
    hospitals: Hospital[];
};

function createNewPath(currentSlug: string, currentPath: string, newSlug: string) {
    const currentSlugPathPrefix = `/${currentSlug}`;
    const canStripCurrentSlugFromPath = 0 === currentPath.indexOf(currentSlugPathPrefix);
    return canStripCurrentSlugFromPath
        ? `/${newSlug}${currentPath.substr(currentSlugPathPrefix.length)}`
        : `/${newSlug}`;
}

const HospitalProvider: React.FC<Props> = ({ children, hospitals }) => {
    const history = useHistory();
    const location = useLocation();
    const [lastHospital, setLastHospital] = useState<Hospital | null>(null);
    const hospitalRouteMatch = useRouteMatch<{ hospital: string }>('/:hospital');
    const hospitalSlug = (hospitalRouteMatch && hospitalRouteMatch.params.hospital) || null;
    const hospital = hospitals.find(h => h.slug === hospitalSlug) || null;

    useEffect(() => {
        hospital && setLastHospital(hospital);
    }, [hospital]);

    const setHospital = (h: Hospital) => {
        if (!hospital) {
            history.push(`/${h.slug}`);
            return;
        }

        const currentSlug = hospital.slug;
        const currentPath = location.pathname;

        const newSlug = h.slug;
        const newPath = createNewPath(currentSlug, currentPath, newSlug);

        history.push(newPath);
    };

    return (
        <HospitalContext.Provider value={{ hospital, setHospital, hospitals, lastHospital }}>
            {children}
        </HospitalContext.Provider>
    );
};

export default HospitalProvider;
