import React, { useState, useEffect } from 'react';
import { Patients } from '../../../../../../api/types';
import { PatientInfo } from '../../ImportPatientDetail.config';
import { Grid, MenuItem, TextField, FormHelperText } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import styles from './ImportSections.module.css';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';

type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;
type Props = {
    funds: Fund[] | null;
    patientInfo: PatientInfo;
};
export const Insurance: React.FC<Props> = ({ funds: allFunds, patientInfo }) => {
    const [countryFunds, setCountryFunds] = useState<Fund[] | null>(allFunds);
    const { values, setFieldValue } = useFormikContext<PatientInfo>();
    const { countryId, healthInsuranceFundId } = values;

    useEffect(() => {
        if (allFunds != null) {
            const newCountryFunds = allFunds.filter((f) => (f.country && f.country.id === countryId) || !f.country);
            setCountryFunds(newCountryFunds);
            if (!newCountryFunds.find((f) => f.id === healthInsuranceFundId)) {
                const newHealthInsuranceId = newCountryFunds.length === 1 ? newCountryFunds[0].id : 0;
                setFieldValue('healthInsuranceFundId', newHealthInsuranceId);
            }
        }
    }, [allFunds, countryId, healthInsuranceFundId, setFieldValue]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={5}>
                {(patientInfo.countryId !== 0 || patientInfo.healthInsuranceFundAttemptedValue !== null) && (
                    <>
                        <Field
                            as="select"
                            name="healthInsuranceFundId"
                            component={FormikSelectField}
                            placeholder="Health insurance fund"
                            disabled={
                                !countryFunds ||
                                (patientInfo.countryId !== 0 && patientInfo.healthInsuranceFundId !== 0)
                            }
                        >
                            <MenuItem key="empty-healthinsurancefund" value={0} disabled>
                                <i>{allFunds ? 'Select a health insurance fund' : 'Loading ...'}</i>
                            </MenuItem>
                            {countryFunds &&
                                countryFunds.map((fund) => (
                                    <MenuItem key={fund.id} value={fund.id}>
                                        {fund.name}
                                    </MenuItem>
                                ))}
                        </Field>
                        {patientInfo.countryId === 0 &&
                            patientInfo.healthInsuranceFundAttemptedValue !== null &&
                            patientInfo.healthInsuranceFundAttemptedValue.length > 0 && (
                                <FormHelperText className={styles.formHelperText}>
                                    Please select the health insurance fund corresponding to{' '}
                                    {patientInfo.healthInsuranceFundAttemptedValue}
                                </FormHelperText>
                            )}
                    </>
                )}
                {patientInfo.countryId === 0 && patientInfo.healthInsuranceFundAttemptedValue === null && (
                    <TextField
                        label="Health insurance fund"
                        defaultValue={patientInfo.healthInsuranceFundAttemptedValue}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )}
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    name="healthInsuranceFundNr"
                    type="text"
                    component={FormikTextField}
                    label="Health insurance fund number"
                />
            </Grid>
        </Grid>
    );
};
