import React from 'react';
import { Patients } from '../../../../api/types';
import FileOverview from '../../files/FileOverview';
import FileModal, { Values } from '../../files/FileModal';
import useApi from '../../../../api/useApi';
import useAuth from '../../../../auth/useAuth';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';
import { useDialog } from '../../../../styles/components/MaterialDialogService';

type PatientFile = Patients.GetPatient.File;

type Props = {
    patientId: number;
    patientFiles: PatientFile[];
    onPatientFilesChanged: (patientFiles: PatientFile[]) => void;
    showSnack: ShowSnack;
};

const PatientFiles: React.FC<Props> = ({ patientId, patientFiles, showSnack, onPatientFilesChanged }) => {
    const { user } = useAuth();
    const dialog = useDialog();
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const api = useApi();

    const handleDownloadFile = async (fileId: number) => {
        await api.get('file/' + fileId, { responseType: 'blob' }).then(response => {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        });
    };

    const downLoadFile = (data: any, type: string, filename: string) => {
        const blob = new Blob([data], { type });

        //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
        }
    };

    const handleNewFile = () => {
        setOpenModal(true);
    };

    const awaitingPromiseRef = React.useRef<{
        resolve: () => void;
        reject: () => void;
    }>();

    const handleClose = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject();
        }

        setOpenModal(false);
    };

    const handleRemoveFile = async (id: number) => {
        const file = patientFiles!.find(m => m.id === id);

        await dialog({
            variant: 'yes/no',
            yesName: `Remove file`,
            title: `Remove ${file!.name}`,
            description: (
                <>
                    Are you sure you would like to remove <strong>{file!.name}</strong>?
                    <br />
                </>
            )
        }).then(async () => {
            const { status } = await api.delete<{}>(`file/${id}`);

            if (status < 400) {
                onPatientFilesChanged(patientFiles!.filter(m => m.id !== id));
                showSnack('File was removed');
            } else {
                showSnack('File could not be removed', 'error');
            }
        });
    };

    const handleSubmit = async (values: Values) => {
        setIsLoading(true);
        setOpenModal(false);

        const filenameWithExtension = values.name.endsWith(values.extension)
            ? values.name
            : values.name + values.extension;

        const { status, data } = await api.post('file', {
            ...values,
            patientId: patientId,
            name: filenameWithExtension,
            mocId: null
        } as Patients.UploadFile.Request);

        if (status < 400) {
            const addedFile = {
                id: data.id,
                contentType: values.contentType,
                name: filenameWithExtension,
                uploadDate: new Date().toISOString(),
                uploadUserName: user!.name,
                patientFileTypeName: data.filetypeName,
                patientFileTypeCode: data.filetypeCode
            } as PatientFile;
            onPatientFilesChanged([...patientFiles, addedFile]);

            showSnack('File was uploaded');
        } else {
            showSnack('Could not upload file', 'error');
        }

        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }

        setIsLoading(false);
    };

    return (
        <>
            <FileOverview
                files={patientFiles}
                loading={isLoading}
                onNewFile={handleNewFile}
                onDownloadFile={handleDownloadFile}
                onRemoveFile={handleRemoveFile}
            />
            <FileModal open={openModal} openedFromMoc={false} onSubmit={handleSubmit} onClose={handleClose} />
        </>
    );
};

export default PatientFiles;
