import React from 'react';
import { useFormikContext, getIn } from 'formik';
import styles from './FormikErrorMessage.module.scss';
import { Values } from '../MocForm.config';

type Props = {
    name: string;
};

export default function FormikErrorMessage({ name }: Props) {
    const { errors } = useFormikContext<Values>();
    const error = getIn(errors, name);

    return <div className={styles.message}>{error}</div>;
}
