import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Typography, Button, Grid, Avatar, CircularProgress } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Identity } from '../../../../api/types';
import { ValidationErrorsResponse } from '../../../../api';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';
import FormikTextField from '../../../../styles/components/forms/FormikTextField';
import PasswordRequirementsList from '../password/PasswordRequirementsList';
import styles from './AccountConfirmationForm.module.scss';
import { initialValues, Values, validationSchema } from './AccountConfirmationForm.config';
import { AxiosInstance } from 'axios';

type Request = Identity.ConfirmAccount.Request;

type Props = {
    id: number;
    token: string;
    email: string;
    onConfirmed: () => void;
    showSnack: ShowSnack;
    api: AxiosInstance;
};

const AccountConfirmationForm: React.FC<Props> = ({ id, token, email, onConfirmed, showSnack, api }) => {
    const [errors, setErrors] = useState<string[]>([]);

    const handleSubmit = async (values: Values) => {
        const request: Request = {
            id,
            token,
            password: values.password
        };

        const { status, data } = await api.post<{} | ValidationErrorsResponse>('account/confirm', request);
        if (status < 400) {
            onConfirmed();
        } else if (status < 500) {
            const validationResponse = data as ValidationErrorsResponse;
            setErrors(Object.values(validationResponse.validationErrors));
        } else {
            showSnack('Could not confirm account', 'error');
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount
        >
            {({ isSubmitting, values, isValid }) => (
                <Form className={styles.form}>
                    <Avatar className={styles.avatar} color="primary">
                        <AccountCircleIcon />
                        {isSubmitting && <CircularProgress style={{ position: 'absolute', top: 0, left: 0 }} />}
                    </Avatar>
                    <Typography variant="h5" className={styles.title}>
                        Create your account
                    </Typography>
                    {!!errors.length && errors.map(e => <p key={e}>{e}</p>)}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Your email address</Typography>
                            <Typography variant="subtitle2" className={styles.email}>
                                {email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Password</Typography>
                            <PasswordRequirementsList value={values.password} />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                autoFocus
                                name="password"
                                type="password"
                                label="Choose a strong password"
                                autoComplete="off"
                                fullWidth
                                component={FormikTextField}
                                disabled={isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="confirmPassword"
                                type="password"
                                label="Confirm the password"
                                autoComplete="off"
                                fullWidth
                                component={FormikTextField}
                                disabled={isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                className={styles.submit_btn}
                                disabled={isSubmitting || !isValid}
                            >
                                Create account
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default AccountConfirmationForm;
