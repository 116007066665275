import { Grid } from '@material-ui/core';
import React from 'react';
import { FastDateField, FastSelectField, FastTextArea, FastTextField } from '../components';
import ItemList from '../components/ItemList';
import { Mocs } from '../../../../../api/types';
import { FastField } from 'formik';
import FormikNumberField from '../../../../../styles/components/forms/FormikNumberField';

type Type = Mocs.GetReferenceData.Moc_Ref_ENETS_Type;
type Unit = Mocs.GetReferenceData.Moc_Ref_ENETS_Unit;

function detail(name: string) {
    return (
        <>
            <Grid item xs={12} md={3}>
                <FastDateField name={`${name}.date`} label="Startdatum" />
            </Grid>
            <Grid item xs={12} md={3}>
                <FastSelectField<Type>
                    name={`${name}.typeCode`}
                    prompt="Selecteer een type"
                    placeholder="Type"
                    referenceDataPath="enetsTypes"
                    detail={({ isOther }) => isOther && <FastTextField name={`${name}.typeOther`} label="Ander type" />}
                    hideSideline={true}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <FastField name={`${name}.value`} type="number" component={FormikNumberField} label="Waarde" />
            </Grid>
            <Grid item xs={12} md={4}>
                <FastSelectField<Unit>
                    name={`${name}.unitCode`}
                    prompt="Selecteer een eenheid"
                    placeholder="Eenheid"
                    referenceDataPath="enetsUnits"
                    detail={({ isOther }) =>
                        isOther && <FastTextField name={`${name}.unitOther`} label="Andere eenheid" />
                    }
                    hideSideline={true}
                />
            </Grid>
            <Grid item xs={12}>
                <FastTextArea name={`${name}.additionalInfo`} label="Extra info" />
            </Grid>
        </>
    );
}

function create() {
    return {
        id: 0,
        date: null,
        typeCode: '',
        typeOther: '',
        value: '',
        unitCode: '',
        unitOther: '',
        additionalInfo: '',
    };
}

export default function ClinicalBiologyList() {
    return (
        <ItemList
            name="clinicalBiology.clinicalBiologies"
            minItems={0}
            addText="Labo waarde toevoegen"
            title="Labo waarde"
            detail={detail}
            create={create}
        />
    );
}
