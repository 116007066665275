import React from 'react';
import { Formik, Form } from 'formik';
import { Paper, Grid, Backdrop, CircularProgress, Typography } from '@material-ui/core';
import { Patients } from '../../../../api/types';
import styles from './MocForm.module.scss';
import MocTypeGuard from './MocTypeGuard';
import MocDetailControls from './controls/MocDetailControls';
import CkzPancreas from './ckzPancreas/CkzPancreas';
import CkzOesophagus from './ckzOesophagus/CkzOesophagus';
import NetMoc from './netMoc/NetMoc';
import LocalMoc from './localMoc/LocalMoc';
import { Values } from './MocForm.config';
import { ValidatedSection } from './sections/validation';
import CkzSarcomen from './ckzSarcomen/CkzSarcomen';

type Patient = Patients.GetPatient.PatientDetail;

type Props = {
    patient: Patient;
    loading: boolean;
    initialValues: Values;
    validatedSections: ValidatedSection[];
    onSubmit: (values: Values) => Promise<void> | void;
};

const MocForm: React.FC<Props> = React.memo(({ patient, loading, initialValues, validatedSections, onSubmit }) => {
    if (loading) {
        return (
            <div className={styles.form} style={{ height: '20rem' }}>
                <Backdrop open={loading} className={styles.form_backdrop}>
                    <CircularProgress />
                    <Typography className={styles.form_backdrop_text}>Loading MOC....</Typography>
                </Backdrop>
            </div>
        );
    }

    return (
        <Formik<Values>
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validateOnMount={false}
        >
            <Form className={styles.form}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <MocDetailControls
                            mocTypeCode={initialValues.mocTypeCode}
                            validatedSections={validatedSections}
                            patient={patient}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Paper square={true} className={styles.form_container}>
                            <div className={styles.container}>
                                <div className={styles.contentContainer}>
                                    <MocTypeGuard type="CKZPancreas">
                                        <CkzPancreas validatedSections={validatedSections} />
                                    </MocTypeGuard>
                                    <MocTypeGuard type="CKZOesophagus">
                                        <CkzOesophagus validatedSections={validatedSections} />
                                    </MocTypeGuard>
                                    <MocTypeGuard type="NETMOC">
                                        <NetMoc validatedSections={validatedSections} />
                                    </MocTypeGuard>
                                    <MocTypeGuard type="LocalMOC">
                                        <LocalMoc validatedSections={validatedSections} />
                                    </MocTypeGuard>
                                    <MocTypeGuard type="CKZSarcomen">
                                        <CkzSarcomen validatedSections={validatedSections} />
                                    </MocTypeGuard>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    );
});

export default MocForm;
