import { Grid } from '@material-ui/core';
import React from 'react';
import { Mocs } from '../../../../../../api/types';
import {
    DateQuestion,
    CheckBoxes,
    Question,
    YesNoQuestion,
    FastDateField,
    FastTextField,
    RadioButtons,
} from '../../components';
import MocSectionContainer from '../MocSectionContainer';
import { CodeAndOtherValue, Values } from '../../MocForm.config';
import { useFormikContext } from 'formik';
import FastSelectField from '../../components/FastSelectField';
import { location } from './Annex55.config';
import { getFields } from './Annex55.fields';
import { TreatmentList } from './TreatmentList';

type annex55Props = {
    isValid: boolean | null;
};

type BasisOfDiagnosis = Mocs.GetReferenceData.Moc_Ref_BasisOfDiagnosis;
type WhoPerformaceStatusType = Mocs.GetReferenceData.Moc_Ref_WhoPerformanceStatus;
type primaryTumourLocationTypes = Mocs.GetReferenceData.Moc_Ref_PrimaryTumor;
type primaryTumourHistologyTypes = Mocs.GetReferenceData.Moc_Ref_PrimaryTumorHistology;
type lateralityType = Mocs.GetReferenceData.Moc_Ref_Laterality;
type locationType = Mocs.GetReferenceData.Moc_Ref_Location;
type metastasisLocationType = Mocs.GetReferenceData.Moc_Ref_MetastasisLocation;
type differentiationType = Mocs.GetReferenceData.Moc_Ref_Differentiation;
type CtType = Mocs.GetReferenceData.Moc_Ref_Ct;
type CnType = Mocs.GetReferenceData.Moc_Ref_Cn;
type CmType = Mocs.GetReferenceData.Moc_Ref_Cm;
type ptType = Mocs.GetReferenceData.Moc_Ref_Pt;
type pnType = Mocs.GetReferenceData.Moc_Ref_Pn;
type pTmype = Mocs.GetReferenceData.Moc_Ref_Pm;
type yptType = Mocs.GetReferenceData.Moc_Ref_Ypt;
type ypnType = Mocs.GetReferenceData.Moc_Ref_Ypn;
type ypTmype = Mocs.GetReferenceData.Moc_Ref_Ypm;
type otherClassificationType = Mocs.GetReferenceData.Moc_Ref_OtherClassification;
type stadiumType = Mocs.GetReferenceData.Moc_Ref_Stadium;
type reasonMocType = Mocs.GetReferenceData.Moc_Ref_ReasonMoc;
type Hospital = Mocs.GetReferenceData.Hospital;

export default function Annex55(props: annex55Props) {
    const { values } = useFormikContext<Values>();
    const { generalInformation, annex55 } = values;

    const fields = getFields(generalInformation.lesionToTreatCode, annex55);

    return generalInformation.lesionToTreatCode !== '' ? (
        <MocSectionContainer code="annex55" isValid={props.isValid}>
            <Grid container spacing={3}>
                {fields.isEnabled.incidenceDateRelapse && (
                    <Grid item xs={12}>
                        <DateQuestion
                            question="Incidentiedatum relapse (Complexe chirurgie)"
                            name="annex55.relapseIncidenceDate"
                            disableFuture
                        />
                    </Grid>
                )}

                {fields.isEnabled.incidenceDate && (
                    <Grid item xs={12}>
                        <DateQuestion question="Incidentiedatum" name="annex55.incidenceDate" disableFuture />
                    </Grid>
                )}

                {fields.isEnabled.basisOfDiagnosis && (
                    <Grid item xs={12}>
                        <Question question="Basis voor diagnose">
                            <RadioButtons<BasisOfDiagnosis>
                                referenceDataPath="basesOfDiagnosis"
                                name="annex55.basisOfDiagnosisCode"
                            />
                        </Question>
                    </Grid>
                )}

                {fields.isEnabled.whoScoreAtDiagnosis && (
                    <Grid item xs={12}>
                        <FastSelectField<WhoPerformaceStatusType>
                            name="annex55.whoScoreAtDiagnosisCode"
                            placeholder="WHO score bij diagnose"
                            referenceDataPath="whoPerformanceStatuses"
                            prompt="Select a score"
                        />
                    </Grid>
                )}

                {fields.isEnabled.primaryTumourLocation && (
                    <Grid item xs={12}>
                        <Question question="Primaire tumor">
                            <FastSelectField<primaryTumourLocationTypes>
                                name="annex55.primaryTumourLocationCode"
                                prompt="Selecteer primaire tumor"
                                placeholder="Primaire tumor lokalisatie"
                                referenceDataPath="primaryTumors"
                            />
                        </Question>
                    </Grid>
                )}

                {fields.isEnabled.laterality && (
                    <Grid item xs={12}>
                        <FastSelectField<lateralityType>
                            name="annex55.lateralityCode"
                            prompt="Selecteer een lateraliteit"
                            placeholder="Lateraliteit"
                            referenceDataPath="lateralityTypes"
                        />
                    </Grid>
                )}

                {fields.isEnabled.primaryTumourHistology && (
                    <Grid item xs={12}>
                        <FastSelectField<primaryTumourHistologyTypes>
                            name="annex55.primaryTumourHistologyCode"
                            prompt="Selecteer histologie primary tumor"
                            placeholder="Primaire tumor histologie"
                            referenceDataPath="primaryTumorHistologies"
                        />
                    </Grid>
                )}

                {fields.isEnabled.differentiation && (
                    <Grid item xs={12}>
                        <FastSelectField<differentiationType>
                            name="annex55.differentiationCode"
                            prompt="Selecteer een differentiatiegraad"
                            placeholder="Differentiatiegraad"
                            referenceDataPath="differentiations"
                        />
                    </Grid>
                )}

                {fields.isEnabled.hasPeriodWithoutSymptoms && (
                    <Grid item xs={12}>
                        <YesNoQuestion
                            question="Ziektevrij interval?"
                            name="annex55.hasPeriodWithoutSymptoms"
                            lang="nl"
                            reverse
                            renderYes={() => (
                                <FastDateField name="annex55.dateFirstRecidive" label="Datum 1e recidief" />
                            )}
                        />
                    </Grid>
                )}

                {fields.isEnabled.location && (
                    <Grid item xs={12}>
                        <Question question="Locatie">
                            <CheckBoxes<locationType, location>
                                referenceDataPath="locations"
                                name="annex55.locations"
                                create={(refValue) => ({ code: refValue.code, metastasisLocations: [] })}
                                detail={(revValue, _, valuePath) => {
                                    switch (revValue.code) {
                                        case 'Metastasen': {
                                            return (
                                                <Grid item xs={12}>
                                                    <CheckBoxes<metastasisLocationType, CodeAndOtherValue>
                                                        referenceDataPath="metastasisLocations"
                                                        name={`${valuePath}.metastasisLocations`}
                                                        create={(metaRefValue) => ({
                                                            code: metaRefValue.code,
                                                            otherValue: '',
                                                        })}
                                                        detail={(metaRefValue, _, metaValuePath) => {
                                                            switch (metaRefValue.code) {
                                                                case 'Andere': {
                                                                    return (
                                                                        <Grid item xs={12}>
                                                                            <FastTextField
                                                                                name={`${metaValuePath}.otherValue`}
                                                                                placeholder="Specifieer"
                                                                            />
                                                                        </Grid>
                                                                    );
                                                                }
                                                                default:
                                                                    return null;
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                            );
                                        }
                                        default:
                                            return null;
                                    }
                                }}
                            />
                        </Question>
                    </Grid>
                )}

                {fields.isEnabled.c_P_Yp && (
                    <>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<CtType>
                                name="annex55.ctCode"
                                placeholder="cT"
                                prompt="Selecteer cT waarde"
                                referenceDataPath="ctTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<CnType>
                                name="annex55.cnCode"
                                placeholder="cN"
                                prompt="Selecteer cN waarde"
                                referenceDataPath="cnTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<CmType>
                                name="annex55.cmCode"
                                placeholder="cM"
                                prompt="Selecteer cM waarde"
                                referenceDataPath="cmTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<ptType>
                                name="annex55.ptCode"
                                placeholder="pT"
                                prompt="Selecteer pT waarde"
                                referenceDataPath="ptTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<pnType>
                                name="annex55.pnCode"
                                placeholder="pN"
                                prompt="Selecteer pN waarde"
                                referenceDataPath="pnTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<pTmype>
                                name="annex55.pmCode"
                                placeholder="pM"
                                prompt="Selecteer pM waarde"
                                referenceDataPath="pmTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<yptType>
                                name="annex55.yptCode"
                                placeholder="ypT"
                                prompt="Selecteer ypT waarde"
                                referenceDataPath="yptTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<ypnType>
                                name="annex55.ypnCode"
                                placeholder="ypN"
                                prompt="Selecteer ypN waarde"
                                referenceDataPath="ypnTypes"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FastSelectField<ypTmype>
                                name="annex55.ypmCode"
                                placeholder="ypM"
                                prompt="Selecteer ypM waarde"
                                referenceDataPath="ypmTypes"
                            />
                        </Grid>
                    </>
                )}

                {fields.isEnabled.metastasisLocations && (
                    <Grid item xs={12}>
                        <Question question="Metastases locatie">
                            <CheckBoxes<metastasisLocationType, CodeAndOtherValue>
                                referenceDataPath="metastasisLocations"
                                name="annex55.metastasisLocations"
                                create={(metaRefValue) => ({
                                    code: metaRefValue.code,
                                    otherValue: '',
                                })}
                                detail={(metaRefValue, _, metaValuePath) => {
                                    switch (metaRefValue.code) {
                                        case 'Andere': {
                                            return (
                                                <Grid item xs={12}>
                                                    <FastTextField
                                                        name={`${metaValuePath}.otherValue`}
                                                        placeholder="Specifieer"
                                                    />
                                                </Grid>
                                            );
                                        }
                                        default:
                                            return null;
                                    }
                                }}
                            />
                        </Question>
                    </Grid>
                )}

                {fields.isEnabled.otherClassification && (
                    <Grid item xs={12}>
                        <FastSelectField<otherClassificationType>
                            name="annex55.otherClassificationCode"
                            prompt="Selecteer een classificatie"
                            placeholder="Andere classificatie"
                            referenceDataPath="otherClassificationTypes"
                            detail={(refVal) => {
                                return refVal.code === '7' ? (
                                    <Grid item xs={12}>
                                        <FastTextField
                                            name="annex55.otherClassificationOtherValue"
                                            placeholder="Specifieer"
                                        />
                                    </Grid>
                                ) : null;
                            }}
                        />
                    </Grid>
                )}

                {fields.isEnabled.stadium && (
                    <Grid item xs={12}>
                        <FastSelectField<stadiumType>
                            name="annex55.stadiumCode"
                            prompt="Selecteer een stadium"
                            placeholder="Stadium"
                            referenceDataPath="stadiums"
                        />
                    </Grid>
                )}

                {fields.isEnabled.executedTreatments && (
                    <>
                        <Grid item xs={12}>
                            <DateQuestion question="Datum van behandeling" name="annex55.treatmentDate" disableFuture />
                        </Grid>
                        <Grid item xs={12}>
                            <TreatmentList title="Uitgevoerde behandeling" maxItems={6} name="annex55.treatments" />
                        </Grid>
                    </>
                )}

                {fields.isEnabled.treatmentPlans && (
                    <Grid item xs={12}>
                        <TreatmentList title={fields.label.treatments} maxItems={6} name="annex55.treatmentPlans" />
                    </Grid>
                )}

                {fields.isEnabled.dateMoc && (
                    <Grid item xs={12}>
                        <DateQuestion question="Datum MOC" name="annex55.annex55MocDate" disableFuture={false} />
                    </Grid>
                )}

                {fields.isEnabled.reasonMoc && (
                    <Grid item xs={12}>
                        <Question question="Reden Moc">
                            <CheckBoxes<reasonMocType, CodeAndOtherValue>
                                name="annex55.reasons"
                                create={(refVal) => ({ code: refVal.code, otherValue: '' })}
                                referenceDataPath="reasonMocTypes"
                                referenceDataFilter={(refVal) => {
                                    if (fields.isPrimaryTumour) return refVal.isPrimaryTumour;
                                    if (fields.isRelapse) return refVal.isRelapse;
                                    if (fields.isMetastasis) return refVal.isMetastasis;
                                    return false;
                                }}
                                detail={(refVal, _, valuePath) => {
                                    return refVal.isOther ? (
                                        <Grid item xs={12}>
                                            <FastTextField name={`${valuePath}.otherValue`} placeholder="Specifieer" />
                                        </Grid>
                                    ) : null;
                                }}
                                paddingBottom={10}
                            />
                        </Question>
                    </Grid>
                )}

                {fields.isEnabled.sourceHospital && (
                    <Grid item xs={12}>
                        <FastSelectField<Hospital>
                            name="annex55.sourceHospitalCode"
                            placeholder="Bron ziekenhuis"
                            prompt="Select een ziekenhuis"
                            referenceDataPath="hospitals"
                            detail={({ isOther }) =>
                                isOther && (
                                    <FastTextField name="annex55.sourceForeignHospital" placeholder="Specifieer" />
                                )
                            }
                        />
                    </Grid>
                )}
            </Grid>
        </MocSectionContainer>
    ) : null;
}
