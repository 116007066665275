import React from 'react';
import MaterialTable from 'material-table';
import { Paper, Grid, List, ListSubheader, ListItem, Button, Typography, Chip, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CompleteIcon from '@material-ui/icons/Done';
import styles from './MocOverview.module.scss';
import { Patients } from '../../../api/types';
import { format, parseJSON } from 'date-fns';
import usePermissions from '../../../auth/usePermissions';

type MuiIcon = typeof WarningIcon | typeof CompleteIcon;

type Moc = Patients.GetPatient.Moc;
type MocDomain = { name: string; types: MocType[] };
type MocType = {
    name: string;
    code: string;
};

type Props = {
    mocs: Moc[];
    mocDomains: MocDomain[];
    selectedMocId: number | null | undefined;
    isReadOnly: boolean;
    onMocDetail: (mocId: number) => void;
    onNewMoc: (type: MocType) => void;
    onRemoveMoc: (mocId: number) => void;
};

const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd MMM yyyy') : '');

const renderIcon = (className: string, Icon: MuiIcon) => (
    <div className={className}>
        <Icon />
    </div>
);

const MocOverview: React.FC<Props> = ({
    mocs,
    mocDomains,
    selectedMocId,
    isReadOnly,
    onMocDetail,
    onNewMoc,
    onRemoveMoc,
}) => {
    const { canRemoveMoc } = usePermissions();

    return (
        <Paper className={styles.paper}>
            <Grid container spacing={3} className={styles.gridContainer}>
                <Grid item xs={12} md={isReadOnly ? 12 : 9}>
                    <Typography variant="overline">MOCS</Typography>
                    <MaterialTable<Moc>
                        style={{ margin: '1rem 0', height: '330px', overflowY: 'auto' }}
                        columns={[
                            { title: 'Id', field: 'id', hidden: true },
                            {
                                title: 'MOC type',
                                field: 'mocTypeName',
                                render: (rowData) => (
                                    <span>
                                        {rowData!.mocTypeName} {!rowData!.id && <Chip label="New" color="secondary" />}{' '}
                                    </span>
                                ),
                            },
                            {
                                title: 'MC date',
                                field: 'creationDate',
                                defaultSort: 'desc',
                                customSort: (a, b) => (a.mocDate ? parseJSON(a.mocDate).getTime() : 0) - (b.mocDate ? parseJSON(b.mocDate).getTime(): 0),
                                render: (data) => showDate(data.mocDate),
                            },
                            {
                                title: 'Creation date',
                                field: 'creationDate',
                                customSort: (a, b) => (a.creationDate ? parseJSON(a.creationDate).getTime() : 0) - (b.creationDate ? parseJSON(b.creationDate).getTime(): 0),
                                render: (data) => showDate(data.creationDate),
                            },
                            {
                                title: '',
                                field: 'isValid',
                                render: (data) => {
                                    const isComplete = data.isValid === true;
                                    const isWarning = data.isValid === false && data.id !== 0;

                                    return (
                                        <>
                                            {isComplete && renderIcon(styles.complete, CompleteIcon)}
                                            {isWarning && renderIcon(styles.warning, WarningIcon)}
                                        </>
                                    );
                                },
                            },
                            {
                                title: '',
                                field: 'buttons',
                                cellStyle: {
                                    padding: '6px',
                                    textAlign: 'right',
                                },
                                render: (data) => (
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {canRemoveMoc && (
                                            <IconButton
                                                color="primary"
                                                onClick={() => onRemoveMoc(data!.id)}
                                                className={styles.edit_icon_button}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        data={mocs}
                        options={{
                            sorting: true,
                            search: false,
                            pageSize: 5,
                            toolbar: false,
                            paging: false,
                            thirdSortClick: false,
                            headerStyle: { zIndex: 0 },
                            rowStyle: (rowdata) => {
                                if (rowdata.id === selectedMocId) {
                                    return { backgroundColor: 'rgba(0, 0, 0, 0.15)' };
                                }
                                return {};
                            },
                        }}
                        onRowClick={(_, rowData) => onMocDetail(rowData!.id)}
                        localization={{ body: { emptyDataSourceMessage: 'No MOCs found' } }}
                    />
                </Grid>
                {!isReadOnly && (
                    <Grid item xs={12} md={3}>
                        <List className={styles.list}>
                            {mocDomains.map((domain) => (
                                <span key={domain.name}>
                                    <ListSubheader className={styles.listSubheader} disableSticky={true}>
                                        <Typography variant="overline">{domain.name}</Typography>
                                    </ListSubheader>
                                    {domain.types.map((type) => (
                                        <ListItem key={type.name} className={styles.listItem}>
                                            <Button
                                                onClick={() => onNewMoc(type)}
                                                fullWidth
                                                color="primary"
                                                classes={{ label: styles.button_label }}
                                            >
                                                <AddIcon /> {type.name}
                                            </Button>
                                        </ListItem>
                                    ))}
                                </span>
                            ))}
                        </List>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default MocOverview;
