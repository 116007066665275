import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import { FastSelectField } from '../../components';
import { Mocs } from '../../../../../../api/types';
import MedicalImagingList from '../../enets/MedicalImagingList';

type CtType = Mocs.GetReferenceData.Moc_Ref_Ct;
type CnType = Mocs.GetReferenceData.Moc_Ref_Cn;
type CmType = Mocs.GetReferenceData.Moc_Ref_Cm;

type Props = {
    isValid: boolean | null;
};

export default function Imaging(props: Props) {
    return (
        <MocSectionContainer code="imaging" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CtType>
                        name="imaging.ctCode"
                        placeholder="cT"
                        prompt="Selecteer cT waarde"
                        referenceDataPath="ctTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CnType>
                        name="imaging.cnCode"
                        placeholder="cN"
                        prompt="Selecteer cN waarde"
                        referenceDataPath="cnTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CmType>
                        name="imaging.cmCode"
                        placeholder="cM"
                        prompt="Selecteer cM waarde"
                        referenceDataPath="cmTypes"
                    />
                </Grid>
                <Grid item xs={12}>
                    <MedicalImagingList name="imaging" isSarcomen={false}></MedicalImagingList>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
