import * as React from 'react';
import { MaterialDialog, DialogOptions } from './MaterialDialog';

const MaterialDialogServiceContext = React.createContext<(options: DialogOptions) => Promise<void>>(Promise.reject);

export const useDialog = () => React.useContext(MaterialDialogServiceContext);

interface IProps {
    children: React.ReactNode | undefined;
}

export const MaterialDialogServiceProvider = ({ children }: IProps) => {
    const [dialogState, setDialogState] = React.useState<DialogOptions | null>(null);

    const awaitingPromiseRef = React.useRef<{
        resolve: () => void;
        reject: () => void;
    }>();

    const openDialog = (options: DialogOptions) => {
        setDialogState(options);
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (dialogState) {
            if (dialogState.catchOnCancel && awaitingPromiseRef.current) {
                awaitingPromiseRef.current.reject();
            }
            setDialogState(null);
        }
    };

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve();
        }

        setDialogState(null);
    };

    return (
        <>
            <MaterialDialogServiceContext.Provider value={openDialog}>{children}</MaterialDialogServiceContext.Provider>

            <MaterialDialog
                open={Boolean(dialogState)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                {...dialogState!}
            />
        </>
    );
};
