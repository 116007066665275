import { Mocs } from '../../../../../../../api/types';

export type GetMocValues = Pick<Mocs.CkzSarcomenData, 'annex55FileId'>;

export type SaveMocValues = Pick<Mocs.SaveMoc.CkzSarcomenFields, 'annex55FileId'>;

export type FormValues = {
    annex55FileId: number | null;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    annex55FileId: moc.annex55FileId,
});

export function getNewMocValues(): GetMocValues {
    return {
        annex55FileId: null,
    };
}

export function mapSubmitValues(form: FormValues): SaveMocValues {
    return {
        annex55FileId: form.annex55FileId,
    };
}

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
