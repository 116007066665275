import React, { useState, useEffect } from 'react';
import { Typography, Paper, Toolbar, Grid, CircularProgress, Backdrop } from '@material-ui/core';
import { Identity } from '../../../api/types';
import useApi from '../../../api/useApi';
import OncoBaseSnackbar, { useSnackBar } from '../../../styles/components/OncoBaseSnackbar';
import NewUserForm from './new/NewUserForm';
import UsersOverview from './overview/UsersOverview';
import styles from './UsersPage.module.scss';
import ErrorSummary from '../../../styles/components/ErrorSummary';

type RolesResponse = Identity.GetRoles.Response;
type Role = Pick<Identity.GetRoles.ENETSRole, 'id' | 'name' | 'isAdmin' | 'isReadonly'>;
type UsersResponse = Identity.GetUsers.Response;
type User = Pick<Identity.GetUsers.User, 'id' | 'userName' | 'email' | 'isConfirmed' | 'roleId'>;

const UsersPage: React.FC = () => {
    const api = useApi();
    const [showSnack, snackBarProps] = useSnackBar();
    const [roles, setRoles] = useState<Role[] | null>(null);
    const [users, setUsers] = useState<User[] | null>(null);
    const [isRolesLoading, setIsRolesLoading] = useState<boolean>(true);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const rolesResponse = await api.get<RolesResponse>('roles');
                setRoles(rolesResponse.data.roles.filter(r => r.isHospitalDependent));
            } catch {
                setError('Could not connect to server');
            }

            setIsRolesLoading(false);
        })();
    }, [api]);

    useEffect(() => {
        (async () => {
            try {
                const usersResponse = await api.get<UsersResponse>('users');
                setUsers(usersResponse.data.users);
            } catch {
                setError('Could not connect to server');
            }

            setIsUsersLoading(false);
        })();
    }, [api]);

    const handleUserAdded = (user: User) => {
        setUsers([user, ...users!]);
    };

    const handleUsersChanged = (newUsers: User[]) => setUsers(newUsers);

    if ((!roles || !users || isRolesLoading || isUsersLoading) && error === null) {
        return (
            <Backdrop open={true} className={styles.backdrop}>
                <div className={styles.backdrop_content}>
                    <CircularProgress color="inherit" />
                    <Typography className={styles.backdrop_text}>Loading users and roles...</Typography>
                </div>
            </Backdrop>
        );
    }

    if (error !== null) {
        return (
            <div>
                <ErrorSummary errors={[error]} />
            </div>
        );
    }

    return (
        <>
            <Paper style={{ margin: '1rem 0' }}>
                <Toolbar>
                    <Grid container style={{ margin: '.5rem 0' }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">New user</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <NewUserForm
                                users={users!}
                                roles={roles!}
                                onUserAdded={handleUserAdded}
                                showSnack={showSnack}
                            />
                        </Grid>
                    </Grid>
                </Toolbar>
            </Paper>
            <UsersOverview roles={roles!} users={users!} onUsersChanged={handleUsersChanged} showSnack={showSnack} />

            <OncoBaseSnackbar {...snackBarProps} />
        </>
    );
};

export default UsersPage;
