import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import styles from './OncoBaseMenuItem.module.scss';
import { MenuItem } from '@material-ui/core';

type OncoBaseMenuItemProps = {
    path?: string;
    children: React.ReactNode;
    disabled?: boolean;
    onClick?: (e: any) => void;
};

const OncoBaseMenuItem: React.FC<OncoBaseMenuItemProps> = React.forwardRef(
    ({ path, children, onClick, disabled }, ref) => {
        const isActive = useRouteMatch(path);
        const history = useHistory();
        const handleClick = (e: React.MouseEvent) => {
            onClick && onClick(e);
            path && history.push(path);
        };
        return (
            <MenuItem
                className={isActive && path ? styles.item_active : styles.item}
                onClick={handleClick}
                innerRef={ref}
                disabled={disabled}
            >
                {children}
            </MenuItem>
        );
    }
);

export default OncoBaseMenuItem;
