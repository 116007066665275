import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Values } from '../../MocForm.config';
import FormikNumberField from '../../../../../../styles/components/forms/FormikNumberField';
import { Mocs } from '../../../../../../api/types';
import MocSectionContainer from '../../sections/MocSectionContainer';
import { FastField } from '../../Fast';
import { useResetOnValue, useEmptyArray } from '../useResetEffect';
import { YesNoQuestion, CheckBoxes, FastSelectField, FastTextField } from '../../components';

type Comorbidity = Mocs.GetReferenceData.Moc_Ref_Comorbidity;
type AntithromboticMedication = Mocs.GetReferenceData.Moc_Ref_AntithromboticMedication;
type WhoPerformanceStatus = Mocs.GetReferenceData.Moc_Ref_WhoPerformanceStatus;
type AsaScore = Mocs.GetReferenceData.Moc_Ref_AsaScore;

type Props = {
    isValid: boolean | null;
};

const PatientCharacteristics: React.FC<Props> = React.memo(({ isValid }) => {
    const {
        values: { generalInformation },
    } = useFormikContext<Values>();

    const hadSurgery = generalInformation.hadSurgery;

    useResetOnValue(
        'patientCharacteristics.isComorbidity',
        false,
        useEmptyArray('patientCharacteristics.comorbidityTypes')
    );

    useResetOnValue(
        'patientCharacteristics.isAntithromboticMedication',
        false,
        useEmptyArray('patientCharacteristics.antithromboticMedicationTypeCodes')
    );

    return (
        <>
            {hadSurgery && (
                <MocSectionContainer code="patient-characteristics" isValid={isValid}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <FastField
                                name="patientCharacteristics.patientHeight"
                                type="number"
                                component={FormikNumberField}
                                label="Height (in cm; min: 40, max: 260)"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FastField
                                name="patientCharacteristics.patientWeight"
                                type="number"
                                component={FormikNumberField}
                                label="Weight at time of surgery (in kg; min: 2, max: 300)"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FastSelectField<WhoPerformanceStatus>
                                name="patientCharacteristics.whoPerformanceStatusCode"
                                placeholder="WHO performance status at time of surgery"
                                prompt="Select a WHO performance status"
                                referenceDataPath="whoPerformanceStatuses"
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FastSelectField<AsaScore>
                                name="patientCharacteristics.asaScoreCode"
                                placeholder="ASA score (pre-operative risk)"
                                prompt="Select an ASA score"
                                referenceDataPath="asaScores"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="patientCharacteristics.isComorbidity"
                                question="Comorbidity (prior to surgery) - Charlson Modified Index"
                            >
                                <CheckBoxes<Comorbidity, { code: string; otherValue: string }>
                                    referenceDataPath="comorbidityTypes"
                                    name="patientCharacteristics.comorbidityTypes"
                                    create={(reference) => ({ code: reference.code, otherValue: '' })}
                                    detail={(reference, _, valuePath) =>
                                        reference.isOther && (
                                            <FastTextField
                                                name={`${valuePath}.otherValue`}
                                                placeholder="Specify other"
                                            />
                                        )
                                    }
                                />
                            </YesNoQuestion>
                        </Grid>

                        <Grid item xs={12}>
                            <YesNoQuestion
                                name="patientCharacteristics.isAntithromboticMedication"
                                question="Is the patient currently (=at time of surgery) treated with antithrombotic medication?"
                            >
                                <CheckBoxes<AntithromboticMedication, { code: string }>
                                    referenceDataPath="antithromboticMedications"
                                    name="patientCharacteristics.antithromboticMedicationTypeCodes"
                                    create={(reference) => ({ code: reference.code })}
                                />
                            </YesNoQuestion>
                        </Grid>
                    </Grid>
                </MocSectionContainer>
            )}
        </>
    );
});

export default PatientCharacteristics;
