import React from 'react';
import { Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckboxProps, fieldToCheckbox } from 'formik-material-ui';

const FormikCheckbox = ({ form, inputRef, ...props }: CheckboxProps) => {
    return (
        <MuiCheckbox
            {...fieldToCheckbox({ ...props, inputRef, form })}
            inputRef={inputRef}
            disabled={form.isSubmitting}
        />
    );
};

export default FormikCheckbox;
