import React from 'react';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { Values } from '../MocForm.config';
import { Grid, Button, IconButton, Card, Typography, List, ListItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './ItemList.module.scss';

type Props<TItemType> = {
    name: string;
    minItems: number;
    maxItems?: number;
    addText: string;
    title?: string;
    detail: (name: string) => React.ReactElement<{}>;
    create: () => TItemType;
};

export default function ItemList<TItemType>({
    name,
    minItems,
    maxItems,
    addText,
    title,
    detail,
    create,
}: Props<TItemType>) {
    const { values } = useFormikContext<Values>();
    const listItems: TItemType[] = getIn(values, name);
    const canRemove = listItems?.length > minItems;
    const addButtonIsDisabled = !!maxItems && listItems?.length >= maxItems;

    const renderListItem = (index: number, remove: (index: number) => void) => (
        <ListItem key={`${name}_${index}`} className={styles.tigerstriped}>
            <Grid container item xs={11} spacing={1}>
                {detail(`${name}[${index}]`)}
            </Grid>
            <Grid container item xs={1} style={{ marginLeft: 'auto' }}>
                <IconButton disabled={!canRemove} color="primary" onClick={() => remove(index)}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </ListItem>
    );

    return (
        <Card style={{ padding: '.5rem .5rem 1rem .5rem' }}>
            {title && <Typography style={{ fontSize: '1.25rem', marginBottom: '1rem' }}>{title}</Typography>}
            <FieldArray name={name}>
                {({ remove, push }) => (
                    <>
                        <List>
                            {listItems?.length > 0 && listItems.map((_, index) => renderListItem(index, remove))}
                        </List>
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={addButtonIsDisabled}
                            onClick={() => push(create())}
                        >
                            {addText}
                        </Button>
                    </>
                )}
            </FieldArray>
        </Card>
    );
}
