import React, { useState, useRef } from 'react';
import MaterialTable, { Query, QueryResult } from 'material-table';
import qs from 'query-string';
import { Patients } from '../../../api/types';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory, RouteComponentProps } from 'react-router';
import { format, parseJSON } from 'date-fns';
import useApi from '../../../api/useApi';
import usePermissions from '../../../auth/usePermissions';
import ErrorSummary from '../../../styles/components/ErrorSummary';
import useHospital from '../../../hospital/useHospital';
import styles from './PatientsPage.module.scss';
import { useDialog } from '../../../styles/components/MaterialDialogService';
import OncoBaseSnackbar, { useSnackBar } from '../../../styles/components/OncoBaseSnackbar';

type Response = Patients.GetPatients.Response;
type Patient = Patients.GetPatients.Patient;

const showDate = (date: string | null) => {
    return date ? format(parseJSON(date), 'dd MMM yyyy') : '';
};

const PatientsPage: React.FC<RouteComponentProps> = ({ match }) => {
    const { canAddNewPatient, canRemovePatient } = usePermissions();
    const [error, setError] = useState<string | null>(null);
    const dialog = useDialog();
    const [showSnack, snackBarProps] = useSnackBar();
    const [patients, setPatients] = useState<Patient[] | null>(null);
    const queryRef = useRef<Query<Patient>>();
    const { hospital } = useHospital();
    const history = useHistory();
    const url = match.url;
    const api = useApi();

    const [tableRef] = useState<React.RefObject<any>>(React.createRef<any>());

    const newPatient = () => {
        history.push(`${url}/new`);
    };

    const showPatientDetail = (rowData: any) => {
        history.push(`${url}/${rowData.id}`);
    };

    // copied from ExportPage.tsx, maybe we can make a 'downloadService' or something ?
    const downLoadFile = (data: any, type: string, filename: string) => {
        const blob = new Blob([data], { type });

        //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
        }
    };

    const getData = async (query: Query<Patient>): Promise<QueryResult<Patient>> => {
        setError(null);
        queryRef.current = query;
        const url = `patients?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
        })}`;
        try {
            const { data, status } = await api.get<Response>(url);
            if (status < 400) {
                const { patients, pageIndex, totalCount } = data;
                setPatients(patients);
                return {
                    data: patients,
                    page: pageIndex,
                    totalCount,
                };
            } else {
                setError('Could not load patients');
            }
        } catch {
            setError('Could not connect to server');
        }

        return {
            data: [],
            page: 0,
            totalCount: 0,
        };
    };

    const refreshTable = () => {
        if (tableRef.current) tableRef.current.onQueryChange();
    };

    const handleRemovePatient = async (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        const patient = patients!.find((m) => m.id === id);

        await dialog({
            variant: 'yes/no',
            yesName: `Remove patient`,
            title: `Remove patient: ${patient!.uzaNr}`,
            description: (
                <>
                    Are you sure you would like to remove patient with UZA nr: <strong>{patient!.uzaNr}</strong>?
                </>
            ),
        }).then(async () => {
            const { status } = await api.delete<{}>(`patients/${id}`);

            if (status < 400) {
                refreshTable();
                showSnack('Patient was removed');
            } else {
                showSnack('Patient could not removed', 'error');
            }
        });
    };

    const handleExportToExcelButtonClicked = async () => {
        var query = queryRef.current;
        if (!query) return;

        const url = `patients/excel?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
        })}`;
        var response = await api.get(url, { responseType: 'blob' });
        if (response.status < 400) {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        } else {
            showSnack("Couldn't download export file", 'error');
        }
    };

    return (
        <>
            {error !== null && (
                <div>
                    <ErrorSummary errors={[error]} />
                </div>
            )}
            <MaterialTable<Patient>
                tableRef={tableRef}
                key={hospital!.slug}
                style={{ margin: '1rem 0' }}
                title="All Patients"
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Patient ID', field: 'oncobasePatientNr' },
                    { title: 'UZA patientnumber', field: 'uzaNr' },
                    { title: 'Surname', field: 'surname', defaultSort: 'asc' },
                    { title: 'First name', field: 'firstName' },
                    { title: 'Date of birth', field: 'dateOfBirth', render: (data) => showDate(data.dateOfBirth) },
                    { title: 'Date of death', field: 'dateOfDeath', render: (data) => showDate(data.dateOfDeath) },
                    { title: 'SSN', field: 'ssn' },
                    {
                        title: '',
                        field: 'delete',
                        hidden: !canRemovePatient,
                        cellStyle: {
                            padding: '6px',
                        },
                        render: (data) => (
                            <IconButton
                                color="primary"
                                disabled={!data!.canBeDeleted}
                                onClick={(e) => handleRemovePatient(e, data!.id)}
                                className={styles.edit_icon_button}
                            >
                                <DeleteIcon />
                            </IconButton>
                        ),
                    },
                ]}
                data={getData}
                options={{
                    sorting: true,
                    search: false,
                    pageSize: 10,
                    thirdSortClick: false,

                    headerStyle: { zIndex: 0 },
                }}
                onRowClick={(_, rowData) => showPatientDetail(rowData)}
                actions={[
                    {
                        icon: 'newpatient',
                        isFreeAction: true,
                        onClick: newPatient,
                    },
                    {
                        icon: 'exportexcel',
                        isFreeAction: true,
                        onClick: handleExportToExcelButtonClicked,
                    },
                ]}
                components={{
                    Action: (props) => {
                        if (props.action.icon === 'newpatient') {
                            return (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    disabled={!canAddNewPatient}
                                >
                                    <AddIcon /> Add a new patient
                                </Button>
                            );
                        }
                        if (props.action.icon === 'exportexcel') {
                            return (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={(event) => props.action.onClick(event, props.data)}
                                    style={{ margin: '1rem' }}
                                >
                                    Export to excel
                                </Button>
                            );
                        }
                        return <Button />;
                    },
                }}
                localization={{ body: { emptyDataSourceMessage: 'No patients found' } }}
            />
            <OncoBaseSnackbar {...snackBarProps} />
        </>
    );
};

export default PatientsPage;
