import { Grid } from '@material-ui/core';
import React from 'react';
import { CheckboxField, FastDateField, FastSelectField, FastTextField } from '../components';
import ItemList from '../components/ItemList';

function detail(name: string) {
    return (
        <>
            <Grid item xs={3}>
                <FastDateField name={`${name}.startDate`} label="Startdatum*" />
            </Grid>
            <Grid item xs={6}>
                <FastSelectField
                    name={`${name}.treatmentCode`}
                    prompt="Selecteer een behandeling"
                    placeholder="Behandeling*"
                    referenceDataPath="ckzSarcomenTreatmentPlans"
                />
            </Grid>
            <Grid item xs={3}>
                <CheckboxField name={`${name}.currentTherapy`} label="Huidige therapie" />
            </Grid>
            <Grid item xs={3}>
                <FastDateField name={`${name}.endDate`} label="Einddatum" />
            </Grid>
            <Grid item xs={6}>
                <FastTextField name={`${name}.treatmentOther`} label="Opmerking" />
            </Grid>
        </>
    );
}

function create() {
    return {
        id: 0,
        treatmentCode: '',
        treatmentOther: '',
        currentTherapy: false,
        sae: false,
        startDate: null,
        endDate: null,
    };
}

type ExecutedTreatmentListProps = {
    name: string;
};

export default function ExecutedTreatmentListSarcomen({ name }: ExecutedTreatmentListProps) {
    return (
        <ItemList
            name={`${name}.executedTreatments`}
            minItems={0}
            addText="Behandeling toevoegen"
            title="Uitgevoerde behandelingen"
            detail={detail}
            create={create}
        />
    );
}
