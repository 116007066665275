import React from 'react';
import { Field } from 'formik';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import { FormHelperText, MenuItem } from '@material-ui/core';
import { isValid, Selectable } from '../../EditPatient.config';
import styles from '../EditPatientFormImport.module.scss';
import { EditFormLabels } from '../EditPatientFormLabels';
type SelectFieldWithChangesProps = {
    fieldName: string;
    placeholder: EditFormLabels;
    selectionInfo: string;
    options: Selectable[];
    attemptedValue: string;
    oldValue: string;
    defaultValue: number;
    enableSelection: boolean;
    sectionChangesTracker: Map<EditFormLabels, boolean>;
};
export default function SelectFieldWithChanges(props: SelectFieldWithChangesProps) {
    function showUpdate(property: EditFormLabels) {
        return props.sectionChangesTracker.get(property);
    }
    const enableSelectionHelperText = isValid(props.attemptedValue)
        ? `ZIS found ${props.attemptedValue}. Please select the matching ${props.placeholder.toLocaleLowerCase()}.`
        : `ZIS could not find ${props.placeholder.toLocaleLowerCase()}.`;
    return (
        <>
            <Field
                as="select"
                name={props.fieldName}
                component={FormikSelectField}
                placeholder={props.placeholder}
                disabled={!props.enableSelection}
            >
                <MenuItem key="empty-indication" value={props.defaultValue}>
                    <i>{props.selectionInfo}</i>
                </MenuItem>
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.display}
                    </MenuItem>
                ))}
            </Field>
            {showUpdate(props.placeholder) && (
                <>
                    {props.enableSelection && (
                        <FormHelperText className={styles.formHelperText}>{enableSelectionHelperText}</FormHelperText>
                    )}
                    <FormHelperText className={styles.formHelperText}>Current: {props.oldValue}</FormHelperText>
                </>
            )}
        </>
    );
}
