import React, { Fragment } from 'react';
import * as pathology from '../../../../forms/sections/pathology/Pathology.config';
import ReactPDF, { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { ReferenceDataMapper } from '../..';
import { QuestionProps, formatDate, QuestionMocAnswer, QuestionMocCodeAndOtherWithValueAndUnit } from '../Questions';
import List, { Item, MainSubItem, SubItem } from '../List';
import { Mocs } from '../../../../../../../api/types';

type ReferenceData = Mocs.GetReferenceData.Response;
type PathologySubsectionItem = {
    date: string | null;
    locationCode: string;
    comment: string;
    kindCode: string | null;
    sectionTypes: sectionType[];
    fileId: number | null;
};
type sectionType = {
    valueCode: string;
    valueOther: number | '';
    typeCode: string;
    typeOther: string;
    unit: string;
    info: string;
};
type PathologyProps = {
    values: pathology.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function Pathology(props: PathologyProps) {
    const biopsies: PathologySubsectionItem[] = props.values.biopsyDates.map((b) => ({
        date: b.date,
        locationCode: b.locationCode,
        comment: b.comment,
        fileId: b.fileId,
        sectionTypes: b.biopsyTypes,
        kindCode: null,
    }));
    const cytologies: PathologySubsectionItem[] = props.values.cytologyDates.map((c) => ({
        date: c.date,
        locationCode: c.locationCode,
        comment: c.comment,
        fileId: c.fileId,
        sectionTypes: c.cytologyTypes,
        kindCode: null,
    }));
    const resections: PathologySubsectionItem[] = props.values.resectionDates.map((r) => ({
        date: r.date,
        locationCode: r.locationCode,
        comment: r.comment,
        fileId: r.fileId,
        sectionTypes: r.resectionTypes,
        kindCode: r.kindCode,
    }));
    const isEmpty =
        (biopsies === null || biopsies.length === 0) &&
        (cytologies === null || cytologies.length === 0) &&
        (resections === null || resections.length === 0);

    return isEmpty ? (
        <View>
            <QuestionMocAnswer question="Anatomopathologie" answer="/" />
        </View>
    ) : (
        <View>
            <Text style={styles.mocSubHeader}>Anatomopathologie</Text>
            <QuestionListSubsectionItems
                question="Biopsie"
                mapTypeKey="biopsyTypes"
                mapValueTypeKey="biopsyValues"
                answer={biopsies}
                mapper={props.mapReferenceData}
                overridingStyle={styles.answer}
            />
            <QuestionListSubsectionItems
                question="Cytologie"
                mapTypeKey="cytologyTypes"
                mapValueTypeKey="cytologyValues"
                answer={cytologies}
                mapper={props.mapReferenceData}
                overridingStyle={styles.answer}
            />
            <QuestionListSubsectionItems
                question="Resectie"
                mapTypeKey="enetsResectionTypes"
                mapValueTypeKey="enetsResectionValues"
                answer={resections}
                mapper={props.mapReferenceData}
                overridingStyle={styles.answer}
            />
        </View>
    );
}

type QuestionListSubsectionItemsProps = QuestionProps & {
    mapTypeKey: keyof ReferenceData;
    mapValueTypeKey: keyof ReferenceData;
    answer: PathologySubsectionItem[];
    mapper: ReferenceDataMapper;
    overridingStyle: ReactPDF.Style;
};
function QuestionListSubsectionItems(props: QuestionListSubsectionItemsProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}</Text>
            <List>
                {props.answer.map((item, key) => (
                    <Fragment key={key}>
                        <Item>
                            <Text style={styles.text}>{`${formatDate(item.date)}`}</Text>
                        </Item>
                        <MainSubItem>
                            <QuestionMocAnswer
                                question="Locatie"
                                answer={props.mapper('primaryTumors', item.locationCode) ?? '/'}
                                overridingStyle={props.overridingStyle}
                            />
                        </MainSubItem>
                        {item.kindCode && (
                            <MainSubItem>
                                <QuestionMocAnswer
                                    question="Soort"
                                    answer={props.mapper('resectionKinds', item.kindCode!) ?? '/'}
                                    overridingStyle={props.overridingStyle}
                                />
                            </MainSubItem>
                        )}
                        <MainSubItem>
                            <QuestionMocAnswer
                                question="Commentaar"
                                answer={item.comment ?? '/'}
                                overridingStyle={props.overridingStyle}
                            />
                        </MainSubItem>
                        <List>
                            {item.sectionTypes.map((type, innerKey) => (
                                <Fragment key={innerKey}>
                                    <MainSubItem>
                                        <QuestionMocCodeAndOtherWithValueAndUnit
                                            question="Type"
                                            answer={props.mapper(props.mapTypeKey, type.typeCode) ?? '/'}
                                            other={type.typeOther}
                                            value={props.mapper(props.mapValueTypeKey, type.valueCode) ?? '/'}
                                            valueOther={type.valueOther}
                                            unit={type.unit ?? ''}
                                            overridingStyle={props.overridingStyle}
                                        />
                                    </MainSubItem>
                                    <List>
                                        <SubItem>
                                            <QuestionMocAnswer
                                                question="Info"
                                                answer={type.info ?? '/'}
                                                overridingStyle={props.overridingStyle}
                                            />
                                        </SubItem>
                                    </List>
                                </Fragment>
                            ))}
                        </List>
                    </Fragment>
                ))}
            </List>
        </View>
    ) : (
        <QuestionMocAnswer question={props.question} answer="/" />
    );
}
