import React, { useState, useEffect } from 'react';
import MocsTable from './MocsTable';
import { Mocs } from '../../api/types';
import { Paper, Select, MenuItem, Divider, Typography } from '@material-ui/core';
import useData, { ResponseDataToData } from '../../api/useData';
import styles from './Mocs.module.scss';

type FilterResponse = Mocs.GetMocsFilterData.Response;

const actionsResponseMapper: ResponseDataToData<FilterResponse> = d => d;

const boolToNumber = (from: boolean | null) => {
    return from === true ? 1 : from === false ? 2 : 0;
};

const MocsPage: React.FC = () => {
    const [referringHospital, setReferringHospital] = useState<number>(0);
    const [mocType, setMocType] = useState<number>(0);
    const [validationType, setValidationType] = useState<boolean | null>(null);

    const [filters, setFilters] = useState<FilterResponse | null>(null);
    const [getFilterData, filterDataResponse] = useData('mocs/filters', actionsResponseMapper);

    useEffect(() => {
        getFilterData();
    }, [getFilterData]);

    useEffect(() => {
        filterDataResponse.hasLoaded && !filterDataResponse.isError && setFilters(filterDataResponse.data);
    }, [filterDataResponse]);

    const handleReferringHospitalChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== undefined) {
            setReferringHospital(event.target.value as number);
        } else {
            setReferringHospital(0);
        }
        refreshTable();
    };

    const handleMocTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== undefined) {
            setMocType(event.target.value as number);
        } else {
            setMocType(0);
        }
        refreshTable();
    };

    const handleValidationTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        switch (event.target.value as number) {
            case 1:
                setValidationType(true);
                break;
            case 2:
                setValidationType(false);
                break;
            default:
                setValidationType(null);
                break;
        }

        refreshTable();
    };

    const tableRef = React.createRef<any>();
    const refreshTable = () => {
        if (tableRef.current) tableRef.current.onQueryChange();
    };

    return (
        <>
            <main>
                <Paper className={styles.paper}>
                    <Typography variant="h5">Filters</Typography>
                    <div className={styles.container}>
                        <Select
                            className={styles.filter}
                            value={referringHospital}
                            fullWidth={true}
                            placeholder="Referring hospital"
                            variant="outlined"
                            onChange={handleReferringHospitalChanged}
                        >
                            <MenuItem key="emptyAction" value={0}>
                                All referring hospitals
                            </MenuItem>
                            <Divider />
                            {filters &&
                                filters.referringHospitals &&
                                filters.referringHospitals.map(({ id, value }) => (
                                    <MenuItem key={id} value={id}>
                                        {value}
                                    </MenuItem>
                                ))}
                        </Select>
                        <Select
                            className={styles.filter}
                            value={mocType}
                            fullWidth={true}
                            variant="outlined"
                            placeholder="MOC Type"
                            onChange={handleMocTypeChanged}
                        >
                            <MenuItem key="emptyAction" value={0}>
                                All MOC types
                            </MenuItem>
                            <Divider />
                            {filters &&
                                filters.mocTypes &&
                                filters.mocTypes.map(({ id, value }) => (
                                    <MenuItem key={id} value={id}>
                                        {value}
                                    </MenuItem>
                                ))}
                        </Select>
                        <Select
                            className={styles.filter}
                            value={boolToNumber(validationType)}
                            fullWidth={true}
                            variant="outlined"
                            placeholder="Complete / Incomplete"
                            onChange={handleValidationTypeChanged}
                        >
                            <MenuItem value={0}>All complete & incomplete</MenuItem>
                            <Divider />
                            <MenuItem value={1}>Only complete</MenuItem>
                            <MenuItem value={2}>Only incomplete</MenuItem>
                        </Select>
                    </div>
                </Paper>
                <MocsTable
                    tableRef={tableRef}
                    mocTypeId={mocType}
                    referringHospitalId={referringHospital}
                    validationType={validationType}
                />
            </main>
        </>
    );
};

export default MocsPage;
