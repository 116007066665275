import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps, fieldToTextField } from 'formik-material-ui';

const FormikTextArea = (props: TextFieldProps) => {
    return (
        <MuiTextField
            {...fieldToTextField(props)}
            inputRef={props.inputRef}
            variant="outlined"
            fullWidth
            multiline
            rowsMax={10}
        />
    );
};

export default FormikTextArea as React.ComponentType;
