import React from 'react';
import { Grid } from '@material-ui/core';
import Question from './Question';
import { MocFileType } from '../MocForm.config';
import FileNameField from './FileNameField';

type Props = {
    type: MocFileType;
    question: string;
    name: string;
};

const FileNameQuestion: React.FC<Props> = ({ type, question, name }) => (
    <Question question={question}>
        <Grid container>
            <Grid item xs={12}>
                <FileNameField type={type} name={name} />
            </Grid>
        </Grid>
    </Question>
);

export default FileNameQuestion;
