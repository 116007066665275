import { parseJSON, format } from 'date-fns';
export function formatDate(value: string | Date | null) {
    if (!value) {
        return null;
    }
    if (typeof value === 'string') {
        return value;
    }
    const date = parseJSON(value);
    const formatted = format(date, 'dd MMM yyyy');
    return formatted;
}
