import React, { useRef } from 'react';
import cs from 'classnames';
import { Paper, Typography, TextField, InputAdornment, IconButton } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import styles from './LinkCallout.module.scss';

type Props = {
    link: string;
    onClose?: () => void;
};

const LinkCallout: React.FC<Props> = ({ children, link, onClose }) => {
    const linkRef = useRef<HTMLInputElement>();

    const handleLinkClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (linkRef.current) {
            linkRef.current.select();
        }
    };

    const handleLinkCopy = (e: React.SyntheticEvent) => {
        e.preventDefault();
        if (linkRef.current) {
            linkRef.current.select();
            document.execCommand('copy');
            linkRef.current.focus();
        }
    };

    return (
        <Paper className={styles.link_paper}>
            <Typography component="p" variant="body1">
                {children}
            </Typography>
            <TextField
                value={link}
                variant="filled"
                fullWidth
                className={styles.link_textfield}
                inputProps={{
                    readOnly: true,
                    className: styles.link_input,
                    ref: linkRef,
                    onClick: handleLinkClick
                }}
                InputProps={{
                    className: styles.link_formcontrol,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="copy link to clipboard"
                                onClick={handleLinkCopy}
                                size="small"
                                className={styles.link_button}
                            >
                                <CopyIcon />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            {onClose && (
                <IconButton
                    aria-label="close callout"
                    onClick={onClose}
                    size="small"
                    className={cs(styles.link_button, styles.close_button)}
                >
                    <CloseIcon />
                </IconButton>
            )}
        </Paper>
    );
};

export default LinkCallout;
