import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField, useFormikContext } from 'formik';
import FormikTextArea from '../../../../../../styles/components/forms/FormikTextArea';
import { Values } from '../../MocForm.config';
import usePermissions from '../../../../../../auth/usePermissions';

type Props = {
    hadSurgery: boolean | '';
};

const Reports: React.FC<Props> = ({ hadSurgery }) => {
    const { initialValues } = useFormikContext<Values>();
    const { generalInformation } = initialValues;
    const permissions = usePermissions();
    const { canEditLockedMocReports } = permissions;

    const mccmReport = generalInformation.mccmReport;
    const pathologyReport = generalInformation.pathologyReport;
    const surgeryReport = generalInformation.surgeryReport;

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="subtitle2">
                    Please upload the following report(s) without patient identification variables
                </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
                <FastField
                    name="generalInformation.mccmReport"
                    type="text"
                    component={FormikTextArea}
                    label="MC/CM report"
                    disabled={mccmReport !== '' && !canEditLockedMocReports}
                />
            </Grid>
            {hadSurgery && (
                <>
                    <Grid item xs={12} md={12}>
                        <FastField
                            name="generalInformation.pathologyReport"
                            type="text"
                            component={FormikTextArea}
                            label="Pathology report"
                            disabled={pathologyReport !== '' && !canEditLockedMocReports}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <FastField
                            name="generalInformation.surgeryReport"
                            type="text"
                            component={FormikTextArea}
                            label="Surgery report"
                            disabled={surgeryReport !== '' && !canEditLockedMocReports}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FastField
                            name="generalInformation.risivSurgeonOne"
                            type="text"
                            component={FormikTextArea}
                            label="Surgeon 1: number RISIV/INAMI"
                            disabled={surgeryReport !== '' && !canEditLockedMocReports}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FastField
                            name="generalInformation.risivSurgeonTwo"
                            type="text"
                            component={FormikTextArea}
                            label="Surgeon 2: number RISIV/INAMI"
                            disabled={surgeryReport !== '' && !canEditLockedMocReports}
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export default Reports;
