import { Mocs } from '../../../../../../api/types';
import { toMocNumber, toMocBoolean, toValuesNumber, toValuesBoolean, toValuesString } from '../../mappers';
import { CodeAndOtherValue, CodeValue } from '../../MocForm.config';

export type GetMocValues = Pick<
    Mocs.CkzPancreasData & Mocs.CkzOesophagusData,
    | 'patientHeight'
    | 'patientWeight'
    | 'whoPerformanceStatusCode'
    | 'asaScoreCode'
    | 'isComorbidity'
    | 'comorbidityTypes'
    | 'isAntithromboticMedication'
    | 'antithromboticMedicationTypes'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzPancreasFields & Mocs.SaveMoc.CkzOesophagusFields,
    | 'patientHeight'
    | 'patientWeight'
    | 'whoPerformanceStatusCode'
    | 'asaScoreCode'
    | 'isComorbidity'
    | 'comorbidityTypes'
    | 'isAntithromboticMedication'
    | 'antithromboticMedicationTypes'
>;

export type FormValues = {
    patientHeight: number | '';
    patientWeight: number | '';
    whoPerformanceStatusCode: string;
    asaScoreCode: string;
    isComorbidity: boolean | '';
    comorbidityTypes: CodeAndOtherValue[];
    isAntithromboticMedication: boolean | '';
    antithromboticMedicationTypeCodes: CodeValue[];
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    patientHeight: toValuesNumber(moc.patientHeight),
    patientWeight: toValuesNumber(moc.patientWeight),
    whoPerformanceStatusCode: toValuesString(moc.whoPerformanceStatusCode),
    asaScoreCode: toValuesString(moc.asaScoreCode),
    isComorbidity: toValuesBoolean(moc.isComorbidity),
    comorbidityTypes: moc.comorbidityTypes.map((a) => ({
        code: a.comorbidityCode,
        otherValue: a.otherValue,
    })),
    isAntithromboticMedication: toValuesBoolean(moc.isAntithromboticMedication),
    antithromboticMedicationTypeCodes: moc.antithromboticMedicationTypes.map((a) => ({
        code: a.antithromboticMedicationCode,
    })),
});

export const getNewMocValues = (): GetMocValues => ({
    patientHeight: 0,
    patientWeight: 0,
    whoPerformanceStatusCode: '',
    asaScoreCode: '',
    isComorbidity: null,
    comorbidityTypes: [],
    isAntithromboticMedication: null,
    antithromboticMedicationTypes: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    patientHeight: toMocNumber(form.patientHeight),
    patientWeight: toMocNumber(form.patientWeight),
    whoPerformanceStatusCode: form.whoPerformanceStatusCode,
    asaScoreCode: form.asaScoreCode,
    isComorbidity: toMocBoolean(form.isComorbidity),
    comorbidityTypes: form.comorbidityTypes,
    isAntithromboticMedication: toMocBoolean(form.isAntithromboticMedication),
    antithromboticMedicationTypes: form.antithromboticMedicationTypeCodes,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
