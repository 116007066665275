import React from 'react';
import { Grid, FormHelperText, MenuItem } from '@material-ui/core';
import { Field, ErrorMessage } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import FormikDatePickerField from '../../../../../../styles/components/forms/FormikDatePickerField';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import { Gender } from '../../../../../../api/enums';

const PersonalInformation: React.FC = () => (
    <Grid container spacing={3}>
        <Grid item xs={6} md={5}>
            <Field name="firstName" type="text" component={FormikTextField} label="First name" />
        </Grid>
        <Grid item xs={6} md={5}>
            <Field name="surname" type="text" component={FormikTextField} label="Surname" />
        </Grid>
        <Grid item style={{ padding: 0 }} md={2}></Grid>
        <Grid item xs={6} md={5}>
            <Field name="dateOfBirth" component={FormikDatePickerField as any} label="Date of birth" disableFuture />
            <ErrorMessage name="dateOfBirth">
                {() => (
                    <FormHelperText className="error error-message" error={true}>
                        Date of birth is a required field
                    </FormHelperText>
                )}
            </ErrorMessage>
        </Grid>
        <Grid item xs={6} md={5}>
            <Field name="dateOfDeath" component={FormikDatePickerField as any} label="Date of death" disableFuture />
            <ErrorMessage name="dateOfDeath">
                {() => (
                    <FormHelperText className="error error-message" error={true}>
                        Please enter a valid date
                    </FormHelperText>
                )}
            </ErrorMessage>
        </Grid>
        <Grid item xs={6} md={2} />
        <Grid item xs={6} md={5}>
            <Field as="select" name="gender" component={FormikSelectField} placeholder="Gender">
                <MenuItem key="empty-indication" value={2} disabled>
                    <i>Select a gender</i>
                </MenuItem>
                {Object.keys(Gender)
                    .filter((key) => !isNaN(Number(key)))
                    .map((gender) => (
                        <MenuItem key={Number(gender)} value={Number(gender)}>
                            {Gender[Number(gender)]}
                        </MenuItem>
                    ))}
            </Field>
            <ErrorMessage name="gender">
                {(msg) => (
                    <FormHelperText className="error error-message" error={true}>
                        {msg}
                    </FormHelperText>
                )}
            </ErrorMessage>
        </Grid>
    </Grid>
);

export default PersonalInformation;
