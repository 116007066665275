import { AxiosInstance, AxiosResponse } from 'axios';
import { Account as Model } from '../types';

export function signin(api: AxiosInstance, request: Model.SignIn.SignInModel): Promise<AxiosResponse> {
    return api.post('signin', request);
}

export function signout(api: AxiosInstance): Promise<AxiosResponse> {
    return api.post('signout');
}
