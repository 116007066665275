import React from 'react';
import { TextFieldProps } from 'formik-material-ui';
import { KeyboardDatePicker, MaterialUiPickersDate, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { nl } from 'date-fns/locale';

const FormikDatePickerField = ({
    onError,
    field,
    form,
    variant = 'outlined',
    fullWidth = true,
    ...props
}: TextFieldProps) => {
    const value = field.value as string | null;
    const setValue = (d: string | null) => form.setFieldValue(field.name, d);
    const handleChange = (date: MaterialUiPickersDate | null) => {
        if (!date) {
            setValue(null);
        } else {
            const isValid = !isNaN(date.getTime());
            if (isValid) {
                setValue(date.toISOString());
            } else {
                setValue(date as any); // set the invalid date to trigger validation
            }
        }
    };
    return (
        <MuiPickersUtilsProvider locale={nl} utils={DateFnsUtils}>
            <KeyboardDatePicker
                {...{ ...props, field, form }}
                disabled={form.isSubmitting || props.disabled}
                autoOk
                fullWidth={fullWidth}
                variant="inline"
                inputVariant={variant}
                format="dd/MM/yyyy"
                value={value}
                onChange={handleChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export default FormikDatePickerField as React.ComponentType;
