import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import ClinicalBiologyList from '../../enets/ClinicalBiologyList';
import { Grid } from '@material-ui/core';

type Props = {
    isValid: boolean | null;
};

export default function ClinicalBiology(props: Props) {
    return (
        <MocSectionContainer code="clinical-biology" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ClinicalBiologyList />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
