import React from 'react';
import { Form, Formik, Field, FormikHelpers } from 'formik';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import useApi from '../../../../api/useApi';
import { Identity } from '../../../../api/types';
import FormikTextField from '../../../../styles/components/forms/FormikTextField';
import PasswordRequirementsList from './PasswordRequirementsList';
import { initialValues, Values, validationSchema } from './ChangePasswordForm.config';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';

type Request = Identity.ChangePassword.Request;
type Props = {
    userId: number;
    showSnack: ShowSnack;
};

const ChangePasswordForm: React.FC<Props> = ({ userId, showSnack }) => {
    const api = useApi();

    async function handleSubmit(values: Values, formik: FormikHelpers<Values>) {
        const request: Request = {
            id: userId,
            currentPassword: values.currentPassword,
            newPassword: values.password
        };

        const { status } = await api.put<{}>('account/password', request);

        if (status < 400) {
            showSnack('Your new password was saved');
            formik.resetForm();
        } else {
            showSnack('The password could not be saved', 'error');
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnMount={true}
            validateOnBlur={false}
        >
            {({ values, isSubmitting, isValid }) => (
                <Form>
                    <Grid container>
                        <Grid item xs={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="currentPassword"
                                        type="password"
                                        label="Current password"
                                        autoComplete="off"
                                        fullWidth
                                        component={FormikTextField}
                                        disabled={isSubmitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="password"
                                        type="password"
                                        label="New password"
                                        autoComplete="off"
                                        fullWidth
                                        component={FormikTextField}
                                        disabled={isSubmitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="confirmPassword"
                                        type="password"
                                        label="Confirm the password"
                                        autoComplete="off"
                                        fullWidth
                                        component={FormikTextField}
                                        disabled={isSubmitting}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <SaveIcon />
                                        &nbsp;&nbsp;Save new password
                                    </Button>
                                    &nbsp;&nbsp;
                                    {isSubmitting && <CircularProgress style={{ position: 'absolute' }} size={36} />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            {(!!values.password.length || !!values.confirmPassword.length) && (
                                <PasswordRequirementsList value={values.password || values.confirmPassword} />
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default ChangePasswordForm;
