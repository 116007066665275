import { Mocs } from '../../../../../../api/types';
import MakeOptional from '../../MakeOptional';
import { toMocBoolean, toMocDate, toValuesBoolean, toValuesString, toValuesDate } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzPancreasData & Mocs.CkzOesophagusData,
    | 'hadSurgery'
    | 'indicationCode'
    | 'indicationOtherValue'
    | 'lesionToTreatCode'
    | 'hasAnnex55'
    | 'mccmReport'
    | 'pathologyReport'
    | 'surgeryReport'
    | 'referred'
    | 'referringHospitalCode'
    | 'referringForeignHospital'
    | 'mccmAtReferringHospital'
    | 'mccmReferringHospitalDate'
    | 'hospitalizedAtReferringHospital'
    | 'dateOfLastConsultationAtReferringHospital'
    | 'dateOfDischargeAtReferringHospital'
    | 'firstDiagnosticTypeCode'
    | 'firstDiagnosticDate'
    | 'firstTissueSampleMethodCode'
    | 'firstTissueSampleMethodDate'
    | 'dateOfDiagnosis'
    | 'risivSurgeonOne'
    | 'risivSurgeonTwo'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzPancreasFields & Mocs.SaveMoc.CkzOesophagusFields,
    | 'hadSurgery'
    | 'indicationCode'
    | 'indicationOtherValue'
    | 'hasAnnex55'
    | 'lesionToTreatCode'
    | 'mccmReport'
    | 'pathologyReport'
    | 'surgeryReport'
    | 'referred'
    | 'referringHospitalCode'
    | 'referringForeignHospital'
    | 'mccmAtReferringHospital'
    | 'mccmReferringHospitalDate'
    | 'hospitalizedAtReferringHospital'
    | 'dateOfLastConsultationAtReferringHospital'
    | 'dateOfDischargeAtReferringHospital'
    | 'firstDiagnosticTypeCode'
    | 'firstDiagnosticDate'
    | 'firstTissueSampleMethodCode'
    | 'firstTissueSampleMethodDate'
    | 'dateOfDiagnosis'
    | 'risivSurgeonOne'
    | 'risivSurgeonTwo'
>;

export type FormValues = {
    hadSurgery: boolean | '';
    hasAnnex55: boolean;
    lesionToTreatCode: string;
    indicationCode: string;
    indicationOtherValue: string;
    mccmReport: string;
    pathologyReport: string;
    surgeryReport: string;
    referred: boolean | '';
    referringHospitalCode: string;
    referringForeignHospital: string;
    mccmAtReferringHospital: boolean | '';
    mccmReferringHospitalDate: string | null;
    hospitalizedAtReferringHospital: boolean | '';
    dateOfLastConsultationAtReferringHospital: string | null;
    dateOfDischargeAtReferringHospital: string | null;
    firstDiagnosticTypeCode: string;
    firstDiagnosticDate: string | null;
    firstTissueSampleMethodCode: string;
    firstTissueSampleMethodDate: string | null;
    dateOfDiagnosis: string | null;
    risivSurgeonOne: string;
    risivSurgeonTwo: string;
};

export const mapInitialValues = (moc: MakeOptional<GetMocValues>): FormValues => ({
    hadSurgery: toValuesBoolean(moc.hadSurgery),
    hasAnnex55: moc.hasAnnex55!,
    lesionToTreatCode: toValuesString(moc.lesionToTreatCode),
    indicationCode: toValuesString(moc.indicationCode),
    indicationOtherValue: toValuesString(moc.indicationOtherValue),
    mccmReport: toValuesString(moc.mccmReport),
    pathologyReport: toValuesString(moc.pathologyReport),
    surgeryReport: toValuesString(moc.surgeryReport),
    referred: toValuesBoolean(moc.referred),
    referringHospitalCode: toValuesString(moc.referringHospitalCode),
    referringForeignHospital: toValuesString(moc.referringForeignHospital),
    mccmAtReferringHospital: toValuesBoolean(moc.mccmAtReferringHospital),
    mccmReferringHospitalDate: toValuesDate(moc.mccmReferringHospitalDate),
    hospitalizedAtReferringHospital: toValuesBoolean(moc.hospitalizedAtReferringHospital),
    dateOfLastConsultationAtReferringHospital: toValuesDate(moc.dateOfLastConsultationAtReferringHospital),
    dateOfDischargeAtReferringHospital: toValuesDate(moc.dateOfDischargeAtReferringHospital),
    firstDiagnosticTypeCode: toValuesString(moc.firstDiagnosticTypeCode),
    firstDiagnosticDate: toValuesDate(moc.firstDiagnosticDate),
    firstTissueSampleMethodCode: toValuesString(moc.firstTissueSampleMethodCode),
    firstTissueSampleMethodDate: toValuesDate(moc.firstTissueSampleMethodDate),
    dateOfDiagnosis: toValuesDate(moc.dateOfDiagnosis),
    risivSurgeonOne: toValuesString(moc.risivSurgeonOne),
    risivSurgeonTwo: toValuesString(moc.risivSurgeonTwo),
});

export const getNewMocValues = (): GetMocValues => ({
    hadSurgery: null,
    indicationCode: '',
    indicationOtherValue: '',
    hasAnnex55: false,
    lesionToTreatCode: '',
    mccmReport: '',
    pathologyReport: '',
    surgeryReport: '',
    referred: null,
    referringHospitalCode: '',
    referringForeignHospital: '',
    mccmAtReferringHospital: null,
    mccmReferringHospitalDate: null,
    hospitalizedAtReferringHospital: null,
    dateOfLastConsultationAtReferringHospital: null,
    dateOfDischargeAtReferringHospital: null,
    firstDiagnosticTypeCode: '',
    firstDiagnosticDate: null,
    firstTissueSampleMethodCode: '',
    firstTissueSampleMethodDate: null,
    dateOfDiagnosis: null,
    risivSurgeonOne: '',
    risivSurgeonTwo: '',
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    hadSurgery: toMocBoolean(form.hadSurgery),
    indicationCode: form.indicationCode,
    indicationOtherValue: form.indicationOtherValue,
    hasAnnex55: form.hasAnnex55,
    lesionToTreatCode: form.lesionToTreatCode,
    mccmReport: form.mccmReport,
    pathologyReport: form.pathologyReport,
    surgeryReport: form.surgeryReport,
    referred: toMocBoolean(form.referred),
    referringHospitalCode: form.referringHospitalCode,
    referringForeignHospital: form.referringForeignHospital,
    mccmAtReferringHospital: toMocBoolean(form.mccmAtReferringHospital),
    mccmReferringHospitalDate: toMocDate(form.mccmReferringHospitalDate),
    hospitalizedAtReferringHospital: toMocBoolean(form.hospitalizedAtReferringHospital),
    dateOfLastConsultationAtReferringHospital: form.dateOfLastConsultationAtReferringHospital,
    dateOfDischargeAtReferringHospital: form.dateOfDischargeAtReferringHospital,
    firstDiagnosticTypeCode: form.firstDiagnosticTypeCode,
    firstDiagnosticDate: toMocDate(form.firstDiagnosticDate),
    firstTissueSampleMethodCode: form.firstTissueSampleMethodCode,
    firstTissueSampleMethodDate: toMocDate(form.firstTissueSampleMethodDate),
    dateOfDiagnosis: toMocDate(form.dateOfDiagnosis),
    risivSurgeonOne: form.risivSurgeonOne,
    risivSurgeonTwo: form.risivSurgeonTwo,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
