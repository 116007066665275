import * as Yup from 'yup';
export type SearchValues = {
    uzaNr: string;
};

export const initialValues: SearchValues = {
    uzaNr: '',
};
export function validateSearchForm(otherPatientNumbers: string[] | null) {
    return Yup.object().shape({
        uzaNr: Yup.number()
            .required('UZA Patientnumber is a required field')
            .test('len', 'The number must be exactly 9 digits', (val) => (val + '').length === 9)
            .notOneOf(
                (otherPatientNumbers || []).map((nr) => parseInt(nr, 10)),
                'This patient was already registered'
            ),
    });
}
