import { useEffect, useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Values } from '../MocForm.config';

type PathValue = {
    path: string;
    getValue: () => any;
};

export const useConstant = <T>(path: string, value: T): PathValue =>
    useMemo(() => ({ path, getValue: () => value }), [path, value]);

export const useEmptyArray = (path: string): PathValue => useMemo(() => ({ path, getValue: () => [] }), [path]);

export function useResetOnValue<TWatch = any>(watchPath: string, watchValue: TWatch, ...resets: PathValue[]) {
    const { setFieldValue } = useFormikContext<Values>();
    const [watchField] = useField<TWatch>(watchPath);
    const watchFieldValue = watchField.value;

    useEffect(() => {
        const watchValueIsTriggered = watchFieldValue === watchValue;
        if (watchValueIsTriggered) {
            resets.forEach(({ path, getValue }) => setFieldValue(path, getValue()));
        }
        // eslint: Complains about ...spread operator
        // eslint-disable-next-line
    }, [...resets, watchPath, watchValue, watchFieldValue, setFieldValue]);
}

export function useResetOnChange(watchPath: string, ...resets: PathValue[]) {
    const { setFieldValue } = useFormikContext<Values>();
    const [watchField] = useField(watchPath);
    const watchFieldValue = watchField.value;
    const [oldWatchFieldValue, setOldWatchFieldValue] = useState(watchFieldValue);

    useEffect(() => {
        const watchValueIsTriggered = watchFieldValue !== oldWatchFieldValue;
        if (watchValueIsTriggered) {
            resets.forEach(({ path, getValue }) => setFieldValue(path, getValue()));
            setOldWatchFieldValue(watchFieldValue);
        }
        // eslint: Complains about ...spread operator
        // eslint-disable-next-line
    }, [...resets, watchFieldValue, oldWatchFieldValue, setFieldValue]);
}

export function useResetOnChangeUnless(watchPath: string, unlessWatchPath: string, ...resets: PathValue[]) {
    const { setFieldValue } = useFormikContext<Values>();
    const [watchField] = useField(watchPath);
    const watchFieldValue = watchField.value;
    const [oldWatchFieldValue, setOldWatchFieldValue] = useState(watchFieldValue);

    const [unlessWatchField] = useField(unlessWatchPath);
    const unlessWatchFieldValue = unlessWatchField.value;
    const [oldUnlessWatchFieldValue, setOldUnlessWatchFieldValue] = useState(unlessWatchFieldValue);

    useEffect(() => {
        const watchValueIsTriggered = watchFieldValue !== oldWatchFieldValue;
        const unlessPreventedReset = unlessWatchFieldValue !== oldUnlessWatchFieldValue;
        if (watchValueIsTriggered && !unlessPreventedReset) {
            resets.forEach(({ path, getValue }) => setFieldValue(path, getValue()));
        }
        setOldWatchFieldValue(watchFieldValue);
        setOldUnlessWatchFieldValue(unlessWatchFieldValue);
        // eslint: Complains about ...spread operator
        // eslint-disable-next-line
    }, [
        // eslint-disable-next-line
        ...resets,
        watchFieldValue,
        oldWatchFieldValue,
        unlessWatchFieldValue,
        //oldUnlessWatchFieldValue,  // --> exclude from dependency array because we don't want this to trigger a render
        setFieldValue,
    ]);
}

export function useResetOnSetNotIncluded<TWatch = any>(
    watchPath: string,
    watchSet: TWatch[],
    compare: (valA: TWatch, valB: TWatch) => boolean,
    ...resets: PathValue[]
) {
    const { setFieldValue } = useFormikContext<Values>();
    const [watchField] = useField<TWatch[]>(watchPath);
    const watchFieldSet = watchField.value;

    function isIn(val: TWatch, list: TWatch[]) {
        return list.some((listValue) => compare(val, listValue));
    }

    useEffect(() => {
        if (!watchFieldSet.some((fieldValue) => isIn(fieldValue, watchSet))) {
            resets.forEach(({ path, getValue }) => setFieldValue(path, getValue()));
        }
        // eslint: Complains about ...spread operator
        // eslint-disable-next-line
    }, [...resets, watchPath, watchFieldSet, setFieldValue]);
}
