import React from 'react';
import GeneralRemarks from '../sections/generalRemarks/GeneralRemarks';
import { ValidatedSection } from '../sections/validation';
import MocSectionCode from '../sections/MocSectionCode';
import Report from '../sections/report/Report';
import Imaging from '../sections/imaging/Imaging';
import ClinicalBiology from '../sections/clinicalBiology/ClinicalBiology';
import Pathology from '../sections/pathology/Pathology';
import TreatmentPlan from '../sections/TreatmentPlan/Treatmentplan';
type Props = {
    validatedSections: ValidatedSection[];
};

function isValidSection(validatedSections: ValidatedSection[], code: MocSectionCode) {
    const section = validatedSections.find((s) => s.code === code);
    return section ? section.isValid : null;
}

export default function NetMoc(props: Props) {
    const isValid = (code: MocSectionCode) => isValidSection(props.validatedSections, code);
    return (
        <>
            <Report isValid={isValid('report')} />
            <Imaging isValid={isValid('imaging')} />
            <Pathology isValid={isValid('pathology')} />
            <ClinicalBiology isValid={isValid('clinical-biology')} />
            <TreatmentPlan isValid={isValid('treatment-plan')} />
            <GeneralRemarks isValid={isValid('general-remarks')} />
        </>
    );
}
