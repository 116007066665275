import { Mocs } from '../../../../api/types';
import MocTypeCode from '../MocTypeCode';
import * as generalInformation from './sections/generalInformation/GeneralInformation.config';
import * as annex55 from './sections/annex_55/Annex55.config';
import * as patientCharacteristics from './sections/patientCharacteristics/PatientCharacteristics.config';
import * as pancreasSurgery from './sections/surgery/ckzPancreas/Surgery.config';
import * as oesophagusSurgery from './sections/surgery/ckzOesophagus/Surgery.config';
import * as sarcomenSurgery from './sections/surgery/ckzSarcomen/Surgery.config';
import * as pancreasPostSurgery from './sections/postSurgery/ckzPancreas/PostSurgery.config';
import * as oesophagusPostSurgery from './sections/postSurgery/ckzOesophagus/PostSurgery.config';
import * as ckzPancreas from './ckzPancreas';
import * as ckzOesophagus from './ckzOesophagus';
import * as netMoc from './netMoc';
import * as localMoc from './localMoc';
import * as generalRemarks from './sections/generalRemarks/GeneralRemarks.config';
import * as clinicalBiology from './sections/clinicalBiology/ClinicalBiology.config';
import * as report from './sections/report/Report.config';
import * as treatmentPlan from './sections/TreatmentPlan/TreatmentPlan.config';
import * as imaging from './sections/imaging/Imaging.config';
import * as pathology from './sections/pathology/Pathology.config';
import * as ckzSarcomen from './ckzSarcomen';
import * as information from './sections/information/Information.config';
import * as neoAdjuvantTreatment from './sections/neoAdjuvantTreatment/NeoAdjuvantTreatment.config';
import * as adjuvantTherapy from './sections/adjuvantTherapy/AdjuvantTherapy.config';
import * as imagingDimensions from './sections/imagingDimensions/ImagingDimensions.config';
import * as pathologyFnclcc from './sections/pathologyFnclcc/PathologyFnclcc.config';
import * as ckzSarcomenTreatmentPlan from './sections/TreatmentPlan/ckzSarcomen/TreatmentPlan.config';
import * as ckzSarcomenAnnex55 from './sections/annex_55/ckzSarcomen/Annex55.config';
import MocSectionCode, { getSectionCodes } from './sections/MocSectionCode';
import MakeOptional from './MakeOptional';
import MocTypePropNames from './MocTypePropNames';

type GetMoc = MakeOptional<Mocs.GetMoc.MocData, MocTypePropNames>;
type SaveMoc = MakeOptional<Mocs.SaveMoc.Request, MocTypePropNames>;

type MapInitialValues = (moc: GetMoc, patientId: number, mocFiles: MocFile[]) => Values;
type MapSubmitValues = (
    values: SectionValues,
    moc: Pick<GetMoc, 'id' | 'mocTypeCode'>,
    patientId: number
) => MakeOptional<Mocs.SaveMoc.Request, MocTypePropNames>;

type MocTypeHandler = {
    mapInitialSectionValues: (moc: GetMoc) => Partial<SectionValues>;
    getNewMocValues: () => Partial<Pick<GetMoc, MocTypePropNames>>;
    mapSubmitValues: (values: SectionValues) => Pick<SaveMoc, MocTypePropNames>;
};

type MocTypeToHandler = {
    [K in MocTypeCode]: MocTypeHandler;
};

const mocTypeHandlers: MocTypeToHandler = {
    CKZPancreas: ckzPancreas,
    CKZOesophagus: ckzOesophagus,
    NETMOC: netMoc,
    LocalMOC: localMoc,
    CKZSarcomen: ckzSarcomen,
};

export type CodeAndOtherValue = { code: string; otherValue: string };

export type CodeValue = { code: string };

export type CodeAndOtherValueAndOrder = CodeAndOtherValue & {
    sortOrder: number;
};

export type CkzTreatmentItem = CodeAndOtherValueAndOrder & {
    id: number;
};

export type MocFileType = 'MOC' | 'Biopsy' | 'Cytology' | 'Resection' | 'Surgery' | 'Imaging' | 'Annex55';

export type Values = {
    mocId: number | string;
    mocTypeCode: MocTypeCode;
    mocDate: string | null;
    patientId: number;
    mocFiles: MocFile[];
    initialSections: MocSectionCode[];
    generalInformation: generalInformation.FormValues;
    annex55: annex55.FormValues;
    patientCharacteristics: patientCharacteristics.FormValues;
    pancreasSurgery: pancreasSurgery.FormValues;
    oesophagusSurgery: oesophagusSurgery.FormValues;
    sarcomenSurgery: sarcomenSurgery.FormValues;
    pancreasPostSurgery: pancreasPostSurgery.FormValues;
    oesophagusPostSurgery: oesophagusPostSurgery.FormValues;
    generalRemarks: generalRemarks.FormValues;
    report: report.FormValues;
    imaging: imaging.FormValues;
    clinicalBiology: clinicalBiology.FormValues;
    pathology: pathology.FormValues;
    treatmentPlan: treatmentPlan.FormValues;
    information: information.FormValues;
    neoAdjuvantTreatment: neoAdjuvantTreatment.FormValues;
    adjuvantTherapy: adjuvantTherapy.FormValues;
    imagingDimensions: imagingDimensions.FormValues;
    pathologyFnclcc: pathologyFnclcc.FormValues;
    ckzSarcomenTreatmentPlan: ckzSarcomenTreatmentPlan.FormValues;
    ckzSarcomenAnnex55: ckzSarcomenAnnex55.FormValues;
};

export type MocFile = {
    id: number;
    name: string;
    type: MocFileType;
};

export type SectionValues = Omit<Values, 'mocId' | 'mocTypeCode'>;

const initialSectionValues = {
    generalInformation: generalInformation.initialValues,
    annex55: annex55.initialValues,
    patientCharacteristics: patientCharacteristics.initialValues,
    pancreasSurgery: pancreasSurgery.initialValues,
    oesophagusSurgery: oesophagusSurgery.initialValues,
    sarcomenSurgery: sarcomenSurgery.initialValues,
    pancreasPostSurgery: pancreasPostSurgery.initialValues,
    oesophagusPostSurgery: oesophagusPostSurgery.initialValues,
    generalRemarks: generalRemarks.initialValues,
    report: report.initialValues,
    imaging: imaging.initialValues,
    clinicalBiology: clinicalBiology.initialValues,
    pathology: pathology.initialValues,
    treatmentPlan: treatmentPlan.initialValues,
    information: information.initialValues,
    neoAdjuvantTreatment: neoAdjuvantTreatment.initialValues,
    adjuvantTherapy: adjuvantTherapy.initialValues,
    imagingDimensions: imagingDimensions.initialValues,
    pathologyFnclcc: pathologyFnclcc.initialValues,
    ckzSarcomenTreatmentPlan: ckzSarcomenTreatmentPlan.initialValues,
    ckzSarcomenAnnex55: ckzSarcomenAnnex55.initialValues,
};

export const mapInitialValues: MapInitialValues = (moc, patientId, mocFiles) => {
    const mocId = moc.id || `NEW-MOC-${Math.floor(Math.random() * 1000000)}`;
    const mocTypeCode = moc.mocTypeCode as MocTypeCode;
    const sectionValues = { ...initialSectionValues, ...mapInitialSectionValues(moc) };
    const initialSections = getSectionCodes(mocTypeCode, sectionValues);
    return {
        mocId,
        mocTypeCode,
        mocDate: moc.mocDate,
        patientId,
        mocFiles,
        initialSections,
        ...sectionValues,
    };
};

export const mapSubmitValues: MapSubmitValues = (values, { id, mocTypeCode }, patientId) => ({
    id,
    mocTypeCode,
    mocDate: values.mocDate,
    patientId,
    fileIds: values.mocFiles.map((f) => f.id),
    ...mapSectionSubmitValues(values, mocTypeCode),
});

function mapInitialSectionValues(moc: GetMoc): Partial<SectionValues> {
    const typeCode = moc.mocTypeCode as MocTypeCode;
    const typeCodeHandler = mocTypeHandlers[typeCode];

    if (!typeCodeHandler) {
        throw new Error(`No moc type handler configured for moc type: ${typeCode}`);
    }

    return typeCodeHandler.mapInitialSectionValues(moc);
}

function mapSectionSubmitValues(
    values: SectionValues,
    typeCode: string
): Partial<Pick<Mocs.SaveMoc.Request, MocTypePropNames>> {
    const typeCodeHandler = mocTypeHandlers[typeCode as MocTypeCode];

    if (!typeCodeHandler) {
        throw new Error(`No moc type handler configured for moc type: ${typeCode}`);
    }

    return typeCodeHandler.mapSubmitValues(values);
}
