import React from 'react';
import { Container, Paper, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import Section from '../../../styles/components/forms/Section';
import useAuth from '../../../auth/useAuth';
import ChangePasswordForm from './password/ChangePasswordForm';
import OncoBaseSnackbar, { useSnackBar } from '../../../styles/components/OncoBaseSnackbar';
import { Hospital, Role } from '../../../auth/AuthContext';

function isHospitalRole(hospitalRole: Role): hospitalRole is Role & { hospital: Hospital } {
    return hospitalRole.isHospitalDependent && !!hospitalRole.hospital;
}

const AccountPage: React.FC = () => {
    const [showSnack, snackbarProps] = useSnackBar();
    const { user } = useAuth();
    const { id, email, roles } = user!;
    const hospitalRoles = roles.filter(isHospitalRole);
    const adminRole = roles.filter(r => !r.isHospitalDependent && r.isAdmin)[0];
    return (
        <Container component="main">
            <h1>Profile</h1>
            <Paper>
                <Section name="Email address">
                    <p>{email}</p>
                </Section>
                <Section name="Change password">
                    <ChangePasswordForm userId={id} showSnack={showSnack} />
                </Section>
                <Section name="Access">
                    {adminRole && (
                        <p>
                            You are an <strong>{adminRole.name}</strong>
                        </p>
                    )}
                    {!!hospitalRoles.length && (
                        <>
                            <p>You have access to the following hospitals:</p>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Hospital</TableCell>
                                        <TableCell>Role</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hospitalRoles.map(({ hospital, name }) => (
                                        <TableRow key={hospital.name}>
                                            <TableCell>{hospital.name}</TableCell>
                                            <TableCell>{name}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </Section>
            </Paper>
            <OncoBaseSnackbar {...snackbarProps} />
        </Container>
    );
};

export default AccountPage;
