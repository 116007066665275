import { Mocs } from '../../../../../../api/types';
import { toMocBoolean, toMocDate, toValuesBoolean, toValuesString, toValuesDate } from '../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    | 'radiotherapy'
    | 'radiotherapyType'
    | 'radiotherapyStartDate'
    | 'radiotherapyEndDate'
    | 'chemotherapy'
    | 'chemotherapyType'
    | 'chemotherapyTypeOther'
    | 'chemotherapyStartDate'
    | 'chemotherapyEndDate'
    | 'immunotherapy'
    | 'immunotherapyType'
    | 'immunotherapyTypeOther'
    | 'immunotherapyStartDate'
    | 'immunotherapyEndDate'
    | 'cryotherapie'
>;

export type SaveMocValues = Pick<
    Mocs.CkzSarcomenData,
    | 'radiotherapy'
    | 'radiotherapyType'
    | 'radiotherapyStartDate'
    | 'radiotherapyEndDate'
    | 'chemotherapy'
    | 'chemotherapyType'
    | 'chemotherapyTypeOther'
    | 'chemotherapyStartDate'
    | 'chemotherapyEndDate'
    | 'immunotherapy'
    | 'immunotherapyType'
    | 'immunotherapyTypeOther'
    | 'immunotherapyStartDate'
    | 'immunotherapyEndDate'
    | 'cryotherapie'
>;

export type FormValues = {
    radiotherapy: boolean | '';
    radiotherapyType: string;
    radiotherapyStartDate: string | null;
    radiotherapyEndDate: string | null;
    chemotherapy: boolean | '';
    chemotherapyType: string;
    chemotherapyTypeOther: string;
    chemotherapyStartDate: string | null;
    chemotherapyEndDate: string | null;
    immunotherapy: boolean | '';
    immunotherapyType: string;
    immunotherapyTypeOther: string;
    immunotherapyStartDate: string | null;
    immunotherapyEndDate: string | null;
    cryotherapie: boolean | '';
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    radiotherapy: toValuesBoolean(moc.radiotherapy),
    radiotherapyType: toValuesString(moc.radiotherapyType),
    radiotherapyStartDate: toValuesDate(moc.radiotherapyStartDate),
    radiotherapyEndDate: toValuesDate(moc.radiotherapyEndDate),
    chemotherapy: toValuesBoolean(moc.chemotherapy),
    chemotherapyType: toValuesString(moc.chemotherapyType),
    chemotherapyTypeOther: toValuesString(moc.chemotherapyTypeOther),
    chemotherapyStartDate: toValuesDate(moc.chemotherapyStartDate),
    chemotherapyEndDate: toValuesDate(moc.chemotherapyEndDate),
    immunotherapy: toValuesBoolean(moc.immunotherapy),
    immunotherapyType: toValuesString(moc.immunotherapyType),
    immunotherapyTypeOther: toValuesString(moc.immunotherapyTypeOther),
    immunotherapyStartDate: toValuesDate(moc.immunotherapyStartDate),
    immunotherapyEndDate: toValuesDate(moc.immunotherapyEndDate),
    cryotherapie: toValuesBoolean(moc.cryotherapie),
});

export const getNewMocValues = (): GetMocValues => ({
    radiotherapy: false,
    radiotherapyType: '',
    radiotherapyStartDate: null,
    radiotherapyEndDate: null,
    chemotherapy: false,
    chemotherapyType: '',
    chemotherapyTypeOther: '',
    chemotherapyStartDate: null,
    chemotherapyEndDate: null,
    immunotherapy: false,
    immunotherapyType: '',
    immunotherapyTypeOther: '',
    immunotherapyStartDate: null,
    immunotherapyEndDate: null,
    cryotherapie: false,
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    radiotherapy: toMocBoolean(form.radiotherapy),
    radiotherapyType: form.radiotherapyType,
    radiotherapyStartDate: toMocDate(form.radiotherapyStartDate),
    radiotherapyEndDate: toMocDate(form.radiotherapyEndDate),
    chemotherapy: toMocBoolean(form.chemotherapy),
    chemotherapyType: form.chemotherapyType,
    chemotherapyTypeOther: form.chemotherapyTypeOther,
    chemotherapyStartDate: toMocDate(form.chemotherapyStartDate),
    chemotherapyEndDate: toMocDate(form.chemotherapyEndDate),
    immunotherapy: toMocBoolean(form.immunotherapy),
    immunotherapyType: form.immunotherapyType,
    immunotherapyTypeOther: form.immunotherapyTypeOther,
    immunotherapyStartDate: toMocDate(form.immunotherapyStartDate),
    immunotherapyEndDate: toMocDate(form.immunotherapyEndDate),
    cryotherapie: toMocBoolean(form.cryotherapie),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
