import React from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import { EditFormLabels } from '../EditPatientFormLabels';
import styles from '../EditPatientFormImport.module.scss';

type TextfieldWithChangesProps = {
    oldValue: string | null;
    updatedValue: string | null;
    name: EditFormLabels;
    sectionChangesTracker: Map<EditFormLabels, boolean>;
};

export default function TextfieldWithChanges(props: TextfieldWithChangesProps) {
    function showUpdate(property: EditFormLabels) {
        return props.sectionChangesTracker.get(property);
    }
    return (
        <>
            <TextField
                label={props.name}
                defaultValue={props.updatedValue}
                variant="outlined"
                disabled
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {showUpdate(props.name) && (
                <FormHelperText className={styles.formHelperText}>Current: {props.oldValue}</FormHelperText>
            )}
        </>
    );
}
