import React, { useReducer, useRef } from 'react';
import MaterialTable, { Query, QueryResult } from 'material-table';
import useAuth from '../../../../auth/useAuth';
import { Identity } from '../../../../api/types';
import useApi from '../../../../api/useApi';
import useHospital from '../../../../hospital/useHospital';
import { useDialog } from '../../../../styles/components/MaterialDialogService';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';
import UserEmail from './UserEmail';
import UserRole from './UserRole';
import UserDetail from './UserDetail';
import { ValidationErrorsResponse } from '../../../../api';
import ConfirmLinkCallout from '../callout/ConfirmLinkCallout';
import ForgotLinkCallout from '../callout/ForgotLinkCallout';
import { Button } from '@material-ui/core';
import qs from 'query-string';

type Role = { id: number; name: string };
type User = { id: number; email: string; userName: string; isConfirmed: boolean; roleId: number };
type EditUserRoleRequest = Identity.SetUserRole.Request;
type RemoveUserRequest = Identity.RemoveUser.Request;
type CreateConfirmLinkRequest = Identity.CreateConfirmLink.Request;
type CreateConfirmLinkResponse = Identity.CreateConfirmLink.Response;
type CreateForgotLinkRequest = Identity.CreateForgotLink.Request;
type CreateForgotLinkResponse = Identity.CreateForgotLink.Response;
type RowData = User & { role: Role };

type Props = {
    roles: Role[];
    users: User[];
    onUsersChanged: (users: User[]) => void;
    showSnack: ShowSnack;
};

type State = {
    editing: boolean;
    busy: boolean;
    editUser?: RowData;
    saveUser?: RowData;
    removeUser?: RowData;
    linkUser?: RowData;
};

function mapData(roles: Role[] | null, users: User[] | null): RowData[] {
    if (roles === null || users === null) {
        return [];
    }
    return users.map((user) => ({ ...user, role: roles.find((r) => r.id === user.roleId) } as RowData));
}

function reducer(state: State, action: any) {
    switch (action.type) {
        case 'EDIT_START': {
            return { ...state, editing: true, editUser: action.user };
        }
        case 'EDIT_CANCEL': {
            return { ...state, editing: false, editUser: undefined };
        }
        case 'SAVE_START': {
            return { ...state, busy: true, saveUser: action.user };
        }
        case 'SAVE_OK': {
            return {
                ...state,
                editing: false,
                editUser: undefined,
                busy: false,
                saveUser: undefined,
            };
        }
        case 'SAVE_FAIL': {
            return { ...state, busy: false, saveUser: undefined };
        }
        case 'REMOVE_START': {
            return { ...state, busy: true, removeUser: action.user };
        }
        case 'REMOVE_OK': {
            return {
                ...state,
                busy: false,
                removeUser: undefined,
            };
        }
        case 'LINK_START': {
            return { ...state, busy: true, linkUser: action.user };
        }
        case 'LINK_OK': {
            return {
                ...state,
                busy: false,
                linkUser: undefined,
            };
        }
    }
    return state;
}

// copied from ExportPage.tsx, maybe we can make a 'downloadService' or something ?
const downLoadFile = (data: any, type: string, filename: string) => {
    const blob = new Blob([data], { type });

    //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
};

const UsersOverview: React.FC<Props> = ({ roles, users, onUsersChanged, showSnack }) => {
    const api = useApi();
    const dialog = useDialog();
    const { hospital } = useHospital();
    const { user: currentUser } = useAuth();
    const [state, dispatch] = useReducer(reducer, { editing: false, busy: false });
    const queryRef = useRef<Query<RowData>>();
    const isLoading = roles === null || users === null;
    const data = mapData(roles, users);

    function isCurrentUser(user: RowData) {
        return currentUser!.email === user!.email;
    }

    async function handleCreateNewLink(userId: number) {
        const user = users!.find((u) => u.id === userId)!;
        dispatch({ type: 'LINK_START', user });

        const request: CreateConfirmLinkRequest = { id: user.id };
        const { status, data } = await api.post<CreateConfirmLinkResponse | ValidationErrorsResponse>(
            'account/confirm/link',
            request
        );

        if (status < 400) {
            const { confirmLink } = data as CreateConfirmLinkResponse;
            dialog({
                variant: 'info',
                description: <ConfirmLinkCallout email={user.email} link={confirmLink} />,
            });
        } else if (status === 400) {
            const { validationErrors } = data as ValidationErrorsResponse;
            const actualError = Object.values(validationErrors)[0];
            showSnack(actualError || 'The link could not be created', 'error');
        } else {
            showSnack('The link could not be created', 'error');
        }

        dispatch({ type: 'LINK_OK' });
    }

    async function handleCreateForgotLink(userId: number) {
        const user = users!.find((u) => u.id === userId)!;
        dispatch({ type: 'LINK_START', user });

        const request: CreateForgotLinkRequest = { id: user.id };
        const { status, data } = await api.post<CreateForgotLinkResponse | ValidationErrorsResponse>(
            'account/forgot/link',
            request
        );

        if (status < 400) {
            const { forgotLink } = data as CreateForgotLinkResponse;
            dialog({
                variant: 'info',
                description: <ForgotLinkCallout email={user.email} link={forgotLink} />,
            });
        } else if (status === 400) {
            const { validationErrors } = data as ValidationErrorsResponse;
            const actualError = Object.values(validationErrors)[0];
            showSnack(actualError || 'The link could not be created', 'error');
        } else {
            showSnack('The link could not be created', 'error');
        }

        dispatch({ type: 'LINK_OK' });
    }

    async function handleDelete(userId: number) {
        const user = users!.find((u) => u.id === userId)!;
        await dialog({
            variant: 'yes/no',
            yesName: `Remove user`,
            title: `Remove ${user.email} from ${hospital!.name}`,
            description: (
                <>
                    Are you sure you would like to remove <strong>{user.email}</strong> from{' '}
                    <strong>{hospital!.name}</strong>?<br />
                    <ul>
                        <li>The user will not be able to access this hospital's data anymore.</li>
                        <li>The user will still be stored in audit logs.</li>
                    </ul>
                </>
            ),
        });

        dispatch({ type: 'REMOVE_START', user });

        const { userName } = user;
        const data: RemoveUserRequest = { userName };

        const { status } = await api.delete<{}>('users', { data });

        if (status < 400) {
            onUsersChanged(users!.filter((u) => u.id !== user!.id));
            showSnack('User was removed');
        } else {
            showSnack('User could not removed', 'error');
        }

        dispatch({ type: 'REMOVE_OK' });
    }

    function handleEdit(userId: number) {
        const user = users!.find((u) => u.id === userId)!;
        dispatch({ type: 'EDIT_START', user });
    }

    function handleRoleChange(userId: number, roleId: number) {
        const user = { ...users!.find((u) => u.id === userId)!, roleId };
        onUsersChanged(users!.map((u) => (u.id === user.id ? user : u)));
    }

    async function handleSave(userId: number) {
        const user = users!.find((u) => u.id === userId)!;
        dispatch({ type: 'SAVE_START', user });

        const { userName } = user;
        const roleName = roles!.find((r) => r.id === user.roleId)!.name;
        const data: EditUserRoleRequest = { userName, roleName };

        const { status } = await api.patch('users/roles', data);

        if (status < 400) {
            showSnack("User's role was changed");
            dispatch({ type: 'SAVE_OK' });
        } else {
            showSnack("User's role could not be changed", 'error');
            dispatch({ type: 'SAVE_FAIL' });
        }
    }

    function handleCancel() {
        onUsersChanged(users!.map((u) => (u.id === (state.editUser && state.editUser.id) ? state.editUser! : u)));
        dispatch({ type: 'EDIT_CANCEL' });
    }

    const handleExportToExcelButtonClicked = async () => {
        var query = queryRef.current;
        if (!query) return;

        const url = `users/excel?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
        })}`;
        var response = await api.get(url, { responseType: 'blob' });
        if (response.status < 400) {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        } else {
            showSnack("Couldn't download export file", 'error');
        }
    };

    function isEditing(userId: number) {
        return !!state.editUser && userId === state.editUser.id;
    }

    function isSaving(userId: number) {
        return !!state.saveUser && userId === state.saveUser.id;
    }

    function isRemoving(userId: number) {
        return !!state.removeUser && userId === state.removeUser.id;
    }

    function isLinking(userId: number) {
        return !!state.linkUser && userId === state.linkUser.id;
    }

    function isDisabled(userId: number) {
        return state.busy || (state.editing && !isEditing(userId)) || isRemoving(userId);
    }

    const getData = async (query: Query<RowData>): Promise<QueryResult<RowData>> => {
        queryRef.current = query;

        return {
            data: data,
            page: 0,
            totalCount: 0,
        };
    };
    return (
        <MaterialTable<RowData>
            style={{ margin: '1rem 0' }}
            title={`All users of ${hospital!.name}`}
            isLoading={isLoading}
            columns={[
                {
                    title: 'Email',
                    field: 'email',
                    type: 'string',
                    render: (user: RowData) => <UserEmail user={user} isCurrentUser={isCurrentUser(user)} />,
                },
                {
                    title: 'Role',
                    field: 'roleId',
                    type: 'string',
                    render: (user: RowData) =>
                        roles && (
                            <UserRole
                                user={user}
                                onChange={handleRoleChange}
                                roles={roles}
                                isEditing={isEditing(user.id)}
                                disabled={isDisabled(user.id)}
                            />
                        ),
                },
                {
                    render: (user: RowData) => (
                        <UserDetail
                            user={user}
                            isEditing={isEditing(user.id)}
                            isDisabled={isDisabled(user.id)}
                            isSaving={isSaving(user.id)}
                            isRemoving={isRemoving(user.id)}
                            isLinking={isLinking(user.id)}
                            onCreateNewLink={handleCreateNewLink}
                            onCreateForgotLink={handleCreateForgotLink}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                            onSave={handleSave}
                            onCancel={handleCancel}
                            isCurrentUser={isCurrentUser(user)}
                        />
                    ),
                },
            ]}
            data={getData}
            options={{
                sorting: true,
                search: false,
                paging: false,
                thirdSortClick: false,
                headerStyle: { zIndex: 0 },
            }}
            localization={{ body: { emptyDataSourceMessage: 'No users found' } }}
            actions={[
                {
                    icon: '',
                    isFreeAction: true,
                    onClick: handleExportToExcelButtonClicked,
                },
            ]}
            components={{
                Action: (props) => (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(event) => props.action.onClick(event, props.data)}
                        style={{ margin: '1rem' }}
                    >
                        Export to excel
                    </Button>
                ),
            }}
        />
    );
};

export default UsersOverview;
