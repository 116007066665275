import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import Section from '../../../../../styles/components/forms/Section';

type PatientAddressSectionProps = {
    address: string;
    postalCode: string;
    city: string;
    countryName: string;
    mail: string;
    phone: string;
};
export default function PatientAddressSection(props: PatientAddressSectionProps) {
    return (
        <Section name="Address">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label="Address"
                        value={props.address ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Zip"
                        value={props.postalCode ?? ''}
                        placeholder=" "
                        variant="outlined"
                        disabled
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="City"
                        value={props.city ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Country"
                        value={props.countryName ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Mail"
                        value={props.mail ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Tel"
                        value={props.phone ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Section>
    );
}
