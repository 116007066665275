import React from 'react';
import FormikTextField from '../../../../../styles/components/forms/FormikTextField';
import { FastField } from '../Fast';

type Props = {
    name: string;
    label?: string;
};

const FastNumberField: React.FC<Props> = ({ name, label }: Props) => (
    <FastField name={name} type="number" component={FormikTextField} label={label} />
);

export default FastNumberField;
