import { Mocs } from '../../../../../../api/types';
import MakeOptional from '../../MakeOptional';
import { toValuesString } from '../../mappers';

export type GetMocValues = Pick<Mocs.CkzPancreasData & Mocs.CkzOesophagusData, 'remarks'>;

export type SaveMocValues = Pick<Mocs.SaveMoc.CkzPancreasFields & Mocs.SaveMoc.CkzOesophagusFields, 'remarks'>;

export type FormValues = {
    remarks: string;
};

export const mapInitialValues = (moc: MakeOptional<GetMocValues>): FormValues => ({
    remarks: toValuesString(moc.remarks),
});

export const getNewMocValues = (): GetMocValues => ({
    remarks: '',
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    remarks: form.remarks,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
