import React, { useMemo } from 'react';
import { Field, FastFieldAttributes, FieldProps, getIn } from 'formik';

function propsAreEqual(prev: FieldProps<{}>, next: FieldProps<{}>) {
    const { field: prevField, form: prevForm } = prev;
    const { field: nextField, form: nextForm } = next;

    if (prevField.value !== nextField.value) return false;
    if (getIn(prevForm.errors, prevField.name) !== getIn(nextForm.errors, nextField.name)) return false;
    if (getIn(prevForm.touched, prevField.name) !== getIn(nextForm.touched, nextField.name)) return false;
    if (Object.keys(prev).length !== Object.keys(next).length) return false;
    if (prevForm.isSubmitting !== nextForm.isSubmitting) return false;

    return true;
}

export function FastField<Props = {}>({ component, ...props }: FastFieldAttributes<Props>) {
    const memoComponent = useMemo(() => React.memo(component as React.FC<FieldProps>, propsAreEqual), [component]);
    return <Field component={memoComponent} {...props} />;
}
