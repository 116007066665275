import { Mocs } from '../../../../../api/types';
import MakeOptional from '../MakeOptional';
import MocTypePropNames from '../MocTypePropNames';
import * as GeneralRemarks from '../sections/generalRemarks/GeneralRemarks.config';
import * as ClinicalBiology from '../sections/clinicalBiology/ClinicalBiology.config';
import * as Report from '../sections/report/Report.config';
import * as Imaging from '../sections/imaging/Imaging.config';
import * as Pathology from '../sections/pathology/Pathology.config';
import * as TreatmentPlan from '../sections/TreatmentPlan/TreatmentPlan.config';

type GetMocValues = MakeOptional<Mocs.GetMoc.MocData, MocTypePropNames>;
type NewMocValues = Pick<GetMocValues, 'localMoc'>;
type SaveMocValues = Pick<Mocs.SaveMoc.Request, 'localMoc'>;

export type FormValues = {
    report: Report.FormValues;
    imaging: Imaging.FormValues;
    clinicalBiology: ClinicalBiology.FormValues;
    pathology: Pathology.FormValues;
    generalRemarks: GeneralRemarks.FormValues;
    treatmentPlan: TreatmentPlan.FormValues;
};

export const mapInitialSectionValues = (moc: GetMocValues): FormValues => ({
    report: Report.mapInitialValues(moc.localMoc!),
    imaging: Imaging.mapInitialValues(moc.localMoc!),
    clinicalBiology: ClinicalBiology.mapInitialValues(moc.localMoc!),
    pathology: Pathology.mapInitialValues(moc.localMoc!),
    treatmentPlan: TreatmentPlan.mapInitialValues(moc.localMoc!),
    generalRemarks: GeneralRemarks.mapInitialValues(moc.localMoc!),
});

export const getNewMocValues: () => NewMocValues = () => ({
    localMoc: {
        id: 0,
        ...Report.getNewMocValues(),
        ...Imaging.getNewMocValues(),
        ...ClinicalBiology.getNewMocValues(),
        ...Pathology.getNewMocValues(),
        ...GeneralRemarks.getNewMocValues(),
        ...TreatmentPlan.getNewMocValues(),
    },
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    localMoc: {
        ...Report.mapSubmitValues(form.report),
        ...Imaging.mapSubmitValues(form.imaging),
        ...ClinicalBiology.mapSubmitValues(form.clinicalBiology),
        ...Pathology.mapSubmitValues(form.pathology),
        ...GeneralRemarks.mapSubmitValues(form.generalRemarks),
        ...TreatmentPlan.mapSubmitValues(form.treatmentPlan),
    },
});
