import { useMemo } from 'react';
import useHospital from '../hospital/useHospital';
import useAuth from '../auth/useAuth';

export type Permissions = ReturnType<typeof usePermissions>;

const usePermissions = () => {
    const { hospital } = useHospital();
    const { user } = useAuth();
    const permissions = useMemo(() => {
        if (user) {
            const roles = user.roles.filter((r) => !r.hospital || r.hospital === hospital);
            const isUser = !!roles.find((r) => !r.isReadonly);
            const isHospitalAdmin = !!roles.find((r) => r.isHospitalDependent && r.isAdmin);
            const isApplicationAdmin = !!roles.find((r) => !r.isHospitalDependent && r.isAdmin);
            return {
                canAddNewPatient: isUser,
                canEditPatient: isUser,
                canRemovePatient: isApplicationAdmin,
                canAddNewMoc: isUser,
                canRemoveMoc: isApplicationAdmin,
                canManageHospitalUsers: isHospitalAdmin || isApplicationAdmin,
                canManageApplicationUsers: isApplicationAdmin,
                canManageAuditLog: isApplicationAdmin,
                canEditLockedMocReports: isApplicationAdmin,
                canViewHospitalMocOverview: isHospitalAdmin || isApplicationAdmin,
                canViewGeneralMocOverview: isHospitalAdmin || isApplicationAdmin,
                canSearchMultipleHospitals: isApplicationAdmin,
                canExportCKZData: isApplicationAdmin,
            };
        }

        return {
            canAddNewPatient: false,
            canEditPatient: false,
            canRemovePatient: false,
            canAddNewMoc: false,
            canRemoveMoc: false,
            canManageHospitalUsers: false,
            canManageApplicationUsers: false,
            canManageAuditLog: false,
            canEditLockedMocReports: false,
            canViewHospitalMocOverview: false,
            canViewGeneralMocOverview: false,
            canSearchMultipleHospitals: false,
            canExportCKZData: false,
        };
    }, [user, hospital]);

    return permissions;
};

export default usePermissions;
