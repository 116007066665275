import React from 'react';
import { fieldToTextField, TextFieldProps } from 'formik-material-ui';
import { TextField } from '@material-ui/core';
import { FastField } from '../Fast';
import useDebouncedOnChange from './useDebouncedOnChange';

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    style?: React.CSSProperties;
    className?: string;
};

const FastTextField: React.FC<Props> = ({ name, label, placeholder, style, className }: Props) => (
    <FastField
        name={name}
        type="text"
        label={label}
        variant="outlined"
        placeholder={placeholder}
        fullWidth
        component={FastTextFieldInner}
        style={style}
        className={className}
    />
);

const FastTextFieldInner: React.ComponentType<TextFieldProps> = (props) => {
    const muiProps = fieldToTextField(props);
    const debouncedMuiProps = useDebouncedOnChange(muiProps);
    return <TextField {...debouncedMuiProps} inputRef={props.inputRef} />;
};

export default FastTextField;
