import React, { useState, useRef } from 'react';
import qs from 'qs';
import MaterialTable, { Query, QueryResult, Column } from 'material-table';
import { Mocs } from '../../api/types';
import { format, parseJSON } from 'date-fns';
import useApi from '../../api/useApi';
import ErrorSummary from '../../styles/components/ErrorSummary';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CompleteIcon from '@material-ui/icons/Done';
import styles from './Mocs.module.scss';
import OncoBaseSnackbar, { useSnackBar } from '../../styles/components/OncoBaseSnackbar';

type MuiIcon = typeof WarningIcon | typeof CompleteIcon;
type Response = Mocs.GetMocs.Response;
type Moc = Mocs.GetMocs.MocData;

type Props = {
    tableRef: any;
    referringHospitalId: number;
    mocTypeId: number;
    validationType: boolean | null;
    columnsFilter?: (column: Column<Moc>) => boolean;
};

const showDate = (date: string | null) => {
    return date ? format(parseJSON(date), 'dd MMM yyyy') : '';
};

const showYesNoBlank = (answer: boolean | null) => {
    return answer === null ? '' : answer ? 'Yes' : 'No';
};

const renderIcon = (className: string, Icon: MuiIcon) => (
    <div className={className}>
        <Icon />
    </div>
);

const columns = [
    { title: 'Id', field: 'id', hidden: true },
    { title: 'CKZ type', field: 'mocTypeName' },
    { title: 'CKZ MC date', field: 'mocDate', render: (data: Moc) => showDate(data.mocDate) },
    { title: 'Had Surgery', field: 'hadSurgery', render: (data: Moc) => showYesNoBlank(data.hadSurgery) },
    {
        title: 'Date of Surgery',
        field: 'dateOfSurgery',
        render: (data: Moc) => showDate(data.dateOfSurgery),
    },
    { title: 'UZA patientnumber', field: 'uzaNr' },
    { title: 'Surname', field: 'surname' },
    { title: 'First name', field: 'firstName' },
    {
        title: 'Date of birth',
        field: 'dateOfBirth',
        render: (data: Moc) => showDate(data.dateOfBirth),
    },
    { title: 'SSN', field: 'ssn' },
    { title: 'Referring hospital', field: 'referringHospital' },
    {
        title: '',
        field: 'isValid',
        render: (data: Moc) => {
            return (
                <>
                    {data.isValid && renderIcon(styles.complete, CompleteIcon)}
                    {!data.isValid && renderIcon(styles.warning, WarningIcon)}
                </>
            );
        },
    },
    {
        title: '',
        render: (data: Moc) => (
            <Link
                to={`/${data.referringHospitalSlug}/patients/${data.patientId}/mocs/${data.id}`}
                target="_blank"
                style={{ textDecoration: 'none' }}
            >
                <Button variant="contained" color="primary" fullWidth>
                    Detail
                </Button>
            </Link>
        ),
    },
];

// copied from ExportPage.tsx, maybe we can make a 'downloadService' or something ?
const downLoadFile = (data: any, type: string, filename: string) => {
    const blob = new Blob([data], { type });

    //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
};

const MocsTable: React.FC<Props> = ({ tableRef, referringHospitalId, mocTypeId, validationType, columnsFilter }) => {
    const [showSnack, snackbarProps] = useSnackBar();
    const [error, setError] = useState<string | null>(null);
    const queryRef = useRef<Query<Moc>>();
    const api = useApi();

    const getData = async (query: Query<Moc>): Promise<QueryResult<Moc>> => {
        setError(null);
        queryRef.current = query;
        const url = `mocs?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
            filters: {
                referringHospital: referringHospitalId,
                mocType: mocTypeId,
                validationType: validationType,
            },
        })}`;
        try {
            const { data, status } = await api.get<Response>(url);
            if (status < 400) {
                const { mocs, pageIndex, totalCount } = data;
                return {
                    data: mocs,
                    page: pageIndex,
                    totalCount,
                };
            } else {
                setError('Could not load mocs');
            }
        } catch {
            setError('Could not connect to server');
        }

        return {
            data: [],
            page: 0,
            totalCount: 0,
        };
    };

    const handleExportToExcelButtonClicked = async () => {
        var query = queryRef.current;
        if (!query) return;

        const url = `mocs/excel?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
            filters: {
                referringHospital: referringHospitalId,
                mocType: mocTypeId,
                validationType: validationType,
            },
        })}`;
        var response = await api.get(url, { responseType: 'blob' });
        if (response.status < 400) {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        } else {
            showSnack("Couldn't download export file", 'error');
        }
    };

    return (
        <>
            {error !== null && (
                <div>
                    <ErrorSummary errors={[error]} />
                </div>
            )}
            <MaterialTable<Moc>
                tableRef={tableRef}
                key={'MovOverviewTable'}
                style={{ margin: '1rem 0' }}
                title="CKZ / MOC"
                columns={columnsFilter ? columns.filter(columnsFilter) : columns}
                data={getData}
                options={{
                    sorting: true,
                    search: false,
                    pageSize: 10,
                    thirdSortClick: false,

                    headerStyle: { zIndex: 0 },
                }}
                localization={{ body: { emptyDataSourceMessage: 'No Mocs found' } }}
                actions={[
                    {
                        icon: '',
                        isFreeAction: true,
                        onClick: handleExportToExcelButtonClicked,
                    },
                ]}
                components={{
                    Action: (props) => (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            style={{ margin: '1rem' }}
                        >
                            Export to excel
                        </Button>
                    ),
                }}
            />
            <OncoBaseSnackbar {...snackbarProps} />
        </>
    );
};

export default MocsTable;
