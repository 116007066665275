import React from 'react';
import { Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { Container } from '@material-ui/core';
import usePermissions, { Permissions } from '../../auth/usePermissions';
import useHospital from '../../hospital/useHospital';
import UsersPage from './users/UsersPage';
import PatientsPage from './patients/PatientsPage';
import AuditLogPage from './auditlog/AuditLogPage';
import PatientDetailPage from './patients/detail/PatientDetailPage';
import ManualInputPatientPage from './patients/new/ManualInputPatientPage';
import ImportPatientPage from './patients/new/ImportPatientPage';
import EditPatientPage from './patients/edit/EditPatientPage';
import MocsOverviewPage from './mocs/MocsOverviewPage';

const analyzeRoutes = (p: Permissions) => ({
    newManualPatient: p.canAddNewPatient,
    newImportPatient: p.canAddNewPatient,
    users: p.canManageHospitalUsers || p.canManageApplicationUsers,
    auditLog: p.canManageAuditLog,
    mocOverview: p.canViewHospitalMocOverview,
});

const HospitalPage: React.FC<RouteComponentProps> = ({ match }) => {
    const { hospital } = useHospital();
    const permissions = usePermissions();
    const hasRoute = analyzeRoutes(permissions);

    if (!hospital) {
        return <Redirect to="/" />;
    }

    const url = match.url;

    return (
        <Container component="main">
            <Switch>
                {hasRoute.newManualPatient && (
                    <Route path={`${url}/patients/new/manualinput`} component={ManualInputPatientPage} />
                )}
                {hasRoute.newImportPatient && (
                    <Route path={`${url}/patients/new/import`} component={ImportPatientPage} />
                )}
                <Redirect exact from={`${url}/patients/new`} to={`${url}/patients/new/import`} />
                <Route path={`${url}/patients/:id/edit`} component={EditPatientPage} />
                <Route path={`${url}/patients/:id/mocs/:mocid`} component={PatientDetailPage} />
                <Route path={`${url}/patients/:id`} exact component={PatientDetailPage} />
                <Route path={`${url}/patients`} exact component={PatientsPage} />
                {hasRoute.users && <Route path={`${url}/users`} component={UsersPage} />}
                {hasRoute.auditLog && <Route path={`${url}/auditlog`} component={AuditLogPage} />}
                {hasRoute.mocOverview && <Route path={`${url}/mocs`} component={MocsOverviewPage} />}
                <Redirect to={`${url}/patients`} />
            </Switch>
        </Container>
    );
};

export default HospitalPage;
