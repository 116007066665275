import React from 'react';
import * as annex55 from '../../../../forms/sections/annex_55/Annex55.config';
import { ReferenceDataMapper } from '../..';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import {
    QuestionAnswer,
    QuestionAnswerAndOther,
    QuestionDateAnswer,
    QuestionYesNoAnswerAndDate,
    QuestionListWithSublistAnswer,
    QuestionListAnswer,
} from '../Questions';
import { getFields } from '../../../../forms/sections/annex_55/Annex55.fields';

type Annex55Props = {
    values: annex55.FormValues;
    lesionToTreatCode: string;
    mapReferenceData: ReferenceDataMapper;
};

export default function Annex55({ values, mapReferenceData, lesionToTreatCode }: Annex55Props) {
    const isEmpty = JSON.stringify(values) === JSON.stringify(annex55.initialValues);

    const fields = getFields(lesionToTreatCode, values);

    const buildcTNM = function (tValue: string, nValue: string, mValue: string) {
        return `cT${mapReferenceData('ctTypes', tValue)}N${mapReferenceData('cnTypes', nValue)}M${mapReferenceData(
            'cmTypes',
            mValue
        )}`;
    };
    const buildpTNM = function (tValue: string, nValue: string, mValue: string) {
        return `pT${mapReferenceData('ptTypes', tValue)}N${mapReferenceData('pnTypes', nValue)}M${mapReferenceData(
            'pmTypes',
            mValue
        )}`;
    };
    const buildypTNM = function (tValue: string, nValue: string, mValue: string) {
        return `ypT${mapReferenceData('yptTypes', tValue)}N${mapReferenceData('ypnTypes', nValue)}M${mapReferenceData(
            'ypmTypes',
            mValue
        )}`;
    };

    const executedTreatments = values.treatments.map((treatment): string =>
        treatment.otherValue
            ? `${mapReferenceData('ckzTreatments', treatment.code)} - ${treatment.otherValue}`
            : mapReferenceData('ckzTreatments', treatment.code)
    );

    const treatmentPlans = values.treatmentPlans.map((treatment): string =>
        treatment.otherValue
            ? `${mapReferenceData('ckzTreatments', treatment.code)} - ${treatment.otherValue}`
            : mapReferenceData('ckzTreatments', treatment.code)
    );

    const reasons = values.reasons.map((reason): string =>
        reason.otherValue
            ? `${mapReferenceData('reasonMocTypes', reason.code)} - ${reason.otherValue}`
            : mapReferenceData('reasonMocTypes', reason.code)
    );

    return isEmpty ? null : (
        <View>
            <Text style={styles.header}>Annex 55</Text>

            {fields.isEnabled.incidenceDateRelapse && (
                <QuestionDateAnswer
                    question="Incidentiedatum relapse (Complexe chirurgie)"
                    answer={values.relapseIncidenceDate}
                />
            )}

            {fields.isEnabled.incidenceDate && (
                <QuestionDateAnswer question="Incidentiedatum" answer={values.incidenceDate} />
            )}

            {fields.isEnabled.basisOfDiagnosis && (
                <QuestionAnswer
                    question="Basis voor diagnose"
                    answer={mapReferenceData('basesOfDiagnosis', values.basisOfDiagnosisCode)}
                />
            )}

            {fields.isEnabled.whoScoreAtDiagnosis && (
                <QuestionAnswer
                    question="WHO score bij diagnose"
                    answer={mapReferenceData('whoPerformanceStatuses', values.whoScoreAtDiagnosisCode)}
                />
            )}

            {fields.isEnabled.primaryTumourLocation && (
                <QuestionAnswer
                    question="Primaire tumor lokalisatie"
                    answer={mapReferenceData('primaryTumors', values.primaryTumourLocationCode)}
                />
            )}

            {fields.isEnabled.laterality && (
                <QuestionAnswer
                    question="Lateraliteit"
                    answer={mapReferenceData('lateralityTypes', values.lateralityCode)}
                />
            )}

            {fields.isEnabled.primaryTumourHistology && (
                <QuestionAnswer
                    question="Primaire tumor histologie"
                    answer={mapReferenceData('primaryTumorHistologies', values.primaryTumourHistologyCode)}
                />
            )}

            {fields.isEnabled.differentiation && (
                <QuestionAnswer
                    question="Differentiatiegraad"
                    answer={mapReferenceData('differentiations', values.differentiationCode)}
                />
            )}

            {fields.isEnabled.hasPeriodWithoutSymptoms && (
                <QuestionYesNoAnswerAndDate
                    question="Ziektevrij interval"
                    answer={values.hasPeriodWithoutSymptoms}
                    date={values.dateFirstRecidive}
                />
            )}

            {fields.isEnabled.location && (
                <QuestionListWithSublistAnswer
                    question="Locatie"
                    answers={values.locations.map((refValue) => ({
                        answer: mapReferenceData('locations', refValue.code),
                        sublist: refValue.metastasisLocations ?? [],
                    }))}
                />
            )}

            {fields.isEnabled.c_P_Yp && (
                <>
                    <QuestionAnswer question="cTNM" answer={buildcTNM(values.ctCode, values.cnCode, values.cmCode)} />
                    <QuestionAnswer question="pTNM" answer={buildpTNM(values.ptCode, values.pnCode, values.pmCode)} />
                    <QuestionAnswer
                        question="ypTNM"
                        answer={buildypTNM(values.yptCode, values.ypnCode, values.ypmCode)}
                    />
                </>
            )}

            {fields.isEnabled.otherClassification && (
                <QuestionAnswerAndOther
                    question="Andere classificatie"
                    answer={mapReferenceData('otherClassificationTypes', values.otherClassificationCode)}
                    other={values.otherClassificationOtherValue}
                />
            )}

            {fields.isEnabled.stadium && (
                <QuestionAnswer question="Stadium" answer={mapReferenceData('stadiums', values.stadiumCode)} />
            )}

            {fields.isEnabled.executedTreatments && (
                <>
                    <QuestionDateAnswer question="Datum van behandeling" answer={values.treatmentDate} />
                    <QuestionListAnswer question="Uitgevoerde behandeling" answer={executedTreatments} />
                </>
            )}

            {fields.isEnabled.treatmentPlans && (
                <QuestionListAnswer question={fields.label.treatments} answer={treatmentPlans} />
            )}

            {fields.isEnabled.dateMoc && <QuestionDateAnswer question="Datum MOC" answer={values.annex55MocDate} />}

            {fields.isEnabled.reasonMoc && <QuestionListAnswer question="Reden Moc" answer={reasons} />}

            {fields.isEnabled.sourceHospital && (
                <QuestionAnswer
                    question="Bron ziekenhuis"
                    answer={mapReferenceData('hospitals', values.sourceHospitalCode)}
                />
            )}
        </View>
    );
}
