import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Grid, MenuItem, Button, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDialog } from '../../../../styles/components/MaterialDialogService';
import FormikTextField from '../../../../styles/components/forms/FormikTextField';
import FormikSelectField from '../../../../styles/components/forms/FormikSelectField';
import useApi from '../../../../api/useApi';
import { ValidationErrorsResponse } from '../../../../api';
import { Identity } from '../../../../api/types';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';
import ConfirmLinkCallout from '../callout/ConfirmLinkCallout';
import styles from './NewUserForm.module.scss';
import { Role, getInitialValues, getValidationSchema, User } from './NewUserForm.config';

type AddUserRequest = Identity.AddUser.Request;
type AddUserResponse = Identity.AddUser.Response;

type Props = {
    users: User[];
    roles: Role[];
    showSnack: ShowSnack;
    onUserAdded: (user: User) => void;
};

type Values = {
    email: string;
    roleId: number;
};

const NewUserForm: React.FC<Props> = ({ users, roles, showSnack, onUserAdded }) => {
    const api = useApi();
    const dialog = useDialog();
    const initialValues = getInitialValues(roles);
    const validationSchema = getValidationSchema(users, roles);

    const handleSubmit = async (values: Values, formik: FormikHelpers<Values>) => {
        const email = values.email;
        const roleName = roles.find(r => r.id === values.roleId)!.name;
        const request: AddUserRequest = { email, roleName };
        const { data, status } = await api.post<AddUserResponse | ValidationErrorsResponse>('users', request);

        if (status < 400) {
            const response = data as AddUserResponse;
            const hasErrors = !!response.errors.length;
            if (!hasErrors) {
                formik.resetForm();
                onUserAdded({ id: response.id, isConfirmed: response.isConfirmed, userName: values.email, ...values });
                if (response.isConfirmed || !response.confirmLink) {
                    showSnack(`User '${email}' was added.`);
                } else {
                    dialog({
                        variant: 'info',
                        description: <ConfirmLinkCallout email={email} link={response.confirmLink} />
                    });
                }
            } else {
                formik.setFieldError('email', response.errors[0].description);
                showSnack(`User '${values.email}' could not be added.`, 'error');
            }
        } else if (status < 500) {
            const response = data as ValidationErrorsResponse;
            const errorDescriptions = Object.values(response.validationErrors);
            formik.setFieldError('email', errorDescriptions[0]);
        } else {
            showSnack(`User '${values.email}' could not be added.`, 'error');
        }

        formik.setSubmitting(false);
    };

    return (
        <Formik<Values>
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={false}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Grid container spacing={2} style={{ margin: '1rem 0' }}>
                        <Grid item xs={12} md={5}>
                            <Field
                                name="email"
                                component={FormikTextField}
                                label="Email"
                                placeholder="john.doe@example.com"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Field name="roleId" component={FormikSelectField} placeholder="Role">
                                {roles.map(role => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className={styles.button_container}>
                                <Button color="primary" variant="contained" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? (
                                        <CircularProgress size={24} color="inherit" className={styles.icon} />
                                    ) : (
                                        <AddIcon className={styles.icon} />
                                    )}
                                    Add user
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

export default NewUserForm;
