import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/styles';
import { AuthContextProvider } from './auth/AuthContext';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from './App';
import theme from './styles/theme';
import { Configuration } from './config/Configuration';
import ConfigurationProvider from './config/ConfigurationProvider';
import { MaterialDialogServiceProvider } from './styles/components/MaterialDialogService';

type Props = {
    config: Configuration;
};

const Root: React.FC<Props> = ({ config }) => (
    <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MaterialDialogServiceProvider>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <CssBaseline />
                        <AuthContextProvider>
                            <ConfigurationProvider config={config}>
                                <App config={config} />
                            </ConfigurationProvider>
                        </AuthContextProvider>
                    </BrowserRouter>
                </MaterialDialogServiceProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </StylesProvider>
);

export default Root;
