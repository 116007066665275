import React, { Fragment } from 'react';
import * as report from '../../../../forms/sections/report/Report.config';
import { View, Text } from '@react-pdf/renderer';
import { ReferenceDataMapper } from '../..';
import { QuestionProps, QuestionJaNeeMocAnswer, QuestionMocAnswer, formatDate } from '../Questions';
import { ExecutedTreatment } from '../../../../forms/sections/report/Report.config';
import List, { Item } from '../List';
import { styles } from '../../styles';
import { MainSubItem } from '../List';

type ReportProps = {
    values: report.FormValues;
    mapReferenceData: ReferenceDataMapper;
};
export default function ReportCurrentAndOngoingTreatments(props: ReportProps) {
    return (
        <View>
            <QuestionListCurrentAndOngoingTreatments
                question="Reeds uitgevoerde behandelingen"
                answer={props.values.executedTreatments}
                mapper={props.mapReferenceData}
            />
        </View>
    );
}
type QuestionListCurrentAndOngoingTreatmentsProps = QuestionProps & {
    answer: ExecutedTreatment[];
    mapper: ReferenceDataMapper;
};
export function QuestionListCurrentAndOngoingTreatments(props: QuestionListCurrentAndOngoingTreatmentsProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.mocSubHeader}>{props.question}</Text>
            <List>
                {props.answer.map((item, key) => (
                    <Fragment key={key}>
                        <Item>{props.mapper('enetsTreatments', item.treatmentCode)}</Item>

                        <MainSubItem>
                            {item.endDate ? (
                                <Text>
                                    Van {formatDate(item.startDate)} tot {formatDate(item.endDate)}
                                </Text>
                            ) : (
                                <Text>Gestart op {formatDate(item.startDate)}</Text>
                            )}
                        </MainSubItem>

                        <MainSubItem>
                            <QuestionJaNeeMocAnswer
                                question="Huidige therapie"
                                answer={item.currentTherapy}
                                overridingStyle={styles.answer}
                            />
                        </MainSubItem>
                        <MainSubItem>
                            <QuestionJaNeeMocAnswer question="SAE" answer={item.sae} overridingStyle={styles.answer} />
                        </MainSubItem>
                        <MainSubItem>
                            <QuestionMocAnswer
                                question="Opmerkingen"
                                answer={item.treatmentOther}
                                overridingStyle={styles.answer}
                            />
                        </MainSubItem>
                    </Fragment>
                ))}
            </List>
        </View>
    ) : (
        <View>
            <Text style={styles.mocSubHeader}>{props.question}</Text>
            <Text style={styles.indentedAnswer}>/</Text>
        </View>
    );
}
