import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import {
    FastSelectField,
    FastTextField,
    FormikErrorMessage,
    FastDateField,
    YesNoQuestion,
    FileQuestion,
    FastTextArea,
    CheckboxField,
} from '../../components';
import { Mocs } from '../../../../../../api/types';
import PrimaryTumorList from '../../enets/PrimaryTumorList';
import ExecutedTreatmentListSarcomen from '../../enets/ExecutedTreatmentListSarcomen';

type MocType = Mocs.GetReferenceData.Moc_Ref_MocType;
type GeneticalSyndrome = Mocs.GetReferenceData.Moc_Ref_GeneticalSyndrome;

type Props = {
    isValid: boolean | null;
};

export default function Information(props: Props) {
    const mocTypesFilter = (mocType: MocType) => mocType.isSarcomen;
    const geneticalSyndromeFilter = (syndrome: GeneticalSyndrome) => syndrome.isSarcomen;

    return (
        <MocSectionContainer code="information" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <FastDateField name="mocDate" label="Datum MOC*" disableFuture={false} />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <FastSelectField<MocType>
                        name="information.mocTypeCode"
                        placeholder="MOC Type*"
                        prompt="Selecteer MOC type"
                        referenceDataPath="mocTypes"
                        referenceDataFilter={mocTypesFilter}
                    />
                    <FormikErrorMessage name="information.mocTypeCode" />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="information.secondOpinion" label="2de opinie" />
                </Grid>

                <Grid item xs={12}>
                    <FastTextField name="information.requesterMoc" label="Aanvrager MOC" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextField name="information.netMocCoordinator" label="MOC Coördinator" />
                </Grid>
                <Grid item xs={6}>
                    <FastDateField name="information.incidentDate" label="Incidentiedatum*" />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={12}>
                    <FastTextArea name="information.medicalHistory" label="Medische voorgeschiedenis" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea name="information.oncologyHistory" label="Oncologische voorgeschiedenis" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea name="information.currentMedicalDiagnose" label="Huidige probleemstelling" />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<GeneticalSyndrome>
                        name="information.geneticalSyndromeCode"
                        placeholder="Genetisch syndroom*"
                        prompt="Selecteer genetisch syndroom"
                        referenceDataPath="geneticalSyndromes"
                        referenceDataFilter={geneticalSyndromeFilter}
                    />
                    <FormikErrorMessage name="information.geneticalSyndromeCode" />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="information.recidive"
                        question="Recidief ziekte ?*"
                        lang="nl"
                        renderYes={() => (
                            <FastDateField name="information.recidiveDate" label="Datum recidief ziekte*" />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="information.metastase"
                        question="Metastatische ziekte ?*"
                        lang="nl"
                        renderYes={() => (
                            <FastDateField name="information.metastaseDate" label="Datum metastatische ziekte*" />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryTumorList name={'information'} />
                </Grid>
                <Grid item xs={12}>
                    <ExecutedTreatmentListSarcomen name={'information'} />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea
                        name="information.notifyingDoctor"
                        label="Arts(en) ter verwittiging"
                        maxLength={255}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FastTextArea
                        name="information.netMocParticipants"
                        label="Deelnemers Weke delen & Bottumoren"
                        maxLength={255}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="information.icneTworkSigned" label="Lokaal weke delen en bottumoren Signed" />
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="information.icdnetSigned" label="Regionaal weke delen en bottumoren Signed" />
                </Grid>
                <Grid item xs={12}>
                    <FileQuestion type="MOC" question="MOC verslag opladen"></FileQuestion>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
