import React from 'react';
import GeneralRemarks from '../sections/generalRemarks/GeneralRemarks';
import Information from '../sections/information/Information';
import MocSectionCode from '../sections/MocSectionCode';
import Surgery from '../sections/surgery/ckzSarcomen/Surgery';
import NeoAdjuvantTreatment from '../sections/neoAdjuvantTreatment/NeoAdjuvantTreatment';
import { ValidatedSection } from '../sections/validation';
import ImagingDimensions from '../sections/imagingDimensions/ImagingDimensions';
import PathologyFnclcc from '../sections/pathologyFnclcc/PathologyFnclcc';
import Treatmentplan from '../sections/TreatmentPlan/ckzSarcomen/Treatmentplan';
import Annex55 from '../sections/annex_55/ckzSarcomen/Annex55';
import AdjuvantTherapy from '../sections/adjuvantTherapy/AdjuvantTherapy';

type Props = {
    validatedSections: ValidatedSection[];
};

function isValidSection(validatedSections: ValidatedSection[], code: MocSectionCode) {
    const section = validatedSections.find((s) => s.code === code);
    return section ? section.isValid : null;
}

const CkzSarcomen: React.FC<Props> = React.memo(({ validatedSections }) => {
    const isValid = (code: MocSectionCode) => isValidSection(validatedSections, code);
    return (
        <>
            <Information isValid={isValid('information')} />
            <NeoAdjuvantTreatment isValid={isValid('neo-adjuvant-treatment')} />
            <Surgery isValid={isValid('surgery')} />
            <ImagingDimensions isValid={isValid('imaging-dimensions')} />
            <PathologyFnclcc isValid={isValid('pathology-fnclcc')} />
            <AdjuvantTherapy isValid={isValid('adjuvant-therapy')} />
            <Treatmentplan isValid={isValid('ckzsarcomen-treatment-plan')} />
            <Annex55 isValid={isValid('annex55')} />
            <GeneralRemarks isValid={isValid('general-remarks')} />
        </>
    );
});

export default CkzSarcomen;
