import React, { useState, useEffect } from 'react';
import { Grid, FormHelperText } from '@material-ui/core';
import { Values } from '../../../form/PatientForm.config';
import { PatientInfo } from '../../../form/ImportPatientDetail.config';
import { Patients } from '../../../../../../api/types';
import { useFormikContext, Field } from 'formik';
import { EditFormLabels } from '../EditPatientFormLabels';
import styles from '../EditPatientFormImport.module.scss';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import { ImportValues } from '../../EditPatient.config';
import SelectFieldWithChanges from './SelectFieldWithChanges';

type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;
type EditInsuranceProps = {
    oldValues: Values;
    updatedValues: ImportValues;
    hasChanges: Map<EditFormLabels, boolean>;
    funds: Fund[] | null;
};
export default function EditInsurance(props: EditInsuranceProps) {
    const [countryFunds, setCountryFunds] = useState<Fund[] | null>(props.funds);
    const { values, setFieldValue } = useFormikContext<PatientInfo>();
    const { countryId, healthInsuranceFundId } = values;

    useEffect(() => {
        if (props.funds != null) {
            const newCountryFunds = props.funds.filter((f) => (f.country && f.country.id === countryId) || !f.country);
            setCountryFunds(newCountryFunds);
            if (!newCountryFunds.find((f) => f.id === healthInsuranceFundId)) {
                const newHealthInsuranceId = newCountryFunds.length === 1 ? newCountryFunds[0].id : 0;
                setFieldValue('healthInsuranceFundId', newHealthInsuranceId);
            }
        }
    }, [props.funds, countryId, healthInsuranceFundId, setFieldValue]);

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={6}>
                <SelectFieldWithChanges
                    fieldName="healthInsuranceFundId"
                    placeholder={EditFormLabels.HealthInsuranceFund}
                    selectionInfo={props.funds ? 'Select a health insurance fund' : 'Loading ...'}
                    attemptedValue={props.updatedValues.healthInsuranceFundAttemptedValue}
                    oldValue={
                        props.funds
                            ? props.funds.find((f) => f.id === props.oldValues.healthInsuranceFundId)?.name!
                            : `Could not load funds`
                    }
                    defaultValue={0}
                    enableSelection={props.updatedValues.healthInsuranceFundId === 0}
                    sectionChangesTracker={props.hasChanges}
                    options={countryFunds?.map((fund) => ({ id: fund.id, display: fund.name })) ?? []}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="healthInsuranceFundNr"
                    type="text"
                    component={FormikTextField}
                    label="updated: Health insurance fund number"
                />
                <FormHelperText className={styles.formHelperText}>
                    Current: {props.oldValues.healthInsuranceFundNr}
                </FormHelperText>
            </Grid>
        </Grid>
    );
}
