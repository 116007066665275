import { useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import api from '.';
import useConfig from '../config/useConfig';
import useHospital from '../hospital/useHospital';

const createApi = (hospital: { name: string }, baseApi: AxiosInstance) => {
    const defaults = baseApi.defaults;

    const api = axios.create({ ...defaults, headers: { ...defaults.headers, 'X-Hospital': hospital.name } });

    return api;
};

const useApi = () => {
    const { hospital } = useHospital();
    const config = useConfig();
    const hospitalApi = useMemo(() => (hospital ? createApi(hospital, api) : api), [hospital]);

    const checkUnauthorized = (status: number) => {
        if (status === 401) {
            if (window.location.href !== config.app.baseUrl + '/') {
                window.location.href = config.app.baseUrl;
            }
        }
    }

    hospitalApi.interceptors.response.use(
        response => {
            checkUnauthorized(response.status);
            return response;
        },
        error => {
            checkUnauthorized(error.response.status)
            return Promise.reject(error);
        }
    );        
    
    return hospitalApi;
};

export default useApi;
