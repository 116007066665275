import React from 'react';
import { Button, DialogTitle, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import FileModalForm from './FileModalForm';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MocFileType } from '../mocs/forms/MocForm.config';

type Props = {
    open: boolean;
    openedFromMoc: boolean;
    type?: MocFileType;
    onSubmit: (values: Values) => void;
    onClose: () => void;
};

export type Values = {
    file: string;
    name: string;
    extension: string;
    fileTypeId: number;
    contentType: string;
};

const validateForm = () => {
    return Yup.object().shape({
        file: Yup.string().required('You need to select a file'),
        name: Yup.string().required('Filename is required'),
        fileTypeId: Yup.number()
            .required('Please select a File type')
            .moreThan(0, 'Please select a File type')
    });
};

const FileModal: React.FC<Props> = ({ open, openedFromMoc, type, onSubmit, onClose }) => {
    const initialValues: Values = {
        name: '',
        file: '',
        extension: '',
        contentType: '',
        fileTypeId: 0
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <Formik<Values>
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                validationSchema={validateForm}
            >
                <Form>
                    <DialogTitle id="alert-dialog-title">{'Add file'}</DialogTitle>
                    <DialogContent>
                        <FileModalForm openedFromMoc={openedFromMoc} type={type} />
                        &nbsp;
                    </DialogContent>
                    <DialogActions>
                        <>
                            <Button color="primary" variant="contained" type="submit">
                                {'Save'}
                            </Button>
                            <Button color="primary" onClick={onClose} autoFocus>
                                {'Cancel'}
                            </Button>
                        </>
                    </DialogActions>
                </Form>
            </Formik>
        </Dialog>
    );
};

export default FileModal;
