import React from 'react';
import LinkCallout from './LinkCallout';

type Props = {
    email: string;
    link: string;
};

const ConfirmLinkCallout: React.FC<Props> = ({ email, link }) => (
    <LinkCallout link={link}>
        To access this application, <strong>{email}</strong> must complete his/her account using the{' '}
        <strong>following link below.</strong>
        <br />
        This link is <strong>valid for 1 day</strong>, after which you will have to generate a new link.
    </LinkCallout>
);

export default ConfirmLinkCallout;
