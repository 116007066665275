import React from 'react';
import { Grid } from '@material-ui/core';
import Question from './Question';
import { MocFileType } from '../MocForm.config';
import FileField from './FileField';

type Props = {
    type: MocFileType;
    question: string;
};

const FileQuestion: React.FC<Props> = ({ type, question }) => (
    <Question question={question}>
        <Grid container>
            <Grid item xs={12}>
                <FileField type={type} />
            </Grid>
        </Grid>
    </Question>
);

export default FileQuestion;
