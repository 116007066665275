import { useCallback } from 'react';
import useDataReducer, { loaded, error, loading, DataState } from './useDataReducer';
import useApi from './useApi';

export type ResponseDataToData<TData> = (responseData: any) => TData;

export default function<TData>(
    url: string,
    responseDataToData: ResponseDataToData<TData>
): [() => Promise<void>, DataState<TData>] {
    const api = useApi();
    const [reducerState, dispatch] = useDataReducer<TData>();

    const get = useCallback(
        async function() {
            dispatch(loading());

            try {
                const { data, status } = await api.get<TData>(url);
                if (status < 400) {
                    dispatch(loaded(responseDataToData(data)));
                }
                if (status >= 500) {
                    dispatch(error());
                }
            } catch (e) {
                dispatch(error());
            }
        },
        [url, responseDataToData, api, dispatch]
    );

    return [get, reducerState];
}
