import React, { Fragment } from 'react';
import * as imaging from '../../../../forms/sections/imaging/Imaging.config';
import * as pathology from '../../../../forms/sections/pathology/Pathology.config';
import * as report from '../../../../forms/sections/report/Report.config';
import * as treatmentPlan from '../../../../forms/sections/TreatmentPlan/TreatmentPlan.config';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { ReferenceDataMapper } from '../..';
import { CodeAndOtherValue } from '../../../../forms/MocForm.config';
import {
    QuestionJaNeeMetDateMocAnswer,
    QuestionListCodeAndOtherValueAnswer,
    QuestionMocAnswerAndOther,
    QuestionMocDateAnswer,
    QuestionMultiLineAnswer,
} from '../Questions';
import MocTypeCode from '../../../../MocTypeCode';
import ReportCurrentAndOngoingTreatments from '../report/Report';

type DiscussionProps = {
    report: report.FormValues;
    imaging: imaging.FormValues;
    pathology: pathology.FormValues;
    treatmentPlan: treatmentPlan.FormValues;
    mocType: MocTypeCode;
    mapReferenceData: ReferenceDataMapper;
};
export default function Discussion(props: DiscussionProps) {
    const primaryTumors: CodeAndOtherValue[] = props.report.primaryTumors.map((i) => ({
        code: props.mapReferenceData('primaryTumors', i.primaryTumorCode),
        otherValue: props.mapReferenceData('primaryTumorHistologies', i.primaryTumorHistologyCode),
    }));
    return (
        <Fragment>
            <View>
                <Text style={styles.mocSubHeader}>Bespreking</Text>
            </View>
            <View>
                <Text style={styles.indentedAnswer}>
                    Functioneel syndroom:{' '}
                    {props.mapReferenceData('functionalSyndromes', props.report.functionalSyndromeCode) ??
                        'Geen functioneel syndroom'}
                </Text>
            </View>
            <View>
                <Text style={styles.indentedAnswer}>
                    Genetisch syndroom:{' '}
                    {props.mapReferenceData('geneticalSyndromes', props.report.geneticalSyndromeCode) ??
                        'Geen genetisch syndroom'}
                </Text>
            </View>

            {primaryTumors.length > 0 ? (
                <QuestionListCodeAndOtherValueAnswer
                    question="Primaire tumors"
                    answer={primaryTumors}
                    overridingStyle={styles.indentedAnswer}
                />
            ) : (
                <Text style={styles.indentedAnswer}>Primaire tumors: /</Text>
            )}
            <QuestionJaNeeMetDateMocAnswer
                question="Recidief"
                answer={props.report.recidive}
                date={props.report.recidiveDate}
                overridingStyle={styles.indentedAnswer}
            />
            <QuestionJaNeeMetDateMocAnswer
                question="Metastase"
                answer={props.report.metastase}
                date={props.report.metastaseDate}
                overridingStyle={styles.indentedAnswer}
            />
            <QuestionMocAnswerAndOther
                question="WHO-gradering"
                answer={props.mapReferenceData('whoGrades', props.pathology.whoGradeCode) ?? '/'}
                other={props.pathology.whoGradeOther}
                overridingStyle={styles.indentedAnswer}
            />
            <QuestionMocDateAnswer
                question="Incidentiedatum"
                answer={props.report.incidentDate}
                overridingStyle={styles.indentedAnswer}
            />

            <FormatCodes
                codeType="c"
                tCode={props.mapReferenceData('ctTypes', props.imaging.ctCode)}
                nCode={props.mapReferenceData('cnTypes', props.imaging.cnCode)}
                mCode={props.mapReferenceData('cmTypes', props.imaging.cmCode)}
            />
            <FormatCodes
                codeType="p"
                tCode={props.mapReferenceData('ptTypes', props.pathology.ptCode)}
                nCode={props.mapReferenceData('pnTypes', props.pathology.pnCode)}
                mCode={props.mapReferenceData('pmTypes', props.pathology.pmCode)}
            />
            <FormatCodes
                codeType="yp"
                tCode={props.mapReferenceData('yptTypes', props.pathology.yptCode)}
                nCode={props.mapReferenceData('ypnTypes', props.pathology.ypnCode)}
                mCode={props.mapReferenceData('ypmTypes', props.pathology.ypmCode)}
            />
            <ReportCurrentAndOngoingTreatments values={props.report} mapReferenceData={props.mapReferenceData} />
            <Text style={styles.text}>Prognose</Text>
            <Text style={styles.indentedAnswer}>In functie van respons op therapie</Text>
            {props.mocType === 'NETMOC' && (
                <Fragment>
                    <Text style={styles.text}>Mogelijke patiënt voor behandeling in studieverband?</Text>
                    <Text style={styles.indentedAnswer}>
                        {`${
                            props.treatmentPlan.eligibleForAStudy
                                ? `Ja:  ${props.treatmentPlan.concerningStudy}`
                                : 'Neen'
                        }`}
                    </Text>
                </Fragment>
            )}
            <QuestionMultiLineAnswer
                question="Concreet behandelingsplan op korte en lange termijn met motivatie, rekening houdend met
            medische, maar ook psychische en sociale argumenten"
                answer={`Lokaal behandelplan: ${props.treatmentPlan.localTreatmentPlan ?? '/'}${
                    props.mocType === 'NETMOC' &&
                    `\nNET-MOC behandelplan: ${props.treatmentPlan.netMocTreatmentPlan ?? '/'}`
                } `}
            />

            <Text style={styles.text}>
                Naam van de geneesheer die, tijdens een afzonderlijk overleg, toelichting van de diagnose en van het
                voorstel van aanvullende onderzoeken, opvolging en behandelingsplan aan de patiënt geeft volgens het
                verslag van het voorafgaand multidisciplinair oncologisch consult:
            </Text>
        </Fragment>
    );
}

type formatCodeProps = {
    codeType: string;
    tCode: string;
    nCode: string;
    mCode: string;
};
function FormatCodes(props: formatCodeProps) {
    const allAreNullOrEmpty = !props.tCode && !props.nCode && !props.mCode;
    const tCodeFormatted = props.tCode ?? '/';
    const nCodeFormatted = props.nCode ?? '/';
    const mCodeFormatted = props.mCode ?? '/';
    const allCodesFormatted = `${props.codeType}T${tCodeFormatted}N${nCodeFormatted}M${mCodeFormatted}`;
    return (
        <Text style={styles.indentedAnswer}>
            {`${props.codeType}TNM: ${allAreNullOrEmpty ? '/' : allCodesFormatted}`}
        </Text>
    );
}
