import React from 'react';

export type Hospital = {
    id: number;
    name: string;
    slug: string;
    domains: Domain[];
};

export type Domain = {
    name: string;
    types: Type[];
};

export type Type = {
    name: string;
    code: string;
};

type HospitalContextValue = {
    hospital: Hospital | null;
    hospitals: Hospital[];
    setHospital: (hospital: Hospital) => void;
    lastHospital: Hospital | null;
};

const HospitalContext = React.createContext<HospitalContextValue>({} as HospitalContextValue);

export default HospitalContext;
