import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Grid, MenuItem, InputAdornment } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { Field, useFormikContext } from 'formik';
import FormikTextField from '../../../styles/components/forms/FormikTextField';
import { Patients } from '../../../api/types';
import useData, { ResponseDataToData } from '../../../api/useData';
import FormikSelectField from '../../../styles/components/forms/FormikSelectField';
import styles from './FileModalForm.module.scss';
import { Values } from './FileModal';
import { MocFileType } from '../mocs/forms/MocForm.config';

type PatientFileType = Patients.GetFileTypes.PatientFileType;

type Props = {
    openedFromMoc: boolean;
    type?: MocFileType;
};

const fileTypesResponseMapper: ResponseDataToData<PatientFileType[]> = d => d.fileTypes;

const FileModalForm: React.FC<Props> = ({ openedFromMoc, type }) => {
    const {
        acceptedFiles,
        rejectedFiles,
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        multiple: false,
        maxSize: 30000000 // 30MB
    });

    const { setFieldValue, values } = useFormikContext<Values>();

    const [fileTypes, setFileTypes] = useState<PatientFileType[] | null>(null);
    const [getFileTypes, fileTypesResponse] = useData('file/types', fileTypesResponseMapper);

    useEffect(() => {
        getFileTypes();
    }, [getFileTypes]);

    useEffect(() => {
        fileTypesResponse.hasLoaded &&
            !fileTypesResponse.isError &&
            setFileTypes(
                fileTypesResponse.data.filter(f =>
                    openedFromMoc ? f.isMocDependent && f.code === type : !f.isMocDependent
                )
            );
    }, [fileTypesResponse, openedFromMoc, type]);

    useEffect(() => {
        if (fileTypes !== null && !!fileTypes && fileTypes!.length === 1) {
            setFieldValue('fileTypeId', fileTypes[0].id);
        }
    }, [setFieldValue, fileTypes]);

    useEffect(() => {
        const setFieldValuetoBase64 = (file: Blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(setFieldValue('file', reader.result));
                reader.onerror = error => reject(error);
            });

        if (acceptedFiles.length > 0) {
            var lastIndexOfPoint = acceptedFiles[0].name.lastIndexOf('.');
            var name = acceptedFiles[0].name.substr(0, lastIndexOfPoint);
            var extension = acceptedFiles[0].name.substr(lastIndexOfPoint);

            setFieldValue('name', name);
            setFieldValue('extension', extension);
            setFieldValue('contentType', acceptedFiles[0].type);
            setFieldValuetoBase64(acceptedFiles[0]);
        }
    }, [acceptedFiles, setFieldValue]);

    const dzRootClassName = cs(styles.dz_root_base, {
        [styles.dz_root_dragactive]: isDragActive,
        [styles.dz_root_dragaccept]: isDragAccept,
        [styles.dz_root_dragreject]: isDragReject
    });

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <div {...getRootProps({ className: dzRootClassName })}>
                    <input {...getInputProps()} />
                    <Grid container>
                        <Grid item xs={12}>
                            Drag 'n' drop some files here, or click to select files
                        </Grid>
                        {acceptedFiles.length > 0 && (
                            <Grid item xs={12} className={styles.selected_file}>
                                SelectedFile : {acceptedFiles[0].name}
                            </Grid>
                        )}
                        {rejectedFiles.length > 0 && (
                            <Grid item xs={12} className={styles.rejected_file}>
                                {rejectedFiles[0].name} is not a valid file. <br />
                                Filesize is max 30MB.
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Field
                            name="name"
                            type="text"
                            component={FormikTextField}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{values.extension}</InputAdornment>
                            }}
                            label="Filename"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            as="select"
                            name="fileTypeId"
                            component={FormikSelectField}
                            placeholder="File type"
                            disabled={!fileTypes || fileTypes.length === 1}
                        >
                            <MenuItem key="empty-filetype" value={0} disabled>
                                <i>{fileTypes ? 'Select a File type' : 'Loading ...'}</i>
                            </MenuItem>
                            {fileTypes &&
                                fileTypes.map(type => (
                                    <MenuItem key={type.id} value={type.id}>
                                        {type.name}
                                    </MenuItem>
                                ))}
                        </Field>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FileModalForm;
