export const CKZPancreas = 'CKZPancreas';
export const CKZOesophagus = 'CKZOesophagus';
export const NETMOC = 'NETMOC';
export const LocalMOC = 'LocalMOC';
export const CKZSarcomen = 'CKZSarcomen';

type MocTypeCode = typeof CKZPancreas | typeof CKZOesophagus | typeof NETMOC | typeof LocalMOC | typeof CKZSarcomen;

type MocTypeCodeToName = { [K in MocTypeCode]: string };

const MocTypeNames: MocTypeCodeToName = {
    [CKZPancreas]: 'CKZ Pancreas',
    [CKZOesophagus]: 'CKZ Oesophagus',
    [NETMOC]: 'NET super MOC',
    [LocalMOC]: 'NET lokale MOC',
    [CKZSarcomen]: 'Weke delen & bottumoren',
};

export const mocTypeNameFor = (type: MocTypeCode) => MocTypeNames[type];

export default MocTypeCode;
