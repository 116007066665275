import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { parseJSON, format } from 'date-fns';
import { Button, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PdfIcon from '@material-ui/icons/GetApp';
import HtmlIcon from '@material-ui/icons/Description';
import styles from './MocDetailControls.module.scss';
import MocTypeCode from '../../MocTypeCode';
import MocNavigation from './MocNavigation';
import MocInvalidFields from './MocInvalidFields';
import { ValidatedSection, combineValidationToSections } from '../sections/validation';
import { Values } from '../MocForm.config';
import { useMocPdfRenderer, canHandle as canHandlePdf } from '../../pdf';
import { useMocHtmlRenderer, canHandle as canHandleHtml } from '../../html';
import { Patients } from '../../../../../api/types';

type Patient = Patients.GetPatient.PatientDetail;

type DownloadButtonProps = {
    patient: Patient;
    isSubmitting: boolean;
};

function DownloadButton({ isSubmitting, patient }: DownloadButtonProps) {
    const [isBusy, setIsBusy] = useState(false);
    const renderPdf = useMocPdfRenderer();
    const { initialValues, values } = useFormikContext<Values>();
    const hasChanges = JSON.stringify(initialValues) !== JSON.stringify(values);

    async function handlePdfClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setIsBusy(true);

        try {
            const pdfBlob = await renderPdf(patient, values);
            const pdfFileName = `${patient.uzaNr ?? patient.localPatientNr}_${initialValues.mocTypeCode}${
                initialValues.mocDate ? `_${format(parseJSON(initialValues.mocDate), 'dd_MMM_yyyy')}` : ''
            }.pdf`;
            const isInternetExplorer = !!window.navigator.msSaveOrOpenBlob;
            if (isInternetExplorer) {
                window.navigator.msSaveOrOpenBlob(pdfBlob, pdfFileName);
            } else {
                const downloadURL = URL.createObjectURL(pdfBlob);
                const link = document.createElement('a');
                link.href = downloadURL;
                link.download = pdfFileName;
                link.click();
            }
        } finally {
            setIsBusy(false);
        }
    }

    if (!canHandlePdf(values.mocId, values.mocTypeCode)) {
        return null;
    }

    return (
        <div style={{ padding: '1rem 0' }}>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<PdfIcon />}
                type="button"
                onClick={handlePdfClick}
                disabled={hasChanges || isSubmitting || isBusy}
            >
                Download PDF
            </Button>
        </div>
    );
}

type HtmlButtonProps = {
    patient: Patient;
    isSubmitting: boolean;
};
function HtmlButton({ isSubmitting, patient }: HtmlButtonProps) {
    const [isBusy, setIsBusy] = useState(false);
    const renderHtml = useMocHtmlRenderer();
    const { initialValues, values } = useFormikContext<Values>();
    const hasChanges = JSON.stringify(initialValues) !== JSON.stringify(values);

    async function handleHtmlClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setIsBusy(true);

        try {
            const html = renderHtml(patient, values);

            var win = window.open("", "_blank", "");
            if(win !== null){
                win.document.title=`${patient.uzaNr ?? patient.localPatientNr}_${initialValues.mocTypeCode}${
                        initialValues.mocDate ? `_${format(parseJSON(initialValues.mocDate), 'dd_MMM_yyyy')}` : ''}`;
                win.document.body.innerHTML = html;
            }
        } finally {
            setIsBusy(false);
        }
    }

    if (!canHandleHtml(values.mocId, values.mocTypeCode)) {
        return null;
    }

    return (
        <div style={{ padding: '0 0 1rem 0' }}>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<HtmlIcon />}
                type="button"
                onClick={handleHtmlClick}
                disabled={hasChanges || isSubmitting || isBusy}
            >
                Open HTML page
            </Button>
        </div>
    );
}

type MocDetailControlsProps = {
    patient: Patient;
    mocTypeCode: MocTypeCode;
    validatedSections: ValidatedSection[];
};

const MocDetailControls: React.FC<MocDetailControlsProps> = React.memo(({ patient, validatedSections, mocTypeCode }) => {
    const { isSubmitting, values } = useFormikContext<Values>();
    const mocDate = values.mocDate && parseJSON(values.mocDate);
    const sections = combineValidationToSections(validatedSections, values);
    const invalidFields = validatedSections.flatMap((section) => section.invalidFields);
    return (
        <div className={styles.root_container}>
            {sections.length > 0 && <MocNavigation mocTypeCode={mocTypeCode} mocDate={mocDate} sections={sections} />}
            {isSubmitting && <CircularProgress className={styles.loading} />}
            <Button variant="contained" color="primary" startIcon={<SaveIcon />} type="submit" disabled={isSubmitting}>
                Save & Validate
            </Button>
            <Button variant="text" color="primary" type="reset" disabled={isSubmitting}>
                Undo changes
            </Button>
            {typeof values.mocId === 'number' && <DownloadButton isSubmitting={isSubmitting} patient={patient} />}
            {typeof values.mocId === 'number' && <HtmlButton isSubmitting={isSubmitting} patient={patient} />}
            {invalidFields && invalidFields.length > 0 && <MocInvalidFields fields={invalidFields} />}
        </div>
    );
});

export default MocDetailControls;
