import React from 'react';
import { MenuItem, Menu, Hidden } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import styles from './ProfileMenu.module.scss';

type Props = {
    name: string;
    onNavigateToProfile: () => void;
    onSignOut: () => void;
};

const ProfileMenu: React.FC<Props> = ({ name, onNavigateToProfile, onSignOut }) => {
    const [profileMenuElement, setProfileMenuElement] = React.useState<null | HTMLElement>(null);

    const openProfileMenu = (e: React.MouseEvent<HTMLElement>) => setProfileMenuElement(e.currentTarget);
    const closeProfileMenu = () => setProfileMenuElement(null);

    const onOpenManual = () => {
        closeProfileMenu();
        window.open(`${process.env.PUBLIC_URL}/OncoBaseManual.pdf`, '_blank');
    };

    const handleNavigateToProfile = () => {
        closeProfileMenu();
        onNavigateToProfile();
    };

    return (
        <>
            <MenuItem onClick={openProfileMenu}>
                <AccountCircleIcon className={styles.icon} /> <Hidden mdDown>{name}</Hidden>
            </MenuItem>
            <Menu
                variant="menu"
                anchorEl={profileMenuElement}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(profileMenuElement)}
                onClose={closeProfileMenu}
            >
                <MenuItem onClick={handleNavigateToProfile}>
                    <AccountBoxIcon className={styles.icon} />
                    My profile
                </MenuItem>
                <MenuItem onClick={onOpenManual}>
                    <MenuBookIcon className={styles.icon} />
                    Manual
                </MenuItem>
                <MenuItem onClick={onSignOut}>
                    <ExitToAppIcon className={styles.icon} /> Signout
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileMenu;
