import React, { useState } from 'react';
import useApi from '../../../../../api/useApi';
import FileModal, { Values as FileValues } from '../../../files/FileModal';
import { Patients } from '../../../../../api/types';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormikContext } from 'formik';
import { Values as MocValues, MocFileType, MocFile } from '../MocForm.config';
import styles from './FileFieldBase.module.scss';
import OncoBaseSnackbar, { useSnackBar } from '../../../../../styles/components/OncoBaseSnackbar';

type Props = {
    type: MocFileType;
    file: MocFile | null;
    onAfterSubmit?: (fileId: number) => void;
    onAfterDelete?: () => void;
};

const FileFieldBase: React.FC<Props> = ({ type, file, onAfterSubmit, onAfterDelete }) => {
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const api = useApi();
    const [showSnack, snackBarProps] = useSnackBar();
    const { values, setFieldValue, isSubmitting } = useFormikContext<MocValues>();
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const handleDownloadFile = async (fileId: number) => {
        await api.get('file/' + fileId, { responseType: 'blob' }).then((response) => {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        });
    };

    const downLoadFile = (data: any, type: string, filename: string) => {
        const blob = new Blob([data], { type });

        //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
        }
    };

    const handleNewFile = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSubmit = async (fileValues: FileValues) => {
        setOpenModal(false);
        setIsSaving(true);

        const filenameWithExtension = fileValues.name.endsWith(fileValues.extension)
            ? fileValues.name
            : fileValues.name + fileValues.extension;

        const { status, data } = await api.post('file', {
            ...fileValues,
            patientId: values.patientId,
            name: filenameWithExtension,
            mocId: isNaN(+values.mocId) ? 0 : values.mocId,
        } as Patients.UploadFile.Request);

        if (status < 400) {
            var fileToAdd = {
                id: +data.id,
                name: filenameWithExtension,
                type: type,
            } as MocFile;

            setFieldValue('mocFiles', [...values.mocFiles, fileToAdd]);

            onAfterSubmit && onAfterSubmit(fileToAdd.id);

            showSnack('File was uploaded');
        } else {
            showSnack('Could not upload file', 'error');
        }

        setIsSaving(false);
    };

    const deleteFile = async (fileId: number) => {
        if (isNaN(+values.mocId)) {
            await api.delete<{}>(`file/${fileId}`);
        }

        onAfterDelete && onAfterDelete();

        const leftOverFiles = values.mocFiles.filter((f) => f.id !== fileId);
        setFieldValue('mocFiles', leftOverFiles);
    };

    return (
        <>
            {file && (
                <div className={styles.flexContainer}>
                    <Button className={styles.flexLeft} color="primary" onClick={() => handleDownloadFile(file!.id)}>
                        <InsertDriveFileOutlinedIcon />
                        {file!.name}
                    </Button>
                    <Button color="secondary" onClick={() => deleteFile(file!.id)} disabled={isSubmitting}>
                        <DeleteIcon /> Remove File
                    </Button>
                </div>
            )}
            {!file && (
                <>
                    <div className={styles.flexContainer}>
                        {isSaving && (
                            <>
                                <CircularProgress color="inherit" size={24} />
                                <Typography className={styles.loading_text}>Saving file...</Typography>
                            </>
                        )}

                        <Button color="primary" onClick={handleNewFile} disabled={isSubmitting || isSaving}>
                            <AddIcon /> Upload new file
                        </Button>
                    </div>
                    <FileModal
                        open={openModal}
                        openedFromMoc={true}
                        type={type}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                    />
                </>
            )}
            <OncoBaseSnackbar {...snackBarProps} />
        </>
    );
};

export default FileFieldBase;
