import { Mocs } from '../../../../../../../api/types';
import { toMocBoolean, toValuesBoolean, toValuesString, toValuesDate } from '../../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzPancreasData,
    | 'hadPostoperativeComplications'
    | 'generalClavienDindoClassificationCode'
    | 'isReoperationNecessary'
    | 'reoperationSurgeryType'
    | 'reoperationMccmReport'
    | 'reoperationPathologyReport'
    | 'reoperationSurgeryReport'
    | 'postOpDischarged'
    | 'postOpDischargedDate'
    | 'postOpDischargedDestinationCode'
    | 'postOpDischargedTransferHospitalName'
    | 'postOpDischargedTransferBecauseComplications'
    | 'postOpDischargedReadmissionTypeCode'
    | 'postOpDischargedReadmissionReason'
    | 'postOpDied'
    | 'postOpDiedInHospital'
    | 'postOpDiedDate'
    | 'postOpDiedCause'
    | 'wasThereAdjuvenantTherapyAfterSurgery'
    | 'adjuvenantTherapyTypeCode'
    | 'adjuvenantTherapyTypeOtherValue'
    | 'clinicalTrialWasIncludedCode'
    | 'clinicalTrialEudraCTNumber'
    | 'clinicalTrialNctNumber'
    | 'postoperativeComplications'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzPancreasFields,
    | 'hadPostoperativeComplications'
    | 'generalClavienDindoClassificationCode'
    | 'isReoperationNecessary'
    | 'reoperationSurgeryType'
    | 'reoperationMccmReport'
    | 'reoperationPathologyReport'
    | 'reoperationSurgeryReport'
    | 'postOpDischarged'
    | 'postOpDischargedDate'
    | 'postOpDischargedDestinationCode'
    | 'postOpDischargedTransferHospitalName'
    | 'postOpDischargedTransferBecauseComplications'
    | 'postOpDischargedReadmissionTypeCode'
    | 'postOpDischargedReadmissionReason'
    | 'postOpDied'
    | 'postOpDiedInHospital'
    | 'postOpDiedDate'
    | 'postOpDiedCause'
    | 'wasThereAdjuvenantTherapyAfterSurgery'
    | 'adjuvenantTherapyTypeCode'
    | 'adjuvenantTherapyTypeOtherValue'
    | 'clinicalTrialWasIncludedCode'
    | 'clinicalTrialEudraCTNumber'
    | 'clinicalTrialNctNumber'
    | 'postoperativeComplications'
>;

export type FormValues = {
    hadPostoperativeComplications: boolean | '';
    generalClavienDindoClassificationCode: string;
    isReoperationNecessary: boolean | '';
    reoperationSurgeryType: string;
    reoperationMccmReport: string;
    reoperationPathologyReport: string;
    reoperationSurgeryReport: string;
    postOpDischarged: boolean | '';
    postOpDischargedDate: string | null;
    postOpDischargedDestinationCode: string;
    postOpDischargedTransferHospitalName: string;
    postOpDischargedTransferBecauseComplications: boolean | '';
    postOpDischargedReadmissionTypeCode: string;
    postOpDischargedReadmissionReason: string;
    postOpDied: boolean | '';
    postOpDiedInHospital: boolean | '';
    postOpDiedDate: string | null;
    postOpDiedCause: string;
    wasThereAdjuvenantTherapyAfterSurgery: boolean | '';
    adjuvenantTherapyTypeCode: string;
    adjuvenantTherapyTypeOtherValue: string;
    clinicalTrialWasIncludedCode: string;
    clinicalTrialEudraCTNumber: string;
    clinicalTrialNctNumber: string;
    postoperativeComplications: PostoperativeComplication[];
};

export type PostoperativeComplication = {
    code: string;
    isGlsGradeCode: string;
    isGpsGradeCode: string;
    otherValue: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    hadPostoperativeComplications: toValuesBoolean(moc.hadPostoperativeComplications),
    generalClavienDindoClassificationCode: toValuesString(moc.generalClavienDindoClassificationCode),
    isReoperationNecessary: toValuesBoolean(moc.isReoperationNecessary),
    reoperationSurgeryType: toValuesString(moc.reoperationSurgeryType),
    reoperationMccmReport: toValuesString(moc.reoperationMccmReport),
    reoperationPathologyReport: toValuesString(moc.reoperationPathologyReport),
    reoperationSurgeryReport: toValuesString(moc.reoperationSurgeryReport),
    postOpDischarged: toValuesBoolean(moc.postOpDischarged),
    postOpDischargedDate: toValuesDate(moc.postOpDischargedDate),
    postOpDischargedDestinationCode: toValuesString(moc.postOpDischargedDestinationCode),
    postOpDischargedTransferHospitalName: toValuesString(moc.postOpDischargedTransferHospitalName),
    postOpDischargedTransferBecauseComplications: toValuesBoolean(moc.postOpDischargedTransferBecauseComplications),
    postOpDischargedReadmissionTypeCode: toValuesString(moc.postOpDischargedReadmissionTypeCode),
    postOpDischargedReadmissionReason: toValuesString(moc.postOpDischargedReadmissionReason),
    postOpDied: toValuesBoolean(moc.postOpDied),
    postOpDiedInHospital: toValuesBoolean(moc.postOpDiedInHospital),
    postOpDiedDate: toValuesDate(moc.postOpDiedDate),
    postOpDiedCause: toValuesString(moc.postOpDiedCause),
    wasThereAdjuvenantTherapyAfterSurgery: toValuesBoolean(moc.wasThereAdjuvenantTherapyAfterSurgery),
    adjuvenantTherapyTypeCode: toValuesString(moc.adjuvenantTherapyTypeCode),
    adjuvenantTherapyTypeOtherValue: toValuesString(moc.adjuvenantTherapyTypeOtherValue),
    clinicalTrialWasIncludedCode: toValuesString(moc.clinicalTrialWasIncludedCode),
    clinicalTrialEudraCTNumber: toValuesString(moc.clinicalTrialEudraCTNumber),
    clinicalTrialNctNumber: toValuesString(moc.clinicalTrialNctNumber),
    postoperativeComplications: moc.postoperativeComplications.map((p) => ({
        code: p.postoperativeComplicationCode,
        isGpsGradeCode: p.isgpsGradeCode,
        isGlsGradeCode: p.isglsGradeCode,
        otherValue: p.otherValue,
    })),
});

export const getNewMocValues = (): GetMocValues => ({
    hadPostoperativeComplications: null,
    generalClavienDindoClassificationCode: '',
    isReoperationNecessary: null,
    reoperationSurgeryType: '',
    reoperationMccmReport: '',
    reoperationPathologyReport: '',
    reoperationSurgeryReport: '',
    postOpDischarged: null,
    postOpDischargedDate: null,
    postOpDischargedDestinationCode: '',
    postOpDischargedTransferHospitalName: '',
    postOpDischargedTransferBecauseComplications: null,
    postOpDischargedReadmissionTypeCode: '',
    postOpDischargedReadmissionReason: '',
    postOpDied: null,
    postOpDiedInHospital: null,
    postOpDiedDate: null,
    postOpDiedCause: '',
    wasThereAdjuvenantTherapyAfterSurgery: null,
    adjuvenantTherapyTypeCode: '',
    adjuvenantTherapyTypeOtherValue: '',
    clinicalTrialWasIncludedCode: '',
    clinicalTrialEudraCTNumber: '',
    clinicalTrialNctNumber: '',
    postoperativeComplications: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    hadPostoperativeComplications: toMocBoolean(form.hadPostoperativeComplications),
    generalClavienDindoClassificationCode: form.generalClavienDindoClassificationCode,
    isReoperationNecessary: toMocBoolean(form.isReoperationNecessary),
    reoperationSurgeryType: form.reoperationSurgeryType,
    reoperationMccmReport: form.reoperationMccmReport,
    reoperationPathologyReport: form.reoperationPathologyReport,
    reoperationSurgeryReport: form.reoperationSurgeryReport,
    postOpDischarged: toMocBoolean(form.postOpDischarged),
    postOpDischargedDate: form.postOpDischargedDate,
    postOpDischargedDestinationCode: form.postOpDischargedDestinationCode,
    postOpDischargedTransferHospitalName: form.postOpDischargedTransferHospitalName,
    postOpDischargedTransferBecauseComplications: toMocBoolean(form.postOpDischargedTransferBecauseComplications),
    postOpDischargedReadmissionTypeCode: form.postOpDischargedReadmissionTypeCode,
    postOpDischargedReadmissionReason: form.postOpDischargedReadmissionReason,
    postOpDied: toMocBoolean(form.postOpDied),
    postOpDiedInHospital: toMocBoolean(form.postOpDiedInHospital),
    postOpDiedDate: form.postOpDiedDate,
    postOpDiedCause: form.postOpDiedCause,
    wasThereAdjuvenantTherapyAfterSurgery: toMocBoolean(form.wasThereAdjuvenantTherapyAfterSurgery),
    adjuvenantTherapyTypeCode: form.adjuvenantTherapyTypeCode,
    adjuvenantTherapyTypeOtherValue: form.adjuvenantTherapyTypeOtherValue,
    clinicalTrialWasIncludedCode: form.clinicalTrialWasIncludedCode,
    clinicalTrialEudraCTNumber: form.clinicalTrialEudraCTNumber,
    clinicalTrialNctNumber: form.clinicalTrialNctNumber,
    postoperativeComplications: form.postoperativeComplications.map((poc) => ({
        ...poc,
        isgpsGradeCode: poc.isGpsGradeCode,
        isglsGradeCode: poc.isGlsGradeCode,
    })),
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
