import React from 'react';
import { Grid } from '@material-ui/core';
import { formatDate } from '../../../form/ImportPatientDetail.config';
import { Values } from '../../../form/PatientForm.config';
import { Gender } from '../../../../../../api/enums';
import TextfieldWithChanges from './TextfieldWithChanges';
import { EditFormLabels } from '../EditPatientFormLabels';
import { ImportValues } from '../../EditPatient.config';
import { defaultGender } from '../../../form/GenderDefaultValue.config';
import SelectFieldWithChanges from './SelectFieldWithChanges';
type EditPersonalInformationProps = {
    oldValues: Values;
    updatedValues: ImportValues;
    hasChanges: Map<EditFormLabels, boolean>;
};

export default function EditPersonalInformation(props: EditPersonalInformationProps) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={props.oldValues.firstName}
                    updatedValue={props.updatedValues.firstName}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.FirstName}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={props.oldValues.surname}
                    updatedValue={props.updatedValues.surname}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.SurName}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={formatDate(props.oldValues.dateOfBirth)}
                    updatedValue={formatDate(props.updatedValues.dateOfBirth)}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.DateOfBirth}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={formatDate(props.oldValues.dateOfDeath)}
                    updatedValue={formatDate(props.updatedValues.dateOfDeath)}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.DateOfDeath}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectFieldWithChanges
                    fieldName="gender"
                    placeholder={EditFormLabels.Gender}
                    selectionInfo="Select a gender"
                    defaultValue={defaultGender}
                    oldValue={Gender[Number(props.oldValues.gender)]}
                    attemptedValue={props.updatedValues.genderAttemptedValue}
                    enableSelection={props.updatedValues.gender === defaultGender}
                    sectionChangesTracker={props.hasChanges}
                    options={Object.keys(Gender)
                        .filter((key) => !isNaN(Number(key)))
                        .map((gender) => ({ id: Number(gender), display: Gender[Number(gender)] }))}
                />
            </Grid>
        </Grid>
    );
}
