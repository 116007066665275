import React, { PropsWithChildren } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styles from './DragAndDropZone.module.css';
import { List } from '@material-ui/core';

type DroppableListProps = PropsWithChildren<{
    droppableId: string;
}>;

export default function DroppableList(props: DroppableListProps) {
    return (
        <Droppable droppableId={props.droppableId}>
            {(provided) => (
                <List {...provided.droppableProps} ref={provided.innerRef} className={styles.List}>
                    {props.children}
                    {provided.placeholder}
                </List>
            )}
        </Droppable>
    );
}
