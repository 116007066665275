import { Mocs } from '../../../../../../../api/types';
import { CodeAndOtherValue, CodeValue } from '../../../MocForm.config';
import {
    toMocBoolean,
    toMocDate,
    toMocNumber,
    toValuesBoolean,
    toValuesString,
    toValuesNumber,
    toValuesDate,
} from '../../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzOesophagusData,
    | 'isPETPerformedPriorToSurgery'
    | 'hadOtherTreatmentBeforeThisSurgery'
    | 'dateOfSurgery'
    | 'tumourLocationCode'
    | 'surgeryIntentionCode'
    | 'surgeryModeCode'
    | 'surgeryTypeCode'
    | 'minimallyInvasiveSurgeryTypeCode'
    | 'reasonForConversion'
    | 'nomenclatureCode'
    | 'oesophagectomyCode'
    | 'wasResectionPerformedSurgical'
    | 'wasResectionPerformedPathological'
    | 'wasProximalMarginInvolved'
    | 'wasLymphovascularInvasion'
    | 'wasPerineuralInvasion'
    | 'mandardGradeCode'
    | 'gastrectomyCode'
    | 'hadLymphadenectomy'
    | 'lymphadenectomyFieldCode'
    | 'numberOfLocoRegionalLymphNodesRetrieved'
    | 'numberOfMetastaticLocoRegionalLymphNodes'
    | 'hadOtherResections'
    | 'oesophagealConduitCode'
    | 'anastomosisCode'
    | 'anastomosisOtherValue'
    | 'openSurgeryTypes'
    | 'treatmentTypes'
    | 'lymphadenectomyRegions'
    | 'resectionTypes'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzOesophagusFields,
    | 'isPETPerformedPriorToSurgery'
    | 'hadOtherTreatmentBeforeThisSurgery'
    | 'dateOfSurgery'
    | 'tumourLocationCode'
    | 'surgeryIntentionCode'
    | 'surgeryModeCode'
    | 'surgeryTypeCode'
    | 'minimallyInvasiveSurgeryTypeCode'
    | 'reasonForConversion'
    | 'nomenclatureCode'
    | 'oesophagectomyCode'
    | 'wasResectionPerformedSurgical'
    | 'wasResectionPerformedPathological'
    | 'wasProximalMarginInvolved'
    | 'wasLymphovascularInvasion'
    | 'wasPerineuralInvasion'
    | 'mandardGradeCode'
    | 'gastrectomyCode'
    | 'hadLymphadenectomy'
    | 'lymphadenectomyFieldCode'
    | 'numberOfLocoRegionalLymphNodesRetrieved'
    | 'numberOfMetastaticLocoRegionalLymphNodes'
    | 'hadOtherResections'
    | 'oesophagealConduitCode'
    | 'anastomosisCode'
    | 'anastomosisOtherValue'
    | 'openSurgeryTypes'
    | 'treatmentTypes'
    | 'lymphadenectomyRegions'
    | 'resectionTypes'
>;

export type FormValues = {
    isPETPerformedPriorToSurgery: boolean | '';
    hadOtherTreatmentBeforeThisSurgery: boolean | '';
    dateOfSurgery: string | null;
    tumourLocationCode: string;
    surgeryIntentionCode: string;
    surgeryModeCode: string;
    surgeryTypeCode: string;
    minimallyInvasiveSurgeryTypeCode: string;
    reasonForConversion: string;
    nomenclatureCode: string;
    oesophagectomyCode: string;
    wasResectionPerformedSurgical: boolean | '';
    wasResectionPerformedPathological: boolean | '';
    wasProximalMarginInvolved: boolean | '';
    wasLymphovascularInvasion: boolean | '';
    wasPerineuralInvasion: boolean | '';
    mandardGradeCode: string;
    gastrectomyCode: string;
    hadLymphadenectomy: boolean | '';
    lymphadenectomyFieldCode: string;
    numberOfLocoRegionalLymphNodesRetrieved: number | '';
    numberOfMetastaticLocoRegionalLymphNodes: number | '';
    hadOtherResections: boolean | '';
    oesophagealConduitCode: string;
    anastomosisCode: string;
    anastomosisOtherValue: string;
    openSurgeryTypes: CodeValue[];
    treatmentTypes: TreatmentDetails[];
    lymphadenectomyRegions: CodeValue[];
    resectionTypes: CodeAndOtherValue[];
};

export type TreatmentDetails = {
    code: string;
    startDate: string | null;
    latestTreatmentDate: string | null;
    typeOfSurgery: string;
    latestSurgeryDate: string | null;
    otherValue: string;
    otherDate: string | null;
    endoscopicTreatmentTypes: EndoscopicTreatmentDetails[];
};

export type EndoscopicTreatmentDetails = {
    code: string;
    latestTreatmentDate: string | null;
    otherValue: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    isPETPerformedPriorToSurgery: toValuesBoolean(moc.isPETPerformedPriorToSurgery),
    hadOtherTreatmentBeforeThisSurgery: toValuesBoolean(moc.hadOtherTreatmentBeforeThisSurgery),
    dateOfSurgery: toValuesDate(moc.dateOfSurgery),
    tumourLocationCode: toValuesString(moc.tumourLocationCode),
    surgeryIntentionCode: toValuesString(moc.surgeryIntentionCode),
    surgeryModeCode: toValuesString(moc.surgeryModeCode),
    surgeryTypeCode: toValuesString(moc.surgeryTypeCode),
    minimallyInvasiveSurgeryTypeCode: moc.minimallyInvasiveSurgeryTypeCode,
    reasonForConversion: toValuesString(moc.reasonForConversion),
    nomenclatureCode: toValuesString(moc.nomenclatureCode),
    oesophagectomyCode: toValuesString(moc.oesophagectomyCode),
    wasResectionPerformedSurgical: toValuesBoolean(moc.wasResectionPerformedSurgical),
    wasResectionPerformedPathological: toValuesBoolean(moc.wasResectionPerformedPathological),
    wasProximalMarginInvolved: toValuesBoolean(moc.wasProximalMarginInvolved),
    wasLymphovascularInvasion: toValuesBoolean(moc.wasLymphovascularInvasion),
    wasPerineuralInvasion: toValuesBoolean(moc.wasPerineuralInvasion),
    mandardGradeCode: toValuesString(moc.mandardGradeCode),
    gastrectomyCode: toValuesString(moc.gastrectomyCode),
    hadLymphadenectomy: toValuesBoolean(moc.hadLymphadenectomy),
    lymphadenectomyFieldCode: toValuesString(moc.lymphadenectomyFieldCode),
    numberOfLocoRegionalLymphNodesRetrieved: toValuesNumber(moc.numberOfLocoRegionalLymphNodesRetrieved),
    numberOfMetastaticLocoRegionalLymphNodes: toValuesNumber(moc.numberOfMetastaticLocoRegionalLymphNodes),
    hadOtherResections: toValuesBoolean(moc.hadOtherResections),
    oesophagealConduitCode: toValuesString(moc.oesophagealConduitCode),
    anastomosisCode: toValuesString(moc.anastomosisCode),
    anastomosisOtherValue: toValuesString(moc.anastomosisOtherValue),
    treatmentTypes: moc.treatmentTypes.map((t) => ({
        code: t.treatmentTypeCode,
        startDate: t.startDate,
        latestTreatmentDate: t.latestTreatmentDate,
        latestSurgeryDate: t.latestSurgeryDate,
        typeOfSurgery: toValuesString(t.typeOfSurgery),
        otherValue: toValuesString(t.otherValue),
        otherDate: t.otherDate,
        endoscopicTreatmentTypes: t.endoscopicTreatmentTypes.map((e) => ({
            code: e.endoscopicTreatmentTypeCode,
            latestTreatmentDate: e.latestTreatmentDate,
            otherValue: toValuesString(e.otherValue),
        })),
    })),
    lymphadenectomyRegions: moc.lymphadenectomyRegions.map((l) => ({ code: l.lymphadenectomyRegionCode })),
    resectionTypes: moc.resectionTypes.map((v) => ({
        code: v.resectionTypeCode,
        otherValue: toValuesString(v.otherValue),
    })),
    openSurgeryTypes: moc.openSurgeryTypes.map((o) => ({ code: o.openSurgeryTypeCode })),
});

export const getNewMocValues = (): GetMocValues => ({
    isPETPerformedPriorToSurgery: null,
    hadOtherTreatmentBeforeThisSurgery: null,
    dateOfSurgery: null,
    tumourLocationCode: '',
    surgeryIntentionCode: '',
    surgeryModeCode: '',
    surgeryTypeCode: '',
    minimallyInvasiveSurgeryTypeCode: '',
    reasonForConversion: '',
    nomenclatureCode: '',
    oesophagectomyCode: '',
    wasResectionPerformedSurgical: null,
    wasResectionPerformedPathological: null,
    wasProximalMarginInvolved: null,
    wasLymphovascularInvasion: null,
    wasPerineuralInvasion: null,
    mandardGradeCode: '',
    gastrectomyCode: '',
    hadLymphadenectomy: null,
    lymphadenectomyFieldCode: '',
    numberOfLocoRegionalLymphNodesRetrieved: 0,
    numberOfMetastaticLocoRegionalLymphNodes: 0,
    hadOtherResections: null,
    oesophagealConduitCode: '',
    anastomosisCode: '',
    anastomosisOtherValue: '',
    openSurgeryTypes: [],
    treatmentTypes: [],
    lymphadenectomyRegions: [],
    resectionTypes: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    isPETPerformedPriorToSurgery: toMocBoolean(form.isPETPerformedPriorToSurgery),
    hadOtherTreatmentBeforeThisSurgery: toMocBoolean(form.hadOtherTreatmentBeforeThisSurgery),
    dateOfSurgery: form.dateOfSurgery,
    tumourLocationCode: form.tumourLocationCode,
    surgeryIntentionCode: form.surgeryIntentionCode,
    surgeryModeCode: form.surgeryModeCode,
    surgeryTypeCode: form.surgeryTypeCode,
    minimallyInvasiveSurgeryTypeCode: form.minimallyInvasiveSurgeryTypeCode,
    reasonForConversion: form.reasonForConversion,
    nomenclatureCode: form.nomenclatureCode,
    oesophagectomyCode: form.oesophagectomyCode,
    wasResectionPerformedSurgical: toMocBoolean(form.wasResectionPerformedSurgical),
    wasResectionPerformedPathological: toMocBoolean(form.wasResectionPerformedPathological),
    wasProximalMarginInvolved: toMocBoolean(form.wasProximalMarginInvolved),
    wasLymphovascularInvasion: toMocBoolean(form.wasLymphovascularInvasion),
    wasPerineuralInvasion: toMocBoolean(form.wasPerineuralInvasion),
    mandardGradeCode: form.mandardGradeCode,
    gastrectomyCode: form.gastrectomyCode,
    hadLymphadenectomy: toMocBoolean(form.hadLymphadenectomy),
    lymphadenectomyFieldCode: form.lymphadenectomyFieldCode,
    numberOfLocoRegionalLymphNodesRetrieved: toMocNumber(form.numberOfLocoRegionalLymphNodesRetrieved),
    numberOfMetastaticLocoRegionalLymphNodes: toMocNumber(form.numberOfMetastaticLocoRegionalLymphNodes),
    hadOtherResections: toMocBoolean(form.hadOtherResections),
    oesophagealConduitCode: form.oesophagealConduitCode,
    anastomosisCode: form.anastomosisCode,
    anastomosisOtherValue: form.anastomosisOtherValue,
    openSurgeryTypes: form.openSurgeryTypes,
    treatmentTypes: form.treatmentTypes.map((t) => ({
        ...t,
        startDate: toMocDate(t.startDate),
        latestTreatmentDate: toMocDate(t.latestTreatmentDate),
        latestSurgeryDate: toMocDate(t.latestSurgeryDate),
        typeOfSurgery: t.typeOfSurgery,
        otherValue: t.otherValue,
        otherDate: toMocDate(t.otherDate),
        endoscopicTreatmentTypes: t.endoscopicTreatmentTypes.map((e) => ({
            ...e,
            latestTreatmentDate: toMocDate(e.latestTreatmentDate),
            otherValue: e.otherValue,
        })),
    })),
    lymphadenectomyRegions: form.lymphadenectomyRegions,
    resectionTypes: form.resectionTypes,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
