import React, { Fragment } from 'react';
import { View, Text } from '@react-pdf/renderer';
import * as postSurgery from '../../../../../forms/sections/postSurgery/ckzOesophagus/PostSurgery.config';
import {
    QuestionAnswer,
    QuestionYesNoAnswer,
    QuestionYesNoAnswerAndDate,
    QuestionAnswerAndOther,
    QuestionProps,
} from '../../Questions';
import { styles } from '../../../styles';
import List, { Item, SubItem } from '../../List';
import { PostoperativeComplication } from '../../../../../forms/sections/postSurgery/ckzOesophagus/PostSurgery.config';
import { ReferenceDataMapper } from '../../..';

type PostSurgeryProps = {
    values: postSurgery.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function PostSurgery({ values, mapReferenceData }: PostSurgeryProps) {
    return (
        <View>
            <Text style={styles.header}>Post-Surgery</Text>
            <QuestionListPostoperativeComplicationsAnswer
                question={'Which postoperative complications occurred'}
                answer={values.postoperativeComplications}
                mapper={mapReferenceData}
            />
            <QuestionYesNoAnswer
                question={'Did other major postoperative complications occur'}
                answer={values.hadOtherMajorPostopComplications}
            />
            <List>
                {values.majorPostoperativeComplications.map((complication, i) => (
                    <Fragment key={i}>
                        <Item>{mapReferenceData('postoperativeComplications', complication.code)}</Item>
                        {complication.postoperativeComplicationSubTypes.map((subtype, s) => (
                            <SubItem key={s}>
                                {subtype.otherValue !== ''
                                    ? `${mapReferenceData('postoperativeComplicationSubTypes', subtype.code)} - ${
                                          subtype.otherValue
                                      }`
                                    : `${mapReferenceData('postoperativeComplicationSubTypes', subtype.code)}`}
                            </SubItem>
                        ))}
                    </Fragment>
                ))}
            </List>

            <QuestionAnswer
                question={'General Clavien-Dindo classification'}
                answer={mapReferenceData('clavienDindoGrades', values.generalClavienDindoGradeCode)}
            />
            <QuestionYesNoAnswer question={'Re-operation necessary'} answer={values.hadRedoSurgery} />
            <QuestionAnswer
                question={'Type of surgery'}
                answer={mapReferenceData('redoSurgeryTypes', values.redoSurgeryTypeCode)}
            />
            <QuestionAnswer question={'Other surgery type'} answer={values.redoSurgeryTypeOtherValue} />
            <QuestionYesNoAnswerAndDate
                question={'Was the patient discharged after surgery during 90-day post-op'}
                answer={values.postOpDischarged}
                date={values.postOpDischargedDate}
            />
            <QuestionAnswer
                question={'Discharge destination'}
                answer={mapReferenceData('destinations', values.postOpDischargedDestinationCode)}
            />
            <QuestionAnswer
                question={'Hospital name'}
                answer={mapReferenceData('hospitals', values.postOpDischargedTransferHospitalName)}
            />
            <QuestionYesNoAnswer
                question={'Discharged to hospital because of complications'}
                answer={values.postOpDischargedTransferBecauseComplications}
            />
            <QuestionAnswer
                question={'Re-admission within 30 days after discharge'}
                answer={mapReferenceData('readmissionTypes', values.postOpDischargedReadmissionTypeCode)}
            />
            <QuestionAnswer question={'Reason for re-admission'} answer={values.postOpDischargedReadmissionReason} />

            <QuestionYesNoAnswerAndDate
                question={'Did the patient die during the 90-day post-op'}
                answer={values.postOpDied}
                date={values.postOpDiedDate}
            />
            <QuestionYesNoAnswer question={'Did the patient die in-hospital'} answer={values.postOpDiedInHospital} />
            <QuestionAnswer question={'Cause of death'} answer={values.postOpDiedCause} />
            <QuestionYesNoAnswer
                question={'Was there adjuvent therapy after surgery'}
                answer={values.wasThereAdjuvenantTherapyAfterSurgery}
            />
            <QuestionAnswerAndOther
                question={'Type of adjuvent therapy after surgery'}
                answer={mapReferenceData('adjuvenantTherapyTypes', values.adjuvenantTherapyTypeCode)}
                other={values.adjuvenantTherapyTypeOtherValue}
            />
            <QuestionAnswer
                question={'Was the patient included in a clinical trial'}
                answer={values.clinicalTrialWasIncludedCode}
            />
            <QuestionAnswer question={'EudraCT number'} answer={values.clinicalTrialEudraCTNumber} />
            <QuestionAnswer question={'NCT number'} answer={values.clinicalTrialNctNumber} />
        </View>
    );
}

type QuestionListPostoperativeComplicationsAnswerProps = QuestionProps & {
    answer: PostoperativeComplication[];
    mapper: ReferenceDataMapper;
};

export function QuestionListPostoperativeComplicationsAnswer(props: QuestionListPostoperativeComplicationsAnswerProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}</Text>
            <List>
                {props.answer.map((complication, i) => (
                    <Item key={i}>
                        {complication.clavienDindoGradeCode !== '' && complication.clavienDindoGradeCode !== null
                            ? `${props.mapper('postoperativeComplications', complication.code)} - ${props.mapper(
                                  'clavienDindoGrades',
                                  complication.clavienDindoGradeCode
                              )}`
                            : `${props.mapper('postoperativeComplications', complication.code)}`}
                    </Item>
                ))}
            </List>
        </View>
    ) : null;
}
