import React from 'react';
import { Grid, Paper, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form } from 'formik';
import Section from '../../../../../styles/components/forms/Section';
import { formatDate, validateForm } from '../../form/ImportPatientDetail.config';
import EditPersonalInformation from './sections/EditPersonalInformation';
import EditAddress from './sections/EditAddress';
import EditInsurance from './sections/EditInsurance';
import EditIdentification from './sections/EditIdentification';
import SaveIcon from '@material-ui/icons/Save';
import { ManualValues, ImportValues, Country, Fund } from '../EditPatient.config';
import styles from './EditPatientFormImport.module.scss';
import { EditFormLabels } from './EditPatientFormLabels';
import EditOtherMedicalInfo from './sections/EditOtherMedicalInfo';
import PatientDetailBackButton from '../../detail/backbutton/PatientDetailBackButton';
type Props = {
    manualValues: ManualValues;
    importValues: ImportValues;
    countries: Country[] | null;
    funds: Fund[] | null;
    onSubmit: (values: ImportValues) => void;
    onClose: () => void;
};

//found: https://stackoverflow.com/questions/2140627/how-to-do-case-insensitive-string-comparison
function ciEquals(a: string | null, b: string | null) {
    if ((a && !b) || (b && !a)) {
        return false;
    }
    if (!a && !b) return true;
    return a!.localeCompare(b!, undefined, { sensitivity: 'accent' }) === 0;
}

export default function EditPatientFromImport(props: Props) {
    function mapIdentificationChanges() {
        var map = new Map<EditFormLabels, boolean>();
        map.set(EditFormLabels.UzaNr, false);
        map.set(EditFormLabels.SSN, !ciEquals(props.manualValues.ssn, props.importValues.ssn));
        return map;
    }
    function mapAddressChanges() {
        var map = new Map<EditFormLabels, boolean>();
        map.set(EditFormLabels.Address, !ciEquals(props.manualValues.address, props.importValues.address));
        map.set(EditFormLabels.Zip, !ciEquals(props.manualValues.postalCode, props.importValues.postalCode));
        map.set(EditFormLabels.City, !ciEquals(props.manualValues.city, props.importValues.city));
        map.set(EditFormLabels.Country, props.manualValues.countryId !== props.importValues.countryId);
        map.set(EditFormLabels.Mail, !ciEquals(props.manualValues.mail, props.importValues.mail));
        map.set(EditFormLabels.Tel, !ciEquals(props.manualValues.telNr, props.importValues.tel));

        return map;
    }
    function mapInsuranceChanges() {
        var map = new Map<EditFormLabels, boolean>();
        map.set(
            EditFormLabels.HealthInsuranceFund,
            props.manualValues.healthInsuranceFundId !== props.importValues.healthInsuranceFundId
        );
        map.set(
            EditFormLabels.HealthInsuranceFundNr,
            !ciEquals(props.manualValues.healthInsuranceFundNr, props.importValues.healthInsuranceFundNr)
        );
        return map;
    }
    function mapPersonalInformationChanges() {
        var map = new Map<EditFormLabels, boolean>();
        map.set(EditFormLabels.FirstName, !ciEquals(props.manualValues.firstName, props.importValues.firstName));
        map.set(EditFormLabels.SurName, !ciEquals(props.manualValues.surname, props.importValues.surname));
        map.set(
            EditFormLabels.DateOfBirth,
            !ciEquals(formatDate(props.manualValues.dateOfBirth), formatDate(props.importValues.dateOfBirth))
        );
        map.set(
            EditFormLabels.DateOfDeath,
            !ciEquals(formatDate(props.manualValues.dateOfDeath), formatDate(props.importValues.dateOfDeath))
        );
        map.set(EditFormLabels.Gender, props.manualValues.gender !== props.importValues.gender);
        return map;
    }

    const addressChanges = mapAddressChanges();
    const insuranceChanges = mapInsuranceChanges();
    const personalInformationChanges = mapPersonalInformationChanges();
    const identificationChanges = mapIdentificationChanges();

    const zisHasValidDifferences = [
        ...Array.from(identificationChanges.values()),
        ...Array.from(personalInformationChanges.values()),
        ...Array.from(addressChanges.values()),
        ...Array.from(insuranceChanges.values()),
    ].includes(true);

    return (
        <>
            <h2>{zisHasValidDifferences ? `Differences found` : `No differences found`}</h2>
            <Formik
                initialValues={props.importValues}
                enableReinitialize
                onSubmit={props.onSubmit}
                validationSchema={validateForm(props.funds)}
                validateOnMount
            >
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <Paper>
                            <Section name="Identification">
                                <Grid container spacing={3}>
                                    <EditIdentification
                                        oldValues={props.manualValues}
                                        updatedValues={props.importValues}
                                        hasChanges={identificationChanges}
                                    />
                                </Grid>
                            </Section>
                            <Section name="Personal Information">
                                <Grid container spacing={3}>
                                    <EditPersonalInformation
                                        oldValues={props.manualValues}
                                        updatedValues={props.importValues}
                                        hasChanges={personalInformationChanges}
                                    />
                                </Grid>
                            </Section>
                            <Section name="Address">
                                <Grid container spacing={3}>
                                    <EditAddress
                                        oldValues={props.manualValues}
                                        updatedValues={props.importValues}
                                        hasChanges={addressChanges}
                                        countries={props.countries}
                                    />
                                </Grid>
                            </Section>
                            <Section name="Insurance">
                                <Grid container spacing={3}>
                                    <EditInsurance
                                        oldValues={props.manualValues}
                                        updatedValues={props.importValues}
                                        hasChanges={insuranceChanges}
                                        funds={props.funds}
                                    />
                                </Grid>
                            </Section>
                            <Section name="Other Medical Info">
                                <Grid container spacing={3}>
                                    <EditOtherMedicalInfo />
                                </Grid>
                            </Section>
                        </Paper>
                        <div style={{ display: 'flex' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={styles.submitButton}
                                startIcon={<SaveIcon />}
                                disabled={isSubmitting || !isValid || !zisHasValidDifferences}
                            >
                                {'Update & close'}
                            </Button>
                            <div className={styles.submitButton}>
                                <PatientDetailBackButton isContained={false} onGoToPatientDetail={props.onClose} />
                            </div>
                            {isSubmitting && (
                                <CircularProgress size="1.4rem" style={{ marginLeft: '1rem', marginTop: '1rem' }} />
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}
