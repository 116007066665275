import { Grid } from '@material-ui/core';
import React from 'react';
import { FastSelectField, FastTextArea, FastTextField } from '../components';
import ItemList from '../components/ItemList';
import { CytologyType } from '../sections/pathology/Pathology.config';
import { Mocs } from '../../../../../api/types';
import { FastField } from 'formik';
import FormikNumberField from '../../../../../styles/components/forms/FormikNumberField';

type Type = Mocs.GetReferenceData.Moc_Ref_CytologyType;
type Value = Mocs.GetReferenceData.Moc_Ref_CytologyValue;

type Props = {
    name: string;
};

function detail(name: string) {
    return (
        <>
            <Grid item xs={6} md={4}>
                <FastSelectField<Type>
                    name={`${name}.typeCode`}
                    prompt="Selecteer type"
                    placeholder="Type"
                    detail={({ isOther }) => isOther && <FastTextField name={`${name}.typeOther`} label="Ander type" />}
                    referenceDataPath="cytologyTypes"
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <FastSelectField<Value>
                    name={`${name}.valueCode`}
                    prompt="Selecteer waarde"
                    placeholder="Waarde"
                    detail={({ isOther }) => isOther && <FastField name={`${name}.valueOther`} type="number" component={FormikNumberField} label="Ander Getal" />}
                    referenceDataPath="cytologyValues"
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <FastTextField name={`${name}.unit`} label="Eenheid" />
            </Grid>
            <Grid item xs={6} md={12}>
                <FastTextArea name={`${name}.info`} label="Info" />
            </Grid>
        </>
    );
}

function create(): CytologyType {
    return {
        info: '',
        typeCode: '',
        typeOther: '',
        unit: '',
        valueCode: '',
        valueOther: '',
    };
}

export default function CytologyTypeList(props: Props) {
    return <ItemList name={props.name} minItems={0} addText="Extra beschrijving toevoegen" detail={detail} create={create} />;
}
