type Translations = {
    yes: string;
    no: string;
};

type Dutch = 'nl';
type English = 'en';

export type Language = Dutch | English;

type TranslationKey = keyof Translations;
type Translator = (key: TranslationKey) => string;

const dutchTranslations: Translations = {
    no: 'Nee',
    yes: 'Ja',
};

const englishTranslations: Translations = {
    no: 'No',
    yes: 'Yes',
};

function getTranslations(l: Language): Translations {
    switch (l) {
        case 'nl':
            return dutchTranslations;
        case 'en':
            return englishTranslations;
        default:
            throw new Error(`Unknown language: ${l}`);
    }
}

function translate(key: TranslationKey, l: Language) {
    const translations = getTranslations(l);
    const hasTranslation = translations.hasOwnProperty(key);
    return hasTranslation ? translations[key] : key;
}

export function getTranslator(l: Language): Translator {
    return (key: TranslationKey) => translate(key, l);
}

export default Language;
