import { Mocs } from '../../../../../../api/types';
import { toValuesString } from '../../mappers';

export type GetMocValues = Pick<Mocs.GetMoc.LocalMocData, 'ctCode' | 'cnCode' | 'cmCode' | 'medicalImagingRecords'>;

export type SaveMocValues = Pick<Mocs.SaveMoc.LocalMocFields, 'ctCode' | 'cnCode' | 'cmCode' | 'medicalImagingRecords'>;

export type FormValues = {
    ctCode: string;
    cnCode: string;
    cmCode: string;
    medicalImagingRecords: MedicalImaging[];
};

export type MedicalImaging = {
    date: string | null;
    imagingType: string;
    imagingTypeOther: string;
    pacsOnWeb: string;
    conclusion: string;
    progression: boolean | null;
    fileId: number | null;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    ctCode: toValuesString(moc.ctCode),
    cnCode: toValuesString(moc.cnCode),
    cmCode: toValuesString(moc.cmCode),
    medicalImagingRecords: moc.medicalImagingRecords,
});

export const getNewMocValues = (): GetMocValues => ({
    ctCode: '',
    cnCode: '',
    cmCode: '',
    medicalImagingRecords: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    ctCode: form.ctCode,
    cnCode: form.cnCode,
    cmCode: form.cmCode,
    medicalImagingRecords: form.medicalImagingRecords,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
