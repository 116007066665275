import React, { PropsWithChildren } from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { FastTextArea, FastSelectField } from '../../components';
import { TreatmentPlan } from './TreatmentPlan.config';
import styles from './TreatmentPlan.module.css';
import DragAndDropZone from '../../components/DragAndDropZone';
import { Field, useFormikContext } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import FormikCheckbox from '../../../../../../styles/components/forms/FormikCheckbox';
import { Values } from '../../MocForm.config';

type TreatmentplanProps = PropsWithChildren<{
    isValid: boolean | null;
}>;

export default function Treatmentplan(props: TreatmentplanProps) {
    const { values } = useFormikContext<Values>();
    const { mocTypeCode } = values;
    const isNetMoc: boolean = mocTypeCode === 'NETMOC';

    function renderItem(
        item: TreatmentPlan,
        isSelected: boolean,
        refItem: TreatmentPlan | undefined,
        onClick?: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined
    ) {
        return (
            <MenuItem
                onClick={onClick}
                selected={isSelected}
                style={{
                    backgroundColor: isSelected
                        ? 'rgb(152,152,152)'
                        : refItem !== undefined
                        ? refItem.color
                        : item.color,
                }}
            >
                {refItem !== undefined ? refItem.name : item.code}
            </MenuItem>
        );
    }

    return (
        <MocSectionContainer code="treatment-plan" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FastTextArea name="treatmentPlan.localTreatmentPlan" label="Lokaal behandelplan" />
                </Grid>
                {isNetMoc && (
                    <Grid item xs={12}>
                        <FastTextArea name="treatmentPlan.netMocTreatmentPlan" label="NET-MOC behandelplan" />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FastSelectField
                        name="treatmentPlan.tumorEvolution"
                        prompt="Selecteer een tumor evolutie"
                        placeholder="Tumor evolutie"
                        referenceDataPath="tumorEvolutions"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField
                        name="treatmentPlan.treatmentStrategy"
                        prompt="Selecteer een behandel strategie"
                        placeholder="Behandel strategie"
                        referenceDataPath="treatmentStrategies"
                    />
                </Grid>
                {isNetMoc && (
                    <>
                        <Grid item xs={12}>
                            <Field
                                name="treatmentPlan.eligibleForAStudy"
                                type="checkbox"
                                component={FormikCheckbox}
                                label="Komt in aanmerking voor een studie"
                            />
                            <span>Komt in aanmerking voor een studie</span>
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                name="treatmentPlan.concerningStudy"
                                type="Text"
                                component={FormikTextField}
                                label="In verband met studie"
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid container spacing={3} style={{ marginTop: '50px' }}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={styles.border}>
                        <InputLabel shrink className={styles.inputLabel}>
                            Gecodeerd behandelplan
                        </InputLabel>
                        <div style={{ padding: '20px 0 20px 0', display: 'flex' }}>
                            <DragAndDropZone<TreatmentPlan>
                                formikName="treatmentPlan.treatmentPlans"
                                refDataName="treatmentPlans"
                                sourceTitle="Stel het behandelplan op"
                                destinationTitle="Kies uit de volgende stappen"
                                renderItem={renderItem}
                            />
                        </div>
                    </FormControl>
                </Grid>
                {isNetMoc && (
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={styles.border}>
                            <InputLabel shrink className={styles.inputLabel}>
                                NET-MOC Gecodeerd behandelplan
                            </InputLabel>
                            <div style={{ paddingTop: '20px', display: 'flex' }}>
                                <DragAndDropZone<TreatmentPlan>
                                    formikName="treatmentPlan.netMocTreatmentPlans"
                                    refDataName="treatmentPlans"
                                    sourceTitle="Stel het behandelplan op"
                                    destinationTitle="Kies uit de volgende stappen"
                                    renderItem={renderItem}
                                />
                            </div>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
        </MocSectionContainer>
    );
}
