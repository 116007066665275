import { Grid } from '@material-ui/core';
import React from 'react';
import { FastSelectField, FastTextArea, FastTextField } from '../components';
import ItemList from '../components/ItemList';
import { BiopsyType } from '../sections/pathology/Pathology.config';
import { Mocs } from '../../../../../api/types';
import { FastField, useFormikContext, getIn } from 'formik';
import FormikNumberField from '../../../../../styles/components/forms/FormikNumberField';
import { Values } from '../MocForm.config';
import { useConstant, useResetOnChange } from '../sections/useResetEffect';

type Type = Mocs.GetReferenceData.Moc_Ref_BiopsyType;
type Value = Mocs.GetReferenceData.Moc_Ref_BiopsyValue;

type Props = {
    name: string;
    referenceTypeDataFilter?: (reference: Type) => boolean;
    referenceValueDataFilter?: (reference: Value) => boolean;
};

const Detail: React.FC<Props> = ({ name, referenceTypeDataFilter, referenceValueDataFilter }) => {
    const { values } = useFormikContext<Values>();
    const type = getIn(values, `${name}.typeCode`);

    useResetOnChange(`${name}.typeCode`, useConstant(`${name}.valueCode`, ''));
    useResetOnChange(`${name}.typeCode`, useConstant(`${name}.typeOther`, ''));
    useResetOnChange(`${name}.valueCode`, useConstant(`${name}.valueOther`, ''));

    return (
        <>
            <Grid item xs={6} md={4}>
                <FastSelectField<Type>
                    name={`${name}.typeCode`}
                    prompt="Selecteer type"
                    placeholder="Type*"
                    detail={({ isOther }) => isOther && <FastTextField name={`${name}.typeOther`} label="Ander type" />}
                    referenceDataPath="biopsyTypes"
                    referenceDataFilter={referenceTypeDataFilter}
                />
            </Grid>
            <Grid item xs={6} md={4}>
                <span style={{ display: type !== 'SequenceTesting' ? 'block' : 'none' }}>
                    <FastSelectField<Value>
                        name={`${name}.valueCode`}
                        prompt="Selecteer waarde"
                        placeholder="Waarde*"
                        detail={({ isOther }) =>
                            isOther && (
                                <FastField
                                    name={`${name}.valueOther`}
                                    type="number"
                                    component={FormikNumberField}
                                    label="Ander Getal*"
                                />
                            )
                        }
                        referenceDataPath="biopsyValues"
                        referenceDataFilter={referenceValueDataFilter}
                    />
                </span>
                <span style={{ display: type === 'SequenceTesting' ? 'block' : 'none' }}>
                    <FastSelectField<Value>
                        name={`${name}.valueCode`}
                        prompt="Selecteer waarde"
                        placeholder="Waarde*"
                        detail={({ isOther }) =>
                            isOther && (
                                <FastField
                                    name={`${name}.valueOther`}
                                    type="number"
                                    component={FormikNumberField}
                                    label="Ander Getal*"
                                />
                            )
                        }
                        referenceDataPath="biopsyValues"
                        referenceDataFilter={(value) => value.isSequenceTesting}
                    />
                </span>
            </Grid>
            <Grid item xs={6} md={4}>
                <FastTextField name={`${name}.unit`} label="Eenheid" />
            </Grid>
            <Grid item xs={6} md={12}>
                <FastTextArea name={`${name}.info`} label="Info" />
            </Grid>
        </>
    );
};

function create(): BiopsyType {
    return {
        info: '',
        typeCode: '',
        typeOther: '',
        unit: '',
        valueCode: '',
        valueOther: '',
    };
}

export default function BiopsyTypeList(props: Props) {
    return (
        <ItemList
            name={props.name}
            minItems={0}
            addText="Extra beschrijving toevoegen"
            detail={(refName) => <Detail {...props} name={refName} />}
            create={create}
        />
    );
}
