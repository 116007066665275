import React from 'react';
import { Grid } from '@material-ui/core';
import MocSectionContainer from '../MocSectionContainer';
import { FastDateField, FastSelectField, CheckboxField, FormikErrorMessage, FastTextField } from '../../components';
import { Mocs } from '../../../../../../api/types';
import styles from './NeoAdjuvantTreatment.module.scss';
import { Values } from '../../MocForm.config';
import { useFormikContext } from 'formik';
import { useConstant, useResetOnValue } from '../useResetEffect';

type RadiotherapyType = Mocs.GetReferenceData.Moc_Ref_RadiotherapyType;
type ChemotherapyType = Mocs.GetReferenceData.Moc_Ref_ChemotherapyType;

type Props = {
    isValid: boolean | null;
};

const NeoAdjuvantTreatment: React.FC<Props> = React.memo(({ isValid }) => {
    const { values } = useFormikContext<Values>();
    const { neoAdjuvantTreatment } = values;

    const isRadiotherapySelected = neoAdjuvantTreatment.radiotherapy;
    const isChemotherapySelected = neoAdjuvantTreatment.chemotherapy;
    const isImmunotherapySelected = neoAdjuvantTreatment.immunotherapy;

    useResetOnValue(
        'neoAdjuvantTreatment.radiotherapy',
        false,
        useConstant('neoAdjuvantTreatment.radiotherapyType', ''),
        useConstant('neoAdjuvantTreatment.radiotherapyStartDate', null),
        useConstant('neoAdjuvantTreatment.radiotherapyEndDate', null)
    );

    useResetOnValue(
        'neoAdjuvantTreatment.chemotherapy',
        false,
        useConstant('neoAdjuvantTreatment.chemotherapyType', ''),
        useConstant('neoAdjuvantTreatment.chemotherapyTypeOther', ''),
        useConstant('neoAdjuvantTreatment.chemotherapyStartDate', null),
        useConstant('neoAdjuvantTreatment.chemotherapyEndDate', null)
    );

    useResetOnValue(
        'neoAdjuvantTreatment.immunotherapy',
        false,
        useConstant('neoAdjuvantTreatment.immunotherapyType', ''),
        useConstant('neoAdjuvantTreatment.immunotherapyTypeOther', ''),
        useConstant('neoAdjuvantTreatment.immunotherapyStartDate', null),
        useConstant('neoAdjuvantTreatment.immunotherapyEndDate', null)
    );

    return (
        <MocSectionContainer code="neo-adjuvant-treatment" isValid={isValid}>
            <Grid container>
                <Grid item xs={12}>
                    <CheckboxField name="neoAdjuvantTreatment.radiotherapy" label="Radiotherapie" />
                    {isRadiotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FastSelectField<RadiotherapyType>
                                        name="neoAdjuvantTreatment.radiotherapyType"
                                        placeholder="Radiotherapie Type*"
                                        prompt="Selecteer radiotherapie type"
                                        referenceDataPath="radiotherapyTypes"
                                    />
                                    <FormikErrorMessage name="neoAdjuvantTreatment.radiotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField
                                        name="neoAdjuvantTreatment.radiotherapyStartDate"
                                        label="Start datum*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField name="neoAdjuvantTreatment.radiotherapyEndDate" label="Eind datum" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="neoAdjuvantTreatment.chemotherapy" label="Chemotherapie" />
                    {isChemotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FastSelectField<ChemotherapyType>
                                        name="neoAdjuvantTreatment.chemotherapyType"
                                        placeholder="Chemotherapie Type*"
                                        referenceDataFilter={(refValue) => refValue.isOther}
                                        prompt="Selecteer chemotherapie type"
                                        detail={({ isOther }) =>
                                            isOther && (
                                                <FastTextField
                                                    name="neoAdjuvantTreatment.chemotherapyTypeOther"
                                                    label="Ander type*"
                                                />
                                            )
                                        }
                                        referenceDataPath="chemotherapyTypes"
                                    />
                                    <FormikErrorMessage name="neoAdjuvantTreatment.chemotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField
                                        name="neoAdjuvantTreatment.chemotherapyStartDate"
                                        label="Start datum*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField name="neoAdjuvantTreatment.chemotherapyEndDate" label="Eind datum" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="neoAdjuvantTreatment.immunotherapy" label="Immuuntherapie" />
                    {isImmunotherapySelected && (
                        <Grid item xs={12} className={styles.detail_container}>
                            <div className={styles.detail_line}></div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <FastSelectField<ChemotherapyType>
                                        name="neoAdjuvantTreatment.immunotherapyType"
                                        placeholder="Immuuntherapie Type*"
                                        referenceDataFilter={(refValue) => refValue.isOther}
                                        prompt="Selecteer immuuntherapie type"
                                        detail={({ isOther }) =>
                                            isOther && (
                                                <FastTextField
                                                    name="neoAdjuvantTreatment.immunotherapyTypeOther"
                                                    label="Ander type*"
                                                />
                                            )
                                        }
                                        referenceDataPath="chemotherapyTypes"
                                    />
                                    <FormikErrorMessage name="neoAdjuvantTreatment.immunotherapyType" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField
                                        name="neoAdjuvantTreatment.immunotherapyStartDate"
                                        label="Start datum*"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FastDateField
                                        name="neoAdjuvantTreatment.immunotherapyEndDate"
                                        label="Eind datum"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="neoAdjuvantTreatment.cryotherapie" label="Cryotherapie" />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default NeoAdjuvantTreatment;
