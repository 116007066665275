import React, { PropsWithChildren } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    list: {
        paddingTop: 2,
    },
    item: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 10,
    },
    mainSubItem: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 20,
    },
    bulletPoint: {
        width: 10,
        fontSize: 12,
    },
    itemContent: {
        flex: 1,
        fontSize: 12,
        fontFamily: 'Helvetica',
    },
    subItem: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 30,
    },
    subItemColumns: {
        flexDirection: 'column',
        marginBottom: 2,
        paddingLeft: 30,
    },
});

export default function List({ children }: PropsWithChildren<{}>) {
    return <View style={styles.list}>{children}</View>;
}

export function Item({ children }: PropsWithChildren<{}>) {
    return children ? (
        <View style={styles.item}>
            <Text style={styles.itemContent}>• {children}</Text>
        </View>
    ) : null;
}
export function MainSubItem({ children }: PropsWithChildren<{}>) {
    return children ? (
        <View style={styles.mainSubItem}>
            <Text style={styles.itemContent}>• {children}</Text>
        </View>
    ) : null;
}
export function SubItem({ children }: PropsWithChildren<{}>) {
    return children ? (
        <View style={styles.subItem}>
            <Text style={styles.itemContent}>• {children}</Text>
        </View>
    ) : null;
}
