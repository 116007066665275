import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { Values } from '../../forms/MocForm.config';
import { styles } from './styles';
import Footer from './sections/footer';
import { Patients } from '../../../../../api/types';
import { ReferenceDataMapper } from '.';
import MocHeader from './sections/MocHeader';
import MocGeneralInformation from './sections/mocGeneralInformation/MocGeneralInformation';
import Investigations from './sections/investigations/Investigations';
import Discussion from './sections/discussion/Discussion';
import MocClosing from './sections/mocClosing/MocClosing';
type Patient = Patients.GetPatient.PatientDetail;

export default function netMoc(patient: Patient, values: Values, mapReferenceData: ReferenceDataMapper) {
    return (
        <Document>
            <Page style={styles.body}>
                <MocHeader patient={patient} mocTypeCode={values.mocTypeCode} mocDate={values.mocDate} />
                <MocGeneralInformation
                    reportValues={values.report}
                    mocDate={values.mocDate}
                    mocType={values.mocTypeCode}
                    mapReferenceData={mapReferenceData}
                />
                <Investigations
                    imagingValues={values.imaging}
                    pathologyValues={values.pathology}
                    clincalBiology={values.clinicalBiology}
                    mapReferenceData={mapReferenceData}
                />
                <Discussion
                    report={values.report}
                    imaging={values.imaging}
                    pathology={values.pathology}
                    treatmentPlan={values.treatmentPlan}
                    mocType={values.mocTypeCode}
                    mapReferenceData={mapReferenceData}
                />
                <MocClosing />
                <Footer />
            </Page>
        </Document>
    );
}
