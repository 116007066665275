import React, { useEffect, useState } from 'react';
import { Patients } from '../../../../api/types';
import useData, { ResponseDataToData } from '../../../../api/useData';
import { RouteComponentProps, useHistory } from 'react-router';
import useApi from '../../../../api/useApi';
import { convertDateToWorkWithDatePicker } from '../form/PatientForm.config';
import {
    importValuesToPostRequest,
    ImportValues,
    ManualValues,
    mapToImportValues,
    Fund,
    Country,
    manualValuesToPostRequest,
} from './EditPatient.config';
import { Backdrop, CircularProgress, Typography, Button, Grid } from '@material-ui/core';
import styles from './EditPatientPage.module.scss';
import ErrorSummary from '../../../../styles/components/ErrorSummary';
import SearchIcon from '@material-ui/icons/Search';
import { SearchValues } from '../form/ImportPatient.config';
import EditPatientFromImport from './import/EditPatientFormImport';
import EditPatientFormManual from './manual/EditPatientFormManual';
import ReadOnlyPatientData from './readonly/ReadOnlyPatientData';
import PatientDetailBackButton from '../detail/backbutton/PatientDetailBackButton';
import useHospital from '../../../../hospital/useHospital';

interface RouteInfo {
    id: string;
}

const responseMapper: ResponseDataToData<Patients.GetPatient.PatientDetail> = (d) => d.patient;
const fundsResponseMapper: ResponseDataToData<Fund[]> = (d) => d.healthInsuranceFunds;
const countriesResponseMapper: ResponseDataToData<Country[]> = (d) => d.countries;

const EditPatientPage: React.FC<RouteComponentProps<RouteInfo>> = ({ match }) => {
    const api = useApi();
    const history = useHistory();
    const { hospital } = useHospital();
    const [manualValues, setManualValues] = useState<ManualValues | null>(null);
    const [getPatient, patientResponse] = useData('patients/' + match.params.id, responseMapper);
    const [importValues, setImportValues] = useState<ImportValues | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [importError, setImportError] = useState<string | null>(null);
    const [countries, setCountries] = useState<Country[] | null>(null);
    const [getCountries, countriesResponse] = useData('countries', countriesResponseMapper);
    const [isImporting, setIsImporting] = useState<boolean>(false);
    const [hasImported, setHasImported] = useState<boolean>(false);
    const [allowManualEdit, setAllowManualEdit] = useState<boolean>(false);
    const [funds, setFunds] = useState<Fund[] | null>(null);
    const [getFunds, fundsResponse] = useData('healthinsurancefunds', fundsResponseMapper);

    useEffect(() => {
        getFunds();
    }, [getFunds]);

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    useEffect(() => {
        fundsResponse.hasLoaded && !fundsResponse.isError && setFunds(fundsResponse.data);
    }, [fundsResponse]);

    useEffect(() => {
        countriesResponse.hasLoaded && !countriesResponse.isError && setCountries(countriesResponse.data);
    }, [countriesResponse]);

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    useEffect(() => {
        const { hasLoaded, data, isError } = patientResponse;
        if (isError) {
            setError('Could not connect to server');
            return;
        }

        if (hasLoaded) {
            const { dateOfBirth, dateOfDeath, ...patient } = data;
            setManualValues({
                ...patient,
                mail: patient.mail ?? '',
                telNr: patient.telNr ?? '',
                homeDoctorName: patient.homeDoctorName || '',
                homeDoctorContactInfo: patient.homeDoctorContactInfo || '',
                attendingDoctorName: patient.attendingDoctorName || '',
                attendingDoctorContactInfo: patient.attendingDoctorContactInfo || '',
                dateOfBirth: convertDateToWorkWithDatePicker(dateOfBirth) as string,
                dateOfDeath: convertDateToWorkWithDatePicker(dateOfDeath),
            });
        }
    }, [patientResponse]);

    function navigateToDetail() {
        const patientId = Number(match.params.id);
        history.push(`/${hospital!.slug}/patients/${patientId}`);
    }

    const handleSubmit = async (values: ImportValues) => {
        await api.put('patients/zisimported', importValuesToPostRequest(values));
        navigateToDetail();
    };
    const handleManualEditSubmit = async (submitValues: ManualValues) => {
        await api.put('patients/manual', manualValuesToPostRequest(submitValues));
        navigateToDetail();
    };
    const handleBackToPatientDetail = () => {
        navigateToDetail();
    };

    async function handleImport(values: SearchValues) {
        setIsImporting(true);
        try {
            const { status, data } = await api.post<Patients.ImportPatientInfo.Response>('patients/import', {
                ...values,
            });
            if (status < 400) {
                setImportValues(mapToImportValues(data, manualValues!, Number(match.params.id)));
            } else if (status === 404) {
                setImportError("The patient was not found. Please register this patient's information with UZA (ZIS).");
            } else if (status > 400) {
                setImportError(
                    'Something went wrong while trying to import patient from ZIS. Please contact your UZA contact person.'
                );
            }
        } finally {
            setHasImported(true);
            setIsImporting(false);
            return;
        }
    }

    if (error !== null) {
        return (
            <div>
                <ErrorSummary errors={[error]} />
            </div>
        );
    }

    if (manualValues === null) {
        return (
            <Backdrop open={true} className={styles.backdrop}>
                <div className={styles.backdrop_content}>
                    <CircularProgress color="inherit" />
                    <Typography className={styles.backdrop_text}>Loading patient details...</Typography>
                </div>
            </Backdrop>
        );
    }

    return (
        <>
            <h1>Edit Patient Information</h1>

            {importError && <ErrorSummary errors={[importError]} />}
            <Grid container spacing={3}>
                {!importError && !hasImported && !allowManualEdit && (
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            startIcon={<SearchIcon />}
                            disabled={isImporting}
                            className={styles.toEditFormButton}
                            onClick={() => handleImport({ uzaNr: patientResponse.data.uzaNr })}
                        >
                            {(patientResponse.data.zisImport === null ||
                                patientResponse.data.zisImport === undefined) &&
                                `Import from ZIS`}
                            {patientResponse.data.zisImport !== null &&
                                patientResponse.data.zisImport !== undefined &&
                                `Check ZIS for updates`}
                        </Button>
                    </Grid>
                )}

                {isImporting && (
                    <Grid item xs={2}>
                        <CircularProgress size="1.4rem" style={{ marginLeft: '1rem' }} />
                    </Grid>
                )}
                <Grid item xs={2}>
                    {!allowManualEdit && !importValues && !isImporting && (
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            className={styles.toEditFormButton}
                            onClick={() => setAllowManualEdit(true)}
                        >
                            Edit manually
                        </Button>
                    )}
                </Grid>
            </Grid>

            {!importValues && !allowManualEdit && (
                <ReadOnlyPatientData readonlyValues={manualValues} countries={countries} funds={funds} />
            )}

            {importValues === null && manualValues && allowManualEdit && (
                <EditPatientFormManual
                    initialValues={manualValues}
                    funds={funds}
                    countries={countries}
                    onSubmit={handleManualEditSubmit}
                    onClose={handleBackToPatientDetail}
                    hospitalName={hospital!.name}
                />
            )}
            {importValues && (
                <EditPatientFromImport
                    manualValues={manualValues}
                    importValues={importValues}
                    onSubmit={handleSubmit}
                    countries={countries}
                    funds={funds}
                    onClose={handleBackToPatientDetail}
                />
            )}
            {!importValues && !allowManualEdit && (
                <div style={{ paddingTop: '1rem' }}>
                    <PatientDetailBackButton isContained={true} onGoToPatientDetail={handleBackToPatientDetail} />
                </div>
            )}
        </>
    );
};

export default EditPatientPage;
