import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { parse, stringify } from 'qs';
import { Grid, Paper } from '@material-ui/core';
import useApi from '../../../../api/useApi';
import { Identity } from '../../../../api/types';
import OncoBaseSnackbar, { useSnackBar } from '../../../../styles/components/OncoBaseSnackbar';
import WelcomeContent from '../../WelcomeContent';
import styles from './ForgotPasswordPage.module.scss';
import PasswordResetForm from './PasswordResetForm';
import ForgotPasswordInvalid from './ForgotPasswordInvalid';

type ForgotDataResponse = Identity.GetForgotData.Response;
type Search = {
    id?: string;
    token?: string;
};

function parseSearch(search: string): Search {
    return parse(search, { ignoreQueryPrefix: true });
}

const ForgotPasswordPage: React.FC<RouteComponentProps> = ({ location, history }) => {
    const { id, token } = parseSearch(location.search);
    const [isValid, setIsValid] = useState<boolean | null>(null);
    const [showSnack, snackBarProps] = useSnackBar();
    const api = useApi();

    useEffect(() => {
        if (!id || !token) {
            return;
        }
        (async () => {
            const {
                status,
                data: { isValid }
            } = await api.get<ForgotDataResponse>(`account/forgot?${stringify({ id, token })}`);
            if (status < 400 && isValid) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        })();
    }, [api, id, token, location.search]);

    if (!id || !token) {
        history.replace('/');
        return null;
    }

    const handlePasswordChanged = () => {
        history.push('/');
        window.location.reload();
    };

    return (
        <Grid container component="main" className={styles.main}>
            <Grid item xs={false} sm={6} md={7} lg={8} xl={9}>
                <WelcomeContent />
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={4} xl={3} className={styles.signin_griditem}>
                {isValid !== null && (
                    <Paper elevation={4} square className={styles.signin_paper}>
                        <div className={styles.form_wrapper}>
                            {isValid === true && (
                                <PasswordResetForm
                                    id={parseInt(id)}
                                    token={token}
                                    onPasswordChanged={handlePasswordChanged}
                                    showSnack={showSnack}
                                    api={api}
                                />
                            )}
                            {isValid === false && <ForgotPasswordInvalid />}
                        </div>
                    </Paper>
                )}
            </Grid>
            <OncoBaseSnackbar {...snackBarProps} />
        </Grid>
    );
};

export default ForgotPasswordPage;
