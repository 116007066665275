import React from 'react';
import FormikCheckbox from '../../../../../styles/components/forms/FormikCheckbox';
import { Field } from 'formik';
import { FormControlLabel } from '@material-ui/core';

type Props = {
    name: string;
    label?: string;
};

export default function CheckboxField({ name, label }: Props) {
    return <FormControlLabel control={<Field name={name} type='checkbox' component={FormikCheckbox as any} />} label={label} />;
}
