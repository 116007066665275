import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import Section from '../../../../../styles/components/forms/Section';
import { Gender } from '../../../../../api/enums';

type PatientPersonalInformationSectionProps = {
    firstName: string;
    surname: string;
    dateOfBirth: string | Date;
    dateOfDeath: string | Date | null;
    gender: number;
};
export default function PatientPersonalInformationSection(props: PatientPersonalInformationSectionProps) {
    return (
        <Section name="Personal Information">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label="Firstname"
                        value={props.firstName}
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="Surname" value={props.surname} variant="outlined" disabled fullWidth multiline />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Date of birth"
                        value={props.dateOfBirth}
                        variant="outlined"
                        disabled
                        multiline
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Date of death"
                        value={props.dateOfDeath ?? ''}
                        variant="outlined"
                        disabled
                        multiline
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Gender"
                        value={Gender[props.gender]}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Section>
    );
}
