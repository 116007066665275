import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '../../styles';
import * as generalRemarks from '../../../../forms/sections/generalRemarks/GeneralRemarks.config';

export default function GeneralRemarks(props: generalRemarks.FormValues) {
    return props.remarks !== '' ? (
        <View>
            <Text style={styles.header}>General Remarks</Text>
            <Text style={styles.text}>{props.remarks}</Text>
        </View>
    ) : null;
}
