import { Values } from '../forms/MocForm.config';
import { handle } from './handling';
import useReferenceData from '../referenceData/useReferenceData';
import { Patients } from '../../../../api/types';

type Patient = Patients.GetPatient.PatientDetail;

export { canHandle } from './handling';

export function useMocPdfRenderer() {
    const referenceData = useReferenceData();
    return (patient: Patient, values: Values) => handle(patient, values, referenceData);
}
