import React from 'react';
import { Patients } from '../../../../../../api/types';
import { PatientInfo } from '../../ImportPatientDetail.config';
import { Grid, MenuItem, TextField, FormHelperText } from '@material-ui/core';
import { Field } from 'formik';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';
import styles from './ImportSections.module.css';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
type Country = Patients.Country;
type Props = {
    countries: Country[] | null;
    patientInfo: PatientInfo;
};

export const Address: React.FC<Props> = ({ countries, patientInfo }) => {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={10}>
                    <TextField
                        label="Address"
                        defaultValue={patientInfo.address}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    <TextField
                        label="Zip"
                        defaultValue={patientInfo.postalCode}
                        placeholder=" "
                        variant="outlined"
                        disabled
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={9} md={7}>
                    <TextField
                        label="City"
                        defaultValue={patientInfo.city}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={6} md={5}>
                    {(patientInfo.countryId !== 0 || !(patientInfo.countryAttemptedValue === '')) && (
                        <>
                            <Field
                                as="select"
                                name="countryId"
                                component={FormikSelectField}
                                placeholder="Country"
                                disabled={patientInfo.countryId !== 0}
                            >
                                <MenuItem key="empty-country" value={0} disabled>
                                    <i>{countries ? 'Select a country' : 'Loading ...'}</i>
                                </MenuItem>
                                {countries &&
                                    countries.map((country) => (
                                        <MenuItem key={country.id} value={country.id}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                            </Field>
                            {patientInfo.countryId === 0 && !(patientInfo.countryAttemptedValue === '') && (
                                <FormHelperText className={styles.formHelperText}>
                                    Please select the country matching with {patientInfo.countryAttemptedValue}
                                </FormHelperText>
                            )}
                        </>
                    )}
                    {patientInfo.countryId === 0 && patientInfo.countryAttemptedValue === '' && (
                        <TextField
                            label="Country"
                            defaultValue={patientInfo.countryAttemptedValue}
                            variant="outlined"
                            disabled
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={6} md={5}>
                    <Field name="mail" type="text" component={FormikTextField} label="Email" disabled />
                </Grid>
                <Grid item xs={6} md={5}>
                    <Field name="tel" type="text" component={FormikTextField} label="Phone" disabled />
                </Grid>
            </Grid>
        </>
    );
};
