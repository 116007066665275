import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import Section from '../../../../../styles/components/forms/Section';

type PatientIdentificationSectionProps = {
    oncobasePatientNr: number;
    uzaNr: string;
    ssn: string;
    localPatientNr: string;
};

export default function patientIdentificationSection(props: PatientIdentificationSectionProps) {
    return (
        <Section name="Identification">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label="Patient ID"
                        value={props.oncobasePatientNr ?? ''}
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField label="SSN" value={props.ssn ?? ''} variant="outlined" fullWidth multiline disabled />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Uza nr"
                        value={props.uzaNr ?? ''}
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Local patient number"
                        value={props.localPatientNr ?? ''}
                        variant="outlined"
                        fullWidth
                        multiline
                        disabled
                    />
                </Grid>
            </Grid>
        </Section>
    );
}
