import React from 'react';
import { Button, Tooltip, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import ConfirmIcon from '@material-ui/icons/AccountBox';
import PasswordIcon from '@material-ui/icons/VpnKey';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Delete';
import styles from './UserDetail.module.scss';

type User = { id: number; isConfirmed: boolean };

type Props = {
    user: User;
    isEditing: boolean;
    isDisabled: boolean;
    isSaving: boolean;
    isRemoving: boolean;
    isLinking: boolean;
    onCreateNewLink: (userId: number) => void;
    onCreateForgotLink: (userId: number) => void;
    onEdit: (userId: number) => void;
    onDelete: (userId: number) => void;
    onSave: (userId: number) => void;
    onCancel: () => void;
    isCurrentUser: boolean;
};

const withTooltip = (tooltip: string, toolTipped: React.ReactNode) => (
    <Tooltip title={tooltip}>
        <span>{toolTipped}</span>
    </Tooltip>
);

const UserDetail: React.FC<Props> = ({
    user,
    isEditing,
    isDisabled,
    isSaving,
    isRemoving,
    isLinking,
    onCreateNewLink,
    onCreateForgotLink,
    onEdit,
    onDelete,
    onSave,
    onCancel,
    isCurrentUser
}) => (
    <div className={styles.container}>
        {isEditing ? (
            <>
                <Button variant="outlined" color="primary" onClick={() => onSave(user.id)} disabled={isDisabled}>
                    {isSaving ? (
                        <CircularProgress className={styles.icon} size={24} />
                    ) : (
                        <SaveIcon className={styles.icon} />
                    )}{' '}
                    Save
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => onCancel()} disabled={isDisabled}>
                    <CancelIcon className={styles.icon} /> Cancel
                </Button>
            </>
        ) : (
            <>
                {isCurrentUser ? (
                    <>
                        {withTooltip(
                            'You cannot edit your own account',
                            <Button variant="outlined" color="primary" disabled={true}>
                                <EditIcon className={styles.icon} /> Edit
                            </Button>
                        )}
                        {withTooltip(
                            'You cannot remove your own account',
                            <Button variant="outlined" color="secondary" disabled={true}>
                                <RemoveIcon className={styles.icon} /> Remove
                            </Button>
                        )}
                    </>
                ) : (
                    <>
                        {user.isConfirmed ? (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => onCreateForgotLink(user.id)}
                                disabled={isDisabled}
                            >
                                {isLinking ? (
                                    <CircularProgress className={styles.icon} size={24} />
                                ) : (
                                    <PasswordIcon className={styles.icon} />
                                )}{' '}
                                Create password link
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => onCreateNewLink(user.id)}
                                disabled={isDisabled}
                            >
                                {isLinking ? (
                                    <CircularProgress className={styles.icon} size={24} />
                                ) : (
                                    <ConfirmIcon className={styles.icon} />
                                )}{' '}
                                Create activation link
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => onEdit(user.id)}
                            disabled={isDisabled}
                        >
                            <EditIcon className={styles.icon} /> Edit
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => onDelete(user.id)}
                            disabled={isDisabled}
                        >
                            {isRemoving ? (
                                <CircularProgress className={styles.icon} size={24} />
                            ) : (
                                <RemoveIcon className={styles.icon} />
                            )}{' '}
                            Remove
                        </Button>
                    </>
                )}
            </>
        )}
    </div>
);

export default UserDetail;
