import React from 'react';
import ItemList from '../components/ItemList';
import { PrimaryTumor } from '../sections/report/Report.config';
import { FastSelectField } from '../components';
import { Grid } from '@material-ui/core';

function detail(name: string) {
    return (
        <>
            <Grid item xs={12}>
                <FastSelectField
                    name={`${name}.primaryTumorCode`}
                    prompt="Selecteer primaire tumor"
                    placeholder="Primaire tumor"
                    referenceDataPath="primaryTumors"
                />
            </Grid>
            <Grid item xs={12}>
                <FastSelectField
                    name={`${name}.primaryTumorHistologyCode`}
                    prompt="Selecteer histologie primary tumor"
                    placeholder="Histologie primary tumor"
                    referenceDataPath="primaryTumorHistologies"
                />
            </Grid>
        </>
    );
}

function create(): PrimaryTumor {
    return {
        primaryTumorCode: '',
        primaryTumorHistologyCode: '',
    };
}

type PrimaryTumorListProps = {
    name: string;
};

export default function PrimaryTumorList({ name }: PrimaryTumorListProps) {
    return (
        <ItemList
            name={`${name}.primaryTumors`}
            minItems={1}
            maxItems={3}
            addText="Extra primaire tumor toevoegen"
            title="Primaire tumor"
            detail={detail}
            create={create}
        />
    );
}
