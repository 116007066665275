import api from '../api';
import { Configuration } from './Configuration';

const configJsonUrl = `${process.env.PUBLIC_URL}/config.json`;

export const getConfig = async () => {
    const response = await api.get(configJsonUrl);
    const config = response.data as Configuration;

    // Allow local environment overwrites
    config.api.baseUrl = process.env.REACT_APP_CONFIG_API_BASE_URL || config.api.baseUrl || '';

    return config;
};
