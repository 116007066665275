import React, { Fragment } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../../styles';
import * as surgery from '../../../../../forms/sections/surgery/ckzOesophagus/Surgery.config';
import {
    QuestionYesNoAnswer,
    QuestionAnswer,
    QuestionListAnswer,
    QuestionListCodeAndOtherValueAnswer,
    QuestionDateAnswer,
    formatDateWithPrefix,
    QuestionProps,
} from '../../Questions';
import List, { Item, SubItem } from '../../List';
import { ReferenceDataMapper } from '../../..';
import { CodeAndOtherValue } from '../../../../../forms/MocForm.config';

type SurgeryProps = {
    values: surgery.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function Surgery({ values, mapReferenceData }: SurgeryProps) {
    const openSurgeryTypes = values.openSurgeryTypes.map((type) => mapReferenceData('openSurgeryTypes', type.code));
    const lymphadenectomyRegions = values.lymphadenectomyRegions.map((region) =>
        mapReferenceData('lymphadenectomyRegions', region.code)
    );
    const resectionTypes: CodeAndOtherValue[] = values.resectionTypes.map((resectiontype) => ({
        code: mapReferenceData('resectionTypes', resectiontype.code),
        otherValue: resectiontype.otherValue,
    }));
    return (
        <View>
            <Text style={styles.header}>Surgery</Text>

            <QuestionYesNoAnswer
                question="PET/CT performed prior to surgery"
                answer={values.isPETPerformedPriorToSurgery}
            />
            <QuestionYesNoAnswer
                question="Did the patient receive any other treatment modality before this surgical procedure"
                answer={values.hadOtherTreatmentBeforeThisSurgery}
            />

            <QuestionListPostoperativeComplicationsAnswer
                question={'Other treatment modalities:'}
                answer={values.treatmentTypes}
                mapper={mapReferenceData}
            />
            <QuestionDateAnswer question={'Date of surgery'} answer={values.dateOfSurgery} />
            <QuestionAnswer
                question={'Tumour location'}
                answer={mapReferenceData('tumourLocations', values.tumourLocationCode)}
            />
            <QuestionAnswer
                question={'Surgery intention'}
                answer={mapReferenceData('surgeryIntentions', values.surgeryIntentionCode)}
            />

            <QuestionAnswer
                question={'Type of surgery'}
                answer={mapReferenceData('surgeryTypes', values.surgeryTypeCode)}
            />

            <QuestionAnswer
                question={'Type of  Minimally Invasive Surgery'}
                answer={mapReferenceData('minimallyInvasiveSurgeryTypes', values.minimallyInvasiveSurgeryTypeCode)}
            />
            <QuestionListAnswer question={'Type of Open'} answer={openSurgeryTypes} />
            <QuestionAnswer question={'Reason for conversion'} answer={values.reasonForConversion} />

            <QuestionAnswer
                question={'Nomenclature code'}
                answer={mapReferenceData('nomenclatureCodes', values.nomenclatureCode)}
            />
            <QuestionAnswer
                question={'Oesophagectomy'}
                answer={mapReferenceData('oesophagectomies', values.oesophagectomyCode)}
            />
            <QuestionYesNoAnswer
                question={'Was a macroscopic R0-resection performed'}
                answer={values.wasResectionPerformedSurgical}
            />
            <QuestionYesNoAnswer
                question={'Was a microscopic R0-resection performed'}
                answer={values.wasResectionPerformedPathological}
            />
            <QuestionYesNoAnswer
                question={'Was the proximal margin involved'}
                answer={values.wasProximalMarginInvolved}
            />
            <QuestionYesNoAnswer question={'Was there perineural invasion'} answer={values.wasPerineuralInvasion} />
            <QuestionAnswer
                question={'Mandard grade'}
                answer={mapReferenceData('mandardGrades', values.mandardGradeCode)}
            />
            <QuestionAnswer
                question={'Gastrectomy'}
                answer={mapReferenceData('gastrectomies', values.gastrectomyCode)}
            />
            <QuestionYesNoAnswer question={'Lymphadenectomy'} answer={values.hadLymphadenectomy} />
            <QuestionAnswer
                question={'Field lymphadenectomy'}
                answer={mapReferenceData('fields', values.lymphadenectomyFieldCode)}
            />
            <QuestionListAnswer question={'Region lymphadenectomy '} answer={lymphadenectomyRegions} />
            <QuestionAnswer
                question={'Number of loco-regional lymph nodes retrieved'}
                answer={values.numberOfLocoRegionalLymphNodesRetrieved}
            />
            <QuestionAnswer
                question={'Number of metastatic loco-regional lymph nodes'}
                answer={values.numberOfMetastaticLocoRegionalLymphNodes}
            />
            <QuestionYesNoAnswer question={'Other resections'} answer={values.hadOtherResections} />
            <QuestionListCodeAndOtherValueAnswer question={'Type of other resections'} answer={resectionTypes} />
            <QuestionAnswer
                question={'Oesophageal conduit'}
                answer={mapReferenceData('oesophagealConduits', values.oesophagealConduitCode)}
            />
            <QuestionAnswer question={'Anastomosis'} answer={mapReferenceData('anastomoses', values.anastomosisCode)} />
            <QuestionAnswer question={'Anastomosis details'} answer={values.anastomosisOtherValue} />
        </View>
    );
}

type QuestionListTreatmentDetailsProps = QuestionProps & {
    answer: surgery.TreatmentDetails[];
    mapper: ReferenceDataMapper;
};
function QuestionListPostoperativeComplicationsAnswer(props: QuestionListTreatmentDetailsProps) {
    return props.answer && props.answer.length !== 0 ? (
        <>
            <Text style={styles.text}>{props.question}</Text>
            {props.answer.map((treatmentType, keyOuterMap) => {
                const surgeryHasDetails =
                    treatmentType.typeOfSurgery ||
                    treatmentType.otherValue ||
                    treatmentType.startDate ||
                    treatmentType.latestSurgeryDate ||
                    treatmentType.latestTreatmentDate ||
                    treatmentType.otherDate;
                return (
                    <Fragment key={keyOuterMap}>
                        <Item>{props.mapper('treatmentTypes', treatmentType.code)}</Item>
                        <List>
                            {treatmentType.endoscopicTreatmentTypes.map((e, keyInnerMap) => (
                                <Fragment key={keyInnerMap}>
                                    <SubItem>
                                        {e.otherValue !== ''
                                            ? `${props.mapper('endoscopicTreatmentTypes', e.code)} - ${e.otherValue}`
                                            : `${props.mapper('endoscopicTreatmentTypes', e.code)}`}
                                    </SubItem>
                                    <SubItem>
                                        {formatDateWithPrefix(' finished on', treatmentType.latestTreatmentDate)}
                                    </SubItem>
                                </Fragment>
                            ))}
                        </List>
                        {surgeryHasDetails ? (
                            <Fragment>
                                <SubItem>
                                    {treatmentType.typeOfSurgery !== '' && treatmentType.otherValue !== ''
                                        ? `${treatmentType.typeOfSurgery} - ${treatmentType.otherValue}`
                                        : `${treatmentType.typeOfSurgery}${treatmentType.otherValue}`}
                                </SubItem>
                                <SubItem>{formatDateWithPrefix('Started on', treatmentType.startDate)}</SubItem>
                                <SubItem>
                                    {formatDateWithPrefix('Latest surgery on', treatmentType.latestSurgeryDate)}
                                </SubItem>
                                <SubItem>
                                    {formatDateWithPrefix('Latest treatment on', treatmentType.latestTreatmentDate)}
                                </SubItem>
                                <SubItem>{formatDateWithPrefix('Latest on', treatmentType.otherDate)}</SubItem>
                            </Fragment>
                        ) : null}
                    </Fragment>
                );
            })}
        </>
    ) : null;
}
