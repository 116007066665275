import React from 'react';
import { Field } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import { PatientInfo } from '../../ImportPatientDetail.config';

type Props = {
    uzaNr?: string;
    patientInfo?: PatientInfo;
};

const Identification: React.FC<Props> = ({ uzaNr, patientInfo }) => (
    <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
            {!uzaNr && (
                <Field
                    name="uzaNr"
                    type="text"
                    component={FormikTextField}
                    label="UZA patientnumber"
                    inputProps={{ maxLength: 9 }}
                    disabled
                />
            )}

            {uzaNr && <TextField variant="outlined" label="UZA patientnumber" value={uzaNr} disabled fullWidth />}
        </Grid>
        <Grid item xs={12} md={12}>
            <TextField label="SSN" defaultValue={patientInfo?.ssn} variant="outlined" disabled fullWidth />
        </Grid>
    </Grid>
);
export default Identification;
