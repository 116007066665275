import React, { PropsWithChildren } from 'react';
import { Draggable } from 'react-beautiful-dnd';

type DraggableItemProps = PropsWithChildren<{
    draggableId: string;
    index: number;
}>;

export default function DraggableItem(props: DraggableItemProps) {
    return (
        <Draggable draggableId={props.draggableId} index={props.index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                >
                    {props.children}
                </div>
            )}
        </Draggable>
    );
}
