import { TextFieldProps } from '@material-ui/core/TextField';
import { useState, useCallback, useEffect } from 'react';

type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;
type ChangeHandler = (event: ChangeEvent) => void;

export default function useDebouncedOnChange(originalProps: TextFieldProps): TextFieldProps {
    const { value, onChange, ...restProps } = originalProps;
    const [debouncedValue, setDebouncedValue] = useState(value as string);

    const debouncedOnChange = useCallback(
        (e: ChangeEvent) => {
            setDebouncedValue(e.currentTarget.value);
        },
        [setDebouncedValue]
    );

    useEffect(() => {
        setDebouncedValue(value as string);
    }, [value]);

    useEffect(() => {
        if (debouncedValue === value) {
            return;
        }

        const onChangeTrigger = setTimeout(() => triggerOnChange(debouncedValue, originalProps, onChange), 200);
        return () => clearTimeout(onChangeTrigger);
    }, [debouncedValue, value, onChange, originalProps]);

    return { value: debouncedValue, onChange: debouncedOnChange, ...restProps };
}

function triggerOnChange(value: string, { name }: TextFieldProps, onChange?: ChangeHandler) {
    const target = { value, name };
    const event = { target, currentTarget: target } as ChangeEvent;
    onChange && onChange(event);
}
