import React from 'react';
import { Menu, Hidden } from '@material-ui/core';
import OncoBaseMenuItem from './OncoBaseMenuItem';
import styles from './OncoBaseMenu.module.css';

type Props = {
    title: string;
    initial?: string;
    children: (onClose: () => void) => React.ReactNode;
};

const OncoBaseMenu: React.FC<Props> = props => {
    const [menuElement, setMenuElement] = React.useState<null | HTMLElement>(null);
    const openMenu = (e: React.MouseEvent<HTMLElement>) => setMenuElement(e.currentTarget);
    const closeMenu = () => setMenuElement(null);
    const initial = props.initial || props.title.length > 0 ? props.title[0] : '';
    const initialRest = props.title.substr(initial.length, props.title.length - initial.length);

    return (
        <div className={styles.group}>
            <OncoBaseMenuItem onClick={openMenu}>
                <span className={styles.title}>
                    {initial}
                    <Hidden xsDown>{initialRest}</Hidden>
                </span>
            </OncoBaseMenuItem>
            <Menu
                variant="menu"
                anchorEl={menuElement}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                getContentAnchorEl={null}
                keepMounted
                open={Boolean(menuElement)}
                onClose={closeMenu}
            >
                {props.children(closeMenu)}
            </Menu>
        </div>
    );
};

export default OncoBaseMenu;
