import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import useAuth from '../auth/useAuth';
import { Hospital, Role } from '../auth/AuthContext';
import HospitalProvider from '../hospital/HospitalProvider';
import Header from '../header/Header';
import ProfilePage from './home/account/ProfilePage';
import HospitalPage from './hospital/HospitalPage';
import MocsPage from './mocs/MocsPage';
import ExportPage from './export/ExportPage';
import ReportPage from './report/ReportPage';

function isHospitalRole(role: Role): role is Role & { hospital: Hospital } {
    return role.isHospitalDependent && !!role.hospital;
}

function getDefaultRoute(hospitals: Hospital[]) {
    return hospitals.length ? `/${hospitals[0].slug}` : '/profile';
}

const AuthenticatedApp: React.FC = () => {
    const { user } = useAuth();
    const hospitals = user!.roles.filter(isHospitalRole).map((r) => r.hospital);
    const defaultRoute = getDefaultRoute(hospitals);

    return (
        <HospitalProvider hospitals={hospitals}>
            <Header />
            <Switch>
                <Redirect strict from="/account/confirm" to="/profile" />
                <Redirect strict from="/account/forgot" to="/profile" />
                <Route path="/profile" exact component={ProfilePage} />
                <Route path="/mocs" exact component={MocsPage} />
                <Route path="/export" exact component={ExportPage} />
                <Route path="/report" exact component={ReportPage} />
                <Route path="/:hospital_slug" component={HospitalPage} />
                <Redirect to={defaultRoute} />
            </Switch>
        </HospitalProvider>
    );
};

export default AuthenticatedApp;
