import React, { Fragment } from 'react';
import cx from 'classnames';
import { getIn, useFormikContext } from 'formik';
import { Grid, FormControlLabel, Radio } from '@material-ui/core';
import { FormikRadioGroup } from '../../../../../styles/components/forms/FormikRadioGroup';
import { Mocs } from '../../../../../api/types';
import useReferenceData from '../../referenceData/useReferenceData';
import { FastField } from '../Fast';
import useFieldValue from '../sections/useFieldValue';
import styles from './RadioButtons.module.scss';

const alwaysTrue = () => true;

type ReferenceData = Mocs.GetReferenceData.Response;
type ReferenceObject = { id: number; code?: string; name: string };

type Props<TReferenceItem extends ReferenceObject> = {
    name: string;
    referenceDataPath: keyof ReferenceData;
    referenceDataFilter?: (reference: TReferenceItem) => boolean;
    detail?: (reference: TReferenceItem) => React.ReactNode;
    activeLine?: boolean;
    activeCode?: RegExp;
};

function RadioButtons<TReferenceItem extends ReferenceObject>({
    name,
    referenceDataPath,
    referenceDataFilter = alwaysTrue,
    detail,
    activeLine,
    activeCode = /$^/
}: Props<TReferenceItem>) {
    const referenceData = useReferenceData();
    const referenceDataItems = (getIn(referenceData, referenceDataPath) as TReferenceItem[]).filter(
        referenceDataFilter
    );
    const value = useFieldValue<string>(name);
    const { isSubmitting } = useFormikContext();
    const code = referenceDataItems.filter(item => item.code === value).map(item => item.code)[0];
    const isActive = code && activeCode.test(code);
    return (
        <FastField name={name} component={FormikRadioGroup} disabled={isSubmitting}>
            <div className={cx(styles.content, { [styles.active]: isActive })}>
                {activeLine && <div className={styles.line}></div>}
                <Grid container className={styles.buttons_container}>
                    {referenceDataItems.map(reference => {
                        const thisSelected = value === reference.code;
                        const valueDetail = detail && thisSelected && detail(reference);
                        return (
                            <Fragment key={reference.code}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        key={reference.code}
                                        value={reference.code}
                                        control={<Radio color="primary" />}
                                        disabled={isSubmitting}
                                        label={reference.name}
                                    />
                                </Grid>
                                {valueDetail && (
                                    <Grid item xs={12} className={styles.detail_container}>
                                        <div className={styles.detail_line}></div>
                                        {valueDetail}
                                    </Grid>
                                )}
                            </Fragment>
                        );
                    })}
                </Grid>
            </div>
        </FastField>
    );
}

export default RadioButtons;
