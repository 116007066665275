import React from 'react';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import { Paper, CircularProgress, Grid, Button } from '@material-ui/core';
import Section from '../../../../styles/components/forms/Section';
import { validateSearchForm } from './ImportPatient.config';
import { SearchValues } from './ImportPatient.config';
import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { Patients } from '../../../../api/types';
import useApi from '../../../../api/useApi';
import ErrorSummary from '../../../../styles/components/ErrorSummary';
import FormikTextField from '../../../../styles/components/forms/FormikTextField';
import SearchIcon from '@material-ui/icons/Search';

const getPatientNrs = (api: AxiosInstance) =>
    api.get<Patients.GetPatientNumbers.Response>('patients/numbers').then((r) => r.data.patientNumbers);

type Props = {
    initialValues: SearchValues;
    onSubmit: (values: SearchValues, formikHelpers: FormikHelpers<SearchValues>) => void;
    importErrors: string;
};

export default function ImportPatientForm({ initialValues, onSubmit, importErrors }: Props) {
    const api = useApi();
    const patientNrs = useQuery('patients/numbers', () => getPatientNrs(api), { refetchOnWindowFocus: false });

    if (patientNrs.isLoading) {
        return <CircularProgress />;
    }

    const errors: string[] = [];
    if (patientNrs.isError) {
        patientNrs.isError && errors.push('Could not load existing patient numbers');
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validateSearchForm(patientNrs.data!)}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Paper>
                        <ErrorSummary errors={errors} />
                        <Section name="Identification">
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item xs={6} md={5}>
                                    <Field
                                        name="uzaNr"
                                        type="text"
                                        component={FormikTextField}
                                        label="UZA patientnumber"
                                        inputProps={{ maxLength: 9 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                        startIcon={<SearchIcon />}
                                    >
                                        Search ZIS
                                    </Button>
                                    {isSubmitting && <CircularProgress size="1.4rem" style={{ marginLeft: '1rem' }} />}
                                </Grid>
                            </Grid>
                            {importErrors && <ErrorSummary errors={[importErrors]} />}
                        </Section>
                    </Paper>
                </Form>
            )}
        </Formik>
    );
}
