import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { formatDate } from './Questions';
import { styles } from '../styles';
import { Patients } from '../../../../../../api/types';
import MocTypeCode, { mocTypeNameFor } from '../../../MocTypeCode';

type Patient = Patients.GetPatient.PatientDetail;

type MocHeaderProps = {
    patient: Patient;
    mocTypeCode: MocTypeCode;
    mocDate: string | null;
};
export default function MocHeader(props: MocHeaderProps) {
    return (
        <Fragment>
            <View style={styles.border}>
                <View style={styles.leftColumn}>
                    <Text style={styles.mocType}>{mocTypeNameFor(props.mocTypeCode)}</Text>
                    <Text style={styles.text}>{formatDate(props.mocDate)}</Text>
                </View>
            </View>
            <View>
                <Text style={styles.answer}>
                    Patiënt: {props.patient.surname}, {props.patient.firstName}
                </Text>
            </View>
            <View>
                <Text style={styles.answer}>Geboortedatum: {formatDate(props.patient.dateOfBirth)}</Text>
            </View>
            <View>
                <Text style={styles.answer}>
                    Adres: {props.patient.address}, {props.patient.postalCode} {props.patient.city}{' '}
                    {props.patient.countryName}
                </Text>
            </View>
            <View>
                <Text style={styles.answer}>Lokaal patiëntennummer: {props.patient.localPatientNr ?? `/`}</Text>
            </View>
            <View>
                <Text style={styles.answer}>UZA patiëntennummer: {props.patient.uzaNr ?? `/`}</Text>
            </View>
            <View>
                <Text style={styles.answer}>Rijksregisternummer: {props.patient.ssn ?? `/`}</Text>
            </View>
            <View>
                <Text style={styles.answer}>Huisarts: {props.patient.homeDoctorName ?? `/`}</Text>
            </View>
            <View>
                <Text style={styles.answer}>Mutualiteit: {props.patient.healthInsuranceFundName ?? `/`}</Text>
            </View>
        </Fragment>
    );
}
