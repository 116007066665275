import React, { useCallback } from 'react';
import { getIn, FastField, FastFieldProps } from 'formik';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Mocs } from '../../../../../api/types';
import { MUI_EMPTY_VALUE, FORMIK_EMPTY_VALUE } from '../../../../../styles/components/forms/FormikSelectField';
import useReferenceData from '../../referenceData/useReferenceData';
import useFieldValue from '../sections/useFieldValue';
import styles from './FastSelectField.module.scss';

type ReferenceData = Mocs.GetReferenceData.Response;
type ReferenceObject = { code: string; name: string };

const alwaysTrue = () => true;

type Props<TReferenceItem extends ReferenceObject> = {
    name: string;
    prompt: string;
    placeholder?: string;
    referenceDataPath: keyof ReferenceData;
    referenceDataFilter?: (reference: TReferenceItem) => boolean;
    detail?: (reference: TReferenceItem) => React.ReactNode;
    detailAside?: boolean;
    hideSideline?: boolean;
};

function FastSelectField<TReferenceItem extends ReferenceObject>({
    name,
    prompt,
    placeholder,
    referenceDataPath,
    referenceDataFilter = alwaysTrue,
    detail,
    detailAside,
    hideSideline,
}: Props<TReferenceItem>) {
    const referenceData = useReferenceData();
    const referenceDataItems = (getIn(referenceData, referenceDataPath) as TReferenceItem[]).filter(
        referenceDataFilter
    );

    const emptyItem = { code: MUI_EMPTY_VALUE, name: prompt } as TReferenceItem;
    const options = [emptyItem, ...referenceDataItems];

    const valueCode = useFieldValue<string>(name);
    let value = options.find(
        (reference) => reference.code === (valueCode === FORMIK_EMPTY_VALUE ? MUI_EMPTY_VALUE : valueCode)
    );

    if (value === undefined) value = emptyItem;

    const valueDetail = detail && value && detail(value);

    return (
        <Grid container spacing={2}>
            <Grid item xs={detailAside ? 6 : 12}>
                <FastField name={name}>
                    {({ form }: FastFieldProps) => (
                        <Autocomplete
                            openOnFocus
                            clearOnBlur
                            disableListWrap
                            handleHomeEndKeys
                            blurOnSelect
                            options={options}
                            value={value}
                            onChange={(_, newValue) => form.setFieldValue(name, newValue?.code || FORMIK_EMPTY_VALUE)}
                            getOptionDisabled={(item) => item.code === MUI_EMPTY_VALUE}
                            getOptionLabel={(item) => item.name}
                            renderInput={(params) => <TextField {...params} label={placeholder} variant="outlined" />}
                            ListboxComponent={
                                VirtualListComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>
                            }
                        />
                    )}
                </FastField>
            </Grid>
            {valueDetail && (
                <Grid item xs={detailAside ? 6 : 12} className={styles.detail_container}>
                    {!detailAside && !hideSideline && <div className={styles.detail_line}></div>}
                    {valueDetail}
                </Grid>
            )}
        </Grid>
    );
}

const VirtualListComponent = React.forwardRef<HTMLDivElement>((props, ref) => {
    const itemData = React.Children.toArray(props.children);
    const itemCount = itemData.length;
    const itemSize = 38;
    const height = Math.min(10 * itemSize, itemCount * itemSize);
    const width = '100%';

    const renderRow = useCallback((props: ListChildComponentProps) => {
        const { data, index, style } = props;
        return (
            <Typography noWrap style={style}>
                {data[index]}
            </Typography>
        );
    }, []);

    return (
        <div ref={ref} role="listbox" onMouseDown={(e) => e.preventDefault()}>
            <FixedSizeList {...{ itemData, itemCount, itemSize, width, height }}>{renderRow}</FixedSizeList>
        </div>
    );
});

export default FastSelectField;
