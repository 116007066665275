import React, { useState, useRef } from 'react';
import qs from 'qs';
import JSONPretty from 'react-json-pretty';
import useHospital from '../../../hospital/useHospital';
import useApi from '../../../api/useApi';
import ErrorSummary from '../../../styles/components/ErrorSummary';
import MaterialTable, { Query, QueryResult } from 'material-table';
import { Auditing } from '../../../api/types';
import { translateAction } from './actionTranslator';
import { format, parseJSON, startOfDay, addDays, parseISO, addMinutes } from 'date-fns';
import './pretty-json.css';
import styles from './AuditLog.module.scss';
import { Button } from '@material-ui/core';
import { useSnackBar } from '../../../styles/components/OncoBaseSnackbar';

type Response = Auditing.GetAuditLogs.Response;
type Log = Auditing.GetAuditLogs.AuditRecord;

type Props = {
    tableRef: any;
    startDate: string | null;
    endDate: string | null;
    action: string | undefined;
};

const showDataJson = (data: string | null, showSummary: boolean) => {
    if (data && data !== '{}') {
        if (showSummary) {
            return <span className={styles.show_details}>Click to show details...</span>;
        }
        return <JSONPretty id="json-pretty" data={data}></JSONPretty>;
    }
    return '';
};

const formatDate = (date: Date) => format(addMinutes(date, date.getTimezoneOffset()), 'dd/MM/yyyy HH:mm:ss');

const showDate = (date: string | null) => (date ? formatDate(parseJSON(date)) : '');

// copied from ExportPage.tsx, maybe we can make a 'downloadService' or something ?
const downLoadFile = (data: any, type: string, filename: string) => {
    const blob = new Blob([data], { type });

    //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
    }
};

const AuditLogTable: React.FC<Props> = ({ tableRef, startDate, endDate, action }) => {
    const [showSnack] = useSnackBar();
    const [error, setError] = useState<string | null>(null);
    const [selectedLogId, setSelectedLogId] = useState<number>();
    const queryRef = useRef<Query<Log>>();
    const { hospital } = useHospital();
    const api = useApi();

    const getData = async (query: Query<Log>): Promise<QueryResult<Log>> => {
        setError(null);
        queryRef.current = query;
        const url = `auditlog?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
            filters: {
                action: action,
                dateFrom: startDate && startOfDay(parseISO(startDate)).toUTCString(),
                dateTo: endDate && startOfDay(addDays(parseISO(endDate), 1)).toUTCString(),
            },
        })}`;
        try {
            const { data, status } = await api.get<Response>(url);
            if (status < 400) {
                const { auditLogs, pageIndex, totalCount } = data;
                return {
                    data: auditLogs,
                    page: pageIndex,
                    totalCount,
                };
            } else {
                setError('Could not load audit logs');
            }
        } catch {
            setError('Could not connect to server');
        }

        return {
            data: [],
            page: 0,
            totalCount: 0,
        };
    };

    const handleExportToExcelButtonClicked = async () => {
        var query = queryRef.current;
        if (!query) return;

        const url = `auditlog/excel?${qs.stringify({
            ...query,
            orderBy: query.orderBy ? query.orderBy.field : null,
            filters: {
                action: action,
                dateFrom: startDate && startOfDay(parseISO(startDate)).toUTCString(),
                dateTo: endDate && startOfDay(addDays(parseISO(endDate), 1)).toUTCString(),
            },
        })}`;
        var response = await api.get(url, { responseType: 'blob' });
        if (response.status < 400) {
            downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
        } else {
            showSnack("Couldn't download export file", 'error');
        }
    };

    return (
        <>
            {error !== null && (
                <div>
                    <ErrorSummary errors={[error]} />
                </div>
            )}
            <MaterialTable<Log>
                tableRef={tableRef}
                key={hospital!.slug}
                style={{ margin: '1rem 0' }}
                title="Audit Log"
                columns={[
                    { title: 'Id', field: 'id', hidden: true },
                    { title: 'Timestamp', field: 'start', defaultSort: 'desc', render: (data) => showDate(data.start) },
                    { title: 'Action', field: 'action', render: ({ action }) => <>{translateAction(action)}</> },
                    { title: 'Executed by', field: 'username' },
                    { title: 'Patient', field: 'patient' },
                    { title: 'Moc', field: 'moc' },
                    {
                        title: 'Data',
                        field: 'dataJson',
                        render: (data) =>
                            showDataJson(data.dataJson, !(selectedLogId !== undefined && selectedLogId === data.id)),
                    },
                ]}
                data={getData}
                options={{
                    sorting: true,
                    search: false,
                    pageSize: 10,
                    thirdSortClick: false,

                    headerStyle: { zIndex: 0 },
                }}
                onRowClick={(_, rowData) =>
                    setSelectedLogId(
                        rowData !== undefined ? (rowData.id !== selectedLogId ? rowData.id : undefined) : undefined
                    )
                }
                localization={{ body: { emptyDataSourceMessage: 'No audit records found' } }}
                actions={[
                    {
                        icon: '',
                        isFreeAction: true,
                        onClick: handleExportToExcelButtonClicked,
                    },
                ]}
                components={{
                    Action: (props) => (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => props.action.onClick(event, props.data)}
                            style={{ margin: '1rem' }}
                        >
                            Export to excel
                        </Button>
                    ),
                }}
            />
        </>
    );
};

export default AuditLogTable;
