import { Mocs } from '../../../../../../api/types';
import MakeOptional from '../../MakeOptional';
import { toValuesString, toValuesDate, toMocDate, toValuesBoolean, toMocBoolean } from '../../mappers';
import { CkzTreatmentItem, CodeAndOtherValue } from '../../MocForm.config';
import { getIsCmOrPmGreaterOrEqualTo1 } from './Annex55.fields';

export type GetMocValues = Pick<
    Mocs.CkzPancreasData & Mocs.CkzOesophagusData,
    | 'incidenceDate'
    | 'relapseIncidenceDate'
    | 'basisOfDiagnosisCode'
    | 'whoScoreAtDiagnosisCode'
    | 'primaryTumourLocationCode'
    | 'primaryTumourHistologyCode'
    | 'lateralityCode'
    | 'hasPeriodWithoutSymptoms'
    | 'dateFirstRecidive'
    | 'locations'
    | 'differentiationCode'
    | 'ctCode'
    | 'cnCode'
    | 'cmCode'
    | 'ptCode'
    | 'pnCode'
    | 'pmCode'
    | 'yptCode'
    | 'ypnCode'
    | 'ypmCode'
    | 'otherClassificationCode'
    | 'otherClassificationOtherValue'
    | 'stadiumCode'
    | 'treatmentDate'
    | 'treatments'
    | 'annex55MocDate'
    | 'reasons'
    | 'treatmentPlans'
    | 'sourceHospitalCode'
    | 'sourceForeignHospital'
    | 'metastasisLocations'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzPancreasFields & Mocs.SaveMoc.CkzOesophagusFields,
    | 'incidenceDate'
    | 'relapseIncidenceDate'
    | 'basisOfDiagnosisCode'
    | 'whoScoreAtDiagnosisCode'
    | 'primaryTumourLocationCode'
    | 'primaryTumourHistologyCode'
    | 'lateralityCode'
    | 'hasPeriodWithoutSymptoms'
    | 'dateFirstRecidive'
    | 'locations'
    | 'differentiationCode'
    | 'ctCode'
    | 'cnCode'
    | 'cmCode'
    | 'ptCode'
    | 'pnCode'
    | 'pmCode'
    | 'yptCode'
    | 'ypnCode'
    | 'ypmCode'
    | 'otherClassificationCode'
    | 'otherClassificationOtherValue'
    | 'stadiumCode'
    | 'treatmentDate'
    | 'treatments'
    | 'annex55MocDate'
    | 'reasons'
    | 'treatmentPlans'
    | 'sourceHospitalCode'
    | 'sourceForeignHospital'
    | 'metastasisLocations'
>;

export type location = {
    code: string;
    metastasisLocations: CodeAndOtherValue[];
};

export type FormValues = {
    incidenceDate: string | null;
    relapseIncidenceDate: string | null;
    basisOfDiagnosisCode: string;
    whoScoreAtDiagnosisCode: string;
    primaryTumourLocationCode: string;
    primaryTumourHistologyCode: string;
    lateralityCode: string;
    hasPeriodWithoutSymptoms: boolean | '';
    dateFirstRecidive: string | null;
    locations: location[];
    differentiationCode: string;
    ctCode: string;
    cnCode: string;
    cmCode: string;
    ptCode: string;
    pnCode: string;
    pmCode: string;
    yptCode: string;
    ypnCode: string;
    ypmCode: string;
    otherClassificationCode: string;
    otherClassificationOtherValue: string;
    stadiumCode: string;
    treatmentDate: string | null;
    treatments: CkzTreatmentItem[];
    annex55MocDate: string | null;
    reasons: CodeAndOtherValue[];
    treatmentPlans: CkzTreatmentItem[];
    sourceHospitalCode: string;
    sourceForeignHospital: string;
    metastasisLocations: CodeAndOtherValue[];
};

export const mapInitialValues = (moc: MakeOptional<GetMocValues>): FormValues => ({
    incidenceDate: toValuesDate(moc.incidenceDate),
    relapseIncidenceDate: toValuesDate(moc.relapseIncidenceDate),
    basisOfDiagnosisCode: toValuesString(moc.basisOfDiagnosisCode),
    whoScoreAtDiagnosisCode: toValuesString(moc.whoScoreAtDiagnosisCode),
    primaryTumourLocationCode: toValuesString(moc.primaryTumourLocationCode),
    primaryTumourHistologyCode: toValuesString(moc.primaryTumourHistologyCode),
    lateralityCode: toValuesString(moc.lateralityCode),
    hasPeriodWithoutSymptoms: toValuesBoolean(moc.hasPeriodWithoutSymptoms),
    dateFirstRecidive: toValuesDate(moc.dateFirstRecidive),
    locations:
        moc.locations?.map((l) => ({
            code: l.locationCode,
            metastasisLocations:
                l.metastasisLocations.map((ml) => ({
                    code: ml.metastasisLocationCode,
                    otherValue: ml.otherValue,
                })) ?? [],
        })) ?? [],
    differentiationCode: toValuesString(moc.differentiationCode),
    ctCode: toValuesString(moc.ctCode),
    cnCode: toValuesString(moc.cnCode),
    cmCode: toValuesString(moc.cmCode),
    ptCode: toValuesString(moc.ptCode),
    pnCode: toValuesString(moc.pnCode),
    pmCode: toValuesString(moc.pmCode),
    yptCode: toValuesString(moc.yptCode),
    ypnCode: toValuesString(moc.ypnCode),
    ypmCode: toValuesString(moc.ypmCode),
    otherClassificationCode: toValuesString(moc.otherClassificationCode),
    otherClassificationOtherValue: toValuesString(moc.otherClassificationOtherValue),
    stadiumCode: toValuesString(moc.stadiumCode),
    treatmentDate: toValuesDate(moc.treatmentDate),
    treatments:
        moc.treatments?.map((t) => ({
            id: t.id,
            code: t.treatmentCode,
            otherValue: t.otherValue,
            sortOrder: t.sortOrder,
        })) ?? [],
    annex55MocDate: toValuesDate(moc.annex55MocDate),
    reasons:
        moc.reasons?.map((r) => {
            return { code: r.reasonMocCode, otherValue: r.reasonMocOtherValue };
        }) ?? [],
    treatmentPlans:
        moc.treatmentPlans?.map((t) => ({
            id: t.id,
            code: t.treatmentPlanCode,
            otherValue: t.otherValue,
            sortOrder: t.sortOrder,
        })) ?? [],
    sourceHospitalCode: toValuesString(moc.sourceHospitalCode),
    sourceForeignHospital: toValuesString(moc.sourceForeignHospital),
    metastasisLocations:
        moc.metastasisLocations?.map((l) => ({
            code: toValuesString(l.metastasisLocationCode),
            otherValue: toValuesString(l.otherValue),
        })) ?? [],
});

export function getNewMocValues(): GetMocValues {
    return {
        incidenceDate: null,
        relapseIncidenceDate: null,
        basisOfDiagnosisCode: '',
        whoScoreAtDiagnosisCode: '',
        primaryTumourLocationCode: '',
        primaryTumourHistologyCode: '',
        lateralityCode: '',
        hasPeriodWithoutSymptoms: null,
        dateFirstRecidive: null,
        locations: [],
        differentiationCode: '',
        ctCode: '',
        cnCode: '',
        cmCode: '',
        ptCode: '',
        pnCode: '',
        pmCode: '',
        yptCode: '',
        ypnCode: '',
        ypmCode: '',
        otherClassificationCode: '',
        otherClassificationOtherValue: '',
        stadiumCode: '',
        treatmentDate: null,
        treatments: [],
        annex55MocDate: null,
        reasons: [],
        treatmentPlans: [],
        sourceHospitalCode: '',
        sourceForeignHospital: '',
        metastasisLocations: [],
    };
}

export function mapSubmitValues(form: FormValues): SaveMocValues {
    const shouldSaveMetastasisLocations = getIsCmOrPmGreaterOrEqualTo1(form);

    return {
        incidenceDate: toMocDate(form.incidenceDate),
        relapseIncidenceDate: toMocDate(form.relapseIncidenceDate),
        basisOfDiagnosisCode: form.basisOfDiagnosisCode,
        whoScoreAtDiagnosisCode: form.whoScoreAtDiagnosisCode,
        primaryTumourLocationCode: form.primaryTumourLocationCode,
        primaryTumourHistologyCode: form.primaryTumourHistologyCode,
        lateralityCode: form.lateralityCode,
        hasPeriodWithoutSymptoms: toMocBoolean(form.hasPeriodWithoutSymptoms),
        dateFirstRecidive: toMocDate(form.dateFirstRecidive),
        locations: form.locations,
        differentiationCode: form.differentiationCode,
        ctCode: form.ctCode,
        cnCode: form.cnCode,
        cmCode: form.cmCode,
        ptCode: form.ptCode,
        pnCode: form.pnCode,
        pmCode: form.pmCode,
        yptCode: form.yptCode,
        ypnCode: form.ypnCode,
        ypmCode: form.ypmCode,
        otherClassificationCode: form.otherClassificationCode,
        otherClassificationOtherValue: form.otherClassificationOtherValue,
        stadiumCode: form.stadiumCode,
        treatmentDate: toMocDate(form.treatmentDate),
        treatments: form.treatments,
        annex55MocDate: toMocDate(form.annex55MocDate),
        reasons: form.reasons,
        treatmentPlans: form.treatmentPlans,
        sourceHospitalCode: form.sourceHospitalCode,
        sourceForeignHospital: form.sourceForeignHospital,
        metastasisLocations: shouldSaveMetastasisLocations ? form.metastasisLocations : [],
    };
}

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
