import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { Mocs } from '../../../../../../../api/types';
import MocSectionContainer from '../../MocSectionContainer';
import { TreatmentDetails, VascularResectionTypeDetail } from './Surgery.config';
import { CodeAndOtherValue, CodeValue } from '../../../MocForm.config';
import { Values } from '../../../MocForm.config';
import useReferenceData from '../../../../referenceData/useReferenceData';
import {
    useResetOnValue,
    useConstant,
    useResetOnChange,
    useEmptyArray,
    useResetOnSetNotIncluded,
} from '../../useResetEffect';
import {
    YesNoQuestion,
    CheckBoxes,
    FastTextField,
    FastSelectField,
    Question,
    RadioButtons,
    FastNumberField,
    FastDateField,
} from '../../../components';

type TreatmentType = Mocs.GetReferenceData.Moc_Ref_TreatmentType;
type ChemotherapyType = Mocs.GetReferenceData.Moc_Ref_ChemotherapyType;
type Pancreaticoduodenectomy = Mocs.GetReferenceData.Moc_Ref_Pancreaticoduodenectomy;
type SurgeryType = Mocs.GetReferenceData.Moc_Ref_SurgeryType;
type PancreaticResectabilityType = Mocs.GetReferenceData.Moc_Ref_PancreaticResectability;
type NomenclatureCode = Mocs.GetReferenceData.Moc_Ref_NomenclatureCode;
type Localisation = Mocs.GetReferenceData.Moc_Ref_Localisation;
type MinimallyInvasiveSurgeryType = Mocs.GetReferenceData.Moc_Ref_MinimallyInvasiveSurgeryType;
type VascularResectionType = Mocs.GetReferenceData.Moc_Ref_VascularResectionType;
type ArterialResectionType = Mocs.GetReferenceData.Moc_Ref_ArterialResectionType;
type ReconstructionType = Mocs.GetReferenceData.Moc_Ref_ReconstructionType;
type SimultaneousOtherOrganResection = Mocs.GetReferenceData.Moc_Ref_SimultaneousOtherOrganResection;
type ResectionMargin = Mocs.GetReferenceData.Moc_Ref_ResectionMargin;
type LymphadenectomyRegion = Mocs.GetReferenceData.Moc_Ref_LymphadenectomyRegion;

type Props = {
    isValid: boolean | null;
};

const Surgery: React.FC<Props> = React.memo(({ isValid }) => {
    const referenceData = useReferenceData();
    const { values } = useFormikContext<Values>();
    const { generalInformation, pancreasSurgery } = values;

    const hadSurgery = generalInformation.hadSurgery;

    const indicationCode = generalInformation.indicationCode;
    const indication = referenceData.indications.find((ind) => ind.code === indicationCode);
    const isIndicationMalignantTumour = !!indication && indication.code === 'Malignant';
    const hasReceivedChemoOrRadioPriorToSurgery = pancreasSurgery.treatmentTypes.some(
        (treatment) => treatment.code === 'Chemo' || treatment.code === 'Radio'
    );

    useResetOnValue(
        'pancreasSurgery.hadOtherTreatmentBeforeThisSurgery',
        false,
        useEmptyArray('pancreasSurgery.treatmentTypes')
    );

    useResetOnChange(
        'pancreasSurgery.surgeryTypeCode',
        useConstant('pancreasSurgery.minimallyInvasiveSurgeryTypeCode', ''),
        useConstant('pancreasSurgery.reasonForConversion', '')
    );

    useResetOnChange(
        'pancreasSurgery.nomenclatureCode',
        useConstant('pancreasSurgery.pancreaticoduodenectomyCode', ''),
        useConstant('pancreasSurgery.lesionLocalisationCode', ''),
        useConstant('pancreasSurgery.tumourLocalisationCode', '')
    );

    useResetOnValue(
        'pancreasSurgery.hadSimultaneousVascularResection',
        false,
        useEmptyArray('pancreasSurgery.vascularResectionTypes')
    );

    useResetOnValue(
        'pancreasSurgery.hadSimultaneousOtherOrganResection',
        false,
        useEmptyArray('pancreasSurgery.simultaneousOtherOrganResections')
    );

    useResetOnValue(
        'pancreasSurgery.hadLymphadenectomy',
        false,
        useEmptyArray('pancreasSurgery.lymphadenectomyRegionCodes'),
        useConstant('pancreasSurgery.numberOfLymphNodesRetrieved', ''),
        useConstant('pancreasSurgery.numberOfLymphNodesWithTumouralInvolvement', '')
    );

    useResetOnSetNotIncluded<CodeValue>(
        'pancreasSurgery.treatmentTypes',
        [{ code: 'Chemo' }, { code: 'Radio' }] as CodeValue[],
        (treatmentType, chemoOrRadioCodeValue) => treatmentType.code === chemoOrRadioCodeValue.code,
        useConstant('pancreasSurgery.preNeoadjuvantTreatmentPancreaticResectabilityStatusCode', '')
    );

    if (!hadSurgery) {
        return null;
    }

    return (
        <MocSectionContainer code="surgery" isValid={isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="pancreasSurgery.hadOtherTreatmentBeforeThisSurgery"
                        question="Did the patient receive any other treatment modality before this surgical procedure?"
                    >
                        <CheckBoxes<TreatmentType, TreatmentDetails>
                            name="pancreasSurgery.treatmentTypes"
                            referenceDataPath="treatmentTypes"
                            referenceDataFilter={(reference) => reference.isPancreas}
                            create={(reference) => ({
                                code: reference.code,
                                chemotherapyTypeCode: '',
                                chemotherapyTypeOtherValue: '',
                                typeOfSurgery: '',
                                startDate: null,
                                latestTreatmentDate: null,
                                latestSurgeryDate: null,
                                otherValue: '',
                                otherDate: null,
                            })}
                            detail={({ code: treatmentTypeCode }, _, treatmentTypeValuePath) => {
                                switch (treatmentTypeCode) {
                                    case 'Chemo':
                                    case 'Radio': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.startDate`}
                                                        label="Start date"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.latestTreatmentDate`}
                                                        label="Date latest treatment"
                                                    />
                                                </Grid>
                                                {treatmentTypeCode === 'Chemo' && (
                                                    <Grid item xs={12}>
                                                        <FastSelectField<ChemotherapyType>
                                                            name={`${treatmentTypeValuePath}.chemotherapyTypeCode`}
                                                            placeholder="Type of chemotherapy"
                                                            prompt="Select a chemotherapy type"
                                                            referenceDataPath="chemotherapyTypes"
                                                            detail={({ isOther }) =>
                                                                isOther && (
                                                                    <FastTextField
                                                                        name={`${treatmentTypeValuePath}.chemotherapyTypeOtherValue`}
                                                                        label="Specify"
                                                                    />
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        );
                                    }
                                    case 'Surgery': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastTextField
                                                        name={`${treatmentTypeValuePath}.typeOfSurgery`}
                                                        label="Type of surgery"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.latestSurgeryDate`}
                                                        label="Date latest surgery"
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    case 'Other': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastTextField
                                                        name={`${treatmentTypeValuePath}.otherValue`}
                                                        label="Other"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastDateField
                                                        name={`${treatmentTypeValuePath}.otherDate`}
                                                        label="Date other treatment modality"
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </YesNoQuestion>
                </Grid>
                {hasReceivedChemoOrRadioPriorToSurgery && isIndicationMalignantTumour && (
                    <Grid item xs={12}>
                        <Question question="Resectability status of the (peri-)pancreatic lesion before neoadjuvant treatment">
                            <RadioButtons<PancreaticResectabilityType>
                                name="pancreasSurgery.preNeoadjuvantTreatmentPancreaticResectabilityStatusCode"
                                referenceDataPath="pancreaticResectabilities"
                            />
                        </Question>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <FastDateField name="pancreasSurgery.dateOfSurgery" label="Date of surgery" />
                </Grid>

                {isIndicationMalignantTumour && (
                    <Grid item xs={12}>
                        <Question question="Resectability status of the (peri-)pancreatic lesion before surgery">
                            <RadioButtons<PancreaticResectabilityType>
                                name="pancreasSurgery.preSurgeryPancreaticResectabilityStatusCode"
                                referenceDataPath="pancreaticResectabilities"
                            />
                        </Question>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Question question="Type of surgery">
                        <RadioButtons<SurgeryType>
                            name="pancreasSurgery.surgeryTypeCode"
                            referenceDataPath="surgeryTypes"
                            detail={(reference) => {
                                switch (reference.code) {
                                    case 'MIS': {
                                        return (
                                            <FastSelectField<MinimallyInvasiveSurgeryType>
                                                name="pancreasSurgery.minimallyInvasiveSurgeryTypeCode"
                                                prompt="Select a type"
                                                referenceDataPath="minimallyInvasiveSurgeryTypes"
                                                referenceDataFilter={(reference) => reference.isPancreas}
                                            />
                                        );
                                    }
                                    case 'Conversion': {
                                        return (
                                            <FastTextField
                                                name="pancreasSurgery.reasonForConversion"
                                                label="Reason for conversion"
                                            />
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </Question>
                </Grid>

                <Grid item xs={12}>
                    <Question question="Nomenclature code">
                        <RadioButtons<NomenclatureCode>
                            name="pancreasSurgery.nomenclatureCode"
                            referenceDataPath="nomenclatureCodes"
                            referenceDataFilter={(x) => x.isPancreas}
                            detail={({ code }) => {
                                switch (code) {
                                    case '242830': {
                                        return (
                                            <FastSelectField<Pancreaticoduodenectomy>
                                                name="pancreasSurgery.pancreaticoduodenectomyCode"
                                                placeholder="Type of Pancreaticoduodenectomy"
                                                prompt="Select a pancreaticoduodenectomy type"
                                                referenceDataPath="pancreaticoduodenectomies"
                                                detail={(reference) =>
                                                    reference.code === 'Total' && (
                                                        <FastSelectField<Localisation>
                                                            name="pancreasSurgery.lesionLocalisationCode"
                                                            placeholder="Localisation lesion"
                                                            prompt="Select localisation of lesion"
                                                            referenceDataPath="localisations"
                                                        />
                                                    )
                                                }
                                            />
                                        );
                                    }
                                    case '242896': {
                                        return (
                                            <FastSelectField<Localisation>
                                                name="pancreasSurgery.tumourLocalisationCode"
                                                placeholder="Localisation tumour"
                                                prompt="Select localisation of tumour"
                                                referenceDataPath="localisations"
                                            />
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </Question>
                </Grid>

                <Grid item xs={12}>
                    <YesNoQuestion
                        name="pancreasSurgery.hadSimultaneousVascularResection"
                        question="Simultaneous vascular resection"
                    >
                        <CheckBoxes<VascularResectionType, VascularResectionTypeDetail>
                            name="pancreasSurgery.vascularResectionTypes"
                            referenceDataPath="vascularResectionTypes"
                            create={({ code }) => ({
                                code,
                                reconstructionCode: '',
                                arterialResectionCode: '',
                            })}
                            detail={({ code }, _, valuePath) => {
                                switch (code) {
                                    case 'SMV_PV': {
                                        return (
                                            <FastSelectField<ReconstructionType>
                                                name={`${valuePath}.reconstructionCode`}
                                                placeholder="Type of SMV/PV reconstruction"
                                                prompt="Select a reconstruction type"
                                                referenceDataPath="reconstructionTypes"
                                                referenceDataFilter={(reference) => reference.isSMVPV}
                                            />
                                        );
                                    }
                                    case 'Arterial': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <FastSelectField<ArterialResectionType>
                                                        name={`${valuePath}.arterialResectionCode`}
                                                        placeholder="Type of arterial resection"
                                                        prompt="Select a resection type"
                                                        referenceDataPath="arterialResectionTypes"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FastSelectField<ReconstructionType>
                                                        name={`${valuePath}.reconstructionCode`}
                                                        placeholder="Type of arterial reconstruction"
                                                        prompt="Select a reconstruction type"
                                                        referenceDataPath="reconstructionTypes"
                                                        referenceDataFilter={(reference) => reference.isArterial}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                }
                                return null;
                            }}
                        />
                    </YesNoQuestion>
                </Grid>

                <Grid item xs={12}>
                    <YesNoQuestion
                        name="pancreasSurgery.hadSimultaneousOtherOrganResection"
                        question="Simultaneous other organ resection"
                    >
                        <CheckBoxes<SimultaneousOtherOrganResection, CodeAndOtherValue>
                            name="pancreasSurgery.simultaneousOtherOrganResections"
                            referenceDataPath="simultaneousOtherOrganResections"
                            create={({ code }) => ({ code, otherValue: '' })}
                            detail={({ isOther }, _, valuePath) =>
                                isOther && <FastTextField name={`${valuePath}.otherValue`} label="Specify" />
                            }
                        />
                    </YesNoQuestion>
                </Grid>

                {isIndicationMalignantTumour && (
                    <Grid item xs={12}>
                        <FastSelectField<ResectionMargin>
                            name="pancreasSurgery.resectionMarginCode"
                            placeholder="Residual disease - resection margins"
                            prompt="Select a resection margin"
                            referenceDataPath="resectionMargins"
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <YesNoQuestion name="pancreasSurgery.hadLymphadenectomy" question="Lymphadenectomy">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CheckBoxes<LymphadenectomyRegion, { code: string }>
                                    name="pancreasSurgery.lymphadenectomyRegionCodes"
                                    referenceDataPath="lymphadenectomyRegions"
                                    referenceDataFilter={(reference) => reference.isPancreas}
                                    create={({ code }) => ({ code: code })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastNumberField
                                    name="pancreasSurgery.numberOfLymphNodesRetrieved"
                                    label="Number of lymph nodes retrieved"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastNumberField
                                    name="pancreasSurgery.numberOfLymphNodesWithTumouralInvolvement"
                                    label="Number of lymph nodes with tumoural involvement"
                                />
                            </Grid>
                        </Grid>
                    </YesNoQuestion>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default Surgery;
