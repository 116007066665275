import { Mocs } from '../../../../../api/types';
import * as GeneralInformation from '../sections/generalInformation/GeneralInformation.config';
import * as Annex55 from '../sections/annex_55/Annex55.config';
import * as PatientCharacteristics from '../sections/patientCharacteristics/PatientCharacteristics.config';
import * as Surgery from '../sections/surgery/ckzPancreas/Surgery.config';
import * as PostSurgery from '../sections/postSurgery/ckzPancreas/PostSurgery.config';
import * as GeneralRemarks from '../sections/generalRemarks/GeneralRemarks.config';
import MakeOptional from '../MakeOptional';
import MocTypePropNames from '../MocTypePropNames';

type GetMocValues = MakeOptional<Mocs.GetMoc.MocData, MocTypePropNames>;
type NewMocValues = Pick<GetMocValues, 'ckzPancreas'>;
type SaveMocValues = Pick<Mocs.SaveMoc.Request, 'ckzPancreas'>;

export type FormValues = {
    generalInformation: GeneralInformation.FormValues;
    annex55: Annex55.FormValues;
    patientCharacteristics: PatientCharacteristics.FormValues;
    pancreasSurgery: Surgery.FormValues;
    pancreasPostSurgery: PostSurgery.FormValues;
    generalRemarks: GeneralRemarks.FormValues;
};

export const mapInitialSectionValues = (moc: GetMocValues): FormValues => ({
    generalInformation: GeneralInformation.mapInitialValues(moc.ckzPancreas!),
    annex55: Annex55.mapInitialValues(moc.ckzPancreas!),
    patientCharacteristics: PatientCharacteristics.mapInitialValues(moc.ckzPancreas!),
    pancreasSurgery: Surgery.mapInitialValues(moc.ckzPancreas!),
    pancreasPostSurgery: PostSurgery.mapInitialValues(moc.ckzPancreas!),
    generalRemarks: GeneralRemarks.mapInitialValues(moc.ckzPancreas!),
});

export const getNewMocValues: () => NewMocValues = () => ({
    ckzPancreas: {
        id: 0,
        ...GeneralInformation.getNewMocValues(),
        ...Annex55.getNewMocValues(),
        ...PatientCharacteristics.getNewMocValues(),
        ...Surgery.getNewMocValues(),
        ...PostSurgery.getNewMocValues(),
        ...GeneralRemarks.getNewMocValues(),
    },
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    ckzPancreas: {
        ...GeneralInformation.mapSubmitValues(form.generalInformation),
        ...Annex55.mapSubmitValues(form.annex55),
        ...PatientCharacteristics.mapSubmitValues(form.patientCharacteristics),
        ...Surgery.mapSubmitValues(form.pancreasSurgery),
        ...PostSurgery.mapSubmitValues(form.pancreasPostSurgery),
        ...GeneralRemarks.mapSubmitValues(form.generalRemarks),
    },
});
