import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import api from './api';
import { getConfig } from './config';
import './index.scss';
import Root from './Root';

Promise.all([getConfig()]).then(([config]) => {
    api.defaults.baseURL = config.api.baseUrl;
    api.defaults.withCredentials = true;
    api.defaults.validateStatus = status => !!status;
    api.defaults.headers = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
    };

    ReactDOM.render(<Root config={config} />, document.getElementById('root'));
});
