import React from 'react';
import { Grid } from '@material-ui/core';
import FormikDatePickerField from '../../../../../styles/components/forms/FormikDatePickerField';
import { FastField } from '../Fast';
import Question from './Question';

type Props = {
    name: string;
    question: string;
    label?: string;
    disableFuture?: boolean;
};

const DateQuestion: React.FC<Props> = ({ name, question, label, disableFuture = true }) => (
    <Question question={question}>
        <Grid container>
            <Grid item xs={12} md={6}>
                <FastField name={name} component={FormikDatePickerField} disableFuture={disableFuture} />
            </Grid>
        </Grid>
    </Question>
);

export default DateQuestion;
