import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import { EditFormLabels } from '../EditPatientFormLabels';

export default function EditOtherMedicalInfo() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Field
                    name="homeDoctorName"
                    type="text"
                    component={FormikTextField}
                    label={EditFormLabels.NameHomeDoctor}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="homeDoctorContactInfo"
                    type="text"
                    component={FormikTextField}
                    label={EditFormLabels.ContactHomeDoctor}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="attendingDoctorName"
                    type="text"
                    component={FormikTextField}
                    label={EditFormLabels.NameAttendingDoctor}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    name="attendingDoctorContactInfo"
                    type="text"
                    component={FormikTextField}
                    label={EditFormLabels.ContactAttendingDoctor}
                />
            </Grid>
        </Grid>
    );
}
