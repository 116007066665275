import { useFormikContext, getIn } from 'formik';
import { Values } from './MocForm.config';

const regexp = /(?:%{)(.*?)(?:}%)/g;

export default function useReplaceFormValues(form: string): (text: string) => string {
    const { values } = useFormikContext<Values>();
    return (text: string) => {
        const formValuesToBeReplaced = text.match(regexp);

        if (formValuesToBeReplaced === null) return text;

        formValuesToBeReplaced.forEach(value => {
            const cleanedValue = value.split('%{')[1].split('}%')[0];

            const splittedText = cleanedValue.split('|');

            if (splittedText.length === 3) {
                const [propName, trueValue, falseValue] = splittedText;
                const propValue = getIn(values, form + '.' + propName);

                if (propValue !== undefined) text = text.replace(value, propValue ? trueValue : falseValue);
            }
        });

        return text;
    };
}
