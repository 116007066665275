import { Grid } from '@material-ui/core';
import React from 'react';
import ItemList from '../components/ItemList';
import { BiopsyDate } from '../sections/pathology/Pathology.config';
import BiopsyTypeList from './BiopsyTypeList';
import { FastDateField, FastSelectField, FastTextArea, FileNameQuestion } from '../components';
import { Mocs } from '../../../../../api/types';

type Type = Mocs.GetReferenceData.Moc_Ref_BiopsyType;
type Value = Mocs.GetReferenceData.Moc_Ref_BiopsyValue;

function detail(
    name: string,
    referenceTypeDataFilter?: (reference: Type) => boolean,
    referenceValueDataFilter?: (reference: Value) => boolean
) {
    return (
        <>
            <Grid item xs={12} md={4}>
                <FastDateField name={`${name}.date`} label="Datum*"></FastDateField>
            </Grid>
            <Grid item xs={12} md={8}>
                <FastSelectField
                    name={`${name}.locationCode`}
                    prompt="Selecteer locatie biopsie"
                    placeholder="Locatie biopsie*"
                    referenceDataPath="primaryTumors"
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <FastTextArea name={`${name}.comment`} label="Commentaar"></FastTextArea>
            </Grid>
            <Grid item xs={12}>
                <FileNameQuestion type="Biopsy" question="Bestand opladen" name={`${name}.fileId`}></FileNameQuestion>
            </Grid>
            <Grid item xs={12}>
                <BiopsyTypeList
                    name={`${name}.biopsyTypes`}
                    referenceTypeDataFilter={referenceTypeDataFilter}
                    referenceValueDataFilter={referenceValueDataFilter}
                ></BiopsyTypeList>
            </Grid>
        </>
    );
}

function create(): BiopsyDate {
    return {
        date: null,
        comment: '',
        locationCode: '',
        biopsyTypes: [],
        fileId: null,
    };
}

type BiopsyDateListProps = {
    name: string;
    referenceTypeDataFilter: (reference: Type) => boolean;
    referenceValueDataFilter: (reference: Type) => boolean;
};

export default function BiopsyDateList({
    name,
    referenceTypeDataFilter,
    referenceValueDataFilter,
}: BiopsyDateListProps) {
    return (
        <ItemList
            name={`${name}.biopsyDates`}
            minItems={0}
            addText="Extra biopsie toevoegen"
            title="Biopsie"
            detail={(refName) => detail(refName, referenceTypeDataFilter, referenceValueDataFilter)}
            create={create}
        />
    );
}
