import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import { Accordion, AccordionDetails, Typography, IconButton, Paper, AccordionSummary } from '@material-ui/core';
import { format, parseJSON } from 'date-fns';
import { Patients } from '../../../../api/types';
import styles from './PatientInfo.module.scss';
import PatientIdentificationSection from './patientInfoSections/PatientIdentificationSection';
import PatientPersonalInformationSection from './patientInfoSections/PatientPersonalInformationSection';
import PatientAddressSection from './patientInfoSections/PatientAddressSection';
import PatientInsuranceSection from './patientInfoSections/PatientInsuranceSection';
import PatientOtherMedicalInfoSection from './patientInfoSections/PatientOtherMedicalInfoSection';
import { formatDate } from './PatientInfo.config';

type Patient = Patients.GetPatient.PatientDetail;

type Props = {
    isReadOnly: boolean;
    patient: Patient;
    onEdit: () => void;
};

const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd MMM yyyy') : '');

const PatientInfo: React.FC<Props> = React.memo(({ patient, onEdit, isReadOnly }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandedChange = (e: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded);
    };

    const handleEditClick = (_: React.MouseEvent<{}>) => {
        onEdit();
    };

    return (
        <Accordion
            square
            expanded={expanded}
            onChange={handleExpandedChange}
            classes={{ root: styles.expansion_panel, expanded: styles.expansion_panel_expanded }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="patient-details-content"
                id="patient-details-header"
                classes={{ root: styles.summary, content: styles.summary_content, expanded: styles.summary_expanded }}
            >
                <Typography variant="overline" className={styles.label}>
                    PATIENT
                </Typography>
                <Typography variant="h6" className={styles.name}>
                    {patient.surname}, {patient.firstName}
                </Typography>
                <Typography variant="body1" className={styles.date_of_birth}>
                    ° {showDate(patient.dateOfBirth)}
                </Typography>
                <div className={styles.uza_nr_wrapper}>
                    <Typography variant="overline" className={styles.label}>
                        Patient ID
                    </Typography>
                    <Typography variant="body1" className={styles.uza_nr}>
                        {patient.oncobasePatientNr}
                    </Typography>
                </div>
                {patient.uzaNr && (
                    <div className={styles.uza_nr_wrapper}>
                        <Typography variant="overline" className={styles.label}>
                            UZA
                        </Typography>
                        <Typography variant="body1" className={styles.uza_nr}>
                            {patient.uzaNr}
                        </Typography>
                    </div>
                )}
                {patient.localPatientNr && (
                    <div className={styles.uza_nr_wrapper}>
                        <Typography variant="overline" className={styles.label}>
                            Local patient number
                        </Typography>
                        <Typography variant="body1" className={styles.uza_nr}>
                            {patient.localPatientNr}
                        </Typography>
                    </div>
                )}
                {!isReadOnly && (
                    <div className={styles.edit_icon}>
                        <IconButton color="primary" onClick={handleEditClick} className={styles.edit_icon_button}>
                            <EditIcon />
                        </IconButton>
                    </div>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Paper>
                    <Typography>
                        {patient.importedOn
                            ? `Imported on ${showDate(patient.importedOn!)}`
                            : `The patient has not yet been imported. Please import via the edit page.`}
                    </Typography>
                    <PatientIdentificationSection
                        oncobasePatientNr={patient.oncobasePatientNr}
                        uzaNr={patient.uzaNr}
                        ssn={patient.ssn}
                        localPatientNr={patient.localPatientNr}
                    />
                    <PatientPersonalInformationSection
                        firstName={patient.firstName}
                        surname={patient.surname}
                        dateOfBirth={formatDate(showDate(patient.dateOfBirth))!}
                        dateOfDeath={formatDate(showDate(patient.dateOfDeath))}
                        gender={patient.gender}
                    />
                    <PatientAddressSection
                        address={patient.address}
                        postalCode={patient.postalCode}
                        city={patient.city}
                        countryName={patient.countryName}
                        mail={patient.mail}
                        phone={patient.telNr}
                    />
                    <PatientInsuranceSection
                        fundName={patient.healthInsuranceFundName}
                        fundNr={patient.healthInsuranceFundNr}
                    />
                    <PatientOtherMedicalInfoSection
                        homeDoctorName={patient.homeDoctorName}
                        homeDoctorContactInfo={patient.homeDoctorContactInfo}
                        attendingDoctorName={patient.attendingDoctorName}
                        attendingDoctorContactInfo={patient.attendingDoctorContactInfo}
                    />
                </Paper>
            </AccordionDetails>
        </Accordion>
    );
});

export default PatientInfo;
