import React, { useEffect } from 'react';
import { Select as MuiSelectField, InputLabel, FormControl } from '@material-ui/core';
import { SelectProps, fieldToSelect } from 'formik-material-ui';

export const MUI_EMPTY_VALUE = ' ';
export const FORMIK_EMPTY_VALUE = '';

type ChangeHandler = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
) => void;

const FormikSelectField = (props: SelectProps) => {
    const inputLabel = React.useRef<HTMLLabelElement | null>(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const error = !!props.form.errors[props.field.name] && !!props.form.touched[props.field.name];
    const value = props.field.value === FORMIK_EMPTY_VALUE ? MUI_EMPTY_VALUE : props.field.value;
    const onChange: ChangeHandler = (e) => props.field.onChange({ ...e, value: e.target.value === MUI_EMPTY_VALUE ? FORMIK_EMPTY_VALUE : e.target.value });

    useEffect(() => {
        !!inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl variant="outlined" fullWidth error={error}>
            {props.placeholder && (
                <InputLabel ref={inputLabel} className="outlined-label" shrink={true} error={error}>
                    {props.placeholder}
                </InputLabel>
            )}
            <MuiSelectField
                {...{ ...fieldToSelect(props), value, onChange }}
                inputRef={props.inputRef}
                variant="outlined"
                labelWidth={labelWidth}
                error={error}
            />
        </FormControl>
    );
};

export default FormikSelectField;
