import React from 'react';
import {
    Paper,
    List,
    ListItem,
    ListItemIcon,
    Chip,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CompleteIcon from '@material-ui/icons/Done';
import { mocSectionNameFor, elementAnchorIdFor } from '../sections/MocSectionCode';
import styles from './MocNavigation.module.scss';
import classNames from 'classnames';
import MocTypeCode, { mocTypeNameFor } from '../../MocTypeCode';
import { format, isValid } from 'date-fns';
import { Section } from '../sections/validation';

type MuiIcon = typeof WarningIcon | typeof CompleteIcon;

type Props = {
    mocTypeCode: MocTypeCode;
    mocDate: Date | '' | null;
    sections: Section[];
};

const renderIcon = (className: string, Icon: MuiIcon) => (
    <div className={classNames(styles.section, className)}>
        <Icon />
    </div>
);

const MocNavigationItem: React.FC<{ nr: number } & Section> = ({ nr, code: section, isValid, isInvalid }) => {
    const handleClick = () => {
        const elementId = elementAnchorIdFor(section);
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <ListItem button key={section} onClick={handleClick}>
            <ListItemIcon>
                <Chip label={nr} />
            </ListItemIcon>{' '}
            <ListItemText primary={mocSectionNameFor(section)} />
            <ListItemSecondaryAction>
                {isValid && renderIcon(styles.complete, CompleteIcon)}
                {isInvalid && renderIcon(styles.warning, WarningIcon)}
            </ListItemSecondaryAction>
        </ListItem>
    );
};

const MocNavigation: React.FC<Props> = ({ sections, mocTypeCode, mocDate }) => (
    <Paper className={styles.nav_container}>
        <div className={styles.nav_moc_info}>
            <Typography variant="overline" className={styles.mocTypeCode}>
                {mocTypeNameFor(mocTypeCode)}
            </Typography>
            <Typography variant="overline" className={styles.mocTypeCode}>
                {mocDate && isValid(mocDate) && format(mocDate, 'dd MMM yyyy')}
            </Typography>
        </div>
        <List>
            {sections.map((section, index) => {
                return <MocNavigationItem key={section.code} nr={index + 1} {...section} />;
            })}
        </List>
    </Paper>
);

export default MocNavigation;
