import React from 'react';
import { TextField as MuiTextField } from '@material-ui/core';
import { TextFieldProps, fieldToTextField } from 'formik-material-ui';
import NumberFormat from 'react-number-format';
import { InputBaseComponentProps } from '@material-ui/core/InputBase';

const FormikNumberField = (props: TextFieldProps) => {
    const value = Number(props.field.value);
    const setValue = (v: number) => {
        props.form.setFieldValue(props.field.name, v);
    };

    return (
        <MuiTextField
            {...fieldToTextField(props)}
            inputRef={props.inputRef}
            value={value}
            onChange={e => setValue(parseFloat(e.target.value))}
            InputProps={{
                inputComponent: NumberFormatCustom as any
            }}
            variant="outlined"
            fullWidth
        />
    );
};

function NumberFormatCustom(props: InputBaseComponentProps) {
    const { inputRef, id, name, value, className, disabled, onChange, onFocus, onBlur } = props;
    return (
        <NumberFormat
            id={id}
            name={name}
            className={className}
            disabled={disabled}
            value={value}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange!({
                    target: {
                        value: values.floatValue
                    }
                } as any);
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            decimalSeparator="."
            decimalScale={2}
        />
    );
}

export default FormikNumberField as React.ComponentType;
