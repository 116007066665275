import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import AlertIcon from '@material-ui/icons/WarningOutlined';
import styles from './SplashScreen.module.scss';

type Props = {
    isLoading: boolean;
    error: React.ReactNode;
};

const SplashScreen: React.FC<Props> = ({ isLoading, error }) => (
    <div className={styles.page}>
        <Typography variant="h3" className={styles.brand}>
            OncoBase
        </Typography>
        {isLoading && <CircularProgress className={styles.spinner} thickness={2} />}
        {error && (
            <Typography variant="body1" component="p" className={styles.error}>
                <AlertIcon /> {error}
            </Typography>
        )}
    </div>
);

export default SplashScreen;
