import React from 'react';
import GeneralInformation from '../sections/generalInformation/GeneralInformation';
import Annex55 from '../sections/annex_55/Annex55';
import PatientCharacteristics from '../sections/patientCharacteristics/PatientCharacteristics';
import Surgery from '../sections/surgery/ckzPancreas/Surgery';
import PostSurgery from '../sections/postSurgery/ckzPancreas/PostSurgery';
import GeneralRemarks from '../sections/generalRemarks/GeneralRemarks';
import MocSectionCode from '../sections/MocSectionCode';
import { ValidatedSection } from '../sections/validation';

type Props = {
    validatedSections: ValidatedSection[];
};

function isValidSection(validatedSections: ValidatedSection[], code: MocSectionCode) {
    const section = validatedSections.find((s) => s.code === code);
    return section ? section.isValid : null;
}

const CkzPancreas: React.FC<Props> = React.memo(({ validatedSections }) => {
    const isValid = (code: MocSectionCode) => isValidSection(validatedSections, code);
    return (
        <>
            <GeneralInformation isValid={isValid('general-information')} />
            <Annex55 isValid={isValid('annex55')} />
            <PatientCharacteristics isValid={isValid('patient-characteristics')} />
            <Surgery isValid={isValid('surgery')} />
            <PostSurgery isValid={isValid('post-surgery')} />
            <GeneralRemarks isValid={isValid('general-remarks')} />
        </>
    );
});

export default CkzPancreas;
