import React from 'react';
import { Patients } from '../../../../../../api/types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';
import FormikSelectField from '../../../../../../styles/components/forms/FormikSelectField';

type Country = Patients.Country;

type Props = {
    countries: Country[] | null;
};

const Address: React.FC<Props> = ({ countries }) => (
    <Grid container spacing={3}>
        <Grid item xs={12} md={10}>
            <Field name="address" type="text" component={FormikTextField} label="Address" />
        </Grid>
        <Grid item style={{ padding: 0 }} md={2}></Grid>
        <Grid item xs={3} md={3}>
            <Field name="postalCode" type="text" component={FormikTextField} label="Zip" />
        </Grid>
        <Grid item xs={9} md={7}>
            <Field name="city" type="text" component={FormikTextField} label="City" />
        </Grid>
        <Grid item xs={6} md={2} />
        <Grid item xs={6} md={5}>
            <Field
                as="select"
                name="countryId"
                component={FormikSelectField}
                placeholder="Country"
                disabled={!countries}
            >
                <MenuItem key="empty-country" value={0} disabled>
                    <i>{countries ? 'Select a country' : 'Loading ...'}</i>
                </MenuItem>
                {countries &&
                    countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                            {country.name}
                        </MenuItem>
                    ))}
            </Field>
        </Grid>
        <Grid item xs={6} md={5}>
            <Field name="mail" type="text" component={FormikTextField} label="Email" />
        </Grid>
        <Grid item xs={6} md={5}>
            <Field name="telNr" type="text" component={FormikTextField} label="Phone" />
        </Grid>
    </Grid>
);

export default Address;
