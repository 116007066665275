import React, { useState, useEffect } from 'react';
import AuditLogTable from './AuditLogTable';
import { KeyboardDatePicker, MaterialUiPickersDate } from '@material-ui/pickers';
import useData, { ResponseDataToData } from '../../../api/useData';
import { Select, MenuItem, Typography, Paper, Divider } from '@material-ui/core';
import { mapActions, ActionObject } from './actionTranslator';
import styles from './AuditLog.module.scss';

const actionsResponseMapper: ResponseDataToData<string[]> = d => d.actions;

const AuditLogPage: React.FC = () => {
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [action, setAction] = useState<string>('all');

    const [actions, setActions] = useState<ActionObject[] | null>(null);
    const [getActions, actionsResponse] = useData('auditlog/actions', actionsResponseMapper);

    useEffect(() => {
        getActions();
    }, [getActions]);

    useEffect(() => {
        actionsResponse.hasLoaded && !actionsResponse.isError && setActions(mapActions(actionsResponse.data));
    }, [actionsResponse]);

    const handleStartDateChange = (date: MaterialUiPickersDate | null) => {
        if (!date) {
            setStartDate(null);
            refreshTable();
        } else {
            const isValid = !isNaN(date.getTime());
            if (isValid) {
                setStartDate(date.toISOString());
                refreshTable();
            } else {
                setStartDate(date as any); // set the invalid date to trigger validation
            }
        }
    };

    const handleEndDateChange = (date: MaterialUiPickersDate | null) => {
        if (!date) {
            setEndDate(null);
            refreshTable();
        } else {
            const isValid = !isNaN(date.getTime());
            if (isValid) {
                setEndDate(date.toISOString());
                refreshTable();
            } else {
                setEndDate(date as any); // set the invalid date to trigger validation
            }
        }
    };

    const handleActionChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== undefined) {
            setAction(event.target.value as string);
        } else {
            setAction('');
        }
        refreshTable();
    };

    const tableRef = React.createRef<any>();
    const refreshTable = () => {
        tableRef.current.onQueryChange();
    };

    return (
        <>
            {actions && (
                <>
                    <Paper className={styles.paper}>
                        <Typography variant="h5">Filters</Typography>
                        <div className={styles.container}>
                            <KeyboardDatePicker
                                className={styles.filter}
                                autoOk
                                fullWidth={true}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                value={startDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                label="From"
                            />
                            <KeyboardDatePicker
                                className={styles.filter}
                                autoOk
                                fullWidth={true}
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                value={endDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date'
                                }}
                                label="To"
                            />

                            <Select
                                className={styles.filter}
                                value={action}
                                fullWidth={true}
                                variant="outlined"
                                onChange={handleActionChanged}
                            >
                                <MenuItem key="emptyAction" value="all">
                                    All actions
                                </MenuItem>
                                <Divider />
                                {actions &&
                                    actions.map(({ action, name }) => (
                                        <MenuItem key={action} value={action}>
                                            {name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </div>
                    </Paper>

                    <AuditLogTable tableRef={tableRef} startDate={startDate} endDate={endDate} action={action} />
                </>
            )}
        </>
    );
};

export default AuditLogPage;
