import React from 'react';
import { parse } from 'qs';
import PatientForm from '../form/PatientForm';
import useApi from '../../../../api/useApi';
import { Patients } from '../../../../api/types';
import { RouteComponentProps } from 'react-router';
import { Values, initialValues, valuesToPostRequest } from '../form/PatientForm.config';
import useHospital from '../../../../hospital/useHospital';

export default function ManualInputPatientPage({ history, location }: RouteComponentProps) {
    const api = useApi();
    const { hospital } = useHospital();

    const { uzaNrHint } = parse(location.search, { ignoreQueryPrefix: true });

    if (typeof uzaNrHint === 'string' && uzaNrHint.length === 9) {
        initialValues.uzaNr = uzaNrHint;
    }

    const handleSubmit = async (values: Values) => {
        const { status, data } = await api.post<Patients.AddManualPatient.Response>(
            'patients/manual',
            valuesToPostRequest(values)
        );

        if (status < 400) {
            history.push(`/${hospital!.slug}/patients/${data.id}`);
        }
    };

    return (
        <>
            <h1>Register a new patient</h1>
            <PatientForm
                hospitalName={hospital!.name}
                initialValues={initialValues}
                submitText="Save new patient"
                onSubmit={handleSubmit}
            />
        </>
    );
}
