import React from 'react';
import MocSectionCode, { elementAnchorIdFor, mocSectionNameFor, mocSectionIsValidatableFor } from './MocSectionCode';
import styles from './MocSectionContainer.module.scss';
import { Typography, Paper } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import CompleteIcon from '@material-ui/icons/Done';

type Props = {
    code: MocSectionCode;
    isValid: boolean | null;
};

const MocSectionContainer: React.FC<Props> = ({ code, children, isValid }) => {
    const isValidatable = mocSectionIsValidatableFor(code);
    const isComplete = isValidatable && isValid === true;
    const isWarning = isValidatable && isValid === false;

    return (
        <Paper className={styles.container}>
            <div id={elementAnchorIdFor(code)} className={styles.anchor}></div>
            <div className={styles.titleContainer}>
                <Typography variant="h4" className={styles.title}>
                    {mocSectionNameFor(code)}
                </Typography>
                {isComplete && <CompleteIcon className={styles.complete} />}
                {isWarning && <WarningIcon className={styles.warning} />}
            </div>
            {children}
        </Paper>
    );
};

export default MocSectionContainer;
