import React from 'react';
import { Select, MenuItem } from '@material-ui/core';

type SelectChangeHandler = (event: React.ChangeEvent<{ name?: string; value: any }>, child: React.ReactNode) => void;

type User = { id: number; roleId: number };
type Role = { id: number; name: string };

type Props = {
    user: User;
    onChange: (userId: number, roleId: number) => void;
    roles: Role[];
    isEditing: boolean;
    disabled: boolean;
};

function findRole(user: User, roles: Role[]) {
    const role = roles.find(r => r.id === user.roleId);
    return role ? role.name : '';
}

const UserRole: React.FC<Props> = ({ user, onChange, roles, isEditing, disabled }) => {
    const handleChange: SelectChangeHandler = ({ target: { value } }) => {
        const userId = user.id;
        const roleId = typeof value !== 'number' ? parseInt(value) : value;
        onChange(userId, roleId);
    };

    return isEditing ? (
        <Select value={user.roleId} onChange={handleChange} variant="outlined" fullWidth disabled={disabled}>
            {roles.map((role, index) => (
                <MenuItem key={index} value={role.id}>
                    {role.name}
                </MenuItem>
            ))}
        </Select>
    ) : (
        <>{findRole(user, roles)}</>
    );
};

export default UserRole;
