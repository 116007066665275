import React, { Fragment } from 'react';
import * as imaging from '../../../../forms/sections/imaging/Imaging.config';
import * as pathology from '../../../../forms/sections/pathology/Pathology.config';
import * as clincalBiology from '../../../../forms/sections/clinicalBiology/ClinicalBiology.config';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { ReferenceDataMapper } from '../..';
import Imaging from '../Imaging/Imaging';
import Pathology from '../Pathology/Pathology';
import ClinicalBiology from '../clinicalBiology/ClinicalBiology';

type InvestigationsProps = {
    imagingValues: imaging.FormValues;
    pathologyValues: pathology.FormValues;
    clincalBiology: clincalBiology.FormValues;
    mapReferenceData: ReferenceDataMapper;
};
export default function Investigations(props: InvestigationsProps) {
    return (
        <Fragment>
            <View>
                <Text style={styles.mocSubHeader}>Investigaties</Text>
            </View>
            <Imaging values={props.imagingValues} mapReferenceData={props.mapReferenceData} />
            <Pathology values={props.pathologyValues} mapReferenceData={props.mapReferenceData} />
            <ClinicalBiology values={props.clincalBiology} mapReferenceData={props.mapReferenceData} />
        </Fragment>
    );
}
