import React from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import styles from './Section.module.scss';

type Props = {
    name: string;
};

const SectionLabel: React.FC = ({ children }) => (
    <Typography variant="overline" display="block" className={styles.section_title}>
        {children}
    </Typography>
);

const Section: React.FC<Props> = ({ name, children }) => (
    <Container style={{ padding: '1.5rem' }}>
        <Grid container>
            <Grid item xs={3} md={4}>
                <SectionLabel>{name}</SectionLabel>
            </Grid>
            <Grid item xs={9} md={8}>
                {children}
            </Grid>
        </Grid>
    </Container>
);

export default Section;
