import React from 'react';
import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';
import { Button, CircularProgress, Paper, Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Formik, Form } from 'formik';
import Section from '../../../../styles/components/forms/Section';
import ErrorSummary from '../../../../styles/components/ErrorSummary';
import { PatientInfo, validateForm } from './ImportPatientDetail.config';
import { Patients } from '../../../../api/types';
import Identification from './sections/import/Identification';
import { Address } from './sections/import/Address';
import { PersonalInformation } from './sections/import/PersonalInformation';
import { Insurance } from './sections/import/Insurance';
import useApi from '../../../../api/useApi';
import OtherMedicalInfo from './sections/manual/OtherMedicalInfo';

const getFunds = (api: AxiosInstance) =>
    api.get<Patients.GetHealthInsuranceFunds.Response>('healthinsurancefunds').then((r) => r.data.healthInsuranceFunds);

const getCountries = (api: AxiosInstance) =>
    api.get<Patients.GetCountries.Response>('countries').then((r) => r.data.countries);

const getPatientNrs = (api: AxiosInstance) =>
    api.get<Patients.GetPatientNumbers.Response>('patients/numbers').then((r) => r.data.patientNumbers);

type Props = {
    initialValues: PatientInfo;
    onSubmit: (values: PatientInfo) => void;
    uzaNr: string;
    onReset: () => void;
};

export default function ImportDetailPatientForm({ initialValues, onSubmit, uzaNr, onReset }: Props) {
    const api = useApi();
    const funds = useQuery('healthinsurancefunds', () => getFunds(api));
    const countries = useQuery('countries', () => getCountries(api));
    const patientNrs = useQuery('patients/numbers', () => getPatientNrs(api));
    const isLoading = funds.isLoading || countries.isLoading || patientNrs.isLoading;
    if (isLoading) {
        return <CircularProgress />;
    }

    const apiErrors: string[] = [];
    if (funds.isError && countries.isError && patientNrs.isError) {
        apiErrors.push('Could not connect to server');
    } else {
        funds.isError && apiErrors.push('Could not load health insurance funds');
        countries.isError && apiErrors.push('Could not load countries');
        patientNrs.isError && apiErrors.push('Could not load existing patient numbers');
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validateForm(funds.data!)}
            validateOnMount
        >
            {({ isSubmitting, errors, isValid }) => (
                <div style={{ flexGrow: 1 }}>
                    <ErrorSummary errors={errors} />

                    <Form>
                        <Paper>
                            <ErrorSummary errors={apiErrors} />
                            <Section name="Identification">
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={5}>
                                        <Identification uzaNr={uzaNr} patientInfo={initialValues} />
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        <Button
                                            startIcon={<RotateLeftIcon />}
                                            variant="contained"
                                            color="primary"
                                            type="reset"
                                            onClick={onReset}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Section>
                            <Section name="Personal information">
                                <PersonalInformation patientInfo={initialValues} />
                            </Section>
                            <Section name="Address">
                                <Address countries={countries.data!} patientInfo={initialValues} />
                            </Section>
                            <Section name="Insurance">
                                <Insurance funds={funds.data!} patientInfo={initialValues} />
                            </Section>
                            <Section name="Other Medical Info">
                                <OtherMedicalInfo />
                            </Section>
                        </Paper>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{ margin: '1.5rem 0' }}
                            startIcon={<SaveIcon />}
                            disabled={!isValid}
                        >
                            Import new patient
                        </Button>
                        {isSubmitting && <CircularProgress />}
                    </Form>
                </div>
            )}
        </Formik>
    );
}
