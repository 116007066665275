import * as Yup from 'yup';
export type Values = {
    email: string;
    roleId: number;
};

export type User = { id: number; email: string; userName: string; isConfirmed: boolean; roleId: number };
export type Role = { id: number; name: string; isAdmin: boolean; isReadonly: boolean };

export function getInitialValues(roles: Role[]): Values {
    const userRoleId = roles.find(r => !r.isAdmin && !r.isReadonly)!.id;
    return { email: '', roleId: userRoleId };
}

export function getValidationSchema(users: User[], roles: Role[]) {
    const userEmails = users.map(u => u.email);
    const roleIds = roles.map(r => r.id);
    return Yup.object().shape({
        email: Yup.string()
            .email('Invalid email')
            .required('Email is required')
            .notOneOf(userEmails, 'Email already exists in list of users'),
        roleId: Yup.number()
            .oneOf(roleIds, 'Invalid role')
            .required('Role is required')
    });
}
