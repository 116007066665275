import React from 'react';
import { useFormikContext } from 'formik';
import MocTypeCode from '../MocTypeCode';
import { Values } from './MocForm.config';

type Props = {
    type: MocTypeCode;
};

const MocTypeGuard: React.FC<Props> = ({ type, children }) => {
    const { values } = useFormikContext<Values>();
    const { mocTypeCode } = values;
    const isCorrectType = type === mocTypeCode;

    return isCorrectType ? <>{children}</> : null;
};

export default MocTypeGuard;
