import React, { Fragment } from 'react';
import * as imaging from '../../../../forms/sections/imaging/Imaging.config';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';
import { ReferenceDataMapper } from '../..';
import { QuestionProps, formatDate, QuestionMocAnswer } from '../Questions';
import { MedicalImaging } from '../../../../forms/sections/imaging/Imaging.config';
import List, { Item, MainSubItem } from '../List';
import ReactPDF from '@react-pdf/renderer';

type ImagingProps = {
    values: imaging.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function Imaging(props: ImagingProps) {
    return props.values.medicalImagingRecords === null || props.values.medicalImagingRecords.length === 0 ? (
        <View>
            <QuestionMocAnswer question="Medische beelvorming" answer="/" />
        </View>
    ) : (
        <View>
            <QuestionListMedicalImaging
                question="Medische beeldvorming"
                answer={props.values.medicalImagingRecords}
                mapper={props.mapReferenceData}
                overridingStyle={styles.answer}
            />
        </View>
    );
}

type QuestionListMedicalImagingProps = QuestionProps & {
    answer: MedicalImaging[];
    overridingStyle: ReactPDF.Style;
    mapper: ReferenceDataMapper;
};

function QuestionListMedicalImaging(props: QuestionListMedicalImagingProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}</Text>
            <List>
                {props.answer.map((item, key) => (
                    <Fragment key={key}>
                        <Item>
                            <Text style={styles.text}>
                                {`${props.mapper('imagingTypes', item.imagingType)} ${
                                    item.date ? `d.d. ${formatDate(item.date)}` : ''
                                }  `}
                            </Text>
                        </Item>

                        <MainSubItem>
                            <QuestionMocAnswer
                                question="Commentaar"
                                answer={item.imagingTypeOther}
                                overridingStyle={props.overridingStyle}
                            />
                        </MainSubItem>
                        <MainSubItem>
                            <QuestionMocAnswer
                                question="PACS on Web"
                                answer={item.pacsOnWeb}
                                overridingStyle={props.overridingStyle}
                            />
                        </MainSubItem>
                        <MainSubItem>
                            <QuestionMocAnswer
                                question="Conclusie"
                                answer={item.conclusion && item.conclusion.length > 0 ? `\n${item.conclusion}` : ' /'}
                                overridingStyle={props.overridingStyle}
                            />
                        </MainSubItem>
                    </Fragment>
                ))}
            </List>
        </View>
    ) : null;
}
