import React from 'react';
import { Grid } from '@material-ui/core';
import MocSectionContainer from '../../MocSectionContainer';

import { CheckBoxes, FastDateField, FastSelectField, FileNameQuestion } from '../../../components';
import Section from '../../../components/Section';
import { Mocs } from '../../../../../../../api/types';
import { ABDDetail, ComplicationDetail, NCHDetail, OTPDetail, TVHDetail } from './Surgery.config';
import { CodeValue } from '../../../MocForm.config';

type OTP = Mocs.GetReferenceData.Moc_Ref_OTP;
type Side = Mocs.GetReferenceData.Moc_Ref_Side;
type Location = Mocs.GetReferenceData.Moc_Ref_OTPLocation;
type TVH = Mocs.GetReferenceData.Moc_Ref_TVH;
type TVHLevel = Mocs.GetReferenceData.Moc_Ref_TVHLevel;
type NCH = Mocs.GetReferenceData.Moc_Ref_NCH;
type NCHLevel = Mocs.GetReferenceData.Moc_Ref_NCHLevel;
type ABD = Mocs.GetReferenceData.Moc_Ref_ABD;
type Complication = Mocs.GetReferenceData.Moc_Ref_Complication;
type ComplicationInfection = Mocs.GetReferenceData.Moc_Ref_ComplicationInfection;
type ComplicationWoundProblem = Mocs.GetReferenceData.Moc_Ref_ComplicationWoundProblem;

type Props = {
    isValid: boolean | null;
};

const Surgery: React.FC<Props> = React.memo(({ isValid }) => {
    return (
        <MocSectionContainer code="chirurgie" isValid={isValid}>
            <Grid container spacing={3}>
                <Section title="OTP">
                    <Grid item xs={12}>
                        <CheckBoxes<OTP, OTPDetail>
                            referenceDataPath="otPs"
                            name="sarcomenSurgery.otp"
                            create={(reference) => ({
                                code: reference.code,
                                startDate: null,
                                latestTreatmentDate: null,
                                sideCode: '',
                                locationCode: '',
                            })}
                            detail={(_, _value, otpValuePath) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FastDateField name={`${otpValuePath}.startDate`} label="Start datum*" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastDateField
                                                name={`${otpValuePath}.latestTreatmentDate`}
                                                label="Datum laatste behandeling"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastSelectField<Side>
                                                name={`${otpValuePath}.sideCode`}
                                                prompt="Selecteer een zijde"
                                                placeholder="Zijde*"
                                                referenceDataPath="sides"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastSelectField<Location>
                                                name={`${otpValuePath}.locationCode`}
                                                prompt="Selecteer een locatie"
                                                placeholder="Locatie*"
                                                referenceDataPath="otpLocations"
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                </Section>
                <Section title="TVH">
                    <Grid item xs={12}>
                        <CheckBoxes<TVH, TVHDetail>
                            referenceDataPath="tvHs"
                            name="sarcomenSurgery.tvh"
                            create={(reference) => ({
                                code: reference.code,
                                startDate: null,
                                latestTreatmentDate: null,
                                sideCode: '',
                                levelCode: '',
                            })}
                            detail={(tvhType, _value, tvhValuePath) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FastDateField name={`${tvhValuePath}.startDate`} label="Start datum*" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastDateField
                                                name={`${tvhValuePath}.latestTreatmentDate`}
                                                label="Datum laatste behandeling"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastSelectField<Side>
                                                name={`${tvhValuePath}.sideCode`}
                                                prompt="Selecteer een zijde"
                                                placeholder="Zijde*"
                                                referenceDataPath="sides"
                                            />
                                        </Grid>
                                        {tvhType.hasLevel && (
                                            <Grid item xs={12}>
                                                <FastSelectField<TVHLevel>
                                                    name={`${tvhValuePath}.levelCode`}
                                                    prompt="Selecteer een niveau"
                                                    placeholder="Niveau*"
                                                    referenceDataPath="tvhLevels"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                </Section>
                <Section title="NCH">
                    <Grid item xs={12}>
                        <CheckBoxes<NCH, NCHDetail>
                            referenceDataPath="ncHs"
                            name="sarcomenSurgery.nch"
                            create={(reference) => ({
                                code: reference.code,
                                startDate: null,
                                latestTreatmentDate: null,
                                levelCode: '',
                            })}
                            detail={(_, _value, nchValuePath) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FastDateField name={`${nchValuePath}.startDate`} label="Start datum*" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastDateField
                                                name={`${nchValuePath}.latestTreatmentDate`}
                                                label="Datum laatste behandeling"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FastSelectField<NCHLevel>
                                                name={`${nchValuePath}.levelCode`}
                                                prompt="Selecteer een niveau"
                                                placeholder="Niveau*"
                                                referenceDataPath="nchLevels"
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                </Section>
                <Section title="Abdominale">
                    <Grid item xs={12}>
                        <CheckBoxes<ABD, ABDDetail>
                            referenceDataPath="abDs"
                            name="sarcomenSurgery.abd"
                            create={(reference) => ({
                                code: reference.code,
                                surgeryDate: null,
                                sideCode: '',
                            })}
                            detail={(abdType, _value, abdValuePath) => {
                                return (
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FastDateField
                                                name={`${abdValuePath}.surgeryDate`}
                                                label="Datum operatie*"
                                            />
                                        </Grid>
                                        {abdType.hasSide && (
                                            <Grid item xs={12}>
                                                <FastSelectField<Side>
                                                    name={`${abdValuePath}.sideCode`}
                                                    prompt="Selecteer een zijde"
                                                    placeholder="Zijde*"
                                                    referenceDataPath="sides"
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                );
                            }}
                        />
                    </Grid>
                </Section>
                <Section title="Complicaties">
                    <Grid item xs={12}>
                        <CheckBoxes<Complication, ComplicationDetail>
                            referenceDataPath="complications"
                            name="sarcomenSurgery.complications"
                            create={(reference) => ({
                                code: reference.code,
                                infections: [],
                                woundProblems: [],
                            })}
                            detail={({ code }, _value, abdValuePath) => {
                                switch (code) {
                                    case 'infection': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <CheckBoxes<ComplicationInfection, CodeValue>
                                                        referenceDataPath="complicationInfections"
                                                        name={`${abdValuePath}.infections`}
                                                        create={(reference) => ({
                                                            code: reference.code,
                                                        })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    case 'woundproblems': {
                                        return (
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <CheckBoxes<ComplicationWoundProblem, CodeValue>
                                                        referenceDataPath="complicationWoundProblems"
                                                        name={`${abdValuePath}.woundProblems`}
                                                        create={(reference) => ({
                                                            code: reference.code,
                                                        })}
                                                    />
                                                </Grid>
                                            </Grid>
                                        );
                                    }
                                    default:
                                        return null;
                                }
                            }}
                        />
                    </Grid>
                </Section>
                <Grid item xs={12}>
                    <FileNameQuestion
                        type="Surgery"
                        question="Bestand opladen"
                        name="sarcomenSurgery.surgeryFileId"
                    ></FileNameQuestion>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default Surgery;
