import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import * as patientCharacteristics from '../../../../forms/sections/patientCharacteristics/PatientCharacteristics.config';
import { styles } from '../../styles';

import {
    QuestionAnswer,
    QuestionYesNoAnswer,
    QuestionListCodeAndOtherValueAnswer,
    QuestionListAnswer,
} from '../Questions';
import { ReferenceDataMapper } from '../..';

type PatientCharacteristicsProps = {
    values: patientCharacteristics.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function PatientCharacteristics({ values, mapReferenceData }: PatientCharacteristicsProps) {
    const comorbidityValues = values.comorbidityTypes.map((value) => ({
        code: mapReferenceData('comorbidityTypes', value.code),
        otherValue: value.otherValue,
    }));

    const antithromboticMedicationTypeValues = values.antithromboticMedicationTypeCodes.map((value) =>
        mapReferenceData('antithromboticMedications', value.code)
    );
    const patientHeight = values.patientHeight !== '' && values.patientHeight > 0 ? values.patientHeight + 'cm' : '';
    const patientWeight = values.patientWeight !== '' && values.patientWeight > 0 ? values.patientWeight + 'kg' : '';
    return (
        <View>
            <Text style={styles.header}>Patient Characteristics</Text>
            <QuestionAnswer question="Height" answer={patientHeight} />
            <QuestionAnswer question="Weight" answer={patientWeight} />
            <QuestionAnswer
                question="WHO-performance"
                answer={mapReferenceData('whoPerformanceStatuses', values.whoPerformanceStatusCode)}
            />
            <QuestionAnswer question="ASA-score" answer={mapReferenceData('asaScores', values.asaScoreCode)} />
            <QuestionYesNoAnswer question="Comorbidity" answer={values.isComorbidity} />
            <QuestionListCodeAndOtherValueAnswer question="Type of Comorbidity" answer={comorbidityValues} />
            <QuestionYesNoAnswer
                question="Is the patient treated with antithrombotic medication"
                answer={values.isAntithromboticMedication}
            />
            <QuestionListAnswer
                question="Type of antithrombotic medication"
                answer={antithromboticMedicationTypeValues}
            />
        </View>
    );
}
