import React from 'react';
import styles from './MocInvalidFields.module.scss';
import { Typography } from '@material-ui/core';

type Props = {
    fields: string[];
};

const MocInvalidFields: React.FC<Props> = ({ fields }) => (
    <>
        <ul className={styles.invalid_fields}>
            {fields.slice(0, 10).map(f => (
                <li key={f}>
                    <Typography>{f.split('.').join(' .')}</Typography>
                </li>
            ))}
        </ul>
        {fields.length > 10 && (
            <Typography className={styles.invalid_fields_more}>
                ... and <strong>{fields.length - 10} more.</strong>
            </Typography>
        )}
    </>
);

export default MocInvalidFields;
