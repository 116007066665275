import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export interface DialogOptions {
    catchOnCancel?: boolean;
    variant: 'yes/no' | 'info';
    title?: React.ReactNode;
    description: React.ReactNode;
    yesName?: string;
    noName?: string;
    okName?: string;
}

interface DialogProps extends DialogOptions {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

export const MaterialDialog: React.FC<DialogProps> = ({
    open,
    title,
    variant,
    description,
    onSubmit,
    onClose,
    yesName = 'Yes',
    noName = 'No',
    okName = 'OK'
}) => {
    return (
        <Dialog
            open={open}
            maxWidth="lg"
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {variant === 'yes/no' && (
                    <>
                        <Button color="primary" onClick={onSubmit}>
                            {yesName}
                        </Button>
                        <Button color="primary" onClick={onClose} autoFocus>
                            {noName}
                        </Button>
                    </>
                )}

                {variant === 'info' && (
                    <Button color="primary" onClick={onSubmit}>
                        {okName}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
