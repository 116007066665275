import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';
import UnconfirmedIcon from '@material-ui/icons/HelpOutline';
import styles from './UserEmail.module.scss';

type User = { email: string; isConfirmed: boolean };

type Props = {
    user: User;
    isCurrentUser: boolean;
};

const UserEmail: React.FC<Props> = ({ user, isCurrentUser }) => (
    <div className={styles.email_container}>
        <Typography variant="body1">{user.email}</Typography>
        {isCurrentUser && (
            <Typography variant="caption" color="primary" className={styles.this_is_you}>
                (this is you)
            </Typography>
        )}
        {!user.isConfirmed && (
            <Tooltip title="Account not yet confirmed">
                <UnconfirmedIcon color="secondary" className={styles.icon} />
            </Tooltip>
        )}
    </div>
);

export default UserEmail;
