import React from 'react';
import { Values } from '../../../form/PatientForm.config';
import { Grid } from '@material-ui/core';
import TextfieldWithChanges from './TextfieldWithChanges';
import { EditFormLabels } from '../EditPatientFormLabels';
import { ImportValues } from '../../EditPatient.config';

type EditIdentificationProps = {
    oldValues: Values;
    updatedValues: ImportValues;
    hasChanges: Map<EditFormLabels, boolean>;
};
export default function EditIdentification(props: EditIdentificationProps) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={props.oldValues.uzaNr}
                    updatedValue={props.oldValues.uzaNr}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.UzaNr}
                />
            </Grid>

            <Grid item xs={6}>
                <TextfieldWithChanges
                    oldValue={props.oldValues.ssn}
                    updatedValue={props.updatedValues.ssn}
                    sectionChangesTracker={props.hasChanges}
                    name={EditFormLabels.SSN}
                />
            </Grid>
        </Grid>
    );
}
