import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        fontFamily: 'Helvetica',
    },

    header: {
        fontSize: 12,
        margin: '8px 0',
        padding: 5,
        border: 1,
    },
    leftColumn: {
        flexDirection: 'column',
        width: '50%',
        paddingTop: 5,
        paddingRight: 8,
        marginBottom: 10,
    },
    border: {
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        marginBottom: '10px',
    },
    mocHeader: {
        fontSize: 12,
        margin: '8px 0',
        padding: 5,
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
    },
    mocSubHeader: {
        fontSize: 12,
        margin: '2px 0',
        padding: '5px 0 3px 0',
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
    },
    text: {
        margin: '2px 0',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Helvetica-Bold',
    },

    answer: {
        margin: '2px 0',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Helvetica',
    },

    answerRight: {
        margin: '2px 0',
        fontSize: 12,
        textAlign: 'right',
        fontFamily: 'Helvetica',
    },

    multiLineAnswer: {
        margin: '3px 0 5px 5px',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Helvetica',
    },
    indentedAnswer: {
        marginLeft: '5px',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Helvetica',
    },
    closing: {
        padding: '2cm 0',
        fontSize: 12,
        textAlign: 'left',
        fontFamily: 'Helvetica',
    },
    topRightCorner: {
        position: 'absolute',
        top: '30px',
        right: '30px',
    },
    mocType: {
        fontSize: 18,
        margin: '2 0',
    },
    list: {
        paddingTop: 2,
    },
    item: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 10,
    },
    mainSubItem: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 20,
    },
    bulletPoint: {
        width: 10,
        fontSize: 12,
    },
    itemContent: {
        flex: 1,
        fontSize: 12,
        fontFamily: 'Helvetica',
    },
    subItem: {
        flexDirection: 'row',
        marginBottom: 2,
        paddingLeft: 30,
    },
    subItemColumns: {
        flexDirection: 'column',
        marginBottom: 2,
        paddingLeft: 30,
    },
});
