import * as Yup from 'yup';
import { format, parseJSON } from 'date-fns';
import { Patients } from '../../../../api/types';
import { defaultGender } from './GenderDefaultValue.config';
type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;

export function formatDate(value: string | Date | null) {
    if (!value) {
        return null;
    }
    const date = parseJSON(value);
    const formatted = format(date, 'dd MMM yyyy');
    return formatted;
}

export type PatientInfo = {
    importId: number;
    ssn: string;
    firstName: string;
    surname: string;
    address: string;
    postalCode: string;
    city: string;
    countryId: number;
    countryAttemptedValue: string;
    gender: number;
    genderAttemptedValue: string;
    dateOfBirth: string;
    dateOfDeath: string;
    healthInsuranceFundId: number;
    healthInsuranceFundAttemptedValue: string;
    healthInsuranceFundNr: string;
    mail: string;
    tel: string;
    homeDoctorName: string | null;
    homeDoctorContactInfo: string | null;
    attendingDoctorName: string | null;
    attendingDoctorContactInfo: string | null;
};

export function validateForm(funds: Fund[] | null) {
    return Yup.object().shape({
        firstName: Yup.string().required('First name is required. Please update in ZIS.'),
        surname: Yup.string().required('Surname is required. Please update in ZIS.'),
        address: Yup.string().required('Adress is required. Please update in ZIS.'),
        postalCode: Yup.string().required('Postal code is required. please update in ZIS.'),
        city: Yup.string().required('City is required. Please update in ZIS.'),
        healthInsuranceFundNr: Yup.string().when(
            'healthInsuranceFundId',
            (healthInsuranceFundId: number, validationSchema: any) => {
                return funds &&
                    funds.find((f) => f.id === healthInsuranceFundId) &&
                    funds.find((f) => f.id === healthInsuranceFundId)!.isOther
                    ? validationSchema
                    : validationSchema.required('Health Insurance Fund number is required.');
            }
        ),
        healthInsuranceFundId: Yup.number()
            .required('Health insurance fund is required. Please update in ZIS')
            .moreThan(0, 'Please select a Health Insurance Fund'),
        countryId: Yup.number()
            .typeError('Please select a country')
            .required('Country is required. Please update in ZIS.')
            .moreThan(0, 'Please select a country'),
        gender: Yup.number()
            .required('Gender is required. Please update in ZIS.')
            .lessThan(defaultGender, 'Please select a Gender'),
        dateOfBirth: Yup.date().required('Date of birth is a required field. Please update in ZIS.'),
        dateOfDeath: Yup.date().nullable(),
    });
}

export function valuesToPostRequest(values: PatientInfo | null) {
    return {
        ZisImportId: values?.importId,
        ClientProvidedCountryId: values?.countryId,
        ClientProvidedGender: values?.gender,
        ClientProvidedHealthInsuranceFundId: values?.healthInsuranceFundId,
        ClientProvidedHealthInsuranceFundNr: values?.healthInsuranceFundNr,
        HomeDoctorName: values?.homeDoctorName,
        HomeDoctorContactInfo: values?.homeDoctorContactInfo,
        AttendingDoctorName: values?.attendingDoctorName,
        AttendingDoctorContactInfo: values?.attendingDoctorContactInfo,
    };
}

export function getMappedPatientInfo(data: Patients.ImportPatientInfo.Response): PatientInfo {
    return {
        importId: data.importId,
        ssn: data.ssn || '',
        firstName: data.firstName || '',
        surname: data.surname || '',
        address: data.address || '',
        postalCode: data.postalCode || '',
        city: data.city,
        countryId: data.countryId || 0,
        countryAttemptedValue: data.countryAttemptedValue || '',
        gender: data.gender === null ? defaultGender : data.gender,
        genderAttemptedValue: data.genderAttemptedValue || '',
        dateOfBirth: data.dateOfBirth || '',
        dateOfDeath: data.dateOfDeath || '',
        healthInsuranceFundId: data.healthInsuranceFundId || 0,
        healthInsuranceFundAttemptedValue: data.healthInsuranceFundAttemptedValue || '',
        healthInsuranceFundNr: data.healthInsuranceFundNr || '',
        mail: data.mail || '',
        tel: data.tel || '',
        homeDoctorName: '',
        homeDoctorContactInfo: '',
        attendingDoctorName: '',
        attendingDoctorContactInfo: '',
    };
}

export function toISODateFormat(date: string) {
    return format(new Date(date), 'yyyy-MM-dd');
}
