import React, { useState } from 'react';
import { Container, Hidden, Backdrop, CircularProgress, Typography } from '@material-ui/core';
import useHospital from '../hospital/useHospital';
import styles from './Header.module.scss';
import Brand from './common/Brand';
import HospitalPicker from './hospital/picker/HospitalPicker';
import HospitalBackButton from './hospital/backbutton/HospitalBackButton';
import ProfileMenu from './account/ProfileMenu';
import useAuth from '../auth/useAuth';
import api from '../api';
import { signout } from '../api/account';
import PatientSearchBox from './patientsearch/PatientSearchBox';
import { Hospital } from '../hospital/HospitalContext';
import { useHistory } from 'react-router';
import usePermissions from '../auth/usePermissions';
import OncoBaseMenu from '../styles/components/menu/OncoBaseMenu';
import OncoBaseMenuItem from '../styles/components/menu/OncoBaseMenuItem';

const Header: React.FC = () => {
    const history = useHistory();
    const { hospital, setHospital, hospitals, lastHospital } = useHospital();
    const { user, onSignedOut } = useAuth();
    const permissions = usePermissions();
    const {
        canViewHospitalMocOverview,
        canViewGeneralMocOverview,
        canManageAuditLog,
        canManageHospitalUsers,
        canManageApplicationUsers,
        canExportCKZData,
    } = permissions;
    const [isSigningOut, setIsSigningOut] = useState<boolean>(false);

    const hasMenuItemHospitalBackButton = !hospital && (lastHospital || !!hospitals.length);
    const hasMenuItemHospitalPicker = !!(hospital && hospitals);
    const hasMenuItemPatients = !!hospital;
    const hasMenuItemMocOverview =
        !hasMenuItemHospitalBackButton && (canViewHospitalMocOverview || canViewGeneralMocOverview);
    const hasMenuItemUsers = !!hospital && (canManageHospitalUsers || canManageApplicationUsers);
    const hasMenuItemAuditLog = !!hospital && canManageAuditLog;
    const hasMenuManage = hasMenuItemUsers || hasMenuItemAuditLog;

    const handleSignOut = async () => {
        setIsSigningOut(true);
        await signout(api);
        onSignedOut();
        setIsSigningOut(false);
    };

    const handlePatientSelect = (id: number, hospitalSlug: string) => {
        const patientUrl = `/${hospitalSlug}/patients/${id}`;
        history.push(patientUrl);
    };

    const handleNavigateToProfile = () => {
        history.push('/profile');
    };

    const handleNavigateToCKZMocOverview = () => {
        if (canViewGeneralMocOverview) {
            history.push('/mocs');
        } else {
            const hospital = lastHospital || hospitals[0];
            const hospitalMocOverviewUrl = `/${hospital.slug}/mocs`;
            history.push(hospitalMocOverviewUrl);
        }
    };

    const handleNavigateToHospital = (hospital: Hospital) => {
        history.push(`/${hospital.slug}`);
    };

    return (
        <header className={styles.header}>
            <Container className={styles.contents}>
                <Hidden smDown>
                    <Brand />
                </Hidden>
                {hasMenuItemHospitalBackButton && (
                    <HospitalBackButton
                        hospital={lastHospital || hospitals[0]}
                        onGoToHospital={handleNavigateToHospital}
                    />
                )}
                {hasMenuItemHospitalPicker && (
                    <HospitalPicker hospital={hospital!} hospitals={hospitals} onChange={setHospital} />
                )}
                {hasMenuItemPatients && (
                    <OncoBaseMenuItem path={`/${hospital!.slug}/patients`}>
                        P<Hidden xsDown>ATIENTS</Hidden>
                    </OncoBaseMenuItem>
                )}
                {hasMenuItemMocOverview && (
                    <OncoBaseMenuItem onClick={handleNavigateToCKZMocOverview}>CKZ / MOC</OncoBaseMenuItem>
                )}
                {hasMenuManage && (
                    <OncoBaseMenu title="manage">
                        {(closeManageMenu) => [
                            hasMenuItemUsers && (
                                <OncoBaseMenuItem
                                    key="users"
                                    path={`/${hospital!.slug}/users`}
                                    onClick={closeManageMenu}
                                >
                                    Users
                                </OncoBaseMenuItem>
                            ),
                            hasMenuItemAuditLog && (
                                <OncoBaseMenuItem
                                    key="auditlog"
                                    path={`/${hospital!.slug}/auditlog`}
                                    onClick={closeManageMenu}
                                >
                                    Audit Log
                                </OncoBaseMenuItem>
                            ),
                            canExportCKZData && (
                                <OncoBaseMenuItem key="export" path={'/export'} onClick={closeManageMenu}>
                                    Export CKZ Data
                                </OncoBaseMenuItem>
                            ),
                            canExportCKZData && (
                                <OncoBaseMenuItem key="report" path={'/report'} onClick={closeManageMenu}>
                                    Reporting on CKZ Data
                                </OncoBaseMenuItem>
                            ),
                        ]}
                    </OncoBaseMenu>
                )}
                <div className={styles.remaining_space}>
                    {/* This element pushes its predecessors to the left and its successors to the right */}
                </div>
                {hospital && <PatientSearchBox onPatientSelect={handlePatientSelect} />}
                {user && (
                    <div className={styles.right}>
                        <ProfileMenu
                            name={user.name}
                            onSignOut={handleSignOut}
                            onNavigateToProfile={handleNavigateToProfile}
                        />
                    </div>
                )}
                <Backdrop open={isSigningOut} className={styles.backdrop}>
                    <div className={styles.backdrop_content}>
                        <CircularProgress color="inherit" />
                        <Typography className={styles.backdrop_text}>Signing out...</Typography>
                    </div>
                </Backdrop>
            </Container>
        </header>
    );
};

export default Header;
