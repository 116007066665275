import React from 'react';
import ItemList from '../components/ItemList';
import {
    CheckboxField,
    FastDateField,
    FastSelectField,
    FastTextArea,
    FastTextField,
    FileNameQuestion,
} from '../components';
import { Grid } from '@material-ui/core';
import { MedicalImaging } from '../sections/imaging/Imaging.config';
import { Mocs } from '../../../../../api/types';

type Moc_Ref_ImagingType = Mocs.GetReferenceData.Moc_Ref_ImagingType;

function detail(name: string, isSarcomen: boolean) {
    return (
        <>
            <Grid item md={3} xs={12}>
                <FastDateField name={`${name}.date`} label="Datum*" />
            </Grid>
            <Grid item md={5} xs={12}>
                <FastSelectField<Moc_Ref_ImagingType>
                    name={`${name}.imagingType`}
                    prompt="Selecteer beeldvorming type"
                    placeholder="Beeldvorming type*"
                    referenceDataFilter={(refValue) => !isSarcomen || refValue.isSarcomen}
                    referenceDataPath="imagingTypes"
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <FastTextField name={`${name}.imagingTypeOther`} label="Commentaar" />
            </Grid>
            <Grid item md={8} xs={12}>
                <FastTextArea name={`${name}.conclusion`} label="Conclusie*" />
            </Grid>
            <Grid item md={2} xs={12}>
                <FastTextField name={`${name}.pacsOnWeb`} label="PACS on Web*" />
            </Grid>
            <Grid item md={2} xs={12}>
                <CheckboxField name={`${name}.progression`} label="Progressie" />
            </Grid>
            {isSarcomen && (
                <Grid item xs={12}>
                    <FileNameQuestion
                        type="Imaging"
                        question="Bestand opladen"
                        name={`${name}.fileId`}
                    ></FileNameQuestion>
                </Grid>
            )}
        </>
    );
}

function create(): MedicalImaging {
    return {
        date: null,
        imagingType: '',
        imagingTypeOther: '',
        pacsOnWeb: '',
        conclusion: '',
        progression: false,
        fileId: null,
    };
}

type MedicalImagingListProps = {
    name: string;
    isSarcomen: boolean;
};

export default function MedicalImagingList({ name, isSarcomen }: MedicalImagingListProps) {
    return (
        <ItemList
            name={`${name}.medicalImagingRecords`}
            minItems={0}
            addText="Medische beeldvorming toevoegen"
            title="Medische beeldvorming"
            detail={(recordName) => detail(recordName, isSarcomen)}
            create={create}
        />
    );
}
