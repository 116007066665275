import { Mocs } from '../../../../../api/types';
import MakeOptional from '../MakeOptional';
import MocTypePropNames from '../MocTypePropNames';
import * as Information from '../sections/information/Information.config';
import * as Surgery from '../sections/surgery/ckzSarcomen/Surgery.config';
import * as ImagingDimensions from '../sections/imagingDimensions/ImagingDimensions.config';
import * as GeneralRemarks from '../sections/generalRemarks/GeneralRemarks.config';
import * as NeoAdjuvantTreatment from '../sections/neoAdjuvantTreatment/NeoAdjuvantTreatment.config';
import * as AdjuvantTherapy from '../sections/adjuvantTherapy/AdjuvantTherapy.config';
import * as PathologyFnclcc from '../sections/pathologyFnclcc/PathologyFnclcc.config';
import * as TreatmentPlan from '../sections/TreatmentPlan/ckzSarcomen/TreatmentPlan.config';
import * as Annex55 from '../sections/annex_55/ckzSarcomen/Annex55.config';

type GetMocValues = MakeOptional<Mocs.GetMoc.MocData, MocTypePropNames>;
type NewMocValues = Pick<GetMocValues, 'ckzSarcomen'>;
type SaveMocValues = Pick<Mocs.SaveMoc.Request, 'ckzSarcomen'>;

export type FormValues = {
    information: Information.FormValues;
    sarcomenSurgery: Surgery.FormValues;
    imagingDimensions: ImagingDimensions.FormValues;
    neoAdjuvantTreatment: NeoAdjuvantTreatment.FormValues;
    adjuvantTherapy: AdjuvantTherapy.FormValues;
    pathologyFnclcc: PathologyFnclcc.FormValues;
    ckzSarcomenTreatmentPlan: TreatmentPlan.FormValues;
    ckzSarcomenAnnex55: Annex55.FormValues;
    generalRemarks: GeneralRemarks.FormValues;
};

export const mapInitialSectionValues = (moc: GetMocValues): FormValues => ({
    information: Information.mapInitialValues(moc.ckzSarcomen!),
    sarcomenSurgery: Surgery.mapInitialValues(moc.ckzSarcomen!),
    imagingDimensions: ImagingDimensions.mapInitialValues(moc.ckzSarcomen!),
    neoAdjuvantTreatment: NeoAdjuvantTreatment.mapInitialValues(moc.ckzSarcomen!),
    adjuvantTherapy: AdjuvantTherapy.mapInitialValues(moc.ckzSarcomen!),
    pathologyFnclcc: PathologyFnclcc.mapInitialValues(moc.ckzSarcomen!),
    ckzSarcomenTreatmentPlan: TreatmentPlan.mapInitialValues(moc.ckzSarcomen!),
    ckzSarcomenAnnex55: Annex55.mapInitialValues(moc.ckzSarcomen!),
    generalRemarks: GeneralRemarks.mapInitialValues(moc.ckzSarcomen!),
});

export const getNewMocValues: () => NewMocValues = () => ({
    ckzSarcomen: {
        id: 0,
        ...Information.getNewMocValues(),
        ...Surgery.getNewMocValues(),
        ...ImagingDimensions.getNewMocValues(),
        ...NeoAdjuvantTreatment.getNewMocValues(),
        ...AdjuvantTherapy.getNewMocValues(),
        ...PathologyFnclcc.getNewMocValues(),
        ...TreatmentPlan.getNewMocValues(),
        ...Annex55.getNewMocValues(),
        ...GeneralRemarks.getNewMocValues(),
    },
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    ckzSarcomen: {
        ...Information.mapSubmitValues(form.information),
        ...Surgery.mapSubmitValues(form.sarcomenSurgery),
        ...ImagingDimensions.mapSubmitValues(form.imagingDimensions),
        ...NeoAdjuvantTreatment.mapSubmitValues(form.neoAdjuvantTreatment),
        ...AdjuvantTherapy.mapSubmitValues(form.adjuvantTherapy),
        ...PathologyFnclcc.mapSubmitValues(form.pathologyFnclcc),
        ...TreatmentPlan.mapSubmitValues(form.ckzSarcomenTreatmentPlan),
        ...Annex55.mapSubmitValues(form.ckzSarcomenAnnex55),
        ...GeneralRemarks.mapSubmitValues(form.generalRemarks),
    },
});
