import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import * as report from '../../../../forms/sections/report/Report.config';
import { ReferenceDataMapper } from '../..';
import { styles } from '../../styles';
import MocTypeCode from '../../../../MocTypeCode';
import { formatDate, QuestionMocAnswer, QuestionMultiLineAnswer } from '../Questions';

type mocGeneralInformationProps = {
    reportValues: report.FormValues;
    mapReferenceData: ReferenceDataMapper;
    mocType: MocTypeCode;
    mocDate: string | null;
};
export default function MocGeneralInformation(props: mocGeneralInformationProps) {
    return (
        <Fragment>
            <View>
                <Text style={styles.mocSubHeader}>
                    {props.mapReferenceData('mocTypes', props.reportValues.mocTypeCode)}
                </Text>
                <QuestionMocAnswer
                    question={props.mocType === 'LocalMOC' ? `Locale MOC datum` : `NET-MOC datum`}
                    answer={formatDate(props.mocDate) ?? `/`}
                    overridingStyle={styles.indentedAnswer}
                />
                <QuestionMocAnswer
                    question="Aanvragende geneesheer lokale MOC"
                    answer={props.reportValues.requesterLocalMoc ?? `/`}
                    overridingStyle={styles.indentedAnswer}
                />
                <QuestionMocAnswer
                    question="Aanvragende geneesheer NET-MOC"
                    answer={props.reportValues.requesterNetMoc ?? `/`}
                    overridingStyle={styles.indentedAnswer}
                />
                {props.mocType === 'NETMOC' && (
                    <Fragment>
                        <QuestionMocAnswer
                            question="NET-MOC coördinator"
                            answer={props.reportValues.netMocCoordinator ?? `/`}
                            overridingStyle={styles.indentedAnswer}
                        />
                        <QuestionMocAnswer
                            question="Deelnemers NET-MOC"
                            answer={props.reportValues.netMocParticipants}
                            overridingStyle={styles.indentedAnswer}
                        />
                    </Fragment>
                )}
            </View>
            <View style={{ paddingTop: '3' }}>
                <QuestionMultiLineAnswer
                    question="Medische voorgeschiedenis"
                    answer={props.reportValues.medicalHistory ?? `/`}
                />
                <QuestionMultiLineAnswer
                    question="Oncologische voorgeschiedenis"
                    answer={props.reportValues.oncologyHistory ?? `/`}
                />
                <QuestionMultiLineAnswer
                    question="Huidige probleemstelling"
                    answer={props.reportValues.currentMedicalDiagnose ?? `/`}
                />
            </View>
        </Fragment>
    );
}
