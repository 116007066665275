import React from 'react';
import { Grid } from '@material-ui/core';
import { Mocs } from '../../../../../../api/types';
import { YesNoQuestion, FastTextField, FastSelectField, FastDateField } from '../../components';

type Destination = Mocs.GetReferenceData.Moc_Ref_Destination;
type ReadmissionType = Mocs.GetReferenceData.Moc_Ref_ReadmissionType;

type PostSurgerySection = 'pancreasPostSurgery' | 'oesophagusPostSurgery';

type Props = {
    section: PostSurgerySection;
};

const PatientDischarged: React.FC<Props> = ({ section }) => {
    const fieldPath = (relative: string) => `${section}.${relative}`;
    return (
        <YesNoQuestion
            name={fieldPath('postOpDischarged')}
            question="Was the patient discharged after surgery during the 90-day post-op period?"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FastDateField name={fieldPath('postOpDischargedDate')} label="Discharge date after surgery" />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<Destination>
                        name={fieldPath('postOpDischargedDestinationCode')}
                        placeholder="Destination?"
                        prompt="Select a destination"
                        referenceDataPath="destinations"
                        detail={({ code }) =>
                            code === 'Transfer_hospital' && (
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <FastTextField
                                            name={fieldPath('postOpDischargedTransferHospitalName')}
                                            label="Name of the other hospital"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <YesNoQuestion
                                            name={fieldPath('postOpDischargedTransferBecauseComplications')}
                                            question="Because of complications?"
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<ReadmissionType>
                        name={fieldPath('postOpDischargedReadmissionTypeCode')}
                        placeholder="Re-admission within 30 days after discharge"
                        prompt="Select a re-admission type"
                        referenceDataPath="readmissionTypes"
                        detail={({ code }) => {
                            switch (code) {
                                case 'Yes_surgery':
                                case 'Yes_other':
                                    return (
                                        <FastTextField
                                            name={fieldPath('postOpDischargedReadmissionReason')}
                                            label="Reason for re-admission"
                                        />
                                    );
                            }
                            return null;
                        }}
                    />
                </Grid>
            </Grid>
        </YesNoQuestion>
    );
};

export default PatientDischarged;
