import React from 'react';
import { Grid } from '@material-ui/core';
import { YesNoQuestion, FastTextField, FastDateField } from '../../components';

type PostSurgerySection = 'pancreasPostSurgery' | 'oesophagusPostSurgery';

type Props = {
    section: PostSurgerySection;
};

const PatientDied: React.FC<Props> = ({ section }) => {
    const fieldPath = (relative: string) => `${section}.${relative}`;
    return (
        <YesNoQuestion name={fieldPath('postOpDied')} question="Did the patient die during the 90-day post-op period?">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <YesNoQuestion name={fieldPath('postOpDiedInHospital')} question="In hospital?" />
                </Grid>
                <Grid item xs={12}>
                    <FastDateField name={fieldPath('postOpDiedDate')} label="Date of death" />
                </Grid>
                <Grid item xs={12}>
                    <FastTextField name={fieldPath('postOpDiedCause')} label="Cause of death" />
                </Grid>
            </Grid>
        </YesNoQuestion>
    );
};

export default PatientDied;
