import { pdf } from '@react-pdf/renderer';
import MocTypeCode from '../MocTypeCode';
import { Mocs, Patients } from '../../../../api/types';
import { Values } from '../forms/MocForm.config';
import { ckzPancreas, ckzOesophagus, localMoc, netMoc, Renderer, ReferenceDataMapper } from './renderers';

type Patient = Patients.GetPatient.PatientDetail;
export type PdfElementFactory = (patient: Patient, values: Values, referenceData: ReferenceData) => JSX.Element;

type PdfElementFactoryMappings = {
    [mocType in MocTypeCode]?: PdfElementFactory;
};

type ReferenceData = Mocs.GetReferenceData.Response;

function createPdfElementFactory(renderer: Renderer): PdfElementFactory {
    return (patient, values, referenceData) => {
        const mapReferenceData = createReferenceDataMapper(referenceData);
        return renderer(patient, values, mapReferenceData);
    };
}

function createReferenceDataMapper(referenceData: ReferenceData): ReferenceDataMapper {
    return (key, code) => {
        const referenceItems = referenceData[key];
        const currentItem = referenceItems.find((item) => item.code === code);
        return currentItem ? currentItem.name : code;
    };
}

const pdfElementFactories: PdfElementFactoryMappings = {
    CKZPancreas: createPdfElementFactory(ckzPancreas),
    CKZOesophagus: createPdfElementFactory(ckzOesophagus),
    LocalMOC: createPdfElementFactory(localMoc),
    NETMOC: createPdfElementFactory(netMoc),
};

export function canHandle(mocId: number | string, mocType: MocTypeCode) {
    if (!(typeof mocId === 'number')) {
        return false;
    }

    const mocHasBeenSaved = !!mocId;
    const canRenderMocType = !!pdfElementFactories[mocType];

    return mocHasBeenSaved && canRenderMocType;
}

export function getPdfElementFactory(mocTypeCode: MocTypeCode){
    const pdfElementFactory = pdfElementFactories[mocTypeCode];
    if (!pdfElementFactory) {
        throw new Error(`No PDF element factory was implemented yet for moc type: ${mocTypeCode}`);
    }
    return pdfElementFactory;
}

export async function handle(patient: Patient, values: Values, referenceData: ReferenceData) {
    const pdfElementFactory = getPdfElementFactory(values.mocTypeCode);
    const pdfElement = pdfElementFactory(patient, values, referenceData);
    return await pdf(pdfElement).toBlob();
}
