import React, { useEffect, useState } from 'react';
import useAuth from './auth/useAuth';
import UnauthenticatedApp from './modules/UnauthenticatedApp';
import AuthenticatedApp from './modules/AuthenticatedApp';
import SplashScreen from './modules/SplashScreen';
import useApi from './api/useApi';
import { getCurrentUser } from './api/identity';
import { Configuration } from './config/Configuration';

const isSuccess = (status: number) => 200 <= status && status < 300;
const isError = (status: number) => 500 <= status;

type Props = {
    config: Configuration;
};

const App: React.FC<Props> = ({ config }) => {
    const { isAuthenticated, onSignedIn } = useAuth();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<React.ReactNode>('');

    useEffect(() => {
        (async function() {
            if (isAuthenticated) {
                return;
            }
            try {
                const { status, data } = await getCurrentUser(api);
                if (isSuccess(status)) {
                    onSignedIn(data);
                }
                if (isError(status)) {
                    setError('Something went wrong, please try again later');
                }
            } catch {
                const errorMessage =
                    process.env.NODE_ENV === 'production' ? (
                        'Could not connect to server'
                    ) : (
                        <>
                            Could not connect to server:&nbsp;<strong>{config.api.baseUrl}</strong>
                        </>
                    );

                setError(errorMessage);
            }
            setIsLoading(false);
        })();
    }, [api, onSignedIn, isAuthenticated, config.api.baseUrl]);

    if (isLoading || error) {
        return <SplashScreen isLoading={isLoading} error={error} />;
    }

    return isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />;
};

export default App;
