import React from 'react';
import { Typography, Link } from '@material-ui/core';
import Logo from './Logo';
import styles from './WelcomeContent.module.scss';

const WelcomeContent: React.FC = () => {
    return (
        <section className={styles.welcome}>
            <div className={styles.content}>
                <div>
                    <Typography variant="h2" className={styles.title}>
                        OncoBase
                    </Typography>
                    <Typography className={styles.paragraph}>
                        Het UZA werd door de overheid in 2019 erkend als referentiecentrum voor slokdarm- en
                        pancreasheelkunde. Binnen het NETwerk voor neuro-endocriene tumoren kregen we reeds in 2017 een
                        Europese erkenning. Naar aanleiding van deze erkenningen werd in januari 2020 een nieuwe
                        databank opgericht, namelijk OncoBase.
                    </Typography>
                    <Typography className={styles.paragraph}>
                        In deze overkoepelende databank centraliseren we de gevraagde patiëntengegevens om zo de
                        zorgkwaliteit te optimaliseren.
                    </Typography>
                    <Typography className={styles.paragraph}>
                        Betrokken personen krijgen enkel toegang tot de nodige patiëntengegevens, waarbij rekening wordt
                        gehouden met de privacy wetgeving.
                    </Typography>
                    <Typography className={styles.paragraph}>
                        Als pilootproject starten we begin januari 2020 met de gegevens van de pancreas- en
                        slokdarmtumoren, alsook met de neuro-endocriene tumoren (NET).
                    </Typography>
                    <Typography className={styles.paragraph_contact}>
                        Heeft u vragen over deze databank? Neem contact op met{' '}
                        <Link href="mailto:kankerregistratie@uza.be">kankerregistratie@uza.be</Link> of via{' '}
                        <Link href="tel://003238213244">03 821 32 44</Link>.
                    </Typography>
                    <Typography className={styles.paragraph}>
                        We werken hiervoor nauw samen met de volgende partners (zie logo’s). Daarnaast nemen ook deze
                        ziekenhuizen deel aan OncoBase: AZ Sint-Blasius en OLV Ziekenhuis Aalst.
                    </Typography>
                </div>
            </div>
            <div className={styles.images}>
                <Logo logoFileName="uza.jpg" />
                <Logo logoFileName="az_klina.jpg" />
                <Logo logoFileName="az_monica.jpg" />
                <Logo logoFileName="az_riverland.png" />
                <Logo logoFileName="az_voorkempen.png" />
                <Logo logoFileName="sint_dimpna.svg" />
                <Logo logoFileName="az_mol.png" />
                <Logo logoFileName="az_herentals.png" />
                <Logo logoFileName="az_turnhout.png" />
                <Logo logoFileName="vitaz.png" />
                <Logo logoFileName="zna.jpg" />
                <Logo logoFileName="gza.jpg" />
                <Logo logoFileName="univantw.png" />
            </div>
        </section>
    );
};

export default WelcomeContent;
