import React from 'react';
import { useFormikContext } from 'formik';
import { Grid, Typography } from '@material-ui/core';
import MocSectionContainer from '../../../sections/MocSectionContainer';
import useReferenceData from '../../../../referenceData/useReferenceData';
import { Values } from '../../../MocForm.config';
import {
    PostoperativeComplication,
    MajorPostoperativeComplication,
    PostoperativeComplicationSubType
} from './PostSurgery.config';
import { useResetOnValue, useResetOnChange, useEmptyArray, useConstant } from '../../useResetEffect';
import { Mocs } from '../../../../../../../api/types';
import { YesNoQuestion, FastSelectField, FastTextArea, FastTextField, Question, CheckBoxes } from '../../../components';
import PatientDischarged from '../PatientDischarged';
import PatientDied from '../PatientDied';
import AdjuvantTherapy from '../AdjuvantTherapy';
import usePermissions from '../../../../../../../auth/usePermissions';

type ClavienDindoGrade = Mocs.GetReferenceData.Moc_Ref_ClavienDindoGrade;
type RedoSurgeryType = Mocs.GetReferenceData.Moc_Ref_RedoSurgeryType;
type RefPostoperativeComplication = Mocs.GetReferenceData.Moc_Ref_PostoperativeComplication;
type RefPostoperativeComplicationSubType = Mocs.GetReferenceData.Moc_Ref_PostoperativeComplicationSubType;

type Props = {
    isValid: boolean | null;
};

const PostSurgery: React.FC<Props> = React.memo(({ isValid }) => {
    const { indications } = useReferenceData();
    const { values, initialValues } = useFormikContext<Values>();
    const hadSurgery = values.generalInformation.hadSurgery;
    const indication = indications.find(indi => indi.code === values.generalInformation.indicationCode);

    const { oesophagusPostSurgery } = initialValues;
    const permissions = usePermissions();
    const { canEditLockedMocReports } = permissions;

    const mccmReport = oesophagusPostSurgery.redoSurgeryMccmReport;
    const pathologyReport = oesophagusPostSurgery.redoSurgeryPathologyReport;
    const surgeryReport = oesophagusPostSurgery.redoSurgerySurgeryReport;

    useResetOnValue(
        'oesophagusPostSurgery.hadOtherMajorPostopComplications',
        false,
        useEmptyArray('oesophagusPostSurgery.majorPostoperativeComplications')
    );

    useResetOnValue(
        'oesophagusPostSurgery.hadRedoSurgery',
        false,
        useConstant('oesophagusPostSurgery.redoSurgeryTypeCode', ''),
        useConstant('oesophagusPostSurgery.redoSurgeryTypeOtherValue', ''),
        useConstant('oesophagusPostSurgery.redoSurgeryMccmReport', ''),
        useConstant('oesophagusPostSurgery.redoSurgeryPathologyReport', ''),
        useConstant('oesophagusPostSurgery.redoSurgerySurgeryReport', '')
    );

    useResetOnChange(
        'oesophagusPostSurgery.redoSurgeryTypeCode',
        useConstant('oesophagusPostSurgery.redoSurgeryTypeOtherValue', '')
    );

    useResetOnValue(
        'oesophagusPostSurgery.postOpDischarged',
        false,
        useConstant('oesophagusPostSurgery.postOpDischargedDate', null),
        useConstant('oesophagusPostSurgery.postOpDischargedDestinationCode', ''),
        useConstant('oesophagusPostSurgery.postOpDischargedTransferHospitalName', ''),
        useConstant('oesophagusPostSurgery.postOpDischargedTransferBecauseComplications', ''),
        useConstant('oesophagusPostSurgery.postOpDischargedReadmissionTypeCode', ''),
        useConstant('oesophagusPostSurgery.postOpDischargedReadmissionReason', '')
    );

    useResetOnChange(
        'oesophagusPostSurgery.postOpDischargedDestinationCode',
        useConstant('oesophagusPostSurgery.postOpDischargedTransferHospitalName', ''),
        useConstant('oesophagusPostSurgery.postOpDischargedTransferBecauseComplications', '')
    );

    useResetOnChange(
        'oesophagusPostSurgery.postOpDischargedReadmissionTypeCode',
        useConstant('oesophagusPostSurgery.postOpDischargedReadmissionReason', '')
    );

    useResetOnValue(
        'oesophagusPostSurgery.postOpDied',
        false,
        useConstant('oesophagusPostSurgery.postOpDiedInHospital', ''),
        useConstant('oesophagusPostSurgery.postOpDiedDate', null),
        useConstant('oesophagusPostSurgery.postOpDiedCause', '')
    );

    useResetOnValue(
        'oesophagusPostSurgery.wasThereAdjuvenantTherapyAfterSurgery',
        false,
        useConstant('oesophagusPostSurgery.adjuvenantTherapyTypeCode', '')
    );

    useResetOnChange(
        'oesophagusPostSurgery.clinicalTrialWasIncludedCode',
        useConstant('oesophagusPostSurgery.clinicalTrialEudraCTNumber', ''),
        useConstant('oesophagusPostSurgery.clinicalTrialNctNumber', '')
    );

    if (!hadSurgery) {
        return null;
    }

    return (
        <MocSectionContainer code="post-surgery" isValid={isValid}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Question question="Which of the following postoperative complications occurred?">
                        <CheckBoxes<RefPostoperativeComplication, PostoperativeComplication>
                            name="oesophagusPostSurgery.postoperativeComplications"
                            referenceDataPath="postoperativeComplications"
                            referenceDataFilter={reference => reference.isOesophagus && !reference.isMajorComplication}
                            create={({ code }) => ({
                                code,
                                clavienDindoGradeCode: ''
                            })}
                            detail={({ code }, _, valuePath) =>
                                code !== 'None' && (
                                    <FastSelectField<ClavienDindoGrade>
                                        name={`${valuePath}.clavienDindoGradeCode`}
                                        placeholder="Clavien-Dindo Grade"
                                        prompt="Select a Clavien-dindo grade"
                                        referenceDataPath="clavienDindoGrades"
                                    />
                                )
                            }
                        />
                    </Question>
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion
                        name="oesophagusPostSurgery.hadOtherMajorPostopComplications"
                        question="Did other major postoperative complications occur (Clavien-Dindo grade IIIb, IVa, IVb or
                                V, 90 days post-op, in-hospital)?"
                    >
                        <CheckBoxes<RefPostoperativeComplication, MajorPostoperativeComplication>
                            name="oesophagusPostSurgery.majorPostoperativeComplications"
                            referenceDataPath="postoperativeComplications"
                            referenceDataFilter={reference => reference.isOesophagus && reference.isMajorComplication}
                            create={({ code }) => ({
                                code,
                                postoperativeComplicationSubTypes: []
                            })}
                            detail={({ code }, _, valuePath) => (
                                <CheckBoxes<RefPostoperativeComplicationSubType, PostoperativeComplicationSubType>
                                    name={`${valuePath}.postoperativeComplicationSubTypes`}
                                    referenceDataPath="postoperativeComplicationSubTypes"
                                    referenceDataFilter={reference => reference.postoperativeComplicationCode === code}
                                    create={({ code }) => ({
                                        code,
                                        otherValue: ''
                                    })}
                                    detail={({ isOther }, _, subValuePath) =>
                                        isOther && (
                                            <FastTextField
                                                name={`${subValuePath}.otherValue`}
                                                label="Other, non-listed, postoperative complication"
                                            />
                                        )
                                    }
                                />
                            )}
                        />
                    </YesNoQuestion>
                </Grid>
                <Grid item xs={12}>
                    <FastSelectField<ClavienDindoGrade>
                        name="oesophagusPostSurgery.generalClavienDindoGradeCode"
                        placeholder="General Clavien-Dindo grade (90 days post-op, in-hospital complications)"
                        prompt="Select a Clavien-Dindo grade"
                        referenceDataPath="clavienDindoGrades"
                        referenceDataFilter={reference => reference.isGeneral}
                    />
                </Grid>
                <Grid item xs={12}>
                    <YesNoQuestion name="oesophagusPostSurgery.hadRedoSurgery" question="Redo surgery?">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FastSelectField<RedoSurgeryType>
                                    name="oesophagusPostSurgery.redoSurgeryTypeCode"
                                    placeholder="Surgery Type"
                                    prompt="Select a surgery type"
                                    referenceDataPath="redoSurgeryTypes"
                                    detail={({ isOther }) =>
                                        isOther && (
                                            <FastTextField
                                                name="oesophagusPostSurgery.redoSurgeryTypeOtherValue"
                                                label="Other surgery type"
                                            />
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    Please upload the following report(s) without patient identification variables
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="oesophagusPostSurgery.redoSurgeryMccmReport"
                                    label="MC/CM report"
                                    disabled={mccmReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="oesophagusPostSurgery.redoSurgeryPathologyReport"
                                    label="Pathology report"
                                    disabled={pathologyReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FastTextArea
                                    name="oesophagusPostSurgery.redoSurgerySurgeryReport"
                                    label="Surgery report"
                                    disabled={surgeryReport !== '' && !canEditLockedMocReports}
                                />
                            </Grid>
                        </Grid>
                    </YesNoQuestion>
                </Grid>
                <Grid item xs={12}>
                    <PatientDischarged section="oesophagusPostSurgery" />
                </Grid>
                <Grid item xs={12}>
                    <PatientDied section="oesophagusPostSurgery" />
                </Grid>
                <Grid item xs={12}>
                    <AdjuvantTherapy section="oesophagusPostSurgery" indicationCode={indication && indication.code} />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default PostSurgery;
