import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles';

export default function MocClosing() {
    return (
        <View>
            <Text style={styles.closing}>Met vriendelijke groeten</Text>
        </View>
    );
}
