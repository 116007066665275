import React from 'react';
import { Grid } from '@material-ui/core';
import { PatientInfo } from '../../../form/ImportPatientDetail.config';
import { Values } from '../../../form/PatientForm.config';
import { Patients } from '../../../../../../api/types';
import TextfieldWithChanges from './TextfieldWithChanges';
import { EditFormLabels } from '../EditPatientFormLabels';
import SelectFieldWithChanges from './SelectFieldWithChanges';

type Country = Patients.Country;
type Props = {
    oldValues: Values;
    updatedValues: PatientInfo;
    hasChanges: Map<EditFormLabels, boolean>;
    countries: Country[] | null;
};

export default function ReadOnlyAddress(addressProps: Props) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    name={EditFormLabels.Address}
                    oldValue={addressProps.oldValues.address}
                    updatedValue={addressProps.updatedValues.address}
                    sectionChangesTracker={addressProps.hasChanges}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    name={EditFormLabels.Zip}
                    oldValue={addressProps.oldValues.postalCode}
                    updatedValue={addressProps.updatedValues.postalCode}
                    sectionChangesTracker={addressProps.hasChanges}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    name={EditFormLabels.City}
                    oldValue={addressProps.oldValues.city}
                    updatedValue={addressProps.updatedValues.city}
                    sectionChangesTracker={addressProps.hasChanges}
                />
            </Grid>
            <Grid item xs={6}>
                <SelectFieldWithChanges
                    fieldName={'countryId'}
                    placeholder={EditFormLabels.Country}
                    selectionInfo={addressProps.countries ? 'Select a country' : 'Loading ...'}
                    attemptedValue={addressProps.updatedValues.countryAttemptedValue}
                    oldValue={addressProps.countries!.find((c) => c.id === addressProps.updatedValues.countryId)?.name!}
                    defaultValue={0}
                    enableSelection={addressProps.updatedValues.countryId === 0}
                    sectionChangesTracker={addressProps.hasChanges}
                    options={
                        addressProps.countries?.map((country) => ({ id: country.id, display: country.name })) ?? []
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    name={EditFormLabels.Mail}
                    oldValue={addressProps.oldValues.mail}
                    updatedValue={addressProps.updatedValues.mail}
                    sectionChangesTracker={addressProps.hasChanges}
                />
            </Grid>
            <Grid item xs={6}>
                <TextfieldWithChanges
                    name={EditFormLabels.Tel}
                    oldValue={addressProps.oldValues.telNr}
                    updatedValue={addressProps.updatedValues.tel}
                    sectionChangesTracker={addressProps.hasChanges}
                />
            </Grid>
        </Grid>
    );
}
