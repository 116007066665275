import { Mocs } from '../../../../../../../api/types';
import { toMocDate } from '../../../mappers';
import { CodeValue } from '../../../MocForm.config';

export type GetMocValues = Pick<
    Mocs.CkzSarcomenData,
    'otp' | 'tvh' | 'nch' | 'abd' | 'complications' | 'surgeryFileId'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzSarcomenFields,
    'otp' | 'tvh' | 'nch' | 'abd' | 'complications' | 'surgeryFileId'
>;

export type FormValues = {
    otp: OTPDetail[];
    tvh: TVHDetail[];
    nch: NCHDetail[];
    abd: ABDDetail[];
    complications: ComplicationDetail[];
    surgeryFileId: number | null;
};

export type OTPDetail = {
    code: string;
    startDate: string | null;
    latestTreatmentDate: string | null;
    sideCode: string;
    locationCode: string;
};

export type TVHDetail = {
    code: string;
    startDate: string | null;
    latestTreatmentDate: string | null;
    sideCode: string;
    levelCode: string;
};

export type NCHDetail = {
    code: string;
    startDate: string | null;
    latestTreatmentDate: string | null;
    levelCode: string;
};

export type ABDDetail = {
    code: string;
    surgeryDate: string | null;
    sideCode: string;
};

export type ComplicationDetail = {
    code: string;
    infections: CodeValue[];
    woundProblems: CodeValue[];
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    otp: moc.otp.map((o) => ({
        code: o.otpCode,
        startDate: o.startDate,
        latestTreatmentDate: o.latestTreatmentDate,
        sideCode: o.sideCode,
        locationCode: o.locationCode,
    })),
    tvh: moc.tvh.map((t) => ({
        code: t.tvhCode,
        startDate: t.startDate,
        latestTreatmentDate: t.latestTreatmentDate,
        sideCode: t.sideCode,
        levelCode: t.levelCode,
    })),
    nch: moc.nch.map((n) => ({
        code: n.nchCode,
        startDate: n.startDate,
        latestTreatmentDate: n.latestTreatmentDate,
        levelCode: n.levelCode,
    })),
    abd: moc.abd.map((a) => ({
        code: a.abdCode,
        surgeryDate: a.surgeryDate,
        sideCode: a.sideCode,
    })),
    complications: moc.complications.map((c) => ({
        code: c.complicationCode,
        infections: c.infections.map((i) => ({ code: i.infectionCode })),
        woundProblems: c.woundProblems.map((p) => ({ code: p.woundProblemCode })),
    })),
    surgeryFileId: moc.surgeryFileId,
});

export const getNewMocValues = (): GetMocValues => ({
    otp: [],
    tvh: [],
    nch: [],
    abd: [],
    complications: [],
    surgeryFileId: null,
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    otp: form.otp.map((o) => ({
        ...o,
        otpCode: o.code,
        startDate: toMocDate(o.startDate),
        latestTreatmentDate: toMocDate(o.latestTreatmentDate),
        sideCode: o.sideCode,
        locationCode: o.locationCode,
    })),
    tvh: form.tvh.map((t) => ({
        ...t,
        tvhCode: t.code,
        startDate: toMocDate(t.startDate),
        latestTreatmentDate: toMocDate(t.latestTreatmentDate),
        sideCode: t.sideCode,
        levelCode: t.levelCode,
    })),
    nch: form.nch.map((n) => ({
        ...n,
        nchCode: n.code,
        startDate: toMocDate(n.startDate),
        latestTreatmentDate: toMocDate(n.latestTreatmentDate),
        levelCode: n.levelCode,
    })),
    abd: form.abd.map((a) => ({
        ...a,
        abdCode: a.code,
        surgeryDate: toMocDate(a.surgeryDate),
        sideCode: a.sideCode,
    })),
    complications: form.complications.map((c) => ({
        ...c,
        complicationCode: c.code,
        infections: c.infections.map((i) => ({ infectionCode: i.code })),
        woundProblems: c.woundProblems.map((w) => ({ woundProblemCode: w.code })),
    })),
    surgeryFileId: form.surgeryFileId,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
