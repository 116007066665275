import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Mocs, Patients } from '../../../api/types';
import useData, { ResponseDataToData } from '../../../api/useData';
import useApi from '../../../api/useApi';
import MocForm from './forms/MocForm';
import MocTypeCode from './MocTypeCode';
import { Values, mapInitialValues, mapSubmitValues, MocFileType } from './forms/MocForm.config';
import { ReferenceDataProvider } from './referenceData/ReferenceDataContext';
import OncoBaseSnackbar, { useSnackBar } from '../../../styles/components/OncoBaseSnackbar';
import { elementAnchorIdFor, getSectionCodes } from './forms/sections/MocSectionCode';
import { ValidatedSection, mapValidationToSections } from './forms/sections/validation';
import styles from './MocDetail.module.scss';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

type SaveResponse = Mocs.SaveMoc.Response;
type ReferenceData = Mocs.GetReferenceData.Response;
type PatientFile = Patients.GetPatient.File;
type Patient = Patients.GetPatient.PatientDetail;

type Props = {
    patient: Patient;
    mocId: number;
    mocTypeCode: MocTypeCode;
    mocFiles: PatientFile[];
    isReadOnly: boolean;
    onMocCreated: (id: number, mocDate: string | null, isValid: boolean) => void;
    onMocChanged: (id: number, mocDate: string | null, isValid: boolean) => void;
    onMocFilesChanged: (mocFiles: PatientFile[]) => void;
};

const mocResponseMapper: ResponseDataToData<Mocs.GetMoc.MocData> = (d) => d.moc;
const mocInitialValuesResponseMapper: ResponseDataToData<Mocs.GetMocInitialValues.Response> = (d) => d;
const referenceDataResponseMapper: ResponseDataToData<Mocs.GetReferenceData.Response> = (d) => d;

const MocDetail: React.FC<Props> = ({
    patient,
    mocId,
    mocTypeCode,
    onMocCreated,
    onMocChanged,
    mocFiles,
    onMocFilesChanged,
}) => {
    const history = useHistory();
    const [referenceData, setReferenceData] = useState<ReferenceData | null>(null);
    const [initialValues, setInitialValues] = useState<Values | null>(null);
    const [validatedSections, setValidatedSections] = useState<ValidatedSection[]>([]);

    const [getMoc, mocResponse] = useData('mocs/' + mocId, mocResponseMapper);

    const patientId = patient.id;
    const [getMocInitialValues, mocInitialValuesResponse] = useData(
        `mocs/initialvalues?patientId=${patientId}&mocTypeCode=${mocTypeCode}`,
        mocInitialValuesResponseMapper
    );
    const [getReferenceData, referenceDataResponse] = useData('mocs/referencedata', referenceDataResponseMapper);
    const api = useApi();
    const [showSnack, snackBarProps] = useSnackBar();

    useEffect(() => {
        getReferenceData();
    }, [getReferenceData]);

    useEffect(() => {
        referenceDataResponse.hasLoaded && setReferenceData(referenceDataResponse.data);
    }, [referenceDataResponse]);

    useEffect(() => {
        if (mocId > 0) {
            getMoc();
        }
    }, [getMoc, mocId]);

    useEffect(() => {
        if (mocId === 0) {
            getMocInitialValues();
        }
    }, [mocId, getMocInitialValues]);

    useEffect(() => {
        if (mocInitialValuesResponse.hasLoaded && mocId === 0) {
            const newMocInitialValues = mapInitialValues(mocInitialValuesResponse.data.mocData, patientId, []);
            setInitialValues(newMocInitialValues);
            setValidatedSections([]);
        }
    }, [mocId, patientId, mocInitialValuesResponse]);

    useEffect(() => {
        if (mocResponse.hasLoaded && mocId > 0) {
            const responseData = mocResponse.data;
            if (!responseData) {
                history.push('/');
                return;
            }
            const validationErrors = responseData.validationErrors;
            const currentMocFiles = mocFiles.map((pf) => ({ ...pf, type: pf.patientFileTypeCode as MocFileType }));
            const existingMocInitialValues = mapInitialValues(responseData, patient.id, currentMocFiles);

            setInitialValues(existingMocInitialValues);
            setValidatedSections(mapValidationToSections(validationErrors, mocTypeCode, existingMocInitialValues));
        }
        // The line below hides the warning that mocFiles isn't included in the dependency array because the effect doesn't needs to be triggered when the files change.
        // When mocFiles is included in the array this effect will be triggered when the MC date is changed and causes the old values will be put back in the form.
        // eslint-disable-next-line
    }, [mocResponse, patient, mocId]);

    useEffect(() => {
        if (!initialValues || initialValues.mocId === mocId) {
            return;
        }

        setTimeout(() => {
            const elementId = elementAnchorIdFor('general-information');
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }, [initialValues, mocId]);

    const handleSubmit = useCallback(
        async (values: Values) => {
            const isNewMoc = mocId === 0;
            const mocToSave = mapSubmitValues(values, { id: mocId, mocTypeCode }, patient.id);
            const response = await api.post<SaveResponse>('mocs', mocToSave);

            if (response.status < 400) {
                const mocId = response.data.id;
                const validationErrors = response.data.validationErrors;
                const isValid = !validationErrors.length;
                const newInitialValues = { ...values, initialSections: getSectionCodes(mocTypeCode, values) };

                showSnack('Registration was saved.');

                setInitialValues(newInitialValues);
                setValidatedSections(mapValidationToSections(validationErrors, mocTypeCode, newInitialValues));

                if (isNewMoc) {
                    onMocCreated(mocId, values.mocDate, isValid);
                } else {
                    onMocChanged(mocId, values.mocDate, isValid);
                }

                onMocFilesChanged(response.data.mocFiles);
            } else {
                showSnack('Could not save registration', 'error');
            }
        },
        [api, mocId, mocTypeCode, patient, onMocCreated, showSnack, onMocChanged, onMocFilesChanged]
    );

    if (!referenceData || !initialValues) {
        return (
            <Backdrop open={true} className={styles.detail_backdrop}>
                <CircularProgress />
                <Typography className={styles.detail_backdrop_text}>Loading MOC....</Typography>
            </Backdrop>
        );
    }

    const isLoading = (mocId !== 0 && mocId !== initialValues.mocId) || mocInitialValuesResponse.isLoading;

    return (
        <ReferenceDataProvider value={referenceData}>
            <MocForm
                patient={patient}
                loading={isLoading}
                initialValues={initialValues}
                validatedSections={validatedSections}
                onSubmit={handleSubmit}
            />
            <OncoBaseSnackbar {...snackBarProps} />
        </ReferenceDataProvider>
    );
};

export default MocDetail;
