import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { Values } from '../../forms/MocForm.config';
import { styles } from './styles';

import Header from './sections/header';
import Footer from './sections/footer';
import GeneralInformation from './sections/generalInformation/GeneralInformation';
import Annex55 from './sections/annex_55/Annex55';
import PatientCharacteristics from './sections/patientCharacteristics/PatientCharacteristics';
import Surgery from './sections/surgery/ckzOesophagus/Surgery';
import PostSurgery from './sections/postSurgery/ckzOesophagus/PostSurgery';
import GeneralRemarks from './sections/generalRemarks/GeneralRemarks';

import { ReferenceDataMapper } from '.';
import { Patients } from '../../../../../api/types';

type Patient = Patients.GetPatient.PatientDetail;

export default function ckzOesophagus(patient: Patient, values: Values, mapReferenceData: ReferenceDataMapper) {
    const hadSurgery = values.generalInformation.hadSurgery;
    const hasAnnex55 = values.generalInformation.hasAnnex55;

    return (
        <Document>
            <Page style={styles.body}>
                <Header mocTypeCode={values.mocTypeCode} mocDate={values.mocDate} patient={patient} />

                <GeneralInformation values={values.generalInformation} mapReferenceData={mapReferenceData} />
                {hasAnnex55 && (
                    <Annex55
                        values={values.annex55}
                        mapReferenceData={mapReferenceData}
                        lesionToTreatCode={values.generalInformation.lesionToTreatCode}
                    />
                )}
                {hadSurgery ? (
                    <>
                        <PatientCharacteristics
                            values={values.patientCharacteristics}
                            mapReferenceData={mapReferenceData}
                        />
                        <Surgery values={values.oesophagusSurgery} mapReferenceData={mapReferenceData} />
                        <PostSurgery values={values.oesophagusPostSurgery} mapReferenceData={mapReferenceData} />
                    </>
                ) : null}
                <GeneralRemarks {...values.generalRemarks} />

                <Footer />
            </Page>
        </Document>
    );
}
