import { Grid } from '@material-ui/core';
import React from 'react';
import ItemList from '../components/ItemList';
import { ResectionDate } from '../sections/pathology/Pathology.config';
import ResectionTypeList from './ResectionTypeList';
import { FastDateField, FastSelectField, FastTextArea, FileNameQuestion } from '../components';
import { Mocs } from '../../../../../api/types';

type Type = Mocs.GetReferenceData.Moc_Ref_EnetsResectionType;
type Value = Mocs.GetReferenceData.Moc_Ref_EnetsResectionValue;

function detail(
    name: string,
    referenceTypeDataFilter?: (reference: Type) => boolean,
    referenceValueDataFilter?: (reference: Value) => boolean
) {
    return (
        <>
            <Grid item xs={12} md={4}>
                <FastDateField name={`${name}.date`} label="Datum*" />
            </Grid>
            <Grid item xs={12} md={8}>
                <FastSelectField
                    name={`${name}.locationCode`}
                    prompt="Selecteer locatie resectie"
                    placeholder="Locatie resectie*"
                    referenceDataPath="primaryTumors"
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <FastSelectField
                    name={`${name}.kindCode`}
                    prompt="Selecteer een soort"
                    placeholder="Soort"
                    referenceDataPath="resectionKinds"
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <FastTextArea name={`${name}.comment`} label="Commentaar" />
            </Grid>
            <Grid item xs={12}>
                <FileNameQuestion type="Resection" question="Bestand opladen" name={`${name}.fileId`} />
            </Grid>
            <Grid item xs={12}>
                <ResectionTypeList
                    name={`${name}.resectionTypes`}
                    referenceTypeDataFilter={referenceTypeDataFilter}
                    referenceValueDataFilter={referenceValueDataFilter}
                />
            </Grid>
        </>
    );
}

function create(): ResectionDate {
    return {
        date: null,
        comment: '',
        locationCode: '',
        kindCode: '',
        resectionTypes: [],
        fileId: null,
    };
}

type ResectionDateListProps = {
    name: string;
    referenceTypeDataFilter?: (reference: Type) => boolean;
    referenceValueDataFilter?: (reference: Value) => boolean;
};

export default function ResectionDateList({
    name,
    referenceTypeDataFilter,
    referenceValueDataFilter,
}: ResectionDateListProps) {
    return (
        <ItemList
            name={`${name}.resectionDates`}
            minItems={0}
            addText="Extra resectie toevoegen"
            title="Resectie"
            detail={(refName) => detail(refName, referenceTypeDataFilter, referenceValueDataFilter)}
            create={create}
        />
    );
}
