import { Mocs } from '../../../../../../../api/types';
import { CodeAndOtherValue, CodeValue } from '../../../MocForm.config';
import {
    toMocBoolean,
    toMocDate,
    toMocNumber,
    toValuesBoolean,
    toValuesString,
    toValuesNumber,
    toValuesDate,
} from '../../../mappers';

export type GetMocValues = Pick<
    Mocs.CkzPancreasData,
    | 'dateOfSurgery'
    | 'hadOtherTreatmentBeforeThisSurgery'
    | 'surgeryTypeCode'
    | 'minimallyInvasiveSurgeryTypeCode'
    | 'reasonForConversion'
    | 'nomenclatureCode'
    | 'pancreaticoduodenectomyCode'
    | 'lesionLocalisationCode'
    | 'tumourLocalisationCode'
    | 'hadSimultaneousVascularResection'
    | 'hadSimultaneousOtherOrganResection'
    | 'resectionMarginCode'
    | 'hadLymphadenectomy'
    | 'numberOfLymphNodesRetrieved'
    | 'numberOfLymphNodesWithTumouralInvolvement'
    | 'treatmentTypes'
    | 'lymphadenectomyRegions'
    | 'vascularResectionTypes'
    | 'simultaneousOtherOrganResections'
    | 'preNeoadjuvantTreatmentPancreaticResectabilityStatusCode'
    | 'preSurgeryPancreaticResectabilityStatusCode'
>;

export type SaveMocValues = Pick<
    Mocs.SaveMoc.CkzPancreasFields,
    | 'hadOtherTreatmentBeforeThisSurgery'
    | 'dateOfSurgery'
    | 'surgeryTypeCode'
    | 'minimallyInvasiveSurgeryTypeCode'
    | 'reasonForConversion'
    | 'nomenclatureCode'
    | 'pancreaticoduodenectomyCode'
    | 'lesionLocalisationCode'
    | 'tumourLocalisationCode'
    | 'hadSimultaneousVascularResection'
    | 'hadSimultaneousOtherOrganResection'
    | 'resectionMarginCode'
    | 'hadLymphadenectomy'
    | 'numberOfLymphNodesRetrieved'
    | 'numberOfLymphNodesWithTumouralInvolvement'
    | 'treatmentTypes'
    | 'lymphadenectomyRegions'
    | 'vascularResectionTypes'
    | 'simultaneousOtherOrganResections'
    | 'preNeoadjuvantTreatmentPancreaticResectabilityStatusCode'
    | 'preSurgeryPancreaticResectabilityStatusCode'
>;

export type FormValues = {
    hadOtherTreatmentBeforeThisSurgery: boolean | '';
    dateOfSurgery: string | null;
    surgeryTypeCode: string;
    minimallyInvasiveSurgeryTypeCode: string;
    reasonForConversion: string;
    nomenclatureCode: string;
    pancreaticoduodenectomyCode: string;
    lesionLocalisationCode: string;
    tumourLocalisationCode: string;
    hadSimultaneousVascularResection: boolean | '';
    hadSimultaneousOtherOrganResection: boolean | '';
    resectionMarginCode: string;
    hadLymphadenectomy: boolean | '';
    numberOfLymphNodesRetrieved: number | '';
    numberOfLymphNodesWithTumouralInvolvement: number | '';
    preNeoadjuvantTreatmentPancreaticResectabilityStatusCode: string;
    preSurgeryPancreaticResectabilityStatusCode: string;
    treatmentTypes: TreatmentDetails[];
    lymphadenectomyRegionCodes: CodeValue[];
    vascularResectionTypes: VascularResectionTypeDetail[];
    simultaneousOtherOrganResections: CodeAndOtherValue[];
};

export type TreatmentDetails = {
    code: string;
    startDate: string | null;
    latestTreatmentDate: string | null;
    chemotherapyTypeCode: string;
    chemotherapyTypeOtherValue: string;
    typeOfSurgery: string;
    latestSurgeryDate: string | null;
    otherValue: string;
    otherDate: string | null;
};

export type VascularResectionTypeDetail = {
    code: string;
    reconstructionCode: string;
    arterialResectionCode: string;
};

export const mapInitialValues = (moc: GetMocValues): FormValues => ({
    hadOtherTreatmentBeforeThisSurgery: toValuesBoolean(moc.hadOtherTreatmentBeforeThisSurgery),
    dateOfSurgery: toValuesDate(moc.dateOfSurgery),
    reasonForConversion: toValuesString(moc.reasonForConversion),
    surgeryTypeCode: toValuesString(moc.surgeryTypeCode),
    minimallyInvasiveSurgeryTypeCode: toValuesString(moc.minimallyInvasiveSurgeryTypeCode),
    nomenclatureCode: toValuesString(moc.nomenclatureCode),
    pancreaticoduodenectomyCode: toValuesString(moc.pancreaticoduodenectomyCode),
    lesionLocalisationCode: toValuesString(moc.lesionLocalisationCode),
    tumourLocalisationCode: toValuesString(moc.tumourLocalisationCode),
    hadSimultaneousVascularResection: toValuesBoolean(moc.hadSimultaneousVascularResection),
    hadLymphadenectomy: toValuesBoolean(moc.hadLymphadenectomy),
    numberOfLymphNodesRetrieved: toValuesNumber(moc.numberOfLymphNodesRetrieved),
    numberOfLymphNodesWithTumouralInvolvement: toValuesNumber(moc.numberOfLymphNodesWithTumouralInvolvement),
    hadSimultaneousOtherOrganResection: toValuesBoolean(moc.hadSimultaneousOtherOrganResection),
    resectionMarginCode: toValuesString(moc.resectionMarginCode),
    preNeoadjuvantTreatmentPancreaticResectabilityStatusCode: toValuesString(
        moc.preNeoadjuvantTreatmentPancreaticResectabilityStatusCode
    ),
    preSurgeryPancreaticResectabilityStatusCode: toValuesString(moc.preSurgeryPancreaticResectabilityStatusCode),
    treatmentTypes: moc.treatmentTypes.map((t) => ({
        code: t.treatmentTypeCode,
        startDate: t.startDate,
        latestTreatmentDate: t.latestTreatmentDate,
        latestSurgeryDate: t.latestSurgeryDate,
        typeOfSurgery: toValuesString(t.typeOfSurgery),
        otherValue: toValuesString(t.otherValue),
        otherDate: t.otherDate,
        chemotherapyTypeCode: t.chemotherapyTypeCode,
        chemotherapyTypeOtherValue: toValuesString(t.chemotherapyTypeOtherValue),
    })),
    lymphadenectomyRegionCodes: moc.lymphadenectomyRegions.map((l) => ({ code: l.lymphadenectomyRegionCode })),
    vascularResectionTypes: moc.vascularResectionTypes.map((v) => ({
        code: v.vascularResectionTypeCode,
        reconstructionCode: v.reconstructionTypeCode,
        arterialResectionCode: v.arterialResectionTypeCode,
    })),
    simultaneousOtherOrganResections: moc.simultaneousOtherOrganResections.map((s) => ({
        code: s.simultaneousOtherOrganResectionCode,
        otherValue: s.otherValue,
    })),
});

export const getNewMocValues = (): GetMocValues => ({
    hadOtherTreatmentBeforeThisSurgery: null,
    dateOfSurgery: null,
    surgeryTypeCode: '',
    minimallyInvasiveSurgeryTypeCode: '',
    reasonForConversion: '',
    nomenclatureCode: '',
    pancreaticoduodenectomyCode: '',
    lesionLocalisationCode: '',
    tumourLocalisationCode: '',
    hadSimultaneousVascularResection: null,
    hadSimultaneousOtherOrganResection: null,
    resectionMarginCode: '',
    hadLymphadenectomy: null,
    numberOfLymphNodesRetrieved: 0,
    numberOfLymphNodesWithTumouralInvolvement: 0,
    preNeoadjuvantTreatmentPancreaticResectabilityStatusCode: '',
    preSurgeryPancreaticResectabilityStatusCode: '',
    treatmentTypes: [],
    lymphadenectomyRegions: [],
    vascularResectionTypes: [],
    simultaneousOtherOrganResections: [],
});

export const mapSubmitValues = (form: FormValues): SaveMocValues => ({
    hadOtherTreatmentBeforeThisSurgery: toMocBoolean(form.hadOtherTreatmentBeforeThisSurgery),
    dateOfSurgery: form.dateOfSurgery,
    surgeryTypeCode: form.surgeryTypeCode,
    minimallyInvasiveSurgeryTypeCode: form.minimallyInvasiveSurgeryTypeCode,
    reasonForConversion: form.reasonForConversion,
    nomenclatureCode: form.nomenclatureCode,
    pancreaticoduodenectomyCode: form.pancreaticoduodenectomyCode,
    lesionLocalisationCode: form.lesionLocalisationCode,
    tumourLocalisationCode: form.tumourLocalisationCode,
    hadSimultaneousVascularResection: toMocBoolean(form.hadSimultaneousVascularResection),
    hadSimultaneousOtherOrganResection: toMocBoolean(form.hadSimultaneousOtherOrganResection),
    resectionMarginCode: form.resectionMarginCode,
    hadLymphadenectomy: toMocBoolean(form.hadLymphadenectomy),
    numberOfLymphNodesRetrieved: toMocNumber(form.numberOfLymphNodesRetrieved),
    numberOfLymphNodesWithTumouralInvolvement: toMocNumber(form.numberOfLymphNodesWithTumouralInvolvement),
    preNeoadjuvantTreatmentPancreaticResectabilityStatusCode:
        form.preNeoadjuvantTreatmentPancreaticResectabilityStatusCode,
    preSurgeryPancreaticResectabilityStatusCode: form.preSurgeryPancreaticResectabilityStatusCode,
    treatmentTypes: form.treatmentTypes.map((mtt) => ({
        ...mtt,
        chemotherapyTypeCode: mtt.chemotherapyTypeCode,
        startDate: toMocDate(mtt.startDate),
        latestTreatmentDate: toMocDate(mtt.latestTreatmentDate),
        latestSurgeryDate: toMocDate(mtt.latestSurgeryDate),
        other: mtt.otherValue,
        otherDate: toMocDate(mtt.otherDate),
    })),
    lymphadenectomyRegions: form.lymphadenectomyRegionCodes,
    vascularResectionTypes: form.vascularResectionTypes,
    simultaneousOtherOrganResections: form.simultaneousOtherOrganResections,
});

export const initialValues: FormValues = mapInitialValues(getNewMocValues());
