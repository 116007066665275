import React, { useState, useMemo } from 'react';
import { FieldArray, useFormikContext, getIn } from 'formik';
import { Values } from '../MocForm.config';
import { Button, Container, FormLabel } from '@material-ui/core';
import styles from './DragAndDropZone.module.css';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Mocs } from '../../../../../api/types';
import useReferenceData from '../../referenceData/useReferenceData';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import DroppableList from './DroppableList';
import DraggableItem from './DraggableItem';

type ReferenceData = Mocs.GetReferenceData.Response;
type ReferenceObject = { code: string };

type DragAndDropZoneProps<TItemType extends ReferenceObject> = {
    formikName: string;
    refDataName: keyof ReferenceData;
    sourceTitle: string;
    destinationTitle: string;
    renderItem: (
        item: TItemType,
        isSelected: boolean,
        refItem: TItemType | undefined,
        onClick?: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined
    ) => React.ReactElement<{}>;
};

function getSourceList<TItemType extends ReferenceObject>(refdata: TItemType[], destinationData: TItemType[]) {
    return refdata.filter((refitem) => !destinationData.find((dest) => dest.code === refitem.code));
}

export default function DragAndDropZone<TItemType extends ReferenceObject>(props: DragAndDropZoneProps<TItemType>) {
    const referenceDataPath: keyof ReferenceData = props.refDataName;
    const { values } = useFormikContext<Values>();
    var destinationData: TItemType[] = getIn(values, props.formikName);
    const referenceData = useReferenceData();
    const destinationName = 'Destination';
    const sourceName = 'Source';
    const refData = getIn(referenceData, referenceDataPath) as TItemType[];
    const sourceData = useMemo(() => getSourceList(refData, destinationData), [refData, destinationData]);
    const [isSelected, setIsSelected] = useState<boolean[]>(sourceData.map(() => false));
    const [selectedValue, setSelectedValue] = useState<TItemType>();
    const [isFieldSelected, setIsFieldSelected] = useState<boolean[]>(destinationData.map(() => false));
    const [fieldIndex, setFieldIndex] = useState<number | undefined>(undefined);

    function handleSourceClick(item: TItemType, index: number) {
        let newIsSelected = [...isSelected];
        newIsSelected = newIsSelected.map((s, key) => (key !== index ? false : s));
        newIsSelected[index] = !newIsSelected[index];
        setIsSelected(newIsSelected);
        newIsSelected[index] ? setSelectedValue(item) : setSelectedValue(undefined);
    }
    function handleDestinationClick(index: number) {
        let temp = [...isFieldSelected];
        temp = temp.map((t, key) => {
            return key !== index ? false : t;
        });
        temp[index] = !temp[index];
        setIsFieldSelected(temp);
        setFieldIndex(index);
    }
    function handleRemove(remove: (index: number) => void) {
        if (fieldIndex !== undefined) {
            remove(fieldIndex!);
            setIsFieldSelected(isFieldSelected.map((_, key) => false));
            setFieldIndex(undefined);
        }
    }

    function getRefItem(item: TItemType) {
        return refData.find((refItem) => refItem.code === item.code);
    }

    function handleSwap(isUpward: boolean, swap: (indexA: number, indexB: number) => void) {
        if (fieldIndex !== undefined) {
            if (isUpward) {
                fieldIndex !== 0 && swap(fieldIndex, fieldIndex - 1);
            } else {
                fieldIndex < destinationData.length - 1 && swap(fieldIndex, fieldIndex + 1);
            }
            setIsFieldSelected(isFieldSelected.map((_, key) => false));
            setFieldIndex(undefined);
        }
    }

    function handleAdd(push: (obj: any) => void) {
        if (selectedValue !== undefined) push({ ...selectedValue });
        setIsSelected(sourceData.map(() => false));
        setSelectedValue(undefined);
    }

    function resultHasValidSourceAndDestination(result: DropResult) {
        return (
            result.destination === null ||
            result.destination === undefined ||
            result.destination.droppableId === null ||
            result.source === null ||
            result.source.droppableId === null
        );
    }

    function onDragEnd(
        result: DropResult,
        push: (obj: any) => void,
        remove: <T>(index: number) => T | undefined,
        swap: (indexA: number, indexB: number) => void
    ) {
        if (resultHasValidSourceAndDestination(result)) {
            return;
        }
        const source = result.source.droppableId;
        const destination = result.destination!.droppableId;
        if (source === destination) {
            // do step 3c
            if (source === destinationName) {
                if (result.source.index > result.destination!.index) {
                    let b = result.source.index;
                    let a = result.destination!.index;
                    while (b > a) {
                        swap(b, b - 1);
                        b--;
                    }
                } else {
                    let a = result.source.index;
                    let b = result.destination!.index;
                    while (a < b) {
                        swap(a, a + 1);
                        a++;
                    }
                }
            }
            return;
        }
        if (source === sourceName) {
            push({ ...sourceData[result.source.index] });
        } else {
            remove(result.source.index);
        }
        return;
    }

    return (
        <FieldArray name={props.formikName}>
            {({ push, remove, swap }) => (
                <DragDropContext onDragEnd={(result) => onDragEnd(result, push, remove, swap)}>
                    <div>
                        <FormLabel component="legend" style={{ marginLeft: '14px', marginBottom: '10px' }}>
                            {props.destinationTitle}
                        </FormLabel>
                        <div className={`${styles.border} ${styles.listWrapperLeft}`}>
                            <DroppableList droppableId={destinationName}>
                                {destinationData.map((item, index) => (
                                    <DraggableItem
                                        key={JSON.stringify(item)}
                                        draggableId={JSON.stringify(item)}
                                        index={index}
                                    >
                                        {props.renderItem(item, isFieldSelected[index], getRefItem(item), () =>
                                            handleDestinationClick(index)
                                        )}
                                    </DraggableItem>
                                ))}
                            </DroppableList>
                        </div>
                    </div>

                    <Container className={styles.buttonContainer}>
                        <Button onClick={() => handleAdd(push)}>
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button onClick={() => handleRemove(remove)}>
                            <KeyboardArrowRightIcon />
                        </Button>
                        <Button onClick={() => handleSwap(true, swap)}>
                            <KeyboardArrowUpIcon />
                        </Button>
                        <Button onClick={() => handleSwap(false, swap)}>
                            <KeyboardArrowDownIcon />
                        </Button>
                    </Container>
                    <div>
                        <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                            {props.sourceTitle}
                        </FormLabel>
                        <div className={`${styles.border} ${styles.listWrapperRight}`}>
                            <DroppableList droppableId={sourceName}>
                                {sourceData.map((item, index) => (
                                    <DraggableItem
                                        key={JSON.stringify(item)}
                                        draggableId={JSON.stringify(item)}
                                        index={index}
                                    >
                                        {props.renderItem(item, isSelected[index], getRefItem(item), () =>
                                            handleSourceClick(item, index)
                                        )}
                                    </DraggableItem>
                                ))}
                            </DroppableList>
                        </div>
                    </div>
                </DragDropContext>
            )}
        </FieldArray>
    );
}
