import React from 'react';
import { styles } from '../pdf/renderers/styles';

export default function jsxPdfToHtml(root: React.ReactElement): string {
    return elementToString(root);
}

function elementToString(e: React.ReactElement): string {
    if (!e) {
        return '';
    }
    if (typeof e === 'string') {
        return e;
    }
    const { type, props } = e;

    if (props?.fixed) {
        return '';
    }
    if (typeof type === 'string') {
        const htmlStyle = { ...props.style };
        const isSubHeader = JSON.stringify(htmlStyle) === JSON.stringify(styles.mocSubHeader);
        const isMainSubItem = JSON.stringify(htmlStyle) === JSON.stringify(styles.mainSubItem);
        const isSubItem = JSON.stringify(htmlStyle) === JSON.stringify(styles.subItem);

        if (htmlStyle?.fontFamily === 'Helvetica-Bold') {
            htmlStyle.fontFamily = 'Helvetica';
            htmlStyle.fontWeight = 'bold';
            htmlStyle.fontSize = '110%';
        }

        if (!props.children) {
            return '';
        }
        if (typeof props.children === 'string') {
            return isSubHeader ? `<br/> ${props.children} <p/>` : ` ${props.children} <p/>`;
        }
        if (props.children.length) {
            return props.children.map((c: any) => elementToString(c)).join(' ');
        } else {
            if (isMainSubItem) {
                return `•${elementToString(props.children)} <p/>`;
            }
            if (isSubItem) {
                return `••${elementToString(props.children)} <p/>`;
            }
            return `${elementToString(props.children)} <p/>`;
        }
    }
    if (typeof type === 'function') {
        return ` ${elementToString((type as any)(props))}`;
    }
    if (typeof type === 'symbol') {
        var children = props.children;
        if (children instanceof Array) {
            return props.children.map((c: any) => elementToString(c)).join(' ');
        } else {
            var arr = [children];
            return arr.map((c: any) => elementToString(c)).join(' ');
        }
    }
    return '';
}
