import React from 'react';
import MocSectionContainer from '../MocSectionContainer';
import { Grid } from '@material-ui/core';
import MedicalImagingList from '../../enets/MedicalImagingList';
import FormikNumberField from '../../../../../../styles/components/forms/FormikNumberField';
import { FastSelectField } from '../../components';
import { Mocs } from '../../../../../../api/types';
import { FastField } from 'formik';

type CtType = Mocs.GetReferenceData.Moc_Ref_Ct;
type CnType = Mocs.GetReferenceData.Moc_Ref_Cn;
type CmType = Mocs.GetReferenceData.Moc_Ref_Cm;

type Props = {
    isValid: boolean | null;
};

export default function ImagingDimensions(props: Props) {
    return (
        <MocSectionContainer code="imaging-dimensions" isValid={props.isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <FastField
                        name="imagingDimensions.length"
                        type="number"
                        component={FormikNumberField}
                        label="Lengte (mm)"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastField
                        name="imagingDimensions.width"
                        type="number"
                        component={FormikNumberField}
                        label="Breedte (mm)"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastField
                        name="imagingDimensions.height"
                        type="number"
                        component={FormikNumberField}
                        label="Hoogte (mm)"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CtType>
                        name="imagingDimensions.ctCode"
                        placeholder="cT"
                        prompt="Selecteer cT waarde"
                        referenceDataPath="ctTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CnType>
                        name="imagingDimensions.cnCode"
                        placeholder="cN"
                        prompt="Selecteer cN waarde"
                        referenceDataPath="cnTypes"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <FastSelectField<CmType>
                        name="imagingDimensions.cmCode"
                        placeholder="cM"
                        prompt="Selecteer cM waarde"
                        referenceDataPath="cmTypes"
                    />
                </Grid>
                <Grid item xs={12}>
                    <MedicalImagingList name="imagingDimensions" isSarcomen={true}></MedicalImagingList>
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
}
