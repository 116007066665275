import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { format, parseJSON } from 'date-fns';
import { Patients } from '../../../../api/types';
import { Permissions } from '../../../../auth/usePermissions';
import useHospital from '../../../../hospital/useHospital';
import { useDialog } from '../../../../styles/components/MaterialDialogService';
import MocOverview from '../../mocs/MocOverview';
import MocDetail from '../../mocs/MocDetail';
import useApi from '../../../../api/useApi';
import { ShowSnack } from '../../../../styles/components/OncoBaseSnackbar';

type Moc = Patients.GetPatient.Moc;
type PatientFile = Patients.GetPatient.File;
type Patient = Patients.GetPatient.PatientDetail;

type MocType = {
    name: string;
    code: string;
};

type MocDetailRouteParams = { mocId?: string };

type Props = {
    patient: Patient;
    patientBaseUrl: string;
    patientMocs: Moc[];
    permissions: Permissions;
    showSnack: ShowSnack;
    mocFiles: PatientFile[];
    onMocFilesChanged: (mocFiles: PatientFile[]) => void;
};

const PatientMocs: React.FC<Props> = ({
    patient,
    patientBaseUrl,
    patientMocs,
    permissions,
    showSnack,
    mocFiles,
    onMocFilesChanged,
}) => {
    const history = useHistory();
    const dialog = useDialog();
    const routeMatch = useRouteMatch<MocDetailRouteParams>(`${patientBaseUrl}/mocs/:mocId`);
    const { hospital } = useHospital();
    const [selectedMocId, setSelectedMocId] = useState<number | null>(null);
    const [mocs, setMocs] = useState<Moc[]>(patientMocs);
    const mocIdString = (routeMatch && routeMatch.params.mocId) || '';
    const { canAddNewMoc } = permissions;
    const api = useApi();

    useEffect(() => {
        setMocs(patientMocs);
    }, [patientMocs]);

    useEffect(() => {
        const isNew = mocIdString === 'new';
        const mocIdToSelect = isNew ? 0 : parseInt(mocIdString);
        const mocIdIsValid = !isNaN(mocIdToSelect) && mocs.some((m) => m.id === mocIdToSelect);
        mocIdIsValid ? setSelectedMocId(mocIdToSelect) : history.push(patientBaseUrl);
    }, [mocIdString, history, patientBaseUrl, mocs]);

    const handleMocDetail = (id: number) => {
        if (id === selectedMocId) {
            return;
        }
        const goToDetail = () => {
            setMocs(mocs.filter((m) => m.id > 0));
            history.push(`${patientBaseUrl}/mocs/${id}`);
        };
        const newMoc = mocs!.filter((m) => m.id === 0)[0];
        if (newMoc) {
            dialog({
                variant: 'yes/no',
                title: 'You have unsaved changes',
                description: (
                    <span>
                        Your new <strong>{newMoc.mocTypeName}</strong> is not yet saved, <br />
                        do you want to continue and lose your changes?
                    </span>
                ),
            }).then(() => goToDetail());
        } else {
            goToDetail();
        }
    };

    const handleNewMoc = (type: MocType) => {
        const addNewMoc = () => {
            setMocs([
                ...mocs.filter((m) => m.id > 0),
                {
                    id: 0,
                    creationDate: new Date().toISOString(),
                    mocTypeName: type.name,
                    mocTypeCode: type.code,
                    mocDate: null,
                    isValid: false,
                },
            ]);
            history.push(`${patientBaseUrl}/mocs/new`);
        };
        const newMoc = mocs!.filter((m) => m.id === 0)[0];
        if (newMoc) {
            dialog({
                variant: 'yes/no',
                title: 'You have unsaved changes',
                description: (
                    <span>
                        Your new <strong>{newMoc.mocTypeName}</strong> is not yet saved, <br />
                        do you want to continue and lose your changes?
                    </span>
                ),
            }).then(() => addNewMoc());
        } else {
            addNewMoc();
        }
    };

    const handleNewMocCreated = (id: number, mocDate: string | null, isValid: boolean) => {
        const newMoc = mocs.find((m) => m.id === 0);
        const newSavedMoc = { ...newMoc!, id, isValid, mocDate };
        const otherMocs = mocs.filter((m) => m !== newMoc);

        setMocs([newSavedMoc, ...otherMocs]);
        history.push(`${patientBaseUrl}/mocs/${id}`);
    };

    const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd MMM yyyy') : '');

    const handleRemoveMoc = async (id: number) => {
        const moc = mocs!.find((m) => m.id === id);
        const isNewMoc = id === 0;
        const isSavedMoc = moc!.id !== 0;
        const mocDateSet = moc!.mocDate;
        const mocDateNotSet = !moc!.mocDate;

        await dialog({
            variant: 'yes/no',
            yesName: `Remove moc`,
            title: `Remove ${moc!.mocTypeName}`,
            description: (
                <>
                    Are you sure you would like to remove <strong>{moc!.mocTypeName}</strong>?
                    <br />
                    It was created on <strong>{showDate(moc!.creationDate)}</strong>.
                    <br />
                    {isNewMoc && (
                        <>
                            <strong>The moc was never saved.</strong>
                            <br />
                        </>
                    )}
                    {isSavedMoc && mocDateSet && (
                        <>
                            The moc is from <strong>{showDate(moc!.mocDate)}</strong>.<br />
                        </>
                    )}
                    {isSavedMoc && mocDateNotSet && (
                        <>
                            <strong>The moc date is not set!</strong>
                            <br />
                        </>
                    )}
                </>
            ),
        }).then(async () => {
            if (isNewMoc) {
                setMocs(mocs!.filter((m) => m.id !== id));
            } else {
                const { status } = await api.delete<{}>(`mocs/${id}`);

                if (status < 400) {
                    setMocs(mocs!.filter((m) => m.id !== id));
                    showSnack('Moc was removed');
                } else {
                    showSnack('Moc could not removed', 'error');
                }
            }
        });
    };

    const handleMocChanged = (mocId: number, mocDate: string | null, isValid: boolean) => {
        const moc = mocs.find((m) => m.id === mocId)!;

        if (moc.isValid !== isValid || moc.mocDate !== mocDate) {
            const otherMocs = mocs.filter((m) => m !== moc);
            setMocs([{ ...moc, mocDate, isValid }, ...otherMocs]);
        }
    };

    const onSelectedMocFilesChanged = (newSelectedMocFiles: PatientFile[]) => {
        const notSelectedMocFiles = mocFiles.filter((f) => f.mocId !== selectedMocId);
        const newMocFiles = [...notSelectedMocFiles, ...newSelectedMocFiles];
        onMocFilesChanged(newMocFiles);
    };

    const selectedMoc = mocs.find((m) => m.id === selectedMocId);
    const selectedMocFiles = mocFiles.filter((f) => f.mocId === selectedMocId);

    return (
        <>
            <MocOverview
                mocs={mocs}
                mocDomains={hospital!.domains}
                selectedMocId={selectedMocId}
                isReadOnly={!canAddNewMoc}
                onMocDetail={handleMocDetail}
                onNewMoc={handleNewMoc}
                onRemoveMoc={handleRemoveMoc}
            />
            {selectedMoc && (
                <MocDetail
                    patient={patient}
                    mocId={selectedMoc.id}
                    mocTypeCode={selectedMoc.mocTypeCode as any}
                    isReadOnly={!canAddNewMoc}
                    onMocCreated={handleNewMocCreated}
                    onMocChanged={handleMocChanged}
                    mocFiles={selectedMocFiles}
                    onMocFilesChanged={onSelectedMocFilesChanged}
                />
            )}
        </>
    );
};

export default PatientMocs;
