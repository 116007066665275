import React, { Fragment } from 'react';
import { Text, View } from '@react-pdf/renderer';
import * as surgery from '../../../../../forms/sections/surgery/ckzPancreas/Surgery.config';
import { styles } from '../../../styles';
import {
    QuestionAnswer,
    QuestionYesNoAnswer,
    QuestionDateAnswer,
    QuestionListCodeAndOtherValueAnswer,
    QuestionListAnswer,
    QuestionProps,
    formatDateWithPrefix,
} from '../../Questions';
import List, { Item, SubItem } from '../../List';
import { ReferenceDataMapper } from '../../..';

type SurgeryProps = {
    values: surgery.FormValues;
    mapReferenceData: ReferenceDataMapper;
};

export default function Surgery({ values, mapReferenceData }: SurgeryProps) {
    const simultaneousOtherOrganResectionsValues = values.simultaneousOtherOrganResections.map((value) => ({
        code: mapReferenceData('simultaneousOtherOrganResections', value.code),
        otherValue: value.otherValue,
    }));

    const lymphadenectomyRegionValues = values.lymphadenectomyRegionCodes.map((value) =>
        mapReferenceData('lymphadenectomyRegions', value.code)
    );

    return (
        <View>
            <Text style={styles.header}>Surgery</Text>
            <QuestionYesNoAnswer
                question="Did patient receive any other treatment modality before this surgical procedure"
                answer={values.hadOtherTreatmentBeforeThisSurgery}
            />
            <QuestionListTreatmentDetailsAnswer
                question="Other treatment modalities"
                answer={values.treatmentTypes}
                mapper={mapReferenceData}
            />
            <QuestionDateAnswer question="Date of surgery" answer={values.dateOfSurgery} />
            <QuestionAnswer
                question="Type of surgery"
                answer={mapReferenceData('surgeryTypes', values.surgeryTypeCode)}
            />
            <QuestionAnswer
                question="Type of Minimally Invasive surgery"
                answer={mapReferenceData('minimallyInvasiveSurgeryTypes', values.minimallyInvasiveSurgeryTypeCode)}
            />
            <QuestionAnswer question="Reason for conversion" answer={values.reasonForConversion} />
            <QuestionAnswer
                question="Nomenclature code"
                answer={mapReferenceData('nomenclatureCodes', values.nomenclatureCode)}
            />
            <QuestionAnswer
                question="Localisation lesion"
                answer={mapReferenceData('localisations', values.lesionLocalisationCode)}
            />
            <QuestionAnswer
                question="Localisation tumour"
                answer={mapReferenceData('tumourLocations', values.tumourLocalisationCode)}
            />
            <QuestionYesNoAnswer
                question="Simultaneous vascular resection"
                answer={values.hadSimultaneousVascularResection}
            />
            <ResectionAnswer answer={values.vascularResectionTypes} mapper={mapReferenceData} />
            <QuestionYesNoAnswer
                question="Simultaneous other organ resection"
                answer={values.hadSimultaneousOtherOrganResection}
            />
            <QuestionListCodeAndOtherValueAnswer
                question="Type of Other organ resection"
                answer={simultaneousOtherOrganResectionsValues}
            />
            <QuestionAnswer
                question="Residual disease - resection margins"
                answer={mapReferenceData('resectionMargins', values.resectionMarginCode)}
            />
            <QuestionYesNoAnswer question="Lymphadenectomy" answer={values.hadLymphadenectomy} />
            <QuestionListAnswer question="Region" answer={lymphadenectomyRegionValues} />
            <QuestionAnswer question="Number of lymph nodes retrieved" answer={values.numberOfLymphNodesRetrieved} />
            <QuestionAnswer
                question="Number of lymph nodes with tumoural involvement"
                answer={values.numberOfLymphNodesWithTumouralInvolvement}
            />
        </View>
    );
}

type QuestionListTreatmentDetailsAnswerProps = QuestionProps & {
    answer: surgery.TreatmentDetails[];
    mapper: ReferenceDataMapper;
};

function QuestionListTreatmentDetailsAnswer(props: QuestionListTreatmentDetailsAnswerProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}</Text>
            <List>
                {props.answer.map((treatment, i) => (
                    <Fragment key={i}>
                        <Item>{props.mapper('treatmentTypes', treatment.code)}</Item>
                        <SubItem>{formatDateWithPrefix('Started on', treatment.startDate)}</SubItem>
                        <SubItem>{formatDateWithPrefix('Latest on', treatment.latestTreatmentDate)}</SubItem>
                        <SubItem>
                            {treatment.chemotherapyTypeOtherValue
                                ? `${props.mapper('chemotherapyTypes', treatment.chemotherapyTypeCode)} - ${
                                      treatment.chemotherapyTypeOtherValue
                                  }`
                                : props.mapper('chemotherapyTypes', treatment.chemotherapyTypeCode)}
                        </SubItem>
                        <SubItem>{treatment.typeOfSurgery}</SubItem>
                        <SubItem>{formatDateWithPrefix('Latest on', treatment.latestSurgeryDate)}</SubItem>
                        <SubItem>{treatment.otherValue}</SubItem>
                        <SubItem>{formatDateWithPrefix('On', treatment.otherDate)}</SubItem>
                    </Fragment>
                ))}
            </List>
        </View>
    ) : null;
}

type Resections = {
    answer: surgery.VascularResectionTypeDetail[];
    mapper: ReferenceDataMapper;
};

function ResectionAnswer(props: Resections) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <List>
                {props.answer.map((resectionType, i) => (
                    <Fragment key={i}>
                        <Item>{props.mapper('resectionTypes', resectionType.code)}</Item>
                        <SubItem>{props.mapper('arterialResectionTypes', resectionType.arterialResectionCode)}</SubItem>
                        <SubItem>{props.mapper('reconstructionTypes', resectionType.reconstructionCode)}</SubItem>
                    </Fragment>
                ))}
            </List>
        </View>
    ) : null;
}
