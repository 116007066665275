import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import Section from '../../../../../styles/components/forms/Section';

type PatientInsuranceSectionProps = {
    fundName: string;
    fundNr: string;
};
export default function PatientInsuranceSection(props: PatientInsuranceSectionProps) {
    return (
        <Section name="Insurance">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        label="Health insurance fund"
                        value={props.fundName ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Health insurance fund number"
                        value={props.fundNr ?? ''}
                        variant="outlined"
                        disabled
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </Section>
    );
}
