import React from 'react';
import ReactPDF, { Text, View } from '@react-pdf/renderer';
import List, { Item, SubItem } from './List';
import { format, parseJSON } from 'date-fns';
import { styles } from '../styles';
import { CodeAndOtherValue } from '../../../forms/MocForm.config';

export type QuestionProps = {
    question: string;
};

type QuestionAnswerProps = QuestionProps & {
    answer: string | number;
    overridingStyle?: ReactPDF.Style;
};

export function QuestionAnswer(props: QuestionAnswerProps) {
    return props.answer ? (
        <View>
            <Text style={styles.text}>
                {props.question}: <Text style={styles.answer}>{props.answer}</Text>
            </Text>
        </View>
    ) : null;
}
export function QuestionMocAnswer(props: QuestionAnswerProps) {
    return props.answer ? (
        <View>
            <Text style={props.overridingStyle ?? styles.text}>
                {props.question}: <Text style={props.overridingStyle ?? styles.answer}>{props.answer}</Text>
            </Text>
        </View>
    ) : (
        <View>
            <Text style={props.overridingStyle ?? styles.text}>{props.question}: /</Text>
        </View>
    );
}

export function QuestionMultiLineAnswer(props: QuestionAnswerProps) {
    return props.answer ? (
        <View>
            <Text style={styles.text}>{props.question}:</Text>
            <Text style={styles.multiLineAnswer}>{props.answer}</Text>
        </View>
    ) : (
        <View>
            <Text style={styles.text}>{props.question}:</Text>
        </View>
    );
}
export type QuestionYesNoAnswerProps = QuestionProps & {
    answer: boolean | '';
    overridingStyle?: ReactPDF.Style;
};

export function QuestionYesNoAnswer(props: QuestionYesNoAnswerProps) {
    return typeof props.answer === 'boolean' ? (
        <QuestionAnswer
            question={props.question}
            answer={props.answer ? 'Yes' : 'No'}
            overridingStyle={props.overridingStyle}
        />
    ) : null;
}

export function QuestionJaNeeMocAnswer(props: QuestionYesNoAnswerProps) {
    return typeof props.answer === 'boolean' ? (
        <QuestionMocAnswer
            question={props.question}
            answer={props.answer ? 'Ja' : 'Nee'}
            overridingStyle={props.overridingStyle}
        />
    ) : (
        <QuestionMocAnswer question={props.question} answer={'Nee'} overridingStyle={props.overridingStyle} />
    );
}
type QuestionJaNeeMetDateMocAnswerProps = QuestionYesNoAnswerProps & {
    date: string | null;
};
export function QuestionJaNeeMetDateMocAnswer(props: QuestionJaNeeMetDateMocAnswerProps) {
    return typeof props.answer === 'boolean' ? (
        <QuestionMocAnswer
            question={props.question}
            answer={props.answer ? `Ja ${formatDate(props.date) ?? ''}` : 'Nee'}
            overridingStyle={props.overridingStyle}
        />
    ) : (
        <QuestionMocAnswer question={props.question} answer={'Nee'} overridingStyle={props.overridingStyle} />
    );
}

type QuestionDateAnswerProps = QuestionProps & {
    answer: string | null;
    overridingStyle?: ReactPDF.Style;
};

export function QuestionDateAnswer(props: QuestionDateAnswerProps) {
    return props.answer ? <QuestionAnswer question={props.question} answer={formatDate(props.answer)!} /> : null;
}

export function QuestionMocDateAnswer(props: QuestionDateAnswerProps) {
    return props.answer ? (
        <QuestionMocAnswer
            question={props.question}
            answer={formatDate(props.answer)!}
            overridingStyle={props.overridingStyle}
        />
    ) : (
        <QuestionMocAnswer question={props.question} overridingStyle={props.overridingStyle} answer=" /" />
    );
}
type QuestionAnswerAndOtherProps = QuestionAnswerProps & {
    other: string;
};

export function QuestionAnswerAndOther(props: QuestionAnswerAndOtherProps) {
    const answer = props.other ? `${props.answer} - ${props.other}` : props.answer;
    return answer ? (
        <QuestionAnswer question={props.question} answer={answer} overridingStyle={props.overridingStyle} />
    ) : (
        <QuestionAnswer question={props.question} answer={''} overridingStyle={props.overridingStyle} />
    );
}

type QuestionAnswersProps = QuestionProps & {
    answers: string[];
};

export function QuestionAnswers(props: QuestionAnswersProps) {
    return <QuestionAnswer question={props.question} answer={props.answers.join(' - ')} />;
}

export function QuestionMocAnswerAndOther(props: QuestionAnswerAndOtherProps) {
    const answer = props.other ? `${props.answer} - ${props.other}` : props.answer;
    return <QuestionMocAnswer question={props.question} answer={answer} overridingStyle={props.overridingStyle} />;
}

type QuestionMocAnswerAndOtherWithUnitProps = QuestionAnswerAndOtherProps & {
    unit: string;
};
export function QuestionMocAnswerAndOtherWithUnit(props: QuestionMocAnswerAndOtherWithUnitProps) {
    const answer = props.other ? `${props.answer} ${props.other}${props.unit}` : `${props.answer}${props.unit}`;
    return <QuestionMocAnswer question={props.question} answer={answer} overridingStyle={props.overridingStyle} />;
}
type QuestionMocCodeAndOtherWithValueAndUnitProps = QuestionAnswerAndOtherProps & {
    value: string;
    valueOther: number | '';
    unit: string;
};
export function QuestionMocCodeAndOtherWithValueAndUnit(props: QuestionMocCodeAndOtherWithValueAndUnitProps) {
    const CodeFormatted = props.other ? `${props.answer} - ${props.other}` : props.answer;
    const valueFormatted = props.valueOther ? `${props.value} ${props.valueOther}` : props.value;

    const valueAndUnit = `${valueFormatted} ${props.unit}`;
    return (
        <QuestionMocAnswer
            question={`${CodeFormatted}`}
            answer={valueAndUnit}
            overridingStyle={props.overridingStyle}
        />
    );
}
type QuestionAnswerAndDateProps = QuestionAnswerProps & {
    date: string | null;
};

export function QuestionAnswerAndDate(props: QuestionAnswerAndDateProps) {
    const answer = props.date ? `${props.answer} on ${formatDate(props.date)}` : props.answer;
    return answer ? <QuestionAnswer question={props.question} answer={answer} /> : null;
}

type TypeAndDateAndValues = {
    type: string;
    date: string | null;
    value: number | '';
    unit: string;
    unitOther: string;
    comments: string;
};
export function TypeAndDateAndValues(props: TypeAndDateAndValues) {
    const formattedUnit = props.unitOther ? `${props.unit} - ${props.unitOther}` : props.unit;
    const comments = props.comments === null || props.comments === undefined ? '' : ` ${props.comments}.`;
    const formattedAnswer = `${props.type} d.d. ${formatDate(props.date)}: ${props.value} ${formattedUnit}.${comments}`;

    return <Text style={styles.indentedAnswer}>{formattedAnswer}</Text>;
}
export type QuestionYesNoAnswerAndDateProps = QuestionYesNoAnswerProps & {
    date: string | null;
};

export function QuestionYesNoAnswerAndDate(props: QuestionYesNoAnswerAndDateProps) {
    const answer = props.date
        ? `${props.answer ? 'Yes' : 'No'} on ${formatDate(props.date)}`
        : props.answer
        ? 'Yes'
        : 'No';
    return typeof props.answer === 'boolean' ? <QuestionAnswer question={props.question} answer={answer} /> : null;
}

type QuestionListCodeAndOtherValueAnswerProps = QuestionProps & {
    answer: CodeAndOtherValue[];
    overridingStyle?: ReactPDF.Style;
};

export function QuestionListCodeAndOtherValueAnswer(props: QuestionListCodeAndOtherValueAnswerProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={props.overridingStyle ?? styles.text}>{props.question}:</Text>
            <List>
                {props.answer.map((detail, i) => (
                    <Item key={i}>
                        {detail.otherValue !== '' ? `${detail.code} - ${detail.otherValue}` : `${detail.code}`}
                    </Item>
                ))}
            </List>
        </View>
    ) : null;
}

type QuestionListAnswerProps = QuestionProps & {
    answer: string[];
};

export function QuestionListAnswer(props: QuestionListAnswerProps) {
    return props.answer && props.answer.length !== 0 ? (
        <View>
            <Text style={styles.text}>{props.question}:</Text>
            <List>
                {props.answer.map((detail, i) => (
                    <Item key={i}>{detail}</Item>
                ))}
            </List>
        </View>
    ) : null;
}

export function QuestionListMocAnswer(props: QuestionListAnswerProps) {
    return (
        <View>
            <Text style={styles.text}>{props.question}:</Text>
            <List>
                {props.answer.map((detail, i) => (
                    <Item key={i}>{detail}</Item>
                ))}
            </List>
        </View>
    );
}

type QuestionSubItemAnswerProps = {
    answer: string;
    sublist: CodeAndOtherValue[];
};

type QuestionListWithSublistAnswerProps = QuestionProps & {
    answers: QuestionSubItemAnswerProps[];
};

export function QuestionListWithSublistAnswer(props: QuestionListWithSublistAnswerProps) {
    const getKeySublist = (i: number) => `sublist ${i}`;

    return (
        <View>
            <Text style={styles.text}>{props.question}:</Text>
            <List>
                {props.answers.map((detail, i) => (
                    <>
                        <Item key={i}>{detail.answer}</Item>
                        <List>
                            {detail.sublist.map((subItem, i) => (
                                <SubItem key={getKeySublist(i)}>
                                    {subItem.otherValue !== ''
                                        ? `${subItem.code} - ${subItem.otherValue}`
                                        : `${subItem.code}`}
                                </SubItem>
                            ))}
                        </List>
                    </>
                ))}
            </List>
        </View>
    );
}

function formatDateWithFormat(value: string | Date | null, formatString: string) {
    if (!value) {
        return null;
    }
    const date = parseJSON(value);
    const formatted = format(date, formatString);
    return formatted;
}

export function formatDate(value: string | Date | null) {
    return formatDateWithFormat(value, 'dd MMM yyyy');
}

export function formatDateTime(value: string | Date | null) {
    return formatDateWithFormat(value, 'dd MM yyyy HH:mm');
}

export function formatDateWithPrefix(prefix: string | null, startDate: string | Date | null) {
    const formattedStart = formatDate(startDate);
    return formattedStart ? `${prefix} ${formattedStart}` : '';
}
