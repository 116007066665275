import React from 'react';
import { Grid } from '@material-ui/core';
import { useResetOnValue, useConstant, useResetOnChange } from '../useResetEffect';
import { YesNoQuestion, FastSelectField, FastTextField, FastDateField, FileQuestion } from '../../components';
import { Mocs } from '../../../../../../api/types';

type Hospital = Mocs.GetReferenceData.Hospital;

const Referred: React.FC = () => {
    useResetOnValue(
        'generalInformation.referred',
        false,
        useConstant('generalInformation.referringHospitalCode', ''),
        useConstant('generalInformation.referringForeignHospital', ''),
        useConstant('generalInformation.mccmAtReferringHospital', ''),
        useConstant('generalInformation.mccmReferringHospitalDate', null),
        useConstant('generalInformation.hospitalizedAtReferringHospital', ''),
        useConstant('generalInformation.dateOfDischargeAtReferringHospital', null),
        useConstant('generalInformation.dateOfLastConsultationAtReferringHospital', null)
    );

    useResetOnChange(
        'generalInformation.referringHospitalCode',
        useConstant('generalInformation.referringForeignHospital', '')
    );

    useResetOnValue(
        'generalInformation.mccmAtReferringHospital',
        false,
        useConstant('generalInformation.mccmReferringHospitalDate', null)
    );

    useResetOnValue(
        'generalInformation.hospitalizedAtReferringHospital',
        false,
        useConstant('generalInformation.dateOfDischargeAtReferringHospital', null)
    );

    useResetOnValue(
        'generalInformation.hospitalizedAtReferringHospital',
        true,
        useConstant('generalInformation.dateOfLastConsultationAtReferringHospital', null)
    );

    const result = (
        <Grid item xs={12}>
            <YesNoQuestion name="generalInformation.referred" question="Was the patient referred?">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FastSelectField<Hospital>
                            name="generalInformation.referringHospitalCode"
                            placeholder="Referring hospital"
                            prompt="Select a hospital"
                            referenceDataPath="hospitals"
                            detail={({ isOther }) =>
                                isOther && (
                                    <FastTextField
                                        name="generalInformation.referringForeignHospital"
                                        label="Name of other hospital"
                                    />
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <YesNoQuestion
                            name="generalInformation.mccmAtReferringHospital"
                            question="Was there a MOC at the referring hospital?"
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <FastDateField name="generalInformation.mccmReferringHospitalDate" label="Date" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileQuestion type="MOC" question="Upload the MOC from the referring hospital." />
                                </Grid>
                            </Grid>
                        </YesNoQuestion>
                    </Grid>
                    <Grid item xs={12}>
                        <YesNoQuestion
                            variant="both"
                            name="generalInformation.hospitalizedAtReferringHospital"
                            question="Was the patient hospitalized at the referring hospital (before referral)?"
                            renderYes={() => (
                                <FastDateField
                                    name="generalInformation.dateOfDischargeAtReferringHospital"
                                    label="Date of discharge"
                                />
                            )}
                            renderNo={() => (
                                <FastDateField
                                    name="generalInformation.dateOfLastConsultationAtReferringHospital"
                                    label="Date of last consultation"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </YesNoQuestion>
        </Grid>
    );

    return result;
};

export default Referred;
