export type ActionObject = { action: string; name: string };

export function translateAction(action: string) {
    switch (action) {
        case 'AddPatient':
            return 'Patient - Add';
        case 'AddUser':
            return 'User - Add';
        case 'CreateConfirmLink':
            return 'User - Send Link';
        case 'EditPatient':
            return 'Patient - Edit';
        case 'RemoveUser':
            return 'User - Remove';
        case 'SaveMoc':
            return 'Moc - Save';
        case 'RemoveMoc':
            return 'Moc - Remove';
        case 'SetUserRole':
            return 'User - Change Role';
        case 'UploadFile':
            return 'File - Upload';
        default:
            return action;
    }
}

export function mapActions(actions: string[]): ActionObject[] {
    const mapped = actions.map(action => ({ action, name: translateAction(action) }));
    mapped.sort(({ name: name1 }, { name: name2 }) => name1.localeCompare(name2));
    return mapped;
}
