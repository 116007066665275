import React, { useState, useRef, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import classnames from 'classnames';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import { format, parseJSON } from 'date-fns';
import styles from './PatientSearchBox.module.scss';
import useApi from '../../api/useApi';
import { Patients } from '../../api/types';
import usePermissions from '../../auth/usePermissions';

type Patient = Patients.SearchPatients.Patient;
type Hospital = Patients.SearchPatients.Hospital;

type GetSuggestionValue = Autosuggest.GetSuggestionValue<Patient>;
type GetSectionSuggestions = Autosuggest.GetSectionSuggestions<Patient, Hospital>;
type RenderInputComponent = Autosuggest.RenderInputComponent<Patient>;
type RenderSuggestion = Autosuggest.RenderSuggestion<Patient>;
type RenderSectionTitle = (section: Hospital, canSearchMultipleHospitals: boolean) => React.ReactNode;
type RenderSuggestionsContainer = Autosuggest.RenderSuggestionsContainer;
type HandleChange = (e: React.ChangeEvent<HTMLInputElement>, change: Autosuggest.ChangeEvent) => void;
type HandleSuggestionsFetchRequested = Autosuggest.SuggestionsFetchRequested;
type HandleSuggestionsClearRequested = Autosuggest.OnSuggestionsClearRequested;
type HandleSuggestionSelected = Autosuggest.OnSuggestionSelected<Patient>;
type HandleSuggestionHighlighted = (params: { suggestion: Patient }) => void;

const showDate = (date: string | null) => (date ? format(parseJSON(date), 'dd MMM yyyy') : '');

const getSuggestionValue: GetSuggestionValue = (value) => {
    return value.name;
};

const renderInputComponent = (loading: boolean): RenderInputComponent => (props) => {
    const { onChange, onInputCapture, ...inputProps } = props;
    const handleChange = (e: React.ChangeEvent<{}>) => onChange(e, undefined as any);
    const size = inputProps.size as 'small' | 'medium' | undefined;
    const color = inputProps.color as 'primary' | 'secondary' | undefined;
    const textFieldProps = { ...inputProps, size, color };
    return (
        <TextField
            fullWidth
            margin="none"
            variant="outlined"
            placeholder="Search for patients ..."
            onChange={handleChange}
            classes={{
                root: styles.textfield,
            }}
            InputProps={{
                classes: {
                    input: styles.input,
                    focused: styles.input_focused,
                },
                endAdornment: loading && (
                    <InputAdornment position="end">
                        <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                ),
            }}
            {...textFieldProps}
        />
    );
};

const renderSuggestionsContainer = (anchorEl: HTMLElement | null | undefined): RenderSuggestionsContainer => ({
    children,
    containerProps,
}) => (
    <Popper anchorEl={anchorEl} open={Boolean(children)} style={{ zIndex: 2 }}>
        <Paper
            square
            {...containerProps}
            className={styles.suggestion_container}
            style={{ width: anchorEl ? anchorEl.clientWidth : undefined }}
        >
            {children}
        </Paper>
    </Popper>
);

const renderSuggestion = (highlighted: Patient | null): RenderSuggestion => ({ name, uzaNr, dateOfBirth }, params) => {
    const isHighlighted = highlighted && highlighted.uzaNr === uzaNr;
    return (
        <MenuItem component="div" className={classnames(styles.suggestion, isHighlighted && styles.highlighted)}>
            <div className={styles.row}>
                <Typography variant="body1" className={styles.name}>
                    {name}
                </Typography>
            </div>
            <div className={styles.row}>
                <Typography variant="body2" className={styles.dateofbirth}>
                    {showDate(dateOfBirth)}
                </Typography>
                <Typography variant="body2" className={styles.uzaNr}>
                    UZA {uzaNr}
                </Typography>
            </div>
        </MenuItem>
    );
};

const renderSectionTitle: RenderSectionTitle = ({ name }, canSearchMultipleHospitals) => {
    if (canSearchMultipleHospitals) {
        return <span className={styles.section_header}>{name}</span>;
    }
    return null;
};

const getSectionSuggestions: GetSectionSuggestions = (hospital) => {
    return hospital.patients;
};

type Props = {
    onPatientSelect: (id: number, hospitalSlug: string) => void;
};

const PatientSearchBox: React.FC<Props> = ({ onPatientSelect }) => {
    const anchorElRef = useRef<HTMLInputElement | null | undefined>(null);
    const api = useApi();
    const [value, setValue] = useState('');
    const [query, setQuery] = useState('');
    const [queryDebounced, setQueryDebounced] = useState('');
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<Hospital[]>([]);
    const [highlighted, setHighlighted] = useState<Patient | null>(null);
    const permissions = usePermissions();
    const { canSearchMultipleHospitals } = permissions;

    // 1. Whenever 'query' changes, debounce 500ms and then set queryDebounced
    useEffect(() => {
        const setQueryTimeout = setTimeout(() => setQueryDebounced(query), 400);
        return () => clearTimeout(setQueryTimeout);
    }, [query]);

    // 2. Whenever 'queryDebounced' changes, we can call the api
    useEffect(() => {
        if (!queryDebounced || !queryDebounced.trim()) {
            return;
        }

        if (queryDebounced.trim().length < 3) {
            setSuggestions([]);
            return;
        }

        setLoading(true);
        const get = async () => {
            const response = await api.get<Patients.SearchPatients.Response>(`patients/search?query=${queryDebounced}`);
            const { hospitals } = response.data;
            setSuggestions(hospitals);
            setLoading(false);
        };

        get();
    }, [queryDebounced, api]);

    const handleChange: HandleChange = (_, { newValue }) => {
        setValue(newValue);
    };

    const handleSelected: HandleSuggestionSelected = (_, { suggestion }) => {
        onPatientSelect(suggestion.id, suggestion.hospitalSlug);
        handleSuggestionsClearRequested();
    };

    const handleSuggestionHighlighted: HandleSuggestionHighlighted = ({ suggestion }) => {
        setHighlighted(suggestion);
    };

    const handleSuggestionsFetchRequested: HandleSuggestionsFetchRequested = ({ value }) => {
        setQuery(value);
    };

    const handleSuggestionsClearRequested: HandleSuggestionsClearRequested = () => {
        setSuggestions([]);
        setValue('');
        setQuery('');
        setQueryDebounced('');
    };

    return (
        <Autosuggest<Patient>
            multiSection
            theme={{
                container: styles.autosuggest,
                sectionTitle: styles.section_header,
            }}
            ref={(autosuggest) => (anchorElRef.current = autosuggest && autosuggest.input)}
            suggestions={suggestions}
            inputProps={{ value, onChange: handleChange }}
            getSuggestionValue={getSuggestionValue}
            onSuggestionHighlighted={handleSuggestionHighlighted}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            renderSectionTitle={(p) => renderSectionTitle(p, canSearchMultipleHospitals)}
            getSectionSuggestions={getSectionSuggestions}
            renderSuggestion={renderSuggestion(highlighted)}
            renderSuggestionsContainer={renderSuggestionsContainer(anchorElRef.current)}
            renderInputComponent={renderInputComponent(loading)}
            onSuggestionSelected={handleSelected}
        />
    );
};

export default PatientSearchBox;
