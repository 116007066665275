import React from 'react';
import { Button } from '@material-ui/core';
import BackIcon from '@material-ui/icons/KeyboardBackspace';
import styles from './HospitalBackButton.module.scss';
import { Hospital } from '../../../hospital/HospitalContext';

type Props = {
    hospital: Hospital;
    onGoToHospital: (h: Hospital) => void;
};

const HospitalBackButton: React.FC<Props> = ({ hospital, onGoToHospital }) => {
    const onNavigate = () => onGoToHospital(hospital);
    return (
        <Button className={styles.backbutton} onClick={onNavigate}>
            <BackIcon className={styles.icon} /> Back to {hospital.name}
        </Button>
    );
};

export default HospitalBackButton;
