import React, { useState, useEffect } from 'react';
import styles from './ExportPage.module.scss';
import { Paper, Typography, Container, Select, MenuItem, Grid } from '@material-ui/core';
import useApi from '../../api/useApi';
import qs from 'qs';
import OncoBaseSnackbar, { useSnackBar } from '../../styles/components/OncoBaseSnackbar';
import { getQuarter, format, parse, addDays, startOfDay } from 'date-fns';
import { Mocs } from '../../api/types';
import ExportTable from './ExportTable';

type Response = Mocs.Export.Response;
type MocDetail = Mocs.Export.MocDetail;
const startYear = 2019;
const currentYear = new Date().getFullYear();
const currentQuarter = getQuarter(new Date());
const mocTypes = ['CKZPancreas', 'CKZOesophagus'];
const quarters = [1, 3];
const surgeryEnds = ['31/12', '30/06'];
const mcEnds = [
    /*2019*/ ['31/01', '31/07'],
    /*2020*/ ['31/01', '31/07'],
    /*2021*/ ['31/01', '31/07'],
    /*2022*/ ['31/01', '30/06'],
    /*2023*/ ['31/01', '31/07'],
    /*2024*/ ['31/01', '31/07'],
    /*2025*/ ['31/01', '31/07'],
    /*2026*/ ['31/01', '31/07'],
];
const years = Array(currentYear - startYear + 1)
    .fill(1)
    .map((_, index) => startYear + index);
const ExportPage: React.FC = () => {
    const api = useApi();
    const [showSnack, snackbarProps] = useSnackBar();
    const [isExporting, setIsExporting] = useState(false);

    const [mocType, setMocType] = useState<string>('empty');
    const [year, setYear] = useState<number>(currentYear);
    const [quarter, setQuarter] = useState<number>(currentQuarter < 3 ? 1 : 3);
    const [mocDetails, setMocDetails] = useState<MocDetail[]>();
    const selectedPeriodSurgeryEndDate = parse(
        `${surgeryEnds[quarter === 3 ? 1 : 0]}/${quarter === 1 ? year - 1 : year}`,
        'dd/MM/yyyy',
        new Date()
    );

    const selectedPeriodMcEndDate = parse(
        `${mcEnds[year - startYear][quarter === 3 ? 1 : 0]}/${year}`,
        'dd/MM/yyyy',
        new Date()
    );

    const selectedPeriod = `Selected period: Surgery till ${format(
        selectedPeriodSurgeryEndDate,
        'd MMMM yyyy'
    )} + MC till ${format(selectedPeriodMcEndDate, 'd MMMM yyyy')}`;

    const handleMocTypeChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== undefined) {
            setMocType(event.target.value as string);
        } else {
            setMocType('empty');
        }
    };

    const handleYearChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        let yearToSelect = currentYear;
        if (event.target.value !== undefined) {
            yearToSelect = event.target.value as number;
        }
        setYear(yearToSelect);
        if (yearToSelect === currentYear && quarter > currentQuarter) setQuarter(currentQuarter);
    };

    const handleQuarterChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== undefined) {
            setQuarter(event.target.value as number);
        } else {
            setQuarter(currentQuarter);
        }
    };

    const isValidRequest = mocType !== null && mocType !== 'empty' && year !== null && quarter !== null;
    const isValidDownloadRequest =
        isValidRequest && mocDetails !== undefined && mocDetails!.findIndex((m) => m.export) !== -1;

    const handleDownloadFile = async () => {
        setIsExporting(true);

        try{
            const mocIdsToExport = mocDetails!.filter((m) => m.export).map((m) => m.id);
            const downloadKey = await createFileToDownload(mocIdsToExport);
            await getFileToDownload(downloadKey);
            await handleSetMocsExported(mocIdsToExport);
            await getMocDetails();
        }
        catch {
            showSnack("Couldn't download export file", 'error');
        }

        setIsExporting(false);
    };

    const createFileToDownload = async (mocIdsToExport: number[]) => {
        const {data, status} = await api.post<Mocs.CreateExportReport.Response>(`mocs/export/download`, {
            mocTypeCode: mocType,
            mocIdsToExport: mocIdsToExport,
            excelSheetName: `ComplexSurgery_${mocType.replace('CKZ', '')}`,
        } as Mocs.CreateExportReport.Request);
        
        if (status >= 400) {
            throw new Error();
        }

        return data.downloadKey;
    };

    const getFileToDownload = async (downloadKey: string) => {
        const urlGet = `mocs/export/download?${qs.stringify({
            excelFileName: `ComplexSurgery_${mocType.replace('CKZ', '')}_${year}_Q${quarter}.xlsx`,
            KeyFileToDownload: `${downloadKey}`,
        })}`;

        var response = await api.get(urlGet, { responseType: 'blob' });
        if (response.status >= 400) {
            throw new Error();
        }
        
        downLoadFile(response.data, response.headers['content-type'], response.headers['filename']);
    };

    const handleSetMocsExported = async (mocIdsToExport: number[]) => {
        var result = await api.post(`mocs/export/download/commit`, {
            mocIds: mocIdsToExport,
            exportReference: `${mocType}_${year}Q${quarter}_${new Date().getTime()}`,
        } as Mocs.SetExported.Request);

        if (result.status >= 400) { 
            throw new Error();
        } 
    };

    const downLoadFile = (data: any, type: string, filename: string) => {
        const blob = new Blob([data], { type });

        //found on https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, filename);
        } else {
            const downloadURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadURL;
            link.download = filename;
            link.click();
        }
    };

    const getMocDetails = async () => {
        const url = `mocs/export?${qs.stringify({
            mocTypeCode: mocType,
            surgeryEndDate: format(startOfDay(addDays(selectedPeriodSurgeryEndDate, 1)), 'yyyy/MM/dd'),
            mocEndDate: format(startOfDay(addDays(selectedPeriodMcEndDate, 1)), 'yyyy/MM/dd'),
        })}`;
        const { data } = await api.get<Response>(url);
        setMocDetails(data.details);
    };

    const handleCheckExport = (id: number, checked: boolean) => {
        const updatedMocDetails = [...mocDetails!];
        const checkedMocIndex = mocDetails!.findIndex((m) => m.id === id);
        updatedMocDetails![checkedMocIndex].export = checked;
        setMocDetails(updatedMocDetails);
    };

    useEffect(() => {
        if (mocType !== 'empty') {
            getMocDetails();
        }
        // eslint-disable-next-line
    }, [mocType, year, quarter]);

    return (
        <Container component="main">
            <Paper className={styles.paper}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Export CKZ Data</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={5}>
                            <Select value={mocType} fullWidth={true} variant="outlined" onChange={handleMocTypeChanged} disabled={isExporting}>
                                <MenuItem key="empty" value="empty" disabled>
                                    <i>Please select a CKZ type</i>
                                </MenuItem>
                                {mocTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2}>
                                <Select value={year} fullWidth={true} variant="outlined" onChange={handleYearChanged} disabled={isExporting}>
                                    {years.map((year) => (
                                        <MenuItem key={year} value={year}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={2}>
                                <Select
                                    value={quarter}
                                    fullWidth={true}
                                    variant="outlined"
                                    onChange={handleQuarterChanged}
                                    disabled={isExporting}
                                >
                                    {quarters.map((quarter) => (
                                        <MenuItem
                                            key={quarter}
                                            value={quarter}
                                            disabled={year === currentYear && quarter > currentQuarter}
                                        >
                                            Q{quarter}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={8}>
                                {selectedPeriod}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {isValidRequest && mocDetails && (
                    <ExportTable
                        mocDetails={mocDetails}
                        isValidDownloadRequest={isValidDownloadRequest}
                        onCheckExportMoc={handleCheckExport}
                        onDownloadExportFile={handleDownloadFile}
                        isExporting={isExporting}
                    />
                )}
                <OncoBaseSnackbar {...snackbarProps} />
            </Paper>
        </Container>
    );
};

export default ExportPage;
