import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'formik';
import FormikTextField from '../../../../../../styles/components/forms/FormikTextField';

export default function OtherMedicalInfo() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={6} md={5}>
                <Field
                    name="homeDoctorName"
                    type="text"
                    component={FormikTextField}
                    label="Name general practitioner"
                />
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    name="homeDoctorContactInfo"
                    type="text"
                    component={FormikTextField}
                    label="Contact general practitioner"
                />
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    name="attendingDoctorName"
                    type="text"
                    component={FormikTextField}
                    label="Name treating physician"
                />
            </Grid>
            <Grid item xs={6} md={5}>
                <Field
                    name="attendingDoctorContactInfo"
                    type="text"
                    component={FormikTextField}
                    label="Contact treating physician"
                />
            </Grid>
        </Grid>
    );
}
