import React from 'react';
import { Grid } from '@material-ui/core';
import FormikTextArea from '../../../../../../styles/components/forms/FormikTextArea';
import MocSectionContainer from '../../sections/MocSectionContainer';
import { FastField } from '../../Fast';

type Props = {
    isValid: boolean | null;
};

const GeneralRemarks: React.FC<Props> = React.memo(({ isValid }) => {
    return (
        <MocSectionContainer code="general-remarks" isValid={isValid}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <FastField name="generalRemarks.remarks" type="text" component={FormikTextArea} label="Remarks" />
                </Grid>
            </Grid>
        </MocSectionContainer>
    );
});

export default GeneralRemarks;
