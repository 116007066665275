import * as Yup from 'yup';
import { format, addHours } from 'date-fns';
import { Patients } from '../../../../api/types';
import { defaultGender } from './GenderDefaultValue.config';

type Fund = Patients.GetHealthInsuranceFunds.HealthInsuranceFund;

export type Values = {
    ssn: string;
    uzaNr: string;
    firstName: string;
    surname: string;
    address: string;
    postalCode: string;
    city: string;
    healthInsuranceFundNr: string;
    healthInsuranceFundId: number | null;
    countryId: number | null;
    gender: number;
    dateOfBirth: string | null;
    dateOfDeath: string | null;
    localPatientNr: string | null;
    telNr: string | null;
    mail: string | null;
    homeDoctorName: string | null;
    homeDoctorContactInfo: string | null;
    attendingDoctorName: string | null;
    attendingDoctorContactInfo: string | null;
};

export function validateForm(funds: Fund[] | null, otherPatientNumbers: string[] | null) {
    return Yup.object().shape(
        {
            ssn: Yup.string().test(
                'ssn-numbers',
                'SSN can contain dashes, dots and up to 11 numbers',
                (value) =>
                    !value || (value.replace(/[-.\d]/g, '').length === 0 && value.replace(/[^\d]/g, '').length <= 11)
            ),
            uzaNr: Yup.string()
                .when('localPatientNr', {
                    is: (localPatientNr: string) => !localPatientNr,
                    then: Yup.string().required(),
                })
                .test('len', 'The number must be exactly 9 digits', (val) => (val + '').length === 9)
                .notOneOf(
                    (otherPatientNumbers || []).map((nr) => parseInt(nr, 10)),
                    'This patient was already registered'
                )
                .nullable(),
            localPatientNr: Yup.string()
                .when('uzaNr', {
                    is: (uzaNr: string) => !uzaNr,
                    then: Yup.string().required(),
                })
                .nullable(),
            firstName: Yup.string().required('First name is a required field'),
            surname: Yup.string().required('Surname is a required field'),
            address: Yup.string().required('Adress is a required field'),
            postalCode: Yup.string().required('Postal code is a required field'),
            city: Yup.string().required('City is a required field'),
            healthInsuranceFundNr: Yup.string()
                .nullable()
                .when('healthInsuranceFundId', (healthInsuranceFundId: number, validationSchema: any) => {
                    return funds &&
                        funds.find((f) => f.id === healthInsuranceFundId) &&
                        funds.find((f) => f.id === healthInsuranceFundId)!.isOther
                        ? validationSchema
                        : validationSchema.required('Health Insurance Fund number is a required field');
                }),
            healthInsuranceFundId: Yup.number()
                .required('Please select a Health Insurance Fund')
                .moreThan(0, 'Please select a Health Insurance Fund'),
            countryId: Yup.number().required('Please select a Country').moreThan(0, 'Please select a Country'),
            gender: Yup.number().required('Please select a Gender').lessThan(defaultGender, 'Please select a Gender'),
            dateOfBirth: Yup.date().required('Date of birth is a required field'),
            dateOfDeath: Yup.date().nullable(),
            telNr: Yup.string().nullable(),
            mail: Yup.string().nullable(),
            homeDoctorFirstName: Yup.string().nullable(),
            homeDoctorLastName: Yup.string().nullable(),
            homeDoctorContactInfo: Yup.string().nullable(),
            attendingDoctorFirstName: Yup.string().nullable(),
            attendingDoctorLastName: Yup.string().nullable(),
            attendingDoctorContactInfo: Yup.string().nullable(),
        },
        [['uzaNr', 'localPatientNr']]
    );
}

export const initialValues: Values = {
    ssn: '',
    uzaNr: '',
    firstName: '',
    surname: '',
    address: '',
    postalCode: '',
    city: '',
    healthInsuranceFundNr: '',
    healthInsuranceFundId: 0,
    countryId: 22,
    gender: 2,
    dateOfBirth: null,
    localPatientNr: '',
    dateOfDeath: null,
    telNr: '',
    mail: '',
    homeDoctorName: '',
    homeDoctorContactInfo: '',
    attendingDoctorName: '',
    attendingDoctorContactInfo: '',
};

export function convertDateToWorkWithDatePicker(date: string | null) {
    if (date === null) return null;
    return addHours(new Date(date), 12).toISOString();
}

export function valuesToPostRequest(values: Values) {
    return {
        ...values,
        dateOfBirth: toUtc(values.dateOfBirth),
        dateOfDeath: values.dateOfDeath ? toUtc(values.dateOfDeath) : null,
    };
}

function toUtc(date: string | null) {
    if (date === null) return '';
    return format(new Date(date), 'yyyy-MM-dd');
}
