import React from 'react';
import { Grid, FormLabel } from '@material-ui/core';
import styles from './Question.module.scss';

type Props = {
    question: string;
    'children-inline'?: boolean;
};

const Question: React.FC<Props> = ({ question, children, 'children-inline': childrenInline }) => (
    <Grid container spacing={1} className={styles.question}>
        <Grid item xs={12} className={styles.question_text}>
            <FormLabel component="legend" className={styles.label}>
                {question}
            </FormLabel>
            {childrenInline && children}
        </Grid>
        {!childrenInline && children && (
            <Grid item xs={12}>
                {children}
            </Grid>
        )}
    </Grid>
);

export default Question;
